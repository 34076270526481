import { Box, Stack } from '@mui/material';
import { Button, Tooltip, Typography } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp, Close } from '@sweep-io/sweep-design/dist/icons';
import { ReactNode, MouseEvent } from 'react';
import { TruncatedTextTooltip } from './TruncatedTextTooltip';

interface FilterButtonsProps {
  isPressed: boolean;
  text: string;
  preText?: ReactNode;
  title?: string;
  tooltip?: string;
  handleClick: (event: MouseEvent<HTMLElement>) => any;
  showClearBtn?: boolean;
  onClearBtn?: () => any;
  disabled?: boolean;
  dataTestId?: string;
}

export const FilterButton = ({
  handleClick,
  isPressed,
  text,
  title,
  tooltip,
  showClearBtn,
  onClearBtn,
  disabled,
  preText,
  dataTestId,
}: FilterButtonsProps) => {
  return (
    <Stack gap={0.5} direction="row" alignItems="center" minWidth={0}>
      {title && (
        <Typography variant="body" whiteSpace="nowrap">
          {title}:
        </Typography>
      )}
      <Box
        sx={{
          height: '24px', //TODO check if need to add height to the button in the design system
          display: 'inline-flex',
          minWidth: 0,
        }}
      >
        <Button
          variant="outlined"
          size="tiny"
          onClick={handleClick}
          pressed={isPressed}
          disabled={disabled}
        >
          <Box sx={{ display: 'inline-flex', alignItems: 'center', minWidth: 0 }}>
            <Tooltip title={tooltip} placement="top">
              <Box
                component="span"
                sx={{
                  display: 'flex',
                  gap: 0.5,
                  alignItems: 'center',
                  overflow: 'hidden',
                  minWidth: 0,
                }}
                data-testid={dataTestId}
              >
                {preText}
                <TruncatedTextTooltip variant="caption-medium">{text}</TruncatedTextTooltip>
              </Box>
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', pl: 0.5, alignItems: 'baseline' }}>
            {isPressed && !showClearBtn && <ChevronUp />}
            {!isPressed && !showClearBtn && <ChevronDown />}
          </Box>
          {showClearBtn && (
            <Box
              sx={{ display: 'flex', pl: 0.5, alignItems: 'baseline' }}
              onClick={(e) => {
                e.stopPropagation();
                onClearBtn && onClearBtn();
              }}
            >
              <Close />
            </Box>
          )}
        </Button>
      </Box>
    </Stack>
  );
};
