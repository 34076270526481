import { useCallback, useContext, useMemo } from 'react';
import { OnNodeClickProps, NodeEntityTypes } from '../../multi-canvas/canvasTypes';
import { FunnelMapCanvasInternalCtx } from '../FunnelMapCanvasInternalCtx';
import { nodeIdToRecordTypePicklistFullName } from '../helper';
import { UseEventHandlerReturn } from './types';

export const useRecordTypeEventsHandler = (): UseEventHandlerReturn => {
  const {
    onGhostRecordTypeStepButtonClick,
    onRecordTypeStepClick,
    onRecordTypeLabelClick,
    onRecordTypeEditButtonClick,
    onRecordTypeLabelPillClick,
  } = useContext(FunnelMapCanvasInternalCtx);

  const onNodeClick = useCallback(
    ({ id, parentId, entity, event }: OnNodeClickProps) => {
      switch (entity.type) {
        case NodeEntityTypes.GhostStepButton: {
          const [recordTypeId] = id.split('-');
          onGhostRecordTypeStepButtonClick?.({
            recordTypeId,
            event,
          });
          break;
        }
        case NodeEntityTypes.Step:
        case NodeEntityTypes.StepPill: {
          if (!parentId) {
            throw new Error('parentId was not found');
          }
          onRecordTypeStepClick?.({
            stepId: id,
            recordTypePicklistFullName: nodeIdToRecordTypePicklistFullName(id),
            recordTypeApiName: parentId,
            entity,
            event,
          });

          break;
        }
        case NodeEntityTypes.GroupLabel:
          onRecordTypeLabelClick?.({ recordTypeApiName: id, entity, event });
          break;
        case NodeEntityTypes.GroupEditButton:
          onRecordTypeEditButtonClick?.({ recordTypeId: id, event });
          break;
      }
    },
    [
      onGhostRecordTypeStepButtonClick,
      onRecordTypeEditButtonClick,
      onRecordTypeLabelClick,
      onRecordTypeStepClick,
    ],
  );

  const onPillClick = useCallback(
    ({ id, parentId, entity, event }: OnNodeClickProps) => {
      switch (entity.type) {
        case NodeEntityTypes.StepPill: {
          if (!parentId) {
            throw new Error('parentId was not found');
          }

          onRecordTypeStepClick?.({
            stepId: id,
            recordTypePicklistFullName: nodeIdToRecordTypePicklistFullName(id),
            recordTypeApiName: parentId,
            entity,
            event,
          });
          break;
        }
        case NodeEntityTypes.GroupPill: {
          onRecordTypeLabelPillClick?.({ recordTypeApiName: id, entity, event });
          break;
        }
      }
    },
    [onRecordTypeLabelPillClick, onRecordTypeStepClick],
  );

  const onLabelClick = useCallback(
    ({ id, entity, event }: OnNodeClickProps) => {
      switch (entity.type) {
        case NodeEntityTypes.GroupLabel:
          onRecordTypeLabelClick?.({ recordTypeApiName: id, entity, event });
          break;
        default:
          break;
      }
    },
    [onRecordTypeLabelClick],
  );

  const returnFunctions = useMemo(
    () => ({ onNodeClick, onPillClick, onLabelClick }),
    [onNodeClick, onPillClick, onLabelClick],
  );

  return returnFunctions;
};
