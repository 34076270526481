import { Box } from '@mui/material';
import { TitleWithSubtitle } from './atoms/TitleWithSubtitle';
import { BackButton } from './atoms/BackButton';
import { ChatIcon } from './atoms/ChatIcon';
import { ConfigurationType } from '../types';
import { InitialsAvatar } from '../../shared/InitialsAvatar';
import { useDependencies } from '../hooks/useDependencies';
import { ButtonWithActionMenu } from './atoms/ButtonWithActionMenu';
import { Panel } from './atoms/Panel';
import { MiddlePanel } from './atoms/MiddlePanel';
import { ConfigurationItem } from '../DependenciesTypes';

interface DependenciesHeaderProps {
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
  crmOrgId: string;
  historyLength?: number;
}

export const DependenciesHeader = ({
  configurationItem,
  type,
  crmOrgId,
  historyLength,
}: DependenciesHeaderProps) => {
  const { onGoBackInHistory } = useDependencies(crmOrgId);
  const { annotations } = configurationItem ?? {};
  const { changedBy, changedAt } = annotations ?? {};

  return (
    <Panel>
      {historyLength && historyLength > 1 && <BackButton onClick={onGoBackInHistory} />}

      <Box flex={1} display="flex" justifyContent="space-between" alignItems="flex-start">
        <TitleWithSubtitle
          configurationItem={configurationItem}
          crmOrgId={crmOrgId}
          parentType={type}
        />

        <MiddlePanel>
          {changedBy && <InitialsAvatar changedAt={changedAt} userIdOrName={changedBy} />}
          <ChatIcon crmOrgId={crmOrgId} />
          <ButtonWithActionMenu configurationItem={configurationItem} crmOrgId={crmOrgId} />
        </MiddlePanel>
      </Box>
    </Panel>
  );
};
