import { Box, PopoverOrigin } from '@mui/material';
import { useCallback, useState } from 'react';
import {
  AdvancedFilterPopover,
  AdvancedFilterPopoverTexts,
  defaultTexts as defaultPopoverTexts,
} from './AdvancedFilterPopover';
import { FilterButton__deprecated } from '../FilterButton_deprecated';
import { Badge, Button, IconButton } from '@sweep-io/sweep-design';
import { FilterLines } from '@sweep-io/sweep-design/dist/icons';
import { useFeatureToggle } from '../useFeatureToggle';
import { FilterButton } from '../FilterButton';

interface AdvancedFilterTexts extends AdvancedFilterPopoverTexts {
  allSelected: string;
}

const defaultTexts: AdvancedFilterTexts = {
  ...defaultPopoverTexts,
  allSelected: 'All',
};

export type AdvancedFilterItemValue<DataT = any> = {
  value: string;
  label: string;
  data?: DataT;
  isSectionItem?: boolean; // Non selectable item used as a section
};
export interface AdvancedFilterItem<DataT = any> extends AdvancedFilterItemValue<DataT> {
  labelDecoration?: React.ReactNode;
}

export interface AdvancedFilterProps<DataT = any> {
  items: AdvancedFilterItem<DataT>[];
  selectedItems: string[];
  onSelectedItemsChange: (items: string[], itemsData: AdvancedFilterItemValue<DataT>[]) => void;
  texts?: Partial<AdvancedFilterTexts>;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disabled?: boolean;
  onlyIcon?: boolean;
  hideLabels?: boolean;
  renderSeparateClearButton?: boolean;
  filterButtonDataTestId?: string;
}

export type AdvancedFilterRef = {
  clear: () => void;
};

export function itemToItemValue<DataT = any>(
  item: AdvancedFilterItem<DataT>,
): AdvancedFilterItemValue<DataT> {
  return {
    value: item.value,
    label: item.label,
    data: item.data,
  };
}

export const AdvancedFilter = function <DataT = any>({
  items,
  selectedItems,
  onSelectedItemsChange,
  texts,
  anchorOrigin,
  transformOrigin,
  disabled,
  onlyIcon,
  hideLabels,
  filterButtonDataTestId,
  renderSeparateClearButton,
}: AdvancedFilterProps<DataT>) {
  const { newDocumentationUiFilters } = useFeatureToggle();
  const _texts: AdvancedFilterTexts = {
    ...defaultTexts,
    ...texts,
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  let filterButtonText = _texts.allSelected;
  let filterButtonTooltip = '';

  const hasSelectedItems = selectedItems.length > 0;
  const allSelected = selectedItems.length === items.length;
  if (hasSelectedItems) {
    if (allSelected) {
      filterButtonText = _texts.allSelected;
    } else {
      if (selectedItems.length > 0) {
        const firstSelectedItem = items.find((item) => item.value === selectedItems[0]);
        filterButtonText = firstSelectedItem?.label || '';

        if (selectedItems.length > 1) {
          filterButtonText += ` and ${selectedItems.length - 1} more`;

          const chosenItems = items
            .filter((item) => selectedItems.includes(item.value))
            ?.map((item) => item.label);
          filterButtonTooltip = chosenItems?.splice(1).join(', ');
        }
      } else {
        filterButtonText = _texts.allSelected;
      }
    }
  }

  const clearAll = useCallback(() => {
    onSelectedItemsChange([], []);
  }, [onSelectedItemsChange]);

  const showClearButton = hasSelectedItems && !allSelected;

  return (
    <Box
      className="advanced-filter"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={1.5}
    >
      <Box sx={{ display: 'flex', minWidth: 0 }}>
        {onlyIcon && (
          <IconButton onClick={handleClick} variant="outlined">
            <>
              <FilterLines variant="large" />
              {showClearButton && !newDocumentationUiFilters && !renderSeparateClearButton && (
                <Box sx={{ position: 'absolute', top: '-5px', right: '-12px' }}>
                  <Badge variant="destructive" />
                </Box>
              )}
            </>
          </IconButton>
        )}
        {!onlyIcon && !newDocumentationUiFilters && (
          <FilterButton__deprecated
            dataTestId={filterButtonDataTestId}
            isPressed={Boolean(anchorEl)}
            handleClick={handleClick}
            text={filterButtonText}
            showClearBtn={showClearButton}
            onClearBtn={clearAll}
            title={texts?.title ?? ''}
            tooltip={filterButtonTooltip}
            disabled={disabled}
          />
        )}

        {!onlyIcon && newDocumentationUiFilters && (
          <FilterButton
            dataTestId={filterButtonDataTestId}
            isPressed={Boolean(anchorEl)}
            handleClick={handleClick}
            text={filterButtonText}
            showClearBtn={showClearButton && !renderSeparateClearButton}
            onClearBtn={clearAll}
            tooltip={filterButtonTooltip}
            title={texts?.title ?? ''}
            disabled={disabled}
          />
        )}

        <AdvancedFilterPopover
          items={items}
          selectedItems={selectedItems}
          anchorEl={anchorEl}
          onSelectedItemsChange={onSelectedItemsChange}
          onClose={() => setAnchorEl(null)}
          texts={texts}
          transformOrigin={transformOrigin}
          anchorOrigin={anchorOrigin}
          hideLabels={hideLabels}
        />
      </Box>

      {newDocumentationUiFilters && showClearButton && renderSeparateClearButton && (
        <Button variant="flat" size="small" startIconName="Close" onClick={() => clearAll()}>
          Clear
        </Button>
      )}
    </Box>
  );
};
