import { Box, Popover, Stack } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';

interface AdvancedFiltersPanelProps {
  children: ReactNode;
  onClearAll?: () => void;
  displayClearAll?: boolean;
}

export const AdvancedFiltersPanel = ({
  children,
  onClearAll,
  displayClearAll,
}: AdvancedFiltersPanelProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const [buttonPosition, setButtonPosition] = useState<DOMRect>();
  const buttonRef = useRef<HTMLDivElement>();

  const handleClick = useCallback(() => {
    // Capture the button's position on the first click
    const buttonRect = buttonRef?.current?.getBoundingClientRect();
    !buttonPosition && setButtonPosition(buttonRect);

    setAnchorEl(buttonRef?.current);
  }, [buttonPosition]);

  return (
    <Stack>
      <Box display="flex" gap={1} ref={buttonRef}>
        <Button
          startIconName="Filter"
          size="tiny"
          variant="outlined"
          pressed={Boolean(anchorEl)}
          onClick={handleClick}
        >
          Filter
          {displayClearAll && (
            <Box
              sx={{ display: 'flex', pl: 0.5, alignItems: 'baseline' }}
              onClick={() => onClearAll && onClearAll()}
            >
              <Close variant="tiny" />
            </Box>
          )}
        </Button>
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        //design team requested that popover stays in the first appearance position, code below covers that
        style={{
          top: 0,
          left: buttonPosition ? buttonPosition.left + window.scrollX - 15 : 'auto',
        }}
      >
        <Stack
          width="500px"
          p={2}
          pt={1.5}
          sx={{
            '& .canvas-filters-wrapper': {
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              '& .advanced-filter, & .single-filter': {
                p: 2,
                borderRadius: '8px',
                border: `1px solid ${colors.grey[200]}`,
              },
            },
          }}
        >
          <Typography variant="h3">Filters</Typography>
          {children}
        </Stack>
      </Popover>
    </Stack>
  );
};
