import { validateSweepCriteria } from '../../../../common/rule-builder/validateSweepCriteria';
import {
  WorkflowAction,
  WorkflowActionEditRecordDto,
  WorkflowActionEditRecordProperty,
  WorkflowActionEnrollCampaignDto,
  WorkflowActionType,
  WorkflowBaseDto,
  WorkflowWhenFiltersType,
  WorkflowWhenType,
} from '../types';

const validateEnrollCampaignAction = (action: WorkflowActionEnrollCampaignDto) => {
  if (!action.status) return false;
  if (!action.campaignId) return false;

  return true;
};

const validateEditRecordActionProperty = (property: WorkflowActionEditRecordProperty) => {
  if (!property._id) return false;
  if (!property.name) return false;
  if (!property.value) return false;
  return true;
};

const validateEditRecordAction = (action: WorkflowActionEditRecordDto) => {
  if (!action.condition) return true;
  if (action.condition.filterType !== WorkflowWhenFiltersType.PROPERTY) return false;
  if (action.condition.whenType !== WorkflowWhenType.FILTERS) return false;
  if (action.condition.filter) {
    const errors = validateSweepCriteria(action.condition.filter.criteria, false, true);
    if (errors.length > 0) return false;
  }

  if (!action.properties) return false;
  if (action.properties.length < 1) return false;

  return action.properties.every(validateEditRecordActionProperty);
};

const validateWorkflowAction = (action: WorkflowAction) => {
  switch (action.actionType) {
    case WorkflowActionType.ENROLL_CAMPAIGN:
      return validateEnrollCampaignAction(action);
    case WorkflowActionType.EDIT_RECORD:
      return validateEditRecordAction(action);
    default:
      return false;
  }
};

export const validateWorkflowForm = (workflow: DeepPartial<WorkflowBaseDto>) => {
  if (!workflow) return false;
  if (!workflow.name) return false;
  if (!workflow.objectName) return false;

  if (!workflow.workflowDetails) return false;
  if (!workflow.workflowDetails.actions) return false;
  if (workflow.workflowDetails.actions.length < 1) return false;

  if (!workflow.workflowDetails.when) return false;
  if (!workflow.workflowDetails.when.whenType) return false;
  if (
    workflow.workflowDetails.when.whenType === WorkflowWhenType.EVENT &&
    !workflow.workflowDetails.when.eventType
  )
    return false;

  // At least one action is required
  if (workflow.workflowDetails.actions.length < 1) return false;

  const [firstAction, ...allOtherActions] = workflow.workflowDetails.actions as WorkflowAction[];

  // First action must be ENROLL_CAMPAIGN
  if (firstAction.actionType !== WorkflowActionType.ENROLL_CAMPAIGN) return false;

  // All other actions must be EDIT_RECORD
  if (!allOtherActions.every((action) => action.actionType === WorkflowActionType.EDIT_RECORD)) {
    return false;
  }

  // Validates all actions
  if (
    !workflow.workflowDetails.actions
      .map((action) => validateWorkflowAction(action as WorkflowAction))
      .every((isValid) => isValid)
  ) {
    return false;
  }

  if (
    workflow.workflowDetails.when.whenType === WorkflowWhenType.FILTERS &&
    !workflow.workflowDetails.when.filter?.criteria?.length
  ) {
    return false;
  }
  const errors = validateSweepCriteria(
    workflow.workflowDetails.when.filter?.criteria as SweepCriterion[],
    workflow.workflowDetails.when.whenType === WorkflowWhenType.EVENT,
    true,
  );

  const isValid = errors.length === 0;

  if (!isValid) return false;

  return true;
};
