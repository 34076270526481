import * as React from "react";
const SvgChatProcessIcon = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 18 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 6, cy: 7, r: 6, fill: "#C7ADFF", style: {
  fill: "#C7ADFF",
  fill: "color(display-p3 0.7818 0.6792 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.65609 4.52558C2.79782 4.22738 3.04206 3.99008 3.34423 3.85701L5.44553 2.93167C5.79881 2.7761 6.20119 2.7761 6.55447 2.93167L8.65577 3.85701C8.95794 3.99008 9.20218 4.22738 9.34391 4.52559L10.2193 6.36766C10.3969 6.74133 10.3969 7.17508 10.2193 7.54875L9.34391 9.39082C9.20218 9.68903 8.95794 9.92632 8.65577 10.0594L6.55447 10.9847C6.20119 11.1403 5.79881 11.1403 5.44553 10.9847L3.34423 10.0594C3.04206 9.92632 2.79782 9.68902 2.65609 9.39082L1.78066 7.54875C1.60307 7.17508 1.60307 6.74133 1.78066 6.36766L2.65609 4.52558Z", fill: "#EBDEFF", style: {
  fill: "#EBDEFF",
  fill: "color(display-p3 0.9235 0.8708 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 4.5, y: 6.5, width: 13, height: 13, rx: 3.5, fill: "#CBD5DB", style: {
  fill: "#CBD5DB",
  fill: "color(display-p3 0.7961 0.8351 0.8577)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 4.5, y: 6.5, width: 13, height: 13, rx: 3.5, stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.0001 16.6C11.7201 16.6 12.6801 16.12 12.6801 16.12L14.2988 16.6C14.3385 16.6005 14.3779 16.5931 14.4147 16.5781C14.4515 16.5632 14.4849 16.541 14.513 16.5129C14.5411 16.4848 14.5633 16.4514 14.5782 16.4146C14.5932 16.3778 14.6006 16.3384 14.6001 16.2987L14.1201 15.16C14.5782 14.3777 14.6001 13 14.6001 13C14.6001 12.0452 14.2208 11.1296 13.5457 10.4544C12.8705 9.77931 11.9549 9.40002 11.0001 9.40002C10.5273 9.40002 10.0592 9.49314 9.62243 9.67406C9.18566 9.85497 8.7888 10.1201 8.45451 10.4544C8.12022 10.7887 7.85505 11.1856 7.67413 11.6224C7.49321 12.0591 7.4001 12.5273 7.4001 13C7.4001 13.4728 7.49321 13.9409 7.67413 14.3777C7.85505 14.8144 8.12022 15.2113 8.45451 15.5456C8.7888 15.8799 9.18566 16.145 9.62243 16.326C10.0592 16.5069 10.2801 16.6 11.0001 16.6Z", stroke: "#214B64", style: {
  stroke: "#214B64",
  stroke: "color(display-p3 0.1299 0.2942 0.3928)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 12L13 12", stroke: "#214B64", style: {
  stroke: "#214B64",
  stroke: "color(display-p3 0.1299 0.2942 0.3928)",
  strokeOpacity: 1
}, strokeWidth: 0.75, strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 14L12 14", stroke: "#214B64", style: {
  stroke: "#214B64",
  stroke: "color(display-p3 0.1299 0.2942 0.3928)",
  strokeOpacity: 1
}, strokeWidth: 0.75, strokeLinejoin: "round" }));
export default SvgChatProcessIcon;
