import { Box, Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import { EditableLabel } from '../common/EditableLabel';
import usePermission from '../common/permissions/usePermission';

const AgentInfoSmall = ({
  icon,
  name,
  persona,
  onEditName,
}: {
  icon: ReactNode;
  name: string;
  persona: string;
  onEditName?: (name: string) => void;
}) => {
  const [isAllowedRename] = usePermission(['edit:ai-agent']);
  return (
    <Stack direction="row" alignItems="center" gap={1} flex={1}>
      <Box sx={{ flex: '0 0 30px' }}>{icon}</Box>
      <Stack gap={0.5}>
        {onEditName && isAllowedRename ? (
          <Box sx={{ position: 'relative', left: '-4px' }}>
            <EditableLabel onValueConfirm={onEditName} value={name} padding={'2px 4px'} />
          </Box>
        ) : (
          <Typography variant="h4" color={colors.grey[900]}>
            {name}
          </Typography>
        )}
        <Typography variant="caption" color={colors.grey[700]}>
          {persona}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AgentInfoSmall;
