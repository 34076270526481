import { colors } from '@sweep-io/sweep-design';
import { HORIZONTAL_PADDING } from '../configuration-canvas-panel/consts';

export const PANEL_SEARCH_BACKGROUND_COLOR = colors.grey[100];
export const PANEL_SEARCH_PADDING = `8px ${HORIZONTAL_PADDING}px`;
export const PANEL_EXPANDED_SEARCH_BORDER_RADIUS = '8px';

export const newSearchSx = {
  background: PANEL_SEARCH_BACKGROUND_COLOR,
  p: PANEL_SEARCH_PADDING,
  borderRadius: PANEL_EXPANDED_SEARCH_BORDER_RADIUS,
};
