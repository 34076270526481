import { Box, Button as MuiButton } from '@mui/material';
import { colors, IconButton, Tooltip, Typography } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp, Close } from '@sweep-io/sweep-design/dist/icons';
import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import React from 'react';
import { TruncatedTextTooltip } from './TruncatedTextTooltip';

const CustomMuiButton = styled(MuiButton)({
  borderRadius: '4px',
  paddingRight: '8px',
  paddingLeft: '8px',
  border: `1px solid ${colors.grey[200]}`,
  color: colors.black,
  height: '32px',
  gap: '8px',
  '&:hover, &.pressed': {
    background: colors.grey[200],
    color: colors.black,
  },
});

interface FilterButtonsProps {
  isPressed: boolean;
  text: string;
  preText?: React.ReactNode;
  title?: string;
  tooltip?: string;
  StartIcon?: React.ReactNode;
  handleClick: (event: React.MouseEvent<HTMLElement>) => any;
  showClearBtn?: boolean;
  onClearBtn?: () => any;
  disabled?: boolean;
  dataTestId?: string;
}

export const FilterButton__deprecated = ({
  handleClick,
  isPressed,
  text,
  title,
  tooltip,
  StartIcon,
  showClearBtn,
  onClearBtn,
  disabled,
  preText,
  dataTestId,
}: FilterButtonsProps) => {
  return (
    <CustomMuiButton
      variant="text"
      size="small"
      onClick={handleClick}
      className={classNames({ pressed: isPressed })}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        minWidth: 0,
        overflow: 'hidden',
      }}
      disabled={disabled}
    >
      {title && (
        <Typography whiteSpace="nowrap" variant="caption">
          {title}
        </Typography>
      )}
      <Box sx={{ display: 'inline-flex', gap: 0.5, alignItems: 'center', minWidth: 0 }}>
        {StartIcon}
        <Tooltip title={tooltip} placement="top">
          <Box
            component="span"
            sx={{
              display: 'flex',
              gap: 0.5,
              alignItems: 'center',
              overflow: 'hidden',
              minWidth: 0,
            }}
            data-testid={dataTestId}
          >
            {preText}
            <TruncatedTextTooltip variant="caption-bold">{text}</TruncatedTextTooltip>
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        {isPressed && !showClearBtn && <ChevronUp />}
        {!isPressed && !showClearBtn && <ChevronDown />}
      </Box>
      {showClearBtn && (
        <IconButton
          size="tiny"
          variant="flat"
          onClick={(e) => {
            e.stopPropagation();
            onClearBtn && onClearBtn();
          }}
        >
          <Close />
        </IconButton>
      )}
    </CustomMuiButton>
  );
};
