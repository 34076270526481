import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';
import { AiAgent } from './aiAgentsConsts';

export interface AiAgentsState {
  environments: {
    [crmOrgId: string]: {
      agents?: AiAgent[];
      isLoading: boolean;
    };
  };
}

const initialState: AiAgentsState = {
  environments: {},
};

export const aiAgentsSlice = createSlice({
  name: 'aiAgents',
  initialState,
  reducers: {
    setAgentsLoading: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
      }>,
    ) => {
      const { crmOrgId } = action.payload;
      state.environments[crmOrgId] = { isLoading: true };
    },
    setAgents: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agents: AiAgent[];
      }>,
    ) => {
      const { crmOrgId, agents } = action.payload;
      state.environments[crmOrgId] = { agents, isLoading: false };
    },
    addAgent: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agent: AiAgent;
      }>,
    ) => {
      const { crmOrgId, agent } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = { agents: [], isLoading: false };
      }
      if (!state.environments[crmOrgId].agents) {
        state.environments[crmOrgId].agents = [];
      }
      state.environments[crmOrgId].agents.push(agent);
    },
    renameAgent: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agentId: string;
        name: string;
      }>,
    ) => {
      const { crmOrgId, agentId, name } = action.payload;
      if (!state.environments[crmOrgId]?.agents) {
        return;
      }
      const relevantAgent = state.environments[crmOrgId].agents.find(
        (agent) => agent.id === agentId,
      );
      if (!relevantAgent) {
        return;
      }
      relevantAgent.name = name;
    },
    deleteAgent: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agentId: string;
      }>,
    ) => {
      const { crmOrgId, agentId } = action.payload;
      if (!state.environments[crmOrgId]?.agents) {
        return;
      }
      state.environments[crmOrgId].agents = state.environments[crmOrgId].agents.filter(
        (agent) => agent.id !== agentId,
      );
    },
  },
});

export const { setAgents, addAgent, renameAgent, deleteAgent, setAgentsLoading } =
  aiAgentsSlice.actions;

export const selectAgents = (crmOrgId?: string) => (state: RootState) => {
  if (crmOrgId) {
    return state.aiAgents.environments[crmOrgId]?.agents;
  }
};

export const selectAgentsIsLoading = (crmOrgId?: string) => (state: RootState) => {
  if (crmOrgId) {
    return state.aiAgents.environments[crmOrgId]?.isLoading;
  }
};

export default aiAgentsSlice.reducer;
