import { Close } from '@mui/icons-material';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';
import { BaseDialog } from '../../common/dialogs/BaseDialog';
import { PaywallSource } from './paywallTypes';
import usePaywall from './usePaywall';

interface PaywallBlockActionDialogProps {
  title: string;
  infoText: string;
  Image: ReactNode;
  closeDialog: () => void;
}

export const PaywallBlockActionDialogV2 = ({
  title,
  infoText,
  Image,
  closeDialog,
}: PaywallBlockActionDialogProps) => {
  const { ctaCallback } = usePaywall();

  return (
    <BaseDialog
      open={true}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          display: 'flex',
          height: '311px',
          width: '100%',
          maxWidth: '745px',
          overflow: 'hidden',
          borderRadius: '20px',
        },
      }}
    >
      <Box sx={{ position: 'absolute', right: '16px', top: '16px', zIndex: 2 }}>
        <IconButton variant="flat" size="large" onClick={closeDialog}>
          <Close />
        </IconButton>
      </Box>
      <Box display="flex" height="100%" flex={1}>
        <Box sx={{ height: '100%', flex: '0 0 300px', overflow: 'hidden' }}>{Image}</Box>
        <Stack flex={2} p={'36px 24px 24px 36px'} height="100%" justifyContent="space-between">
          <Stack
            gap={1}
            sx={{
              '.MuiTypography-h1-bold': {
                lineHeight: '32px', //will be updated in design system
              },
            }}
          >
            <Box maxWidth="70%">
              <Typography variant="h1-bold" color={colors.night[900]}>
                {title}
              </Typography>
            </Box>
            <Box maxWidth="85%">
              <Typography variant="body" color={colors.black}>
                {infoText}
              </Typography>
            </Box>
          </Stack>
          <Box alignSelf="flex-end">
            <Button onClick={() => ctaCallback(PaywallSource.DOWNLOAD_CSV)}>Talk to us</Button>
          </Box>
        </Stack>
      </Box>
    </BaseDialog>
  );
};
