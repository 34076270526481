import RightPanel from '../common/RightPanel';
import { Close, Minimize, Expand } from '@sweep-io/sweep-design/dist/icons';
import { Button, IconButton, Tooltip } from '@sweep-io/sweep-design';
import AiAgentCenterExpanded from './AiAgentCenterExpanded';
import AiAgentCenterCollapsed from './AiAgentCenterCollapsed';
import useAiAgentCenter from './useAiAgentCenter';
import { useCallback, useState } from 'react';
import ProcessSelectionDialog from './ProcessSelectionDialog';
import useSweepNavigate from '../common/useSweepNavigate';
import { appRoutes } from '../../constants/appRoutes';
import { useLocation } from 'react-router';

const AiAgentCenter = ({ closePanel, isOpened }: { closePanel: () => void; isOpened: boolean }) => {
  const [isProcessSelectionDialogOpened, setIsProcessSelectionDialogOpened] = useState(false);
  const { isExpanded, toggleIsExpanded, setIsAgentCreateActive } = useAiAgentCenter();
  const { navigate } = useSweepNavigate();
  const location = useLocation();
  const { pathname } = location;

  const openInstructions = () => {
    //TODO complete
  };

  const navigateAndActivate = useCallback(
    (funnelMapId: string) => {
      setIsAgentCreateActive(true);
      navigate(`${appRoutes.canvasUnited.route}/${funnelMapId}`);
    },
    [navigate, setIsAgentCreateActive],
  );

  const handleNewAgentClick = useCallback(() => {
    const isInCanvas = pathname.startsWith(appRoutes.canvasUnited.route);
    if (!isInCanvas) {
      setIsProcessSelectionDialogOpened(true);
    } else {
      setIsAgentCreateActive(true);
    }
  }, [pathname, setIsAgentCreateActive]);

  const toggleExpandButton = (
    <Tooltip placement="top" title={isExpanded ? 'Collapse' : 'Expand'}>
      <IconButton variant="flat" onClick={toggleIsExpanded} size="small">
        {isExpanded ? <Minimize /> : <Expand />}
      </IconButton>
    </Tooltip>
  );

  const closeButton = (
    <IconButton onClick={closePanel} variant="flat" size="small">
      <Close />
    </IconButton>
  );

  const instructionsButton = (
    <Button variant="outlined" size="tiny" startIconName="Wrench" onClick={openInstructions}>
      Instructions
    </Button>
  );

  return (
    <RightPanel isOpened={isOpened} isExpanded={isExpanded}>
      {isExpanded && (
        <AiAgentCenterExpanded
          toggleExpandButton={toggleExpandButton}
          closeButton={closeButton}
          instructionsButton={instructionsButton}
          onNewAgentClick={handleNewAgentClick}
        />
      )}
      {!isExpanded && (
        <AiAgentCenterCollapsed
          toggleExpandButton={toggleExpandButton}
          closeButton={closeButton}
          instructionsButton={instructionsButton}
          onNewAgentClick={handleNewAgentClick}
        />
      )}
      {isProcessSelectionDialogOpened && (
        <ProcessSelectionDialog
          closeDialog={() => setIsProcessSelectionDialogOpened(false)}
          onSelect={navigateAndActivate}
        />
      )}
    </RightPanel>
  );
};

export default AiAgentCenter;
