import { Outlet } from 'react-router';
import useHandleCrmOrgInRoute from '../../hooks/useHandleCrmOrgInRoute';

const HomeInitialized = () => {
  //Here goes initializations that DO require "crmOrgs" or "hubspot" to be initialized:
  useHandleCrmOrgInRoute();

  return <Outlet />;
};

export default HomeInitialized;
