import { Typography, colors, Radio, Switch } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp, Cpu } from '@sweep-io/sweep-design/dist/icons';
import { Box, Collapse, MenuItem } from '@mui/material';
import { StyledRadioGroup } from '../../common/StyledComponents';
import { EnrichLeadIcon, MatchSettingsIcon } from '../../../icons/generalIcons';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRunOnce } from '../../common/useRunOnce';
import { DedupMatchingType, SweepFilterEndpoint } from '../../../types/enums/DedupMatchingType';
import { CustomHeader } from '../../common/rule-builder/CustomHeader';
import { SweepFieldsRuleBuilder } from '../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { SFDCObjectType } from '../../../types/enums/SFDCObjectType';
import { useSweepFields } from '../../../sweep-fields/useCachedSweepFields';
import { useRefForMultipleRuleBuilders } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../common/fieldsSelectors/NestedFieldsSelector';
import SweepSelect from '../../common/SweepSelect';
import {
  FieldTypeGroup,
  SweepFieldsDtoResponse,
} from '../../../apis/facades/useSweepFieldsApiFacade';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';
import { assignFieldLabels } from '../../../sweep-fields/sweepFieldsLabelsReducer';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import {
  AssignmentActionSelectionType,
  DefaultFieldsData,
  StyledFormControlLabel,
  getAssignActionType,
  getAssignSelection,
  getFiltersByType,
  getFiltersByTypeToSet,
  getFollowUpActionWithoutWebhooks,
  getHandleUpdateCondition,
  getMappingAssignArray,
  getMatchingCondition,
  getNotifyAction,
  getWebhooksFollowUpActions,
  greyLayout,
  handleInnerNotifyAction,
  handleMatchSelection,
  settingsPanelLayout,
} from './helpers';
import { ChildRelationshipSelector } from '../../Automations/ChildRelationshipSelector';
import { FieldAssignmentComponent } from './FieldAssignmentComponent';
import { MatchingNotification } from './MatchingNotification';
import SettingsFilterPanel from './SettingsFilterPanel';
import SettingsTieBreakersPanel from './SettingsTieBreakersPanel';
import SettingsPermissionModePanel from './SettingsPermissionModePanel';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import MatchingLogicFilterPanel from './MatchingLogicFilterPanel';
import { ExternalIntegrationWrapper } from './ExternalIntegrationWrapper';

interface MatchingLeadAccountPageProps {
  readonly?: boolean;
  matchingObject: DeepPartial<AutomationStructureNew>;
  crmOrgId: string;
  onChangeProperty: (
    name: string,
    value: AutomationDetailsDtoNew[keyof AutomationDetailsDtoNew],
  ) => any;
}

enum InvalidSections {
  AssignField = 'AssignField',
  MatchFields = 'MatchFields',
}

export const MatchingLeadAccountPage = ({
  readonly,
  crmOrgId,
  matchingObject,
  onChangeProperty,
}: MatchingLeadAccountPageProps) => {
  const { customLogicInMatching, webhooks } = useFeatureToggle();
  const dispatch = useDispatch();
  const { getSweepFields } = useSweepFields();
  const { getEnrichedNestedPath, getLabelFromIdsWithObjectType } = useSweepFieldsLabels();
  const { getSweepFieldIdsByName } = useSweepFields();
  const [displayError, setDisplayError] = useState(false);
  const [actionsArray, setActionsArray] = useState<(DeepPartial<AutomationAction> | undefined)[]>(
    matchingObject?.automationDetails?.actions || [],
  );
  const [accountOwnerFieldId, setAccountOwnerFieldId] = useState<string>();
  const [leadOwnerFieldId, setLeadOwnerFieldId] = useState<string>();
  const [lookupRelationships, setLookupRelationships] = useState<SweepFieldObjectReferenceDto[]>();
  const [isCheckRelations, setIsCheckRelations] = useState(true);

  useRunOnce(async () => {
    // Get the ownerId fields for account and lead for default values
    const res = await getSweepFieldIdsByName({
      crmOrgId,
      fieldNames: [DefaultFieldsData.AccountOwnerId, DefaultFieldsData.LeadOwnerId],
    });
    const { fieldIds } = res;
    const valueFieldIdsArray = Object.values(fieldIds);
    const _accountOwnerFieldId = valueFieldIdsArray[0][0];
    const _leadOwnerFieldId = valueFieldIdsArray[1][0];
    setAccountOwnerFieldId(_accountOwnerFieldId);
    setLeadOwnerFieldId(_leadOwnerFieldId);
    dispatch(
      assignFieldLabels([
        {
          id: _leadOwnerFieldId,
          labelInfo: {
            objectName: SFDCObjectType.Lead,
            sfFieldName: DefaultFieldsData.OwnerLabel,
            sweepFieldName: DefaultFieldsData.OwnerLabel,
          },
        },
        {
          id: _accountOwnerFieldId,
          labelInfo: {
            objectName: SFDCObjectType.Account,
            sfFieldName: DefaultFieldsData.OwnerLabel,
            sweepFieldName: DefaultFieldsData.OwnerLabel,
          },
        },
      ]),
    );
  });
  useEffect(() => {
    matchingObject && setActionsArray(matchingObject?.automationDetails?.actions ?? []);
  }, [matchingObject]);

  const getMatchingAction = useCallback(() => {
    return actionsArray?.[0] as MatchingAutomationAction;
  }, [actionsArray]);

  const [SelectAssignType, setSelectAssignType] = useState<AssignmentActionSelectionType>(
    getAssignActionType(matchingObject),
  );
  const [isEnrichPanelExpanded, setEnrichPanelExpanded] = useState(
    getMatchingAction()?.actionParams?.fieldMapping?.length > 0,
  );
  const [isMatchPanelExpanded, setMatchPanelExpanded] = useState(
    getMatchingAction()?.actionParams?.useContinuousSync,
  );
  const [isAdvancedPanelExpanded, setAdvancedPanelExpanded] = useState(
    getMatchingAction()?.actionParams?.advancedLogic !== undefined,
  );
  const [invalidSections, setInvalidSection] = useState<InvalidSections>();
  const ruleBuilderRef = useRefForMultipleRuleBuilders();

  const handleMatchingAction = useCallback(
    (prop: string, value: any) => {
      const tempActionMatch = { ...getMatchingAction() };
      const newObj: MatchingAutomationAction = {
        ...tempActionMatch,
        actionParams: {
          ...tempActionMatch.actionParams,
          [prop]: value,
        },
      };
      const newArr = actionsArray.map((el) => (el?.actionType === 'MATCHING' ? newObj : el));
      setActionsArray(newArr);
      onChangeProperty('actions', newArr as AutomationAction[]);
    },
    [actionsArray, getMatchingAction, onChangeProperty],
  );

  const handleChangeMatchingSettings = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value === 'true';
    handleMatchingAction('useContinuousSync', val);
  };

  const handleUpdateCondition = (exitCriteria: SweepCondition) => {
    const ret = getHandleUpdateCondition(matchingObject, exitCriteria);
    handleMatchingAction('followUpActions', ret);
  };

  const defaultFieldStruct: FieldMappingStruct = {
    fromField: {
      fieldIds: accountOwnerFieldId ? [accountOwnerFieldId] : [],
    },
    toField: { fieldIds: leadOwnerFieldId ? [leadOwnerFieldId] : [] },
  };

  const handleMultipleMatchingAction = useCallback(
    (toUpdate: Partial<MatchingActionParams>) => {
      const tempActionMatch = { ...getMatchingAction() };
      const newObj: MatchingAutomationAction = {
        ...tempActionMatch,
        actionParams: {
          ...tempActionMatch.actionParams,
          ...toUpdate,
        },
      };
      const newArr = actionsArray.map((el) => (el?.actionType === 'MATCHING' ? newObj : el));
      setActionsArray(newArr);
      onChangeProperty('actions', newArr as AutomationAction[]);
    },
    [actionsArray, getMatchingAction, onChangeProperty],
  );

  const setFiltersByType = (filter: SweepFilter | undefined, endpoint: SweepFilterEndpoint) => {
    const ret = getFiltersByTypeToSet(matchingObject, filter, endpoint);
    handleMatchingAction('filters', ret);
  };

  const filterCompoundAndLongText = (field: SweepField) =>
    !field.isCompound && field.fieldType !== SweepFieldTypes.LongTextArea;

  const showRelationshipSelector = lookupRelationships && lookupRelationships.length > 1;

  const isCustomLogicActive =
    customLogicInMatching && getMatchingAction()?.actionParams?.matchingLogic;

  const relationshipOptions = lookupRelationships?.map((item) => ({
    value: item.fieldId,
    label: item.refName,
  }));

  useEffect(() => {
    const fetch = async () => {
      if (!getMatchingAction()?.actionParams?.advancedLogic?.isActive || !isCheckRelations) {
        return;
      }

      const childObject =
        getMatchingAction()?.actionParams?.advancedLogic?.endObject?.objectType || 'Contact';
      setLookupRelationships(undefined);
      const res: SweepFieldsDtoResponse = await getSweepFields({
        objectType: [childObject],
        crmOrgId,
        typeGroups: [FieldTypeGroup.FieldReference],
      });
      const relevantItem = res.sweepFields.find((item) => item.objectType === childObject);
      const references = relevantItem?.references?.filter((ref) =>
        ref.objectNames.includes(SFDCObjectType.Account),
      );
      setLookupRelationships(references);
      if (references?.length === 1) {
        const tmpObj = getMatchingAction();
        const tempField: SlackFieldsStruct = {
          fieldIds: [references[0].fieldId],
        };
        const tempLogic = {
          ...tmpObj?.actionParams?.advancedLogic,
          relationshipField: tempField,
          endObject: getMatchingAction()?.actionParams?.advancedLogic?.endObject ?? {
            fieldName: 'AccountId',
            childRelationshipName: 'Contacts',
            childRelationshipLabel: 'Contacts',
            objectType: 'Contact',
            objectTypeLabel: 'Contact',
          },
        };
        handleMatchingAction('advancedLogic', tempLogic);
      }
      setIsCheckRelations(false);
    };

    fetch();
  }, [crmOrgId, getSweepFields, getMatchingAction, handleMatchingAction, isCheckRelations]);

  const handleNotifyAction = (
    automationAction: EmailAutomationAction | SlackAutomationAction | null,
  ) => {
    const ret = handleInnerNotifyAction(matchingObject, automationAction);
    const webhooks = getWebhooksFollowUpActions(matchingObject);
    const toReturn = webhooks?.length ? [...ret, ...webhooks] : ret;
    handleMatchingAction('followUpActions', toReturn);
  };

  const handleMultipleFollowUpActionWebhooksMatching = (
    actionToUpdate: AutomationConditionalActions[],
  ) => {
    const toReturn = actionToUpdate;
    const _otherFollowUps = getFollowUpActionWithoutWebhooks(matchingObject)?.[0];
    if (_otherFollowUps) {
      toReturn.unshift(_otherFollowUps);
    }
    handleMatchingAction('followUpActions', toReturn);
  };

  return (
    <>
      <Box>
        <Typography variant="h4">When a match is found</Typography>

        <StyledRadioGroup
          sx={{ marginTop: '12px' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDisplayError(false);
            setInvalidSection(undefined);
            const value = (event.target as HTMLInputElement).value as AssignmentActionSelectionType;
            setSelectAssignType(value);
            const ret = handleMatchSelection(matchingObject, value, defaultFieldStruct) ?? [];
            const toReturn = [...ret, ...getWebhooksFollowUpActions(matchingObject)];
            handleMatchingAction('followUpActions', toReturn);
          }}
          value={SelectAssignType}
        >
          <StyledFormControlLabel
            disabled={readonly}
            value={AssignmentActionSelectionType.Empty}
            control={<Radio />}
            label={<Typography variant="body">Match lead to account</Typography>}
          />
          <StyledFormControlLabel
            disabled={readonly}
            value={AssignmentActionSelectionType.Assign}
            control={<Radio />}
            label={
              <Typography variant="body">
                Match and then assign lead to the matched account
              </Typography>
            }
          />
          {SelectAssignType === AssignmentActionSelectionType.Assign && (
            <Box sx={{ ...greyLayout }}>
              <Box sx={{ paddingLeft: '24px' }}>
                <Typography variant="body-bold">Assign lead</Typography>
                <FieldAssignmentComponent
                  readonly={readonly}
                  restrictToUser={true}
                  displayError={displayError && invalidSections === InvalidSections.AssignField}
                  assignFromObject={SFDCObjectType.Account}
                  assignToObject={SFDCObjectType.Lead}
                  assignFromLabel={'Account Ownership Field'}
                  assignToLabel={'Lead Ownership field'}
                  assignFromPlaceholder={'Select Account Field'}
                  assignToPlaceholder={'Select Lead Field'}
                  crmOrgId={crmOrgId}
                  mappingArray={getMappingAssignArray(matchingObject)}
                  onChange={(fields: FieldMappingStruct[]) => {
                    const ret = getAssignSelection(matchingObject, fields);
                    handleMatchingAction('followUpActions', ret);
                  }}
                />
              </Box>
            </Box>
          )}
          <StyledFormControlLabel
            disabled={readonly}
            value={AssignmentActionSelectionType.Condition}
            control={<Radio />}
            label={
              <Typography variant="body">
                Match and assign lead to the matched account, only if conditions are met
              </Typography>
            }
          />

          {SelectAssignType === AssignmentActionSelectionType.Condition && (
            <Box sx={{ ...greyLayout }}>
              <Box>
                <>
                  <CustomHeader customHeader="When these conditions are met" />
                  <SweepFieldsRuleBuilder
                    readOnly={readonly}
                    crmOrgId={crmOrgId}
                    objectType={SFDCObjectType.Lead}
                    sweepCondition={getMatchingCondition(matchingObject)}
                    ref={ruleBuilderRef}
                    onChange={(sweepCondition: SweepCondition) => {
                      handleUpdateCondition(sweepCondition);
                    }}
                    disableResolvePolymorphic
                  />
                </>
              </Box>
              <Box sx={{ paddingLeft: '24px' }}>
                <Typography variant="body-bold">Assign lead</Typography>
                <FieldAssignmentComponent
                  readonly={readonly}
                  restrictToUser={true}
                  displayError={displayError && invalidSections === InvalidSections.AssignField}
                  assignFromObject={SFDCObjectType.Account}
                  assignToObject={SFDCObjectType.Lead}
                  assignFromLabel={'Account Ownership Field'}
                  assignToLabel={'Lead Ownership field'}
                  assignFromPlaceholder={'Select Account Field'}
                  assignToPlaceholder={'Select Lead Field'}
                  crmOrgId={crmOrgId}
                  mappingArray={getMappingAssignArray(matchingObject)}
                  onChange={(fields: FieldMappingStruct[]) => {
                    const ret = getAssignSelection(matchingObject, fields);
                    handleMatchingAction('followUpActions', ret);
                  }}
                />
              </Box>
            </Box>
          )}
        </StyledRadioGroup>
      </Box>
      <MatchingNotification
        readonly={readonly}
        objectName={SFDCObjectType.Lead}
        action={getNotifyAction(matchingObject)}
        crmOrgId={crmOrgId}
        displayError={displayError}
        onChange={handleNotifyAction}
      ></MatchingNotification>

      <Box sx={{ marginTop: '40px' }}>
        <Typography variant="h4">Settings</Typography>

        <Box>
          <SettingsFilterPanel
            readonly={readonly}
            title={'Leads filters'}
            filters={getFiltersByType(matchingObject, SweepFilterEndpoint.SOURCE)}
            type={DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING}
            crmOrgId={crmOrgId}
            objectType={SFDCObjectType.Lead}
            onChange={(filter) => setFiltersByType(filter, SweepFilterEndpoint.SOURCE)}
          />
        </Box>
        <Box>
          <SettingsFilterPanel
            readonly={readonly}
            subType={1}
            title={'Matched accounts filters'}
            filters={getFiltersByType(matchingObject, SweepFilterEndpoint.DESTINATION)}
            type={DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING}
            crmOrgId={crmOrgId}
            objectType={SFDCObjectType.Account}
            onChange={(filter) => setFiltersByType(filter, SweepFilterEndpoint.DESTINATION)}
          />
        </Box>
        <Box>
          <SettingsFilterPanel
            readonly={readonly}
            subType={2}
            title={`Match filters`}
            filters={getFiltersByType(matchingObject, SweepFilterEndpoint.MATCH)}
            type={
              getMatchingAction().actionParams?.dmType ?? DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING
            }
            crmOrgId={crmOrgId}
            objectType={SFDCObjectType.Lead}
            referenceObjectType={SFDCObjectType.Account}
            onChange={(filter) => setFiltersByType(filter, SweepFilterEndpoint.MATCH)}
          />
        </Box>
        {customLogicInMatching && (
          <Box>
            <MatchingLogicFilterPanel
              ruleBuilderHeaderLabel={'Potential match'}
              title={'Matching logic'}
              subTitle={'Define the rules for identifying matches'}
              infoTooltipTitle={
                'We leverage a combination of exact and fuzzy logic on standard fields like account name, company, website, address, phone, and more to ensure accurate detection of matches.'
              }
              ruleBuilderTitle={'Set your custom logic to find matches'}
              matchingLogic={getMatchingAction()?.actionParams.matchingLogic}
              readonly={readonly}
              crmOrgId={crmOrgId ?? ''}
              objectType={SFDCObjectType.Lead}
              referenceObjectType={SFDCObjectType.Account}
              onChange={(filter) => {
                // if has logic, reset 'advancedLogic' and 'useContinuousSync (Matching settings)'
                if (filter) {
                  handleMultipleMatchingAction({
                    matchingLogic: filter,
                    advancedLogic: undefined,
                    useContinuousSync: false,
                  });
                } else {
                  handleMultipleMatchingAction({ matchingLogic: filter });
                }
              }}
            />
          </Box>
        )}
        {!isCustomLogicActive && (
          <Box sx={{ ...settingsPanelLayout }}>
            <Collapse in={isAdvancedPanelExpanded} collapsedSize={24}>
              <Box
                className="panelHeader"
                onClick={() => setAdvancedPanelExpanded(!isAdvancedPanelExpanded)}
              >
                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <Cpu variant="large" />
                  <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
                    <Typography variant="body-bold">
                      {customLogicInMatching
                        ? 'Match using related objects'
                        : 'Advanced matching logic'}
                    </Typography>
                    <Typography variant="body">
                      Match lead to account, by using related object under the account as the
                      matching criteria
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {isAdvancedPanelExpanded ? (
                    <ChevronUp variant="large" />
                  ) : (
                    <ChevronDown variant="large" />
                  )}
                </Box>
              </Box>
              <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
                <Box sx={{ display: 'flex', gap: 2, mb: '16px' }}>
                  <Typography variant="body">
                    {customLogicInMatching ? 'Activate' : 'Activate advanced matching logic'}
                  </Typography>
                  <Switch
                    disabled={readonly}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const tmpObj = getMatchingAction();
                      const _isActive = event.target.checked;
                      const tempLogic = _isActive
                        ? {
                            ...tmpObj?.actionParams?.advancedLogic,
                            isActive: _isActive,
                          }
                        : undefined;
                      handleMatchingAction('advancedLogic', tempLogic);
                    }}
                    checked={getMatchingAction()?.actionParams?.advancedLogic?.isActive ?? false}
                  />
                </Box>
                {getMatchingAction()?.actionParams?.advancedLogic?.isActive && (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: '16px' }}>
                      <Typography variant="body">Find identical values between Lead</Typography>
                      <Box
                        sx={{
                          backgroundColor: colors.white,
                          borderRadius: '4px',
                          border: `1px solid ${colors.grey[300]}`,
                        }}
                      >
                        <NestedFieldsSelector
                          readonly={readonly}
                          filterBy={filterCompoundAndLongText}
                          placeholder="Email"
                          disableLookupItemsResolve={true}
                          crmOrgId={crmOrgId}
                          objectType={SFDCObjectType.Lead}
                          nestedPath={getEnrichedNestedPath(
                            getMatchingAction()?.actionParams?.advancedLogic?.startField?.fieldIds,
                          )}
                          onChange={(sweepField: NestedFieldsField) => {
                            const tmpObj = getMatchingAction();
                            const tempField: SlackFieldsStruct = {
                              fieldIds: sweepField.fieldIds,
                            };
                            const tempLogic = {
                              ...tmpObj?.actionParams?.advancedLogic,
                              startField: tempField,
                            };
                            handleMatchingAction('advancedLogic', tempLogic);
                          }}
                        />
                      </Box>
                      <Typography variant="body">and</Typography>
                      <Box>
                        <ChildRelationshipSelector
                          tempValueForDemo={'Contact'}
                          readonly={readonly}
                          ChildRelationshipObject={
                            getMatchingAction()?.actionParams?.advancedLogic?.endObject
                          }
                          crmOrgId={crmOrgId}
                          objectName={SFDCObjectType.Account}
                          onChange={(value) => {
                            const _fieldName = value?.relationshipFieldId?.split('.')?.[1] ?? '';
                            const tmpObj = getMatchingAction();
                            const tempLogic = {
                              ...tmpObj?.actionParams?.advancedLogic,
                              endObject: { ...value, fieldName: _fieldName },
                              endField: undefined,
                            };
                            handleMatchingAction('advancedLogic', tempLogic);
                            setIsCheckRelations(true);
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: colors.white,
                          borderRadius: '4px',
                          border: `1px solid ${colors.grey[300]}`,
                        }}
                      >
                        <NestedFieldsSelector
                          readonly={readonly}
                          filterBy={filterCompoundAndLongText}
                          placeholder="Email"
                          disableLookupItemsResolve={true}
                          crmOrgId={crmOrgId}
                          objectType={
                            getMatchingAction()?.actionParams?.advancedLogic?.endObject
                              ?.objectType ?? 'Contact'
                          }
                          nestedPath={getEnrichedNestedPath(
                            getMatchingAction()?.actionParams?.advancedLogic?.endField?.fieldIds,
                          )}
                          onChange={(sweepField: NestedFieldsField) => {
                            const tmpObj = getMatchingAction();
                            const tempField: SlackFieldsStruct = {
                              fieldIds: sweepField.fieldIds,
                            };
                            const tempLogic = {
                              ...tmpObj?.actionParams?.advancedLogic,
                              endField: tempField,
                            };
                            handleMatchingAction('advancedLogic', tempLogic);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="body">
                        and then match lead to the{' '}
                        <Typography variant="body-bold">
                          {getMatchingAction()?.actionParams?.advancedLogic?.endObject
                            ?.objectTypeLabel ?? 'Contact'}
                        </Typography>
                        's parent account.
                      </Typography>
                    </Box>
                    {showRelationshipSelector && relationshipOptions && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: '16px' }}>
                        <Typography variant="body">
                          Choose relationship field from contact to account
                        </Typography>
                        <SweepSelect
                          SelectProps={{
                            readOnly: readonly,
                            placeholder: 'Choose field',
                            value:
                              getMatchingAction()?.actionParams?.advancedLogic?.relationshipField
                                ?.fieldIds[0] ?? '',
                            onChange: async (e) => {
                              const relationshipId = e.target.value as string;
                              const tmpObj = getMatchingAction();
                              const tempField: SlackFieldsStruct = {
                                fieldIds: [relationshipId],
                              };
                              const tempLogic = {
                                ...tmpObj?.actionParams?.advancedLogic,
                                relationshipField: tempField,
                              };
                              handleMatchingAction('advancedLogic', tempLogic);
                            },
                          }}
                          renderValue={(value) => {
                            const selectedOption = relationshipOptions.find(
                              (option) => option.value === value,
                            );
                            const firstId =
                              getMatchingAction()?.actionParams?.advancedLogic?.relationshipField
                                .fieldIds[0];
                            return (
                              selectedOption?.label ??
                              getLabelFromIdsWithObjectType(firstId ? [firstId] : [])
                            );
                          }}
                        >
                          {relationshipOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </SweepSelect>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Collapse>
          </Box>
        )}

        <Box sx={{ ...settingsPanelLayout }}>
          <Collapse in={isEnrichPanelExpanded} collapsedSize={24}>
            <Box
              className="panelHeader"
              onClick={() => setEnrichPanelExpanded(!isEnrichPanelExpanded)}
            >
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <EnrichLeadIcon />
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
                  <Typography variant="body-bold">Enrich lead</Typography>
                  <Typography variant="body">
                    Update lead fields with values from the matched account
                  </Typography>
                </Box>
              </Box>
              <Box>
                {isEnrichPanelExpanded ? (
                  <ChevronUp variant="large" />
                ) : (
                  <ChevronDown variant="large" />
                )}
              </Box>
            </Box>
            <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
              <FieldAssignmentComponent
                readonly={readonly}
                displayError={displayError && invalidSections === InvalidSections.MatchFields}
                assignFromObject={SFDCObjectType.Account}
                assignToObject={SFDCObjectType.Lead}
                assignFromLabel={'Account Field'}
                assignToLabel={'Lead Field'}
                assignFromPlaceholder={'Select Account Field'}
                assignToPlaceholder={'Select Lead Field'}
                showAddFields={true}
                crmOrgId={crmOrgId}
                mappingArray={getMatchingAction()?.actionParams?.fieldMapping}
                onAddCallback={(emptyField: FieldMappingStruct) => {
                  const tmpObj = getMatchingAction();
                  const tempFields = [...(tmpObj?.actionParams?.fieldMapping || [])];
                  tempFields.push(emptyField);
                  handleMatchingAction('fieldMapping', tempFields);
                }}
                onChange={(fields: FieldMappingStruct[]) => {
                  handleMatchingAction('fieldMapping', fields);
                }}
              />
            </Box>
          </Collapse>
        </Box>

        <Box>
          <SettingsTieBreakersPanel
            readonly={readonly}
            action={getMatchingAction()?.actionParams}
            type={DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING}
            crmOrgId={crmOrgId}
            objectType={SFDCObjectType.Account}
            onChange={(_tieBreakers) => {
              handleMatchingAction('tieBreakers', _tieBreakers);
            }}
          />
        </Box>

        {!isCustomLogicActive && (
          <Box sx={{ ...settingsPanelLayout }}>
            <Collapse in={isMatchPanelExpanded} collapsedSize={24}>
              <Box
                className="panelHeader"
                onClick={() => setMatchPanelExpanded(!isMatchPanelExpanded)}
              >
                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <MatchSettingsIcon />
                  <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
                    <Typography variant="body-bold">Matching settings</Typography>
                    <Typography variant="body">
                      Choose how to evaluate already existing matches
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {isMatchPanelExpanded ? (
                    <ChevronUp variant="large" />
                  ) : (
                    <ChevronDown variant="large" />
                  )}
                </Box>
              </Box>
              <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
                <StyledRadioGroup
                  className="alignTop"
                  sx={{ marginTop: '12px' }}
                  value={String(getMatchingAction()?.actionParams.useContinuousSync) ?? ''}
                  onChange={handleChangeMatchingSettings}
                >
                  <StyledFormControlLabel
                    disabled={readonly}
                    value="false"
                    control={<Radio />}
                    label={
                      <>
                        <Typography variant="body">Keep first match</Typography>
                        <br />
                        <Typography variant="caption">
                          Once matched, stick to existing matches when Leads and Accounts are
                          updated
                        </Typography>
                      </>
                    }
                  />

                  <StyledFormControlLabel
                    disabled={readonly}
                    value="true"
                    control={<Radio />}
                    label={
                      <>
                        <Typography variant="body">Always validate matches</Typography>
                        <br />
                        <Typography variant="caption">
                          Verify existing matches are still up-to-date on every Lead or Account
                          update, and re-evaluate if needed
                        </Typography>
                      </>
                    }
                  />
                </StyledRadioGroup>
              </Box>
            </Collapse>
          </Box>
        )}

        <SettingsPermissionModePanel
          readonly={readonly}
          automationDetails={matchingObject?.automationDetails}
          onChange={(val: AutomationPermissionMode) => {
            onChangeProperty?.('permissionMode', val);
          }}
        />
        {webhooks && (
          <ExternalIntegrationWrapper
            readonly={readonly}
            objectName={SFDCObjectType.Lead}
            crmOrgId={crmOrgId}
            conditionalActions={getWebhooksFollowUpActions(matchingObject) ?? []}
            onChange={handleMultipleFollowUpActionWebhooksMatching}
            referenceObjectType={SFDCObjectType.Account}
          ></ExternalIntegrationWrapper>
        )}
      </Box>
    </>
  );
};
