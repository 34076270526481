import { Box, ListItemIcon, MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import SweepSelect from '../common/SweepSelect';
import { useIntegrations } from '../../hooks/useIntegrations';
import { useSelector } from 'react-redux';
import { selectCustomIntegrations } from '../../reducers/integrationsReducer';
import { useRunOnce } from '../common/useRunOnce';
import { useMemo } from 'react';
import { useSweepRoleGroups } from '../common/useSweepRoleGroups';
import { selectUserInfoData } from '../../reducers/userInfoReducer';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { LogoDevIcon } from '../common/logo-dev-selector/LogoDevIcon';
import { useWebhooksContext } from '../dashboard/webhooks/WebhooksContext';
import { useUserDialogContext } from '../dashboard/user-dialog/UserDialogContext';
import { UserDialogTabTypes } from '../dashboard/user-dialog/UserDialogWrapper';
import { Integrations } from '@sweep-io/sweep-design/dist/icons';

interface WebhookActionWrapperProps {
  readonly?: boolean;
  action: WebhookAutomationAction;
  onChange: (action: WebhookAutomationAction) => any;
}
export const WebhookActionSelector = ({ action, onChange }: WebhookActionWrapperProps) => {
  const { isAdmin } = useSweepRoleGroups();
  const currentUser = useSelector(selectUserInfoData);
  const { getCustomIntegrations } = useIntegrations();
  const customIntegrations = useSelector(selectCustomIntegrations);
  const { setWebhooks } = useWebhooksContext();
  const { openDialog } = useUserDialogContext();

  useRunOnce(async () => {
    await getCustomIntegrations();
  });

  const getList = useMemo(() => {
    const items = customIntegrations.map((elem) => {
      return (
        <MenuItem key={elem.id} value={elem.id}>
          <ListItemIcon>
            {elem.logo && <LogoDevIcon brand={elem.logo} size={16} />}
            {!elem.logo && (
              <Box
                sx={{
                  background: colors.blue[100],
                  width: '18px',
                  height: '18px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  lineHeight: '19px',
                }}
              >
                <Integrations variant={'tiny'} color={colors.blue[600]} />
              </Box>
            )}
          </ListItemIcon>
          {elem.name}
        </MenuItem>
      );
    });
    if (isAdmin(currentUser?.roleGroupId ?? '')) {
      items.push(
        <Box sx={{ p: '8px 12px' }} key={'custom-add-integration'}>
          <Button
            type="primary"
            variant="flat"
            onClick={async () => {
              setWebhooks(true);
              openDialog(UserDialogTabTypes.INTEGRATIONS);
            }}
            size="small"
          >
            Add new integration
          </Button>
        </Box>,
      );
    }
    return items;
  }, [currentUser?.roleGroupId, customIntegrations, isAdmin, openDialog, setWebhooks]);

  return (
    <SweepSelect
      FormControlProps={{
        sx: { width: 'fit-content' },
      }}
      SelectProps={{
        placeholder: 'Choose integration',
        value: !customIntegrations?.length ? '' : (action?.actionParams?.integrationId ?? ''),
        renderValue: (val: any) => {
          const output = customIntegrations.find((d) => d.id === val);
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              {output?.logo && <LogoDevIcon brand={output?.logo} size={16} />}
              <Typography variant="body">{output?.name}</Typography>
            </Stack>
          );
        },
        onChange: (event: SelectChangeEvent<unknown>) => {
          const val = event.target.value as string;
          val &&
            onChange({
              ...action,
              actionParams: {
                ...(action as WebhookAutomationAction).actionParams,
                integrationId: val,
              },
            });
        },
      }}
    >
      {getList}
    </SweepSelect>
  );
};
