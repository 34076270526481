import { ConfirmDialog } from '../common/dialogs/ConfirmDialog';
import { useState } from 'react';
import { TextField } from '@mui/material';

const AiAgentNameDialog = ({
  onConfirm,
  closeDialog,
}: {
  onConfirm: (name: string) => void;
  closeDialog: () => void;
}) => {
  const [localName, setLocalName] = useState('New process optimization agent');

  const _onConfirm = () => {
    onConfirm(localName);
    closeDialog();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      _onConfirm();
    }
  };

  return (
    <ConfirmDialog
      title="Name you new AI agent"
      confirmText="Create Agent"
      onConfirm={_onConfirm}
      isOpen={true}
      handleClose={closeDialog}
      PaperProps={{
        sx: {
          width: 500,
          //content should have 24px padding from title and actions
          '.MuiDialogContent-root': {
            pb: 0,
            pt: 2,
          },
        },
      }}
      disableConfirmButton={!localName}
      enableKeyListener={true}
    >
      <TextField
        fullWidth
        autoFocus
        value={localName}
        onChange={(event) => {
          setLocalName(event.target.value);
        }}
        onKeyDown={handleKeyDown}
      />
    </ConfirmDialog>
  );
};

export default AiAgentNameDialog;
