import { useCallback, useEffect, useMemo } from 'react';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { getUsedRecordTypesAndObjects } from '../configuration-canvas/utils/getUsedRecordTypesAndObjects';
import { useFitAroundElements } from './useFitAroundElements';
import { AdvancedFilter, AdvancedFilterItem } from '../../common/advanced-filter/AdvancedFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCanvasFilterSelectedValuesFor,
  setCanvasFilterItemsDataFromFilterItems,
  setCanvasFilterSelectedValuesFor,
} from './canvasFiltersReducer';
import { advancedFilterItemsToCanvasFilterItemsData } from './helper';

interface ObjectsFilterProps {
  funnelsData: FunnelsData;
  recordTypesData: RecordTypesData;
  automations?: AutomationStructureNew[];
  crmOrgId: string;
  filterKey: string;
  disableFitOnCanvasElements?: boolean;
  renderSeparateClearButton?: boolean;
}

export const ObjectsFilter = ({
  crmOrgId,
  funnelsData,
  recordTypesData,
  automations,
  filterKey,
  disableFitOnCanvasElements,
  renderSeparateClearButton,
}: ObjectsFilterProps) => {
  const dispatch = useDispatch();
  const selectedValues = useSelector(selectCanvasFilterSelectedValuesFor(filterKey));
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });

  const { usedObjectTypes } = useMemo(
    () =>
      getUsedRecordTypesAndObjects({
        recordTypesData,
        funnelsData,
        automations,
      }),
    [automations, funnelsData, recordTypesData],
  );

  const objectsItems: AdvancedFilterItem[] = useMemo(() => {
    return usedObjectTypes
      .map((objectType) => {
        const object = objectTypesByName[objectType];
        if (!object) return;
        return {
          value: objectType,
          label: object.label,
        };
      })
      .filter(Boolean) as AdvancedFilterItem[];
  }, [objectTypesByName, usedObjectTypes]);

  const { fitOnElements } = useFitAroundElements({ funnelsData, recordTypesData });

  const onSelectedItemsChange = useCallback(
    (items: string[]) => {
      dispatch(setCanvasFilterSelectedValuesFor({ filterKey, selectedValues: items }));

      !disableFitOnCanvasElements && fitOnElements(items);
    },
    [disableFitOnCanvasElements, dispatch, filterKey, fitOnElements],
  );

  useEffect(() => {
    dispatch(
      setCanvasFilterItemsDataFromFilterItems({
        filterKey,
        items: advancedFilterItemsToCanvasFilterItemsData(objectsItems),
      }),
    );
  }, [dispatch, filterKey, objectsItems]);

  return (
    <AdvancedFilter
      items={objectsItems}
      selectedItems={selectedValues}
      onSelectedItemsChange={onSelectedItemsChange}
      texts={{ title: 'Objects' }}
      renderSeparateClearButton={renderSeparateClearButton}
    />
  );
};
