import { useCallback, useState } from 'react';
import { useSave } from './useSave';
import { areFunnelMapsEquivalent } from './areFunnelMapsEquivalent';
import cloneDeep from 'lodash/cloneDeep';
import { useConfigurationCanvasState } from './useConfigurationCanvasState';
import { useConfigurationCanvasContext } from './ConfigurationCanvasContext';
import { useErrorHandling } from '../../../hooks/useErrorHandling';
import { updateRecordTypesFunnelsAndHubspotFunnels } from './updateRecordTypesFunnelsAndHubspotFunnels';
import { useSelector } from 'react-redux';
import { selectProductionCrmOrg } from '../environments/environmentsReducer';
import { telemetry } from '../../../telemetry';

export const useUpdateEnvironment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setLastAttemptedFunnelMapSave, canvasCrmOrgId } = useConfigurationCanvasContext();

  const { setCanvasFunnelMap } = useConfigurationCanvasState();

  const { saveFunnelMapIfAllowed } = useSave();
  const { errorHandlingBuilder } = useErrorHandling();
  const isProductionOrg = useSelector(selectProductionCrmOrg)?.id === canvasCrmOrgId;

  const updateEnvironment = useCallback(
    async (envFunnelMap: FunnelMap) => {
      setIsLoading(true);

      const funnelMap = cloneDeep(envFunnelMap);

      updateRecordTypesFunnelsAndHubspotFunnels(funnelMap, isProductionOrg);

      if (!areFunnelMapsEquivalent(funnelMap, envFunnelMap)) {
        errorHandlingBuilder()
          .withErrorNotification('Error updating environment view')
          .withOnError((e) => {
            telemetry.captureError(e, { message: 'Error updating environment view' });
          })
          .execute(async () => await saveFunnelMapIfAllowed(funnelMap));
      } else {
        setLastAttemptedFunnelMapSave(funnelMap);
      }

      // TODO: Add notifications when they are ready in design

      // changes.forEach((change) => {
      //   addNotification({
      //     message: change,
      //     variant: SweepNotificationVariant.Default,
      //     keepOpen: false,
      //   });
      // });

      setCanvasFunnelMap(funnelMap);
      setIsLoading(false);
    },
    [
      errorHandlingBuilder,
      isProductionOrg,
      saveFunnelMapIfAllowed,
      setCanvasFunnelMap,
      setLastAttemptedFunnelMapSave,
    ],
  );

  return {
    updateEnvironment,
    isLoading,
  };
};
