import { useEffect } from 'react';
import { appRoutes } from '../constants/appRoutes';
import useUserInfo from './useUserInfo';
import useSweepNavigate from '../components/common/useSweepNavigate';
import { useSelector } from 'react-redux';
import {
  selectConnectedCrmOrgs,
  selectDefaultCreationEnvironment,
} from '../components/pages/environments/environmentsReducer';
import { useParams } from 'react-router';

const useHandleCrmOrgInRoute = () => {
  const { crmOrgId: paramsCrmOrgId } = useParams<{ crmOrgId: string }>();
  const currentDefaultCreationEnvId = useSelector(selectDefaultCreationEnvironment)?.id;
  const { updateDefaultCreationCrmOrgId } = useUserInfo();
  const crmOrgs = useSelector(selectConnectedCrmOrgs);

  const { navigate } = useSweepNavigate();

  useEffect(() => {
    if (paramsCrmOrgId && paramsCrmOrgId !== currentDefaultCreationEnvId) {
      if (crmOrgs.find((crmOrg) => crmOrg.id === paramsCrmOrgId)) {
        updateDefaultCreationCrmOrgId(paramsCrmOrgId);
      } else {
        navigate(appRoutes.getStarted.route);
      }
    }
  }, [
    crmOrgs,
    currentDefaultCreationEnvId,
    navigate,
    paramsCrmOrgId,
    updateDefaultCreationCrmOrgId,
  ]);
};

export default useHandleCrmOrgInRoute;
