import { ChatShort } from '../common/ai-chat/aiChatTypes';
import { useChatSections } from '../aiAgentCenter/useChatSections';
import ChatsSectionLayout from '../aiAgentCenter/ChatsSectionLayout';
import ChatListItemConnected from './ChatListItemConnected';
import noop from 'lodash/noop';
import { AgentType } from '@server/ai';

const GHOST_ID = 'GHOST_ID';

const FIRST_CHAT_GHOST: ChatShort = {
  id: GHOST_ID,
  name: 'New chat',
  updatedAt: new Date().toISOString(),
  isFavorite: false,
  isLoading: false,
  agentId: '',
  agentType: AgentType.Documentation,
};

export const ChatsSections = ({
  isLoadingChats,
  activeChatId,
  setActiveChatId,
  chats,
  ghostChatItemIsLoading,
}: {
  isLoadingChats: boolean;
  activeChatId?: string;
  setActiveChatId: (id: string) => Promise<void>;
  chats: ChatShort[];
  ghostChatItemIsLoading?: boolean;
}) => {
  const firstChatGhost = Object.assign({}, FIRST_CHAT_GHOST);
  const isEmpty = !isLoadingChats && chats.length === 0;
  const sections = useChatSections(chats);

  return (
    <>
      {isEmpty && (
        <ChatsSectionLayout title="Today">
          <ChatListItemConnected
            chat={{ ...firstChatGhost, isLoading: !!ghostChatItemIsLoading }}
            isActive={true}
            onClick={noop}
            disableActions={true}
          />
        </ChatsSectionLayout>
      )}
      {sections.map((section) =>
        section.items.length > 0 ? (
          <ChatsSectionLayout key={section.title} title={section.title}>
            {section.items.map((chat) => (
              <ChatListItemConnected
                key={chat.id}
                chat={chat}
                isActive={activeChatId === chat.id}
                onClick={() => setActiveChatId(chat.id)}
                disableActions={false}
              />
            ))}
          </ChatsSectionLayout>
        ) : null,
      )}
    </>
  );
};
