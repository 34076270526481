import { Box, Menu, Stack } from '@mui/material';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { ReactNode, useEffect, useState } from 'react';
import { MoreActionsVertical, Pin } from '@sweep-io/sweep-design/dist/icons';
import usePopover from '../../hooks/usePopover';
import ChatMenuActions from '../aiAgentCenter/ChatMenuActions';
import RenameChatDialog from '../aiAgentCenter/RenameChatDialog';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { ClampedText } from '../common/ClampedText';
import Typewriter from '../common/Typewriter';

const ChatListItem = ({
  isActive,
  isLoading,
  isFavorite,
  title,
  subtitle,
  name,
  onClick,
  disableActions,
  onDelete,
  onRename,
  togglePin,
  isAnimateName,
}: {
  isActive: boolean;
  isLoading: boolean;
  isFavorite: boolean;
  isAnimateName: boolean;
  name: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  onClick: () => void;
  onDelete?: () => void;
  togglePin?: () => void;
  onRename?: (name: string) => void;
  disableActions?: boolean;
}) => {
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { closePopover, openPopover, isPopoverOpen, anchorRef, anchorElement } = usePopover();

  const menuIcon = (
    <IconButton ref={anchorRef} onClick={openPopover} variant="flat" size="tiny">
      <MoreActionsVertical />
    </IconButton>
  );

  const [isShowTypewriter, setIsShowTypewriter] = useState(false);
  const textToRenderWithTypewriter = name;
  const textToRender = title ?? name;

  useEffect(() => {
    setIsShowTypewriter(isAnimateName);
  }, [name, isAnimateName]);

  const showMenuIcon = !disableActions && (isHovered || isPopoverOpen);
  const iconToRender = showMenuIcon ? menuIcon : isFavorite ? <Pin /> : null;

  return (
    <>
      <Box
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={isActive ? 'is-active' : ''}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 16px',
          '&:hover, &.is-active': {
            cursor: 'pointer',
            backgroundColor: colors.grey[200],
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            padding: '12px 0',
            borderBottom: `1px solid ${colors.grey[300]}`,
          }}
        >
          {isLoading ? (
            <CenteredCircularProgress circularProgressProps={{ size: '20px' }} />
          ) : (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Stack spacing={1}>
                {!isShowTypewriter && <ClampedText text={textToRender} typographyVariant="body" />}
                {isShowTypewriter && <Typewriter text={textToRenderWithTypewriter} delay={35} />}
                {subtitle && (
                  <Box
                    sx={{
                      overflow: 'hidden',
                      '& .MuiTypography-root': {
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-word',
                      },
                    }}
                  >
                    <Typography variant="caption">{subtitle}</Typography>
                  </Box>
                )}
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '24px',
                  flex: '0 0 24px',
                }}
              >
                {iconToRender}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {onDelete && togglePin && onRename && (
        <Menu
          anchorEl={anchorElement}
          open={isPopoverOpen}
          onClose={closePopover}
          slotProps={{
            paper: {
              sx: {
                width: '311px',
              },
            },
          }}
        >
          <ChatMenuActions
            closeMenu={closePopover}
            onRename={() => setIsRenameDialogOpen(true)}
            onDelete={onDelete}
            togglePin={togglePin}
            isPinned={isFavorite}
          />
        </Menu>
      )}
      {onRename && (
        <RenameChatDialog
          isOpen={isRenameDialogOpen}
          initialName={name}
          onChange={onRename}
          closeDialog={() => setIsRenameDialogOpen(false)}
        />
      )}
    </>
  );
};

export default ChatListItem;
