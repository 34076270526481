import { AiChatInput } from '../common/ai-chat/AiChatInput';
import { useEffect, useState } from 'react';
import useCopilotChats from '../copilotChats/useCopilotChats';

const CopilotInput = ({
  onConfirm,
  isLoading,
  predefinedPrompts,
  disabled,
}: {
  onConfirm: (message: string) => Promise<void>;
  isLoading: boolean;
  disabled: boolean;
  predefinedPrompts?: { [key: string]: string };
}) => {
  const [value, setValue] = useState('');
  const { activeChatId } = useCopilotChats();

  //reset the input when switching chats
  useEffect(() => {
    setValue('');
  }, [activeChatId]);

  return (
    <AiChatInput
      value={value}
      setValue={setValue}
      textFieldVariant="secondary"
      onConfirm={onConfirm}
      isLoading={isLoading}
      predefinedPrompts={predefinedPrompts}
      isCollapsiblePrompts={false}
      disabled={disabled}
    />
  );
};

export default CopilotInput;
