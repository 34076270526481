import { Box, PopoverOrigin } from '@mui/material';
import { FilterButton__deprecated } from '../FilterButton_deprecated';
import {
  SingleSelectFilterPopover,
  SingleSelectFilterPopoverOption,
} from './SingleSelectFilterPopover';
import { useState } from 'react';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { OPTION_ALL } from './utils';
import { FilterButton } from '../FilterButton';
import { useFeatureToggle } from '../useFeatureToggle';

interface SingleSelectFilterProps {
  filterTitleText?: string;
  filterButtonText?: string;
  options: SingleSelectFilterPopoverOption[];
  selectedItemValue: string;
  onSelectItem: (selectedValue: string, isAllSelected: boolean) => void;
  menuOptions?: SingleSelectFilterPopoverOption[];
  selectedMenuOption?: string;
  onSelectMenuOption?: (selectedValue: string) => void;
  onClearItem?: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  icon?: React.ReactNode;
  hideValue?: boolean;
  renderSeparateClearButton?: boolean;
}

export const SingleSelectFilter = ({
  filterTitleText = '',
  filterButtonText = 'All',
  options,
  onSelectItem,
  onClearItem,
  selectedItemValue,
  anchorOrigin,
  transformOrigin,
  icon,
  hideValue,
  menuOptions,
  selectedMenuOption,
  onSelectMenuOption,
  renderSeparateClearButton,
}: SingleSelectFilterProps) => {
  const { newDocumentationUiFilters } = useFeatureToggle();
  const selectedItem = options.find((option) => option.value === selectedItemValue);
  const _filterButtonText = selectedItem?.label ?? filterButtonText;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isPressed = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onSelectMenuAndClose = (selectedValue: string) => {
    if (!onSelectMenuOption) {
      return undefined;
    }

    setAnchorEl(null);
    onSelectMenuOption(selectedValue);
  };

  return (
    <Box sx={{ display: 'flex', minWidth: 0 }} className="single-filter">
      {icon && (
        <IconButton
          variant="flat"
          size="tiny"
          pressed={isPressed}
          onClick={handleClick}
          badgeLabel={
            selectedItem && selectedItem.value !== OPTION_ALL.value && !selectedItem?.hideDot
              ? 1
              : undefined
          }
          badgeVariant="dot"
        >
          <>{icon}</>
        </IconButton>
      )}

      {!icon && !newDocumentationUiFilters && (
        <FilterButton__deprecated
          text={hideValue ? '' : _filterButtonText}
          handleClick={handleClick}
          isPressed={isPressed}
          title={filterTitleText}
          onClearBtn={onClearItem}
          showClearBtn={
            !!onClearItem && !!selectedItem?.value && selectedItem.value !== OPTION_ALL.value
          }
        />
      )}

      {!icon && newDocumentationUiFilters && (
        <FilterButton
          text={hideValue ? '' : _filterButtonText}
          handleClick={handleClick}
          isPressed={isPressed}
          title={filterTitleText}
          onClearBtn={onClearItem}
          showClearBtn={
            !!onClearItem &&
            !!selectedItem?.value &&
            selectedItem.value !== OPTION_ALL.value &&
            !renderSeparateClearButton
          }
        />
      )}

      <SingleSelectFilterPopover
        anchorEl={anchorEl}
        options={options}
        onSelectItem={(selectedValue) => {
          setAnchorEl(null);
          onSelectItem(selectedValue, selectedValue === OPTION_ALL.value);
        }}
        onClose={() => setAnchorEl(null)}
        selectedItem={selectedItemValue}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        menuOptions={menuOptions}
        selectedMenuOption={selectedMenuOption}
        onSelectMenuOption={onSelectMenuAndClose}
      />

      {newDocumentationUiFilters && !!onClearItem && renderSeparateClearButton && (
        <Button variant="flat" size="small" startIconName="Close" onClick={() => onClearItem()}>
          Clear
        </Button>
      )}
    </Box>
  );
};

// This is a wrapper that adds an empty item to the list of options
// The SingleSelectFilter should be refactored to incorporate this functionality
export const SingleSelectFilterWithEmptyItem = ({
  emptyItem,
  ...singleSelectFilterProps
}: SingleSelectFilterProps & {
  emptyItem?: SingleSelectFilterPopoverOption;
}) => {
  const _emptyItem = { ...OPTION_ALL, label: emptyItem?.label ?? OPTION_ALL.label };
  const { options, selectedItemValue, onSelectItem, ...otherProps } = singleSelectFilterProps;
  const _options = [_emptyItem, ...options];
  const selectedItem = options.find((option) => option.value === selectedItemValue) ?? _emptyItem;

  return (
    <SingleSelectFilter
      {...otherProps}
      options={_options}
      selectedItemValue={selectedItem.value}
      onSelectItem={(selectedValue) => {
        onSelectItem(
          selectedValue === _emptyItem.value ? '' : selectedValue,
          selectedValue === _emptyItem.value,
        );
      }}
    />
  );
};
