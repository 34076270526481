export const enum HsPropertyTypes {
  BOOL = 'bool',
  ENUMERATION = 'enumeration',
  DATE = 'date',
  DATE_TIME = 'datetime',
  STRING = 'string',
  NUMBER = 'number',
  //OBJECT_COORDINATES = 'object_coordinates',
  //JSON = 'json',
  //PHONE_NUMBER = 'phone_number',
  MULTISTRING = 'multistring',
}

export const enum HsPropertyFieldTypes {
  BOOLEAN_CHECKBOX = 'booleancheckbox',
  CALCULATION_EQUATION = 'calculation_equation',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SELECT = 'select',
  DATE = 'date',
  FILE = 'file',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  HTML = 'html',
  PHONE_NUMBER = 'phonenumber',
  NUMBER = 'number',
}

export type HsOption = { label: string; value: string; displayOrder: number; hidden: boolean };

type HubspotPropertyBase = {
  name: string;
  label: string;
  options: HsOption[];
  description: string;
  groupName: string;
  hidden: boolean;
  modificationMetadata: {
    archivable: boolean;
    readOnlyDefinition: boolean;
    readOnlyValue: boolean;
  };
};

interface HsFieldBool extends HubspotPropertyBase {
  type: HsPropertyTypes.BOOL;
  fieldType: HsPropertyFieldTypes.BOOLEAN_CHECKBOX | HsPropertyFieldTypes.CALCULATION_EQUATION;
}

interface HsFieldEnumeration extends HubspotPropertyBase {
  type: HsPropertyTypes.ENUMERATION;
  fieldType:
    | HsPropertyFieldTypes.BOOLEAN_CHECKBOX
    | HsPropertyFieldTypes.CHECKBOX
    | HsPropertyFieldTypes.RADIO
    | HsPropertyFieldTypes.SELECT
    | HsPropertyFieldTypes.CALCULATION_EQUATION;
}

interface HsFieldDate extends HubspotPropertyBase {
  type: HsPropertyTypes.DATE;
  fieldType: HsPropertyFieldTypes.DATE;
}

interface HsFieldDateTime extends HubspotPropertyBase {
  type: HsPropertyTypes.DATE_TIME;
  fieldType: HsPropertyFieldTypes.DATE;
}

interface HsFieldString extends HubspotPropertyBase {
  type: HsPropertyTypes.STRING;
  fieldType:
    | HsPropertyFieldTypes.FILE
    | HsPropertyFieldTypes.TEXT
    | HsPropertyFieldTypes.TEXTAREA
    | HsPropertyFieldTypes.CALCULATION_EQUATION
    | HsPropertyFieldTypes.HTML
    | HsPropertyFieldTypes.PHONE_NUMBER;
}

interface HsFieldNumber extends HubspotPropertyBase {
  type: HsPropertyTypes.NUMBER;
  fieldType: HsPropertyFieldTypes.NUMBER | HsPropertyFieldTypes.CALCULATION_EQUATION;
}

// interface HsFieldObjectCoordinates extends HubspotPropertyBase {
//   type: HsPropertyTypes.OBJECT_COORDINATES;
//   fieldType: HsPropertyFieldTypes.TEXT;
// }

// interface HsFieldJson extends HubspotPropertyBase {
//   type: HsPropertyTypes.JSON;
//   fieldType: HsPropertyFieldTypes.TEXT;
// }

// interface HsFieldPhoneNumber extends HubspotPropertyBase {
//   type: HsPropertyTypes.PHONE_NUMBER;
//   fieldType: HsPropertyFieldTypes.PHONE_NUMBER;
// }

export type HubspotField =
  | HsFieldBool
  | HsFieldEnumeration
  | HsFieldDate
  | HsFieldDateTime
  | HsFieldString
  | HsFieldNumber;
// | HsFieldObjectCoordinates
// | HsFieldJson
// | HsFieldPhoneNumber;

export enum HsFilterOperatorsOperators {
  IS_KNOWN = 'IS_KNOWN',
  IS_UNKNOWN = 'IS_UNKNOWN',
  IS_EQUAL_TO = 'IS_EQUAL_TO',
  IS_NOT_EQUAL_TO = 'IS_NOT_EQUAL_TO',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  HAS_EVER_BEEN_EQUAL_TO = 'HAS_EVER_BEEN_EQUAL_TO',
  HAS_NEVER_BEEN_EQUAL_TO = 'HAS_NEVER_BEEN_EQUAL_TO',
  HAS_EVER_CONTAINED = 'HAS_EVER_CONTAINED',
  HAS_NEVER_CONTAINED = 'HAS_NEVER_CONTAINED',
  IS_BETWEEN = 'IS_BETWEEN',
  IS_NOT_BETWEEN = 'IS_NOT_BETWEEN',
  HAS_EVER_BEEN_ANY_OF = 'HAS_EVER_BEEN_ANY_OF',
  HAS_NEVER_BEEN_ANY_OF = 'HAS_NEVER_BEEN_ANY_OF',
  IS_ON_DATE = 'IS_ON_DATE',
  IS_BEFORE_DATE = 'IS_BEFORE_DATE',
  IS_AFTER_DATE = 'IS_AFTER_DATE',
  IS_BEFORE = 'IS_BEFORE',
  IS_AFTER = 'IS_AFTER',
  IS_LESS_THAN_X_DAYS_AGO = 'IS_LESS_THAN_X_DAYS_AGO',
  IS_MORE_THAN_X_DAYS_AGO = 'IS_MORE_THAN_X_DAYS_AGO',
  IS_LESS_THAN_X_DAYS_FROM_NOW = 'IS_LESS_THAN_X_DAYS_FROM_NOW',
  IS_MORE_THAN_X_DAYS_FROM_NOW = 'IS_MORE_THAN_X_DAYS_FROM_NOW',
  UPDATED_IN_LAST_X_DAYS = 'UPDATED_IN_LAST_X_DAYS',
  NOT_UPDATED_IN_LAST_X_DAYS = 'NOT_UPDATED_IN_LAST_DAYS',
  IS_NONE_OF = 'IS_NONE_OF',
  IS_ANY_OF = 'IS_ANY_OF',
  IS_EXACTLY = 'IS_EXACTLY',
  IS_NOT_EXACTLY = 'IS_NOT_EXACTLY',
  CONTAINS_ALL = 'CONTAINS_ALL',
  DOES_NOT_CONTAIN_ALL = 'DOES_NOT_CONTAIN_ALL',
  HAS_EVER_BEEN_EXACTLY = 'HAS_EVER_BEEN_EXACTLY',
  HAS_NEVER_BEEN_EXACTLY = 'HAS_NEVER_BEEN_EXACTLY',
  HAS_EVER_CONTAINED_ALL = 'HAS_EVER_CONTAINED_ALL',
  HAS_NEVER_CONTAINED_ALL = 'HAS_NEVER_CONTAINED_ALL',

  IS_GREATER_THAN = 'IS_GREATER_THAN',
  IS_GREATER_THAN_OR_EQUAL_TO = 'IS_GREATER_THAN_OR_EQUAL_TO',
  IS_LESS_THAN = 'IS_LESS_THAN',
  IS_LESS_THAN_OR_EQUAL_TO = 'IS_LESS_THAN_OR_EQUAL_TO',

  NOT_IN_LIST = 'NOT_IN_LIST',
  IN_LIST = 'IN_LIST',
}
