import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import {
  RuleBuilderData,
  RuleBuilderRowComponentProps,
} from '../../../../common/rule-builder/rule-builder-types';
import { RuleBuilder } from '../../../../common/rule-builder/RuleBuilder';
import { RuleBuilderSelectionRow } from '../../../../common/rule-builder/RuleBuilderSelectionRow';

import { uniqueId } from '../../../../../lib/uniqueId';
import { HubspotFieldsOperatorSelector } from './HubspotFieldsOperatorSelector';
import { HubspotFieldsValueSelector } from './HubspotFieldValueSelector';
import {
  HsFilterOperatorsOperators,
  HsOption,
  HsPropertyFieldTypes,
  HsPropertyTypes,
} from '../hubspot.types';
import { SelectorValueTypes } from '../../../../../types/enums/SelectorValueTypes';
import { validateSweepCriteria } from '../../../../common/rule-builder/validateSweepCriteria';
import { WorkflowWhenEventType, WorkflowWhenFiltersType } from '../types';
import { HubspotHardcodedSelector } from './HubspotEventSelector';
import { useSelector } from 'react-redux';
import {
  selectHubspotEmailsData,
  selectHubspotFormsData,
  selectHubspotPagesData,
} from '../../../../../reducers/hubspotReducer';
import { hsValueToSweepConditionValue } from './utils';
import { HubspotFieldIds, HubspotEventFieldIds, HubspotFilterAddSearchFieldIds } from './types';

const AD_INTERACTION_TYPE_OPTIONS: HsOption[] = [
  {
    displayOrder: 0,
    label: 'Visit',
    value: 'VISIT',
    hidden: false,
  },
  {
    displayOrder: 1,
    label: 'Submission',
    value: 'SUBMISSION',
    hidden: false,
  },
];

const AD_NETWORK_PLACEMENT_OPTIONS: HsOption[] = [
  {
    displayOrder: 0,
    label: 'Partner',
    value: 'PARTNER',
    hidden: false,
  },
  {
    displayOrder: 1,
    label: 'Facebook',
    value: 'FACEBOOK',
    hidden: false,
  },
  {
    displayOrder: 2,
    label: 'Audience Network',
    value: 'AUDIENCE_NETWORK',
    hidden: false,
  },
  {
    displayOrder: 3,
    label: 'Display',
    value: 'DISPLAY',
    hidden: false,
  },
  {
    displayOrder: 4,
    label: 'Instagram',
    value: 'INSTAGRAM',
    hidden: false,
  },
  {
    displayOrder: 5,
    label: 'Search',
    value: 'SEARCH',
    hidden: false,
  },
  {
    displayOrder: 6,
    label: 'Messenger',
    value: 'MESSENGER',
    hidden: false,
  },
  {
    displayOrder: 7,
    label: 'YouTube',
    value: 'YOUTUBE',
    hidden: false,
  },
];

const AD_NETWORK_OPTIONS: HsOption[] = [
  {
    displayOrder: 0,
    label: 'Facebook',
    value: 'FACEBOOK',
    hidden: false,
  },
  {
    displayOrder: 1,
    label: 'LinkedIn',
    value: 'LINKEDIN',
    hidden: false,
  },
  {
    displayOrder: 2,
    label: 'Google',
    value: 'GOOGLE',
    hidden: false,
  },
];

interface RuleBuilderHubspotHardcodedConditionRowData {
  id: string;
  criterion: DeepPartial<SweepCriterion>;
  eventType: WorkflowWhenEventType | WorkflowWhenFiltersType;
}
const useGetOptions = (hsFieldName?: HubspotFieldIds): HsOption[] => {
  const formData = useSelector(selectHubspotFormsData);
  const pagesData = useSelector(selectHubspotPagesData);
  const emailsData = useSelector(selectHubspotEmailsData);

  const getData = () => {
    switch (hsFieldName) {
      case HubspotEventFieldIds.FORM:
        return formData.map((option, idx) => ({
          displayOrder: idx,
          label: option.name,
          value: option.formId,
          hidden: false,
        }));
      case HubspotEventFieldIds.PAGE:
        return pagesData.map((option, idx) => ({
          displayOrder: idx,
          label: option.name,
          value: option.pageId,
          hidden: false,
        }));
      case HubspotEventFieldIds.EMAILS:
        return emailsData.map((option, idx) => ({
          displayOrder: idx,
          label: option.name,
          value: option.emailId,
          hidden: false,
        }));
      case HubspotEventFieldIds.AD_INTERACTION_TYPE:
        return AD_INTERACTION_TYPE_OPTIONS;
      case HubspotEventFieldIds.AD_NETWORK_PLACEMENT:
        return AD_NETWORK_PLACEMENT_OPTIONS;
      case HubspotEventFieldIds.AD_NETWORK:
        return AD_NETWORK_OPTIONS;

      default:
        return [];
    }
  };
  return getData();
};

const hubspotEventFieldIdsValues: HubspotFieldIds[] = Object.values(HubspotEventFieldIds);
const hubspotFilterAddSearchFieldIdsValues: HubspotFieldIds[] = Object.values(
  HubspotFilterAddSearchFieldIds,
);

const getOperatorsForField = (fieldId?: HubspotFieldIds) => {
  if (!fieldId) {
    return [];
  }

  if (hubspotEventFieldIdsValues.includes(fieldId)) {
    return [
      HsFilterOperatorsOperators.IS_ANY_OF,
      HsFilterOperatorsOperators.IS_NONE_OF,
      HsFilterOperatorsOperators.IS_KNOWN,
      HsFilterOperatorsOperators.IS_UNKNOWN,
    ];
  }
  if (hubspotFilterAddSearchFieldIdsValues.includes(fieldId)) {
    return [
      HsFilterOperatorsOperators.CONTAINS,
      HsFilterOperatorsOperators.STARTS_WITH,
      HsFilterOperatorsOperators.ENDS_WITH,
      HsFilterOperatorsOperators.IS_KNOWN,
    ];
  }
  return [];
};

function RuleBuilderHubspotHardcodedConditionRow({
  data,
  index,
  onRowChange,
  onRowDelete,
  readOnly,
}: RuleBuilderRowComponentProps<RuleBuilderHubspotHardcodedConditionRowData>) {
  const { criterion, eventType } = data;

  const [_hsPropertyType, _hsPropertyFieldType] = criterion.fieldType
    ? criterion.fieldType.split(':')
    : [undefined, undefined];
  const hsPropertyType = _hsPropertyType as HsPropertyTypes | undefined;
  const hsPropertyFieldType = _hsPropertyFieldType as HsPropertyFieldTypes | undefined;

  const hsFieldName: HubspotFieldIds | undefined = criterion?._fieldIds?.length
    ? (criterion._fieldIds[0] as HubspotFieldIds)
    : undefined;

  const valueOptions = useGetOptions(hsFieldName);

  const hubspotFieldSelector = (
    <HubspotHardcodedSelector
      onChange={(option) => {
        const newData: RuleBuilderHubspotHardcodedConditionRowData = {
          ...data,
          criterion: {
            ...criterion,
            _fieldIds: [option.value],
            fieldType: option.data?.type,
            operator: '',
            value: '',
          },
        };
        onRowChange(newData);
      }}
      eventType={eventType}
      value={hsFieldName}
      disabled={readOnly}
    />
  );

  const operatorsSelector = hsPropertyType ? (
    <HubspotFieldsOperatorSelector
      hsPropertyType={hsPropertyType as HsPropertyTypes}
      operator={criterion.operator as HsFilterOperatorsOperators}
      disabled={readOnly}
      onChange={(operator) => {
        onRowChange({
          ...data,
          criterion: {
            ...criterion,
            operator,
            value: '',
          },
        });
      }}
      operators={getOperatorsForField(hsFieldName)}
    />
  ) : null;

  const valueSelector =
    criterion.operator && hsFieldName && hsPropertyType ? (
      <HubspotFieldsValueSelector
        hsPropertyType={hsPropertyType}
        hsPropertyFieldType={hsPropertyFieldType}
        value={criterion.value}
        disabled={readOnly}
        removeBorders
        onChange={(value) => {
          onRowChange({
            ...data,
            criterion: {
              ...criterion,
              value: hsValueToSweepConditionValue(value),
            },
          });
        }}
        operator={criterion.operator as HsFilterOperatorsOperators}
        options={valueOptions}
      />
    ) : null;

  return (
    <RuleBuilderSelectionRow
      lineNumber={index + 1}
      firstComponent={hubspotFieldSelector}
      secondComponent={operatorsSelector}
      thirdComponent={valueSelector}
      showDelete
      onDelete={onRowDelete}
      readonly={readOnly}
    />
  );
}

interface HubspotHardcodedRuleBuilderProps {
  sweepCondition?: DeepPartial<SweepCondition>;
  onChange: (newSweepCondition: DeepPartial<SweepCondition>) => any;
  readOnly?: boolean;
  headerRowComponent?: JSX.Element | string;
  eventType: WorkflowWhenEventType | WorkflowWhenFiltersType;
}

export type HubspotHardcodedRuleBuilderRef = {
  triggerValidation: () => string[];
};

export const HubspotHardcodedRuleBuilder = forwardRef<
  HubspotHardcodedRuleBuilderRef,
  HubspotHardcodedRuleBuilderProps
>(({ readOnly, headerRowComponent, sweepCondition, onChange, eventType }, ref) => {
  const ruleBuilderData: RuleBuilderData<RuleBuilderHubspotHardcodedConditionRowData> =
    useMemo(() => {
      const entries: RuleBuilderHubspotHardcodedConditionRowData[] = (
        sweepCondition?.criteria || []
      ).map((criterion) => {
        const id = criterion?.criterionId || uniqueId();
        return {
          id,
          criterion: criterion || {
            criterionId: id,
          },
          eventType,
        };
      });
      return {
        entries,
        logicString: sweepCondition?.criteriaLogic || '',
        eventType,
      };
    }, [sweepCondition?.criteria, sweepCondition?.criteriaLogic, eventType]);

  const newRowProvider = useCallback(() => {
    const newId = uniqueId();
    const data: RuleBuilderHubspotHardcodedConditionRowData = {
      id: newId,
      criterion: {
        criterionId: newId,
        valueType: SelectorValueTypes.LITERAL,
      },
      eventType,
    };
    return data;
  }, [eventType]);

  const [errorIds, setErrorIds] = useState<string[]>([]);
  const [displayErrors, setDisplayErrors] = useState(false);

  const triggerValidation = useCallback(() => {
    const criteria = ruleBuilderData.entries.map((c) => c.criterion);
    const _errorIds = validateSweepCriteria(
      criteria,
      /*excludeFirstEntryFromValidationIfEmpty*/ true,
      true,
    );
    setErrorIds(_errorIds);
    if (_errorIds.length) {
      setDisplayErrors(true);
    }
    return _errorIds;
  }, [ruleBuilderData.entries]);

  useImperativeHandle(
    ref,
    () => ({
      triggerValidation,
    }),
    [triggerValidation],
  );

  useEffect(() => {
    triggerValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RuleBuilder
      readonly={readOnly}
      onChange={(data) => {
        if (data.entries.length === 0) {
          onChange({});
          return;
        }
        const newSweepCondition: DeepPartial<SweepCondition> = {
          criteriaLogic: data.logicString,
          criteria: data.entries.length ? data.entries.map((entry) => entry.criterion) : undefined,
        };

        onChange(newSweepCondition);
      }}
      newRowProvider={newRowProvider}
      ruleBuilderData={ruleBuilderData}
      RowComponent={RuleBuilderHubspotHardcodedConditionRow}
      errorIds={errorIds}
      displayErrors={displayErrors}
      headerRowComponent={headerRowComponent}
    />
  );
});
