import { useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { ConfirmDialog } from '../common/dialogs/ConfirmDialog';
import { colors, Typography } from '@sweep-io/sweep-design';

const RenameChatDialog = ({
  initialName,
  isOpen,
  onChange,
  closeDialog,
}: {
  initialName: string;
  isOpen: boolean;
  onChange: (name: string) => void;
  closeDialog: () => void;
}) => {
  const [updatedName, setUpdateName] = useState(initialName);
  const charLength = updatedName.length;
  const isValid = charLength > 0;

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && isValid) {
      event.stopPropagation();
      onConfirm();
    }
  };

  const onConfirm = () => {
    onChange(updatedName);
    closeDialog();
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      titleVariant="h2-bold"
      title="Rename Chat"
      confirmText="Done"
      onConfirm={onConfirm}
      onCancel={closeDialog}
      dialogContentSx={{ width: '554px' }}
      disableConfirmButton={!isValid}
    >
      <Stack alignItems="flex-end" spacing={0.5}>
        <TextField
          fullWidth
          autoFocus
          placeholder="Enter chat name"
          value={updatedName}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            setUpdateName(e.target.value);
          }}
          inputProps={{ maxlength: 150 }}
        />
        <Typography variant="caption" color={colors.grey[700]}>
          {charLength}/150 characters
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};

export default RenameChatDialog;
