import * as React from "react";
const SvgEnrollmentRulesImg = (props) => /* @__PURE__ */ React.createElement("svg", { width: 171, height: 100, viewBox: "0 0 171 100", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { x: 0.25, width: 170, height: 100, rx: 4, fill: "#F6F6F6", style: {
  fill: "#F6F6F6",
  fill: "color(display-p3 0.9647 0.9647 0.9647)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 12.25, y: 12, width: 14.6487, height: 8, rx: 2, fill: "#FF9900", style: {
  fill: "#FF9900",
  fill: "color(display-p3 1.0000 0.6000 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 12.25, y: 24, width: 43.0178, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 59.2678, y: 24, width: 27.9934, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 12.25, y: 36, width: 41.2893, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 57.5393, y: 36, width: 28.6895, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 90.2288, y: 36, width: 28.6895, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 12.25, y: 52, width: 14.6487, height: 8, rx: 2, fill: "#F371E6", style: {
  fill: "#F371E6",
  fill: "color(display-p3 0.9542 0.4413 0.9029)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 12.25, y: 64, width: 28.6895, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 44.9395, y: 64, width: 13.5295, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 62.469, y: 64, width: 33.2488, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 123.418, y: 80, width: 34.8315, height: 8, rx: 2, fill: "#8FCCFF", style: {
  fill: "#8FCCFF",
  fill: "color(display-p3 0.5608 0.8000 1.0000)",
  fillOpacity: 1
} }));
export default SvgEnrollmentRulesImg;
