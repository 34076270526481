import { Box, Button, CircularProgress, MenuList, Popover, SxProps, Theme } from '@mui/material';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import {
  ReactNode,
  useEffect,
  useState,
  MouseEvent,
  FunctionComponent,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { CenteredCircularProgress } from '../CenteredCircularProgress';
import { ArrowLeft as BackIcon } from '@sweep-io/sweep-design/dist/icons';
import { Typography, colors, IconButton } from '@sweep-io/sweep-design';
import { ItemsPageBox, NestedSelectorButton } from './nestedSelectorStyledComponents';
import { isSamePath, pathFragmentFromItem } from './nestedSelectorHelper';
import { NestedItemClickType, NestedSelectorItem } from './NestedSelectorItem';
import { NestedPathLabel } from './NestedPathLabel';
import { SearchInput } from '../SearchInput';
import { filterItemsBySearch } from '../../../lib/filterItemsBySearch';

export interface NestedItem<T = any> {
  label: string;
  value: string;
  data?: T;
  shouldResolveSubItems?: boolean;
  subItems?: NestedItem<T>[];
  disabled?: boolean;
  tooltip?: ReactNode;
}

export interface NestedSelectorPathFragment {
  label: string;
  value: string;
  data?: any; // used by formulas in automations
}

export type NestedSelectorPath = NestedSelectorPathFragment[];

export interface NestedSelectorPageOfItems<T = any> {
  path: NestedSelectorPath;
  items: NestedItem<T>[];
}

export interface NestedSelectorProps {
  initialItems?: NestedItem[];
  resolveOpen?: (
    currentValue: NestedSelectorPath,
  ) => NestedSelectorPageOfItems[] | Promise<NestedSelectorPageOfItems[]>;
  resolveChildren?: (item: NestedItem) => NestedItem[] | Promise<NestedItem[]>;
  resolveOnlyOnFirstOpen?: boolean;
  onChange: (item: NestedItem, fullPath: NestedSelectorPath) => void | Promise<void>;
  dropDownWidth?: number;
  placeholder?: string;
  nestedPath: NestedSelectorPath; // Controlled
  ref?: any;
  additionalPopoverStyles?: SxProps<Theme>;
  nestedSelectorButtonStyle?: SxProps<Theme>;
  readonly?: boolean;
  onBlurOrCancel?: (reason: 'backdropClick' | 'escapeKeyDown') => any;
  startIcon?: ReactNode;
  loadingItem?: {
    label: string;
    tooltip?: string;
  };
  customButtonText?: string;
  customButtonSx?: SxProps;
  useCustomButton?: boolean;
  customButtonStartIcon?: ReactNode;
  customButtonEndIcon?: ReactNode;
  displayLeadingObjectName?: boolean;
  objectType?: string;
  ActionButton?: FunctionComponent<{ closeSelector: () => void }>;
  EmptyState?: FunctionComponent<{ closeSelector: () => void }>;
  allowParentItemsSelection?: boolean;
  CustomButtonComponent?: FunctionComponent<{ onClick: (i: any) => void; disabled: boolean }>;
  HeaderComponent?: ReactNode;
  labelPretext?: string;
  onClose?: () => void;
}

const getBasePath = (nestedPath: NestedSelectorPath) => nestedPath.slice(0, -1);

export type NestedSelectorRef = {
  reset: () => void;
};

export const NestedSelector = forwardRef<NestedSelectorRef, NestedSelectorProps>(
  (
    {
      initialItems,
      resolveChildren,
      resolveOpen,
      dropDownWidth = 400,
      nestedPath,
      onChange,
      additionalPopoverStyles,
      readonly,
      nestedSelectorButtonStyle,
      onBlurOrCancel,
      startIcon,
      loadingItem,
      resolveOnlyOnFirstOpen,
      customButtonText,
      customButtonSx,
      useCustomButton,
      customButtonStartIcon,
      customButtonEndIcon,
      displayLeadingObjectName,
      objectType,
      ActionButton,
      EmptyState,
      placeholder = '',
      allowParentItemsSelection,
      CustomButtonComponent,
      HeaderComponent,
      labelPretext,
      onClose,
    },
    ref,
  ) => {
    // Local State and Refs
    const [anchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
    const isOpen = Boolean(anchorEl);

    const [pagesOfItems, setPagesOfItems] = useState<NestedSelectorPageOfItems[] | undefined>(
      initialItems ? [{ path: [], items: initialItems }] : undefined,
    );

    // current navigation path. It is based on the path
    const [currentBasePath, setCurrentBasePath] = useState<NestedSelectorPath>(() =>
      getBasePath(nestedPath),
    );

    const [shouldFocusItem, setShouldFocusItem] = useState(false);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Variables
    const currentBasePathIndex = currentBasePath.length;
    const isNotBasePath = Boolean(currentBasePath.length);
    const isEmpty = !Boolean(nestedPath.length);

    // Effects
    useEffect(() => {
      setShouldFocusItem(false);
    }, [search]);

    useEffect(() => {
      setSearch('');
    }, [pagesOfItems]);

    useEffect(() => {
      if (isLoading) {
        setShouldFocusItem(false);
      } else {
        setShouldFocusItem(true);
      }
    }, [isLoading]);

    const hasItems = !!pagesOfItems || nestedPath.length > 0;
    const shouldResolve = !hasItems || !resolveOnlyOnFirstOpen;

    // Event Functions
    const onOpen = useCallback(async () => {
      setPagesOfItems(initialItems ? [{ path: [], items: initialItems }] : undefined);
      setCurrentBasePath(getBasePath(nestedPath));

      if (resolveOpen && shouldResolve) {
        setIsLoading(true);
        const itemsLists = await resolveOpen(nestedPath);
        setPagesOfItems(itemsLists);
        setIsLoading(false);
      }
      setSearch('');
    }, [initialItems, nestedPath, resolveOpen, shouldResolve]);

    const reset = useCallback(async () => {
      await onOpen();
      setCurrentBasePath([]);
    }, [onOpen]);

    useImperativeHandle(ref, () => ({
      reset,
    }));

    const close = useCallback(async () => {
      setTimeout(() => {
        // Timeout to set for the close animation to finish
        setCurrentBasePath(getBasePath(nestedPath));
      }, 500);

      setSearch('');
      onClose?.();
    }, [nestedPath, onClose]);

    useEffect(() => {
      if (isOpen) {
        onOpen();
      } else {
        close();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
      setPopoverAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setPopoverAnchorEl(null);
    };

    const handleItemClick = async (
      item: NestedItem,
      basePath: NestedSelectorPath,
      type: NestedItemClickType,
    ): Promise<boolean> => {
      if (pagesOfItems) {
        const itemPathFragment = pathFragmentFromItem(item);
        const newPath = [...currentBasePath, itemPathFragment];

        let subItems = item.subItems;
        if (!item.subItems && item.shouldResolveSubItems && resolveChildren) {
          setIsLoading(true);
          try {
            subItems = await resolveChildren(item);
            setPagesOfItems([
              ...pagesOfItems.slice(0, currentBasePathIndex + 1),
              {
                path: newPath,
                items: subItems,
              },
            ]);
          } catch {
            setIsLoading(false);
            return false;
          }
          setIsLoading(false);
        }
        const shouldGoToSubItems =
          type === NestedItemClickType.EnterSubItem || !allowParentItemsSelection;
        if (shouldGoToSubItems && subItems) {
          const nextItemListPath = _get(pagesOfItems, [currentBasePathIndex + 1, 'path']);
          if (!isSamePath(newPath, nextItemListPath)) {
            setPagesOfItems([
              ...pagesOfItems.slice(0, currentBasePathIndex + 1),
              {
                path: newPath,
                items: subItems,
              },
            ]);
          }

          setCurrentBasePath(newPath);
        } else {
          onChange && onChange(item, newPath);
          handlePopoverClose();
        }
      }
      return true;
    };

    const handleBackClick = useCallback(() => {
      if (currentBasePath.length > 0) {
        const newBasePath = [...currentBasePath];
        newBasePath.pop();
        setCurrentBasePath(newBasePath);
      }
    }, [currentBasePath]);

    const renderLoader = () => {
      return (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,.0)',
            zIndex: 11111100,
          }}
        >
          <CenteredCircularProgress />
        </Box>
      );
    };

    const renderNav = () => {
      return (
        <Box
          sx={{
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '12px',
          }}
          data-testid="nested-selector-nav"
        >
          {currentBasePath.length && <NestedPathLabel values={currentBasePath} highlightLast />}
        </Box>
      );
    };

    return (
      <>
        {CustomButtonComponent && (
          <CustomButtonComponent onClick={handleButtonClick} disabled={!!readonly} />
        )}
        {useCustomButton && (
          <CustomButton
            handleClick={handleButtonClick}
            customButtonText={customButtonText ?? ''}
            sx={customButtonSx}
            customButtonStartIcon={customButtonStartIcon}
            customButtonEndIcon={customButtonEndIcon}
            disabled={readonly}
          />
        )}
        {!useCustomButton && !CustomButtonComponent && (
          <NestedSelectorButton
            data-testid="nested-selector-button"
            onClick={handleButtonClick}
            fullWidth
            disabled={readonly}
            sx={nestedSelectorButtonStyle}
            startIcon={!isEmpty && startIcon ? startIcon : undefined}
            isOpen={isOpen}
          >
            {isEmpty ? (
              <Box
                component={'span'}
                sx={{
                  color: colors.grey[700],
                  opacity: 0.42, //adjusting to input placeholder styles
                }}
              >
                {placeholder}
              </Box>
            ) : (
              <NestedPathLabel values={nestedPath} firstNodePretext={labelPretext} />
            )}
          </NestedSelectorButton>
        )}
        <Popover
          disableAutoFocus={true}
          data-testid={'nested-selector-menu'}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={(event, reason) => {
            onBlurOrCancel && onBlurOrCancel(reason);
            handlePopoverClose && handlePopoverClose();
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            marginTop: 0,
            '.MuiPopover-paper': {
              boxShadow: 1,
              borderRadius: 1,
            },

            ...additionalPopoverStyles,
          }}
        >
          <Box
            sx={{
              width: dropDownWidth,
              overflow: 'hidden',
            }}
          >
            {isLoading && renderLoader()}
            <Box
              sx={{
                paddingBottom: '12px', //To keep items aligned if we're displaying more than SearchInput
                borderBottom: `1px solid ${colors.grey[300]}`,
              }}
            >
              {HeaderComponent}
              {displayLeadingObjectName && (
                <Box
                  sx={{
                    padding: '14px 24px',
                    borderBottom: `1px solid ${colors.grey[300]}`,
                  }}
                >
                  <Typography variant="body">{objectType}</Typography>
                </Box>
              )}
              <Box
                sx={{
                  padding: '12px',
                  paddingBottom: 0,
                  display: 'flex',
                  flexWrap: 'nowrap',
                  width: '100%',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <SearchInput
                  TextFieldProps={{
                    value: search,
                    onChange: (e) => {
                      setSearch(e.target.value);
                    },
                  }}
                  dataTestId="nested-selector-search-input"
                  onClearButtonClick={() => setSearch('')}
                  autoFocus
                />

                {ActionButton && <ActionButton closeSelector={handlePopoverClose} />}
              </Box>
              {isNotBasePath && (
                <Box sx={{ padding: '0px 20px' }}>
                  <Box
                    sx={{
                      marginTop: '5px',
                      marginLeft: '-6px',
                      marginBottom: '5px',
                    }}
                  >
                    <IconButton size="tiny" variant="flat" onClick={handleBackClick}>
                      <BackIcon />
                    </IconButton>
                  </Box>
                  <Box>{renderNav()}</Box>
                </Box>
              )}
            </Box>
            <Box sx={{ minHeight: 200 }}>
              {pagesOfItems && (
                <Box
                  sx={{
                    width: `${dropDownWidth * (pagesOfItems.length + 1)}px`,
                    transition: '.2s',
                  }}
                  marginLeft={-(currentBasePath.length * dropDownWidth) + 'px'}
                  data-testid="nested-selector-items"
                >
                  {pagesOfItems.map((itemList, idx) => {
                    const filteredItems = filterItemsBySearch<NestedItem>(
                      itemList.items,
                      search,
                      'label',
                    );
                    const testId =
                      currentBasePath.length === idx
                        ? 'nested-selector-visible-items'
                        : 'nested-selector-not-visible-items';

                    return (
                      <ItemsPageBox
                        key={itemList.path.map((p) => p.label).join('/')}
                        data-testid={testId}
                        sx={{
                          width: dropDownWidth + 'px',
                        }}
                      >
                        <MenuList>
                          {loadingItem && (
                            <NestedSelectorItem
                              item={{
                                label: loadingItem.label,
                                value: 'LOADING_ITEM',
                              }}
                              tooltip={loadingItem.tooltip}
                              hideTooltipIcon
                              startIcon={
                                <CircularProgress
                                  size="10px"
                                  sx={{
                                    marginRight: '14px',
                                    color: colors.grey[500],
                                  }}
                                />
                              }
                              variant="italic"
                              disabled={true}
                              basePath={[]}
                            />
                          )}

                          {!filteredItems.length && EmptyState && (
                            <EmptyState closeSelector={handlePopoverClose} />
                          )}

                          {filteredItems.map((item) => (
                            <NestedSelectorItem
                              key={item.value}
                              basePath={itemList.path}
                              item={item}
                              onClick={handleItemClick}
                              selected={_isEqual(pathFragmentFromItem(item), nestedPath[idx])}
                              shouldFocus={shouldFocusItem}
                              disabled={item.disabled}
                              tooltip={item.tooltip}
                            />
                          ))}
                        </MenuList>
                      </ItemsPageBox>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Box>
        </Popover>
      </>
    );
  },
);

const CustomButton = ({
  handleClick,
  sx,
  customButtonText,
  customButtonStartIcon,
  customButtonEndIcon,
  disabled,
}: {
  handleClick: any;
  sx?: SxProps;
  customButtonText: string;
  customButtonStartIcon?: React.ReactNode;
  customButtonEndIcon?: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <Button
      disabled={disabled}
      variant="text"
      color="secondary"
      onClick={handleClick}
      sx={{
        padding: '0 11px',
        '&:hover': {
          color: colors.blue[600],
        },
        ...sx,
      }}
      data-testid="nested-selector-button"
      startIcon={customButtonStartIcon}
      endIcon={customButtonEndIcon}
    >
      <Typography variant="caption-bold">{customButtonText}</Typography>
    </Button>
  );
};
