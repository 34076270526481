import useEventListener from '../components/common/useEventListener';
import { useCallback } from 'react';

const useHandleMessages = (
  messagesNames: string[],
  handleMessageCb: (event: MessageEvent) => any,
) => {
  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (!event.data.action) {
        return;
      }
      if (messagesNames.includes(event.data.action)) {
        handleMessageCb(event);
      }
    },
    [handleMessageCb, messagesNames],
  );
  useEventListener('message', handleMessage);
};

export default useHandleMessages;
