import { Autocomplete, TextField, MenuItem, CircularProgress, TextFieldProps } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { ChevronDown } from '@sweep-io/sweep-design/dist/icons';
import {
  ObjectTypePropertyFilterType,
  objectTypesPropertyFilter,
} from '../../hooks/useObjectTypesWithFetch';

export interface ObjectTypesAutocompleteProps {
  onChange?: (event: any, newValue: ObjectTypesAutocompleteItem | null) => any;
  disabled?: boolean;
  value?: string;
  TextFieldProps?: TextFieldProps;
  objectTypes?: ObjectTypeName[];
  isLoading?: boolean;
  error?: string;
  objectTypeFilters?: ObjectTypePropertyFilterType;
}

export interface ObjectTypesAutocompleteItem extends ObjectTypeName {
  isLoadingOption?: boolean;
}

export const ObjectTypesAutocomplete = ({
  onChange,
  disabled,
  value,
  TextFieldProps,
  objectTypes,
  isLoading,
  error,
  objectTypeFilters,
}: ObjectTypesAutocompleteProps) => {
  const _objectTypes = objectTypesPropertyFilter({
    objectTypes,
    filters: objectTypeFilters || {},
  });

  //if loading, make the first option a "loading..." item
  const options: ObjectTypesAutocompleteItem[] = isLoading
    ? [
        {
          label: '', //should be here for typescript
          objectType: '', //should be here for typescript
          isLoadingOption: true,
        },
        ...(_objectTypes || []),
      ]
    : _objectTypes || [];

  const selectedOption = options.find((option) => option.objectType === value) ?? null;

  return (
    <Autocomplete
      fullWidth
      sx={{
        //style overrides to be consistent with sweep design system of "select"
        '.MuiAutocomplete-popupIndicator': {
          transform: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      }}
      disableClearable={!!selectedOption} // https://github.com/mui/material-ui/issues/29046
      options={options}
      onChange={onChange}
      value={selectedOption}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          placeholder="Choose Object"
          helperText={error}
          error={!!error}
        />
      )}
      isOptionEqualToValue={(option: any, value) => option.objectType === value?.objectType}
      renderOption={(props, { label, isLoadingOption }) => {
        const { key, id, ...rest } = props; //separate key from the other props to avoid a console error
        return (
          <MenuItem key={key + id} {...rest} disabled={isLoadingOption}>
            {isLoadingOption ? (
              <Typography variant="body" color={colors.storm[400]} fontStyle="italic">
                <CircularProgress
                  size="10px"
                  sx={{
                    marginRight: '14px',
                    color: colors.grey[500],
                  }}
                />
                Loading....
              </Typography>
            ) : (
              label
            )}
          </MenuItem>
        );
      }}
      popupIcon={<ChevronDown />}
      disabled={disabled}
    />
  );
};
