import { Box } from '@mui/material';
import { IconButton, colors } from '@sweep-io/sweep-design';
import { useEffect } from 'react';
import { HEADER_TOP_ENDORNMENT } from './helpers';
import { ConfigurationType } from './types';
import { CONFIGURATION_CANVAS_LEFT_PANEL_WIDTH } from '../../pages/configuration-canvas/consts';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { useDependencies } from './hooks/useDependencies';
import DependenciesPaywallBlock from './DependenciesPaywallBlock';
import { isCustomObjectByName } from '../cpq/utils';
import { UI_EVENTS } from '../../../services/events';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { DependenciesContentWrapper } from './DependenciesContentWrapper';
import usePaywall from '../../dashboard/paywall/usePaywall';
import { useTags } from './annotations/tags/useTags';
import { ConfigurationItem } from './DependenciesTypes';

interface DependenciesSideDialogProps {
  crmOrgId: string;
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
  staticView?: boolean;
}

export const DependenciesSideDialog = ({
  crmOrgId,
  configurationItem,
  type,
  staticView,
}: DependenciesSideDialogProps) => {
  const { isPaywallActive } = usePaywall();
  const { objectName, objectApiNames } = configurationItem ?? {};
  const { clearTagOrUserConfigurations } = useTags(crmOrgId);
  const { handleCloseDependencies } = useDependencies(crmOrgId);
  const _objectName = objectApiNames?.[0] ?? objectName;
  const isPaywall = isPaywallActive && _objectName && isCustomObjectByName(_objectName);

  const sendBiEvent = useSendBiEvent();
  useEffect(() => {
    const props = {
      element: type,
      object: _objectName,
    };
    sendBiEvent({ name: UI_EVENTS.documentationOpenCard, props });
  }, [sendBiEvent, _objectName, type]);

  useEffect(() => {
    //clear tags after closing side dialog
    return () => {
      clearTagOrUserConfigurations();
    };
  }, [clearTagOrUserConfigurations]);

  return (
    <Box
      key={configurationItem.id}
      data-testid="dependencies-side-dialog"
      sx={{
        boxShadow: 1,
        position: 'absolute',
        top: '16px',
        background: colors.white,
        left: CONFIGURATION_CANVAS_LEFT_PANEL_WIDTH + 16,
        width: '464px',
        height: 'calc(100% - 32px)', //padding
        borderRadius: 1,
        overflow: 'hidden',
        zIndex: 2,
        ...(staticView ? staticViewSx : {}),
      }}
    >
      <Box
        sx={{
          background: configurationItem.color ?? colors.blue[100],
          width: '100%',
          height: HEADER_TOP_ENDORNMENT,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pr: 1,

          //TODO add option to design system
          button: {
            '&:hover': {
              backgroundColor: 'transparent',
              overflow: 'hidden',

              '&:before': {
                content: '""',
                position: 'absolute',
                height: '100%',
                width: '100%',
                backgroundColor: colors.grey[200],
                mixBlendMode: 'soft-light',
                zIndex: '1',
              },
            },
          },
        }}
        data-testid="dependencies-side-dialog-close-button"
      >
        <IconButton variant="flat" size="tiny" onClick={handleCloseDependencies}>
          <Close />
        </IconButton>
      </Box>

      <Box display="flex" flexDirection="column" height={`calc(100% - ${HEADER_TOP_ENDORNMENT})`}>
        {isPaywall && <DependenciesPaywallBlock objectName={_objectName} />}
        {!isPaywall && (
          <DependenciesContentWrapper
            crmOrgId={crmOrgId}
            configurationItem={configurationItem}
            type={type}
          />
        )}
      </Box>
    </Box>
  );
};

const staticViewSx = {
  position: 'relative',
  left: 'auto',
  top: '0',
  height: '100%',
  minWidth: '464px',
  overflow: 'auto',
};
