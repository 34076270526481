import { Box, Stack } from '@mui/material';
import usePermission from '../common/permissions/usePermission';
import { colors, Tag, Tooltip, Typography } from '@sweep-io/sweep-design';
import { ObjectTypeChip } from '../common/ObjectTypeChip';
import { getDisplayLineTexts } from './helper';
import ListItemActionCard from '../common/ListItemActionCard';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { useAutomationsContext } from './AutomationsContext';
import { AutomationsListProps } from './AutomationsListProps';
import AutomationActionText from './AutomationActionText';

export const AutomationsList = ({
  automations,
  crmOrgId,
  selectedId,
  setHoveredItem,
  onEdit,
  getActionsMenu,
  onActionMenuClick,
}: AutomationsListProps & {
  setHoveredItem?: (automationId?: string) => void;
  selectedId?: string;
}) => {
  const dataByVariant = useAutomationsContext();
  const [isAllowedEdit] = usePermission([dataByVariant.permissions.edit]);
  const getContentColor = (active: boolean) => (active ? colors.grey[700] : colors.grey[500]);
  const getTitleColor = (active: boolean) => (active ? colors.black : colors.grey[700]);

  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });

  return (
    <Box
      className="automations-list-scroll"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `100%`,
        overflowY: 'auto',
      }}
    >
      {automations?.map((automationItem: AutomationStructureNew) => {
        const { triggerText, actionText } = getDisplayLineTexts(automationItem);

        return (
          <Box
            key={`${automationItem.automationId}${automationItem.versionId}`}
            onMouseEnter={() => setHoveredItem && setHoveredItem(automationItem.automationId)}
            onMouseLeave={() => setHoveredItem && setHoveredItem(undefined)}
          >
            <ListItemActionCard
              isSelected={automationItem.automationId === selectedId}
              title={automationItem.name}
              onLineClick={() => onEdit(automationItem)}
              isAllowedBtn={isAllowedEdit}
              titleSX={{ color: getTitleColor(automationItem.isActive) }}
              headerRightContent={
                <>
                  {automationItem.notifyAdmin && (
                    <Tooltip title="Pending admin approval" placement="top">
                      <span>
                        <Tag startIconName="Stamp" iconColor={colors.night[900]} />
                      </span>
                    </Tooltip>
                  )}
                  {!automationItem.isActive && (
                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                      <Typography variant="caption" color={colors.blush[600]}>
                        Inactive
                      </Typography>
                    </Box>
                  )}
                  {automationItem.status === DeployStatusForTable.Pending &&
                    !automationItem.notifyAdmin && (
                      <Tooltip title="Updates pending deploy" placement="top">
                        <span>
                          <Tag startIconName="Refresh" iconColor={colors.night[900]} />
                        </span>
                      </Tooltip>
                    )}
                  {automationItem.status === DeployStatusForTable.Draft && (
                    <Tag startIconName="Edit" label="Draft" iconColor={colors.night[900]} />
                  )}
                  {automationItem.objectName && (
                    <ObjectTypeChip
                      label={
                        objectTypesByName[automationItem.objectName]?.label ||
                        automationItem.objectName
                      }
                      objectType={automationItem.objectName}
                      tagVariant="tiny"
                    />
                  )}
                </>
              }
              content={
                <Box sx={{ color: getContentColor(automationItem.isActive) }}>
                  {triggerText && `${triggerText}:`}
                  <Stack gap="2px">
                    {actionText.map((el, index) => (
                      <AutomationActionText
                        key={index}
                        element={el}
                        color={getContentColor(automationItem.isActive)}
                      />
                    ))}
                  </Stack>
                </Box>
              }
              actionsMenu={getActionsMenu(automationItem)}
              onActionMenuClick={onActionMenuClick(automationItem)}
            />
          </Box>
        );
      })}
    </Box>
  );
};
