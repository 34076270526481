import { Box } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';

export const DialogAbsoluteCloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        right: 24,
        top: 24,
        zIndex: 1,
        height: 'fit-content !important',
      }}
    >
      <IconButton onClick={onClose} variant="flat">
        <Close />
      </IconButton>
    </Box>
  );
};
