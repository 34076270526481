import { useCallback } from 'react';
import { useConfigurationCanvasContext } from './ConfigurationCanvasContext';
import { useFunnelMapApiFacade } from '../../../apis/facades/funnel-map/useFunnelMapApiFacade';
import { funnelMapToFunnelMapPutPayload } from '../../../apis/facades/funnel-map/funnelMapToFunnelMapPutPayload';
import usePermission from '../../common/permissions/usePermission';

export const useSave = () => {
  const { setLastAttemptedFunnelMapSave } = useConfigurationCanvasContext();
  const [hasFunnelMapSavePermissions] = usePermission(['edit:funnel-maps']);

  const { put_funnelMap } = useFunnelMapApiFacade();

  const saveFunnelMapIfAllowed = useCallback(
    async (funnelMap: FunnelMap) => {
      setLastAttemptedFunnelMapSave(funnelMap);
      if (hasFunnelMapSavePermissions) {
        const funnelMapPutPayload = funnelMapToFunnelMapPutPayload(funnelMap);
        await put_funnelMap(funnelMap.id, funnelMapPutPayload);
      }
    },
    [hasFunnelMapSavePermissions, put_funnelMap, setLastAttemptedFunnelMapSave],
  );

  return {
    saveFunnelMapIfAllowed,
  };
};
