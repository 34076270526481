import { useState } from 'react';
import { CampaignMemberStatus } from '../canvas-hubspot-page/campaign-dialog/campaignTypes';
import { CampaignMemberStatusContext, LoadStatus } from './CampaignMemberStatusContext';

export const CampaignMemberStatusProvider = ({
  children,
}: {
  children: React.ReactNode;
  fetchStatuses?: boolean;
}) => {
  const [campaignMemberStatuses, setCampaignMemberStatuses] = useState<CampaignMemberStatus[]>([]);
  const [loadStatus, setLoadStatus] = useState<LoadStatus>('idle');

  return (
    <CampaignMemberStatusContext.Provider
      value={{
        campaignMemberStatuses,
        setCampaignMemberStatuses,
        loadStatus,
        setLoadStatus,
        initialized: true,
      }}
    >
      {children}
    </CampaignMemberStatusContext.Provider>
  );
};
