import useGlobalDataFetcherListener from '../../hooks/global-reducer/useGlobalDataFetcherListener';
import useSendPageVisitEvents from '../../hooks/useSendPageVisitEvents';
import useTrackPageViews from '../../hooks/useTrackPageViews';
import useUpdatePageTitles from '../../hooks/useUpdatePageTitles';
import useOnboardingNavigation from '../../hooks/useOnboardingNavigation';
import useAutoActivateTrial from '../../hooks/useAutoActivateTrial';
import useTestsHelper from '../../hooks/useTestsHelper';
import useWebSockets from '../../webSockets/useWebSockets';
import { useSweepRoleGroups } from '../common/useSweepRoleGroups';
import useLoadAccount from '../../hooks/useLoadAccount';
import { useSelectDefaultCreationEnvHeuristic } from './environments/useSelectDefaultCreationEnvHeuristic';
import { useAuthenticatedAppHubspotLoad } from './canvas-pages/canvas-hubspot-page/useAuthenticatedAppHubspotLoad';
import useSetupMonitoring from '../../hooks/useSetupMonitoring';
import LoadingSweep from '../common/LoadingSweep';
import useSendMeetingBookedEvents from '../../hooks/useSendMeetingBookedEvents';
import HomeInitialized from './HomeInitialized';

const Home = () => {
  //Here goes initializations that DON'T require "crmOrgs" or "hubspot" to be initialized:
  useSendPageVisitEvents();
  useTrackPageViews();
  useUpdatePageTitles();
  useAutoActivateTrial();
  useOnboardingNavigation();
  useGlobalDataFetcherListener();
  useSendMeetingBookedEvents();
  useTestsHelper();
  useWebSockets();
  useSweepRoleGroups();
  useLoadAccount();

  const { crmOrgsAreReady } = useSelectDefaultCreationEnvHeuristic();
  const { hubspotInitialized } = useAuthenticatedAppHubspotLoad();
  useSetupMonitoring();

  if (!crmOrgsAreReady || !hubspotInitialized) {
    return <LoadingSweep />;
  }

  return <HomeInitialized />;
};

export default Home;
