export enum ChatCompletionRequestMessageRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
}
export type SalesforceConfigurationType = string;

export type ChatGptPrompt = string;

export interface ConfigurationDescription {
  data: string;
  error: string;
}

export interface ChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole;
  content: ChatGptPrompt;
}

export enum AiChatContextType {
  CONFIGURATION = 'configuration',
  FIELD = 'field',
}

export interface AiChatConfigurationContext {
  type: AiChatContextType.CONFIGURATION;
  filename: string;
}

export interface AiChatFieldContext {
  type: AiChatContextType.FIELD;
  fieldName: string;
  objectName: string;
}

export type AiChatContextProperties = AiChatConfigurationContext | AiChatFieldContext;

export type AiChatMetadata = {
  id: string;
  name: string;
  isFavorite: boolean;
  agentId: string;
  agentType: AgentType;
  updatedAt: Date;
};

export interface TimedChatGptPromptMessage extends ChatGptPromptMessage {
  createdAt: Date;
}

export type NewChatResponse = {
  id: string;
  name: string;
  message: TimedChatGptPromptMessage & { isError: boolean };
};

export enum AgentType {
  Documentation = 'Documentation',
  ProcessOptimization = 'Process Optimization',
}

export interface AiAgentProperties {
  id: string;
  name: string;
  type: AgentType;
  instructions?: string;
  crmOrgId: string;
  createdAt: Date;
  updatedAt: Date;
}
