import * as React from "react";
const SvgAgentProcessIcon = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 20, cy: 20, r: 20, fill: "#C7ADFF", style: {
  fill: "#C7ADFF",
  fill: "color(display-p3 0.7818 0.6792 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.85365 11.752C9.32606 10.7579 10.1402 9.96693 11.1474 9.52338L18.1518 6.43891C19.3294 5.92033 20.6706 5.92033 21.8482 6.43891L28.8526 9.52338C29.8598 9.96694 30.6739 10.7579 31.1464 11.752L34.0645 17.8922C34.6564 19.1378 34.6564 20.5836 34.0645 21.8292L31.1464 27.9694C30.6739 28.9634 29.8598 29.7544 28.8526 30.198L21.8482 33.2824C20.6706 33.801 19.3294 33.801 18.1518 33.2824L11.1474 30.198C10.1402 29.7544 9.32606 28.9634 8.85365 27.9694L5.93552 21.8292C5.34356 20.5836 5.34356 19.1378 5.93552 17.8922L8.85365 11.752Z", fill: "#EBDEFF", style: {
  fill: "#EBDEFF",
  fill: "color(display-p3 0.9235 0.8708 1.0000)",
  fillOpacity: 1
} }));
export default SvgAgentProcessIcon;
