import { useDispatch, useSelector } from 'react-redux';
import { AdvancedFilter, AdvancedFilterItem } from '../../common/advanced-filter/AdvancedFilter';
import {
  selectCanvasFilterSelectedValuesFor,
  setCanvasFilterItemsDataFromFilterItems,
  setCanvasFilterSelectedValuesFor,
} from './canvasFiltersReducer';
import { useCallback, useEffect } from 'react';

interface MultiGenericFilterProps<DataT = any> {
  label: string;
  items: AdvancedFilterItem<DataT>[];
  filterKey: string;
  renderSeparateClearButton?: boolean;
}

export function MultiGenericFilter<DataT = any>({
  filterKey,
  items,
  label,
  renderSeparateClearButton,
}: MultiGenericFilterProps<DataT>) {
  const dispatch = useDispatch();
  const selectedValues = useSelector(selectCanvasFilterSelectedValuesFor(filterKey));

  const onSelectedItemsChange = useCallback(
    (items: string[]) => {
      dispatch(setCanvasFilterSelectedValuesFor({ filterKey, selectedValues: items }));
    },
    [dispatch, filterKey],
  );

  useEffect(() => {
    dispatch(
      setCanvasFilterItemsDataFromFilterItems({
        filterKey,
        items,
      }),
    );
  }, [dispatch, filterKey, items]);

  return (
    <AdvancedFilter
      items={items}
      selectedItems={selectedValues}
      onSelectedItemsChange={onSelectedItemsChange}
      texts={{ title: label }}
      renderSeparateClearButton={renderSeparateClearButton}
    />
  );
}
