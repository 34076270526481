import { useMemo } from 'react';
import { ActionsMenu, ActionsMenuAction } from '../../../../common/actions-menu/ActionsMenu';
import { MoreActionsVertical } from '@sweep-io/sweep-design/dist/icons';

const DEFAULT_LABEL = 'Add conditions';

export const AddConditionsMenuButton = ({
  onClick,
  label = DEFAULT_LABEL,
  onDelete,
  renderAddConditions,
}: {
  onClick: () => void;
  onDelete?: () => void;
  label?: string;
  renderAddConditions?: boolean;
}) => {
  const actions: ActionsMenuAction[] = useMemo(() => {
    const _actions: ActionsMenuAction[] = [];
    if (Boolean(renderAddConditions)) {
      _actions.push({
        label,
        value: 'add-conditions',
      });
    }

    if (Boolean(onDelete)) {
      _actions.push({
        label: 'Clear',
        value: 'clear',
      });
    }
    return _actions;
  }, [label, onDelete, renderAddConditions]);

  return (
    <ActionsMenu
      actions={actions}
      onClick={(_action) => {
        if (_action.value === 'add-conditions') {
          onClick();
        } else if (_action.value === 'clear') {
          onDelete?.();
        }
      }}
      iconVariant="flat"
      MenuButtonIcon={<MoreActionsVertical />}
      iconSize="tiny"
    />
  );
};
