import { Box, FormControl, Popover } from '@mui/material';
import FieldLabel from '../../common/FieldLabel';
import { SlackFields } from './SlackFields';
import {
  NestedFieldsField,
  NestedFieldsSelector,
} from '../../common/fieldsSelectors/NestedFieldsSelector';
import { customButtonSx } from './slack-action-entry/common';
import { colors, IconButton, Switch, Typography } from '@sweep-io/sweep-design';
import { Plus } from '@sweep-io/sweep-design/dist/icons/Plus';
import { useState } from 'react';
import { Settings } from '@sweep-io/sweep-design/dist/icons';

interface SlackAdditionalFieldsTableProps {
  readonly?: boolean;
  fields: SlackFieldsStruct[];
  updateOnChange: (value: Partial<AutomationSlackRenameDealroomActionParams>) => any;
  crmOrgId: string;
  objectName: string;
  label?: string;
}

export const SlackAdditionalFieldsTable = ({
  readonly,
  fields,
  updateOnChange,
  crmOrgId,
  objectName,
  label = 'Additional fields',
}: SlackAdditionalFieldsTableProps) => {
  const [isShowThreads, setIsShowThread] = useState(
    fields?.some((el) => el?.sendInThread) ?? false,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleShowThreads = () => {
    const newFields = fields?.map((el) => {
      return { ...el, sendInThread: !isShowThreads ? true : undefined };
    });
    updateOnChange({ fields: newFields });
    setIsShowThread(!isShowThreads);
  };

  return (
    <FormControl
      sx={{
        width: '100%',
        marginBottom: '20px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <FieldLabel label={label} />
        <Box sx={{ ml: 1 }}>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="tiny" variant="flat">
            <Settings />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{ p: '18px 20px', display: 'flex', gap: '42px' }}>
              <Typography variant="body">Send attached fields in a thread </Typography>
              <Switch checked={isShowThreads} onChange={handleShowThreads} />
            </Box>
          </Popover>
        </Box>
      </Box>

      <SlackFields
        isShowThreads={isShowThreads}
        readonly={readonly}
        actionFields={fields?.map((f) => ({
          fieldIds: f.fieldIds,
          fieldData: f,
          sendInThread: f?.sendInThread ?? undefined,
        }))}
        onChange={(fields) => {
          updateOnChange({ fields });
        }}
      />

      <Box>
        <Box>
          <NestedFieldsSelector
            readonly={readonly}
            crmOrgId={crmOrgId}
            objectType={objectName}
            customButtonStartIcon={<Plus color={colors.blue[500]} />}
            useCustomButton
            customButtonText="Add field"
            nestedPath={[]}
            customButtonSx={customButtonSx}
            onChange={(sweepField: NestedFieldsField) => {
              const newItem = {
                fieldIds: sweepField.fieldIds,
                _fieldLabels: sweepField.fieldLabels,
              };
              const newFields = [...((fields as SlackFieldsStruct[]) || []), newItem];
              updateOnChange({ fields: newFields });
            }}
          />
        </Box>
      </Box>
    </FormControl>
  );
};
