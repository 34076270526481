import { Box, List } from '@mui/material';
import { InfoDialog } from '../../common/dialogs/InfoDialog';
import { CustomWebhookInterface } from '../webhooks/WebhooksContext';
import { capitalizeString } from '../../../lib/capitalizeFirstLetter';
import { Button, Typography } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { selectEnvironments } from '../../../reducers/global/globalReducer';
import { selectDefaultCreationCrmOrgId } from '../../../reducers/userInfoReducer';
import pick from 'lodash/pick';

export const UsedIntegrationDialog = ({
  integration,
  usedAutomationList,
  isOpen,
  onClose,
}: {
  integration: CustomWebhookInterface;
  usedAutomationList: string[];
  isOpen: boolean;
  onClose: (event: any) => void;
}) => {
  const globalEnvironments = useSelector(selectEnvironments);
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId) ?? '';
  const globalData = globalEnvironments?.[crmOrgId]?.data;
  const automationElements = pick(
    globalData,
    'automations',
    'alerts',
    'scheduledAssignments',
    'assignments',
    'dedup',
    'matching',
    'scheduledReports',
  );
  const automationListToDisplay = new Map();

  usedAutomationList.forEach((automationId) => {
    Object.entries(automationElements).map(([type, children]) => {
      (children as AutomationStructureNew[])?.forEach((el) => {
        if (el.automationId === automationId) {
          automationListToDisplay.set(type, [...(automationListToDisplay?.get(type) ?? []), el]);
        }
      });
    });
  });

  const getDisplayLists = () => {
    const returnElem = [];
    for (const [key, value] of automationListToDisplay) {
      const list = value?.map((el: AutomationStructureNew) => (
        <span style={{ display: 'list-item' }} key={el.automationId}>
          <Typography variant="body">{el.name}</Typography>
        </span>
      ));
      returnElem.push(
        <Box sx={{ mb: 1 }}>
          <Typography variant="body-bold">{getDisplayTypeTitle(key)}</Typography>
          <List sx={{ listStyleType: 'disc', ml: 2, pt: 0 }}>{list}</List>
        </Box>,
      );
    }
    return returnElem;
  };

  const getDisplayTypeTitle = (type: string) => {
    return capitalizeString(
      type
        .split(/(?<![A-Z])(?=[A-Z])/)
        .join(' ')
        .toLowerCase(),
    );
  };

  return (
    <InfoDialog
      titleTypographyVariant={'h2-bold'}
      handleClose={onClose}
      open={isOpen}
      titleJsx={`${capitalizeString(integration?.name)} integration is being used `}
      PaperPropsSx={{ width: '464px' }}
    >
      <Box>
        <Typography variant="body">
          To delete this integration, first remove it from all triggers in which it is used:
        </Typography>
        <Box sx={{ mt: 1 }}>{getDisplayLists()}</Box>

        <Box sx={{ mt: '52px', mb: 3, display: 'flex', flexDirection: 'row-reverse' }}>
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
        </Box>
      </Box>
    </InfoDialog>
  );
};
