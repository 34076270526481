import { Box, MenuItem, Popover } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import { useMemo, useState } from 'react';
import { SearchSelectItem } from '../common/SearchSelect';
import { ObjectTypeChip } from '../common/ObjectTypeChip';
import { highlightMatch } from '../../lib/highlightMatch';
import { SearchInput } from '../common/SearchInput';
import { filterItemsBySearch } from '../../lib/filterItemsBySearch';
import { getFunnelMapObjects, getTypeNameFromVariant } from './helper';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import usePermission from '../common/permissions/usePermission';
import RestrictedTooltip from '../common/permissions/RestrictedTooltip';
import { PopoverProps } from '@mui/material/Popover/Popover';
import { objectsInFunnelMapScope } from './automationsInFunnelDataScope';
import useObjectTypesWithFetch, {
  objectTypesPropertyFilter,
} from '../../hooks/useObjectTypesWithFetch';
import { ButtonVariants } from '@sweep-io/sweep-design/dist/components/Button/types';
import { useAutomationsContext } from './AutomationsContext';

const ITEM_HEIGHT = 40;

export interface AddAutomationButtonProps {
  onSelect: (object: string, type?: DedupMatchingType) => void;
  disabled: boolean;
  buttonText: string;
  crmOrgId: string;
  funnelsData?: FunnelsData;
  excludeAutomationsNotInFunnelMapObjects?: boolean;
  recordTypesData?: RecordTypesData;
  automationVariant: AutomationFormVariant;
  popoverProps: Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin' | 'slotProps'>;
  startIconName: string;
  variant?: ButtonVariants;
}

export const AddAutomationButton = ({
  onSelect,
  disabled,
  buttonText,
  crmOrgId,
  funnelsData = {},
  recordTypesData = {},
  excludeAutomationsNotInFunnelMapObjects,
  automationVariant,
  popoverProps,
  startIconName,
  variant,
}: AddAutomationButtonProps) => {
  const { permissions } = useAutomationsContext();

  const [isAllowedAddButton] = usePermission([permissions.create]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState('');
  const allObjectAndRecordTypesInFunnelMap = getFunnelMapObjects(funnelsData, recordTypesData);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      setSearch('');
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const objectsInFunnelMap = objectsInFunnelMapScope(funnelsData);
  const { objectTypes: _objectTypes, objectTypesByName } = useObjectTypesWithFetch({
    crmOrgId,
  });

  const objectTypes = useMemo(
    () =>
      objectTypesPropertyFilter({
        objectTypes: _objectTypes,
        filters: {
          triggerable: true,
          updateable: true,
          includeBig5: true,
        },
      }),
    [_objectTypes],
  );

  const allObjects = objectTypes?.filter(
    (objectType) => !allObjectAndRecordTypesInFunnelMap.includes(objectType.objectType),
  );
  const promotedItems: SearchSelectItem<ObjectTypeName>[] = useMemo(() => {
    return allObjectAndRecordTypesInFunnelMap
      .filter(
        (objectName) => !!objectTypes?.find((_objectType) => _objectType.objectType === objectName),
      )
      .map((objectType) => ({
        label: objectTypesByName[objectType]?.label,
        value: objectTypesByName[objectType]?.objectType,
      }));
  }, [allObjectAndRecordTypesInFunnelMap, objectTypes, objectTypesByName]);

  const allItems: SearchSelectItem<ObjectTypeName>[] =
    allObjects?.map((objectType) => ({
      label: objectType.label,
      value: objectType.objectType,
    })) ?? [];

  let items: SearchSelectItem<ObjectTypeName>[] = [...promotedItems, ...allItems];

  if (excludeAutomationsNotInFunnelMapObjects) {
    items = items.filter((item) => objectsInFunnelMap.includes(item.value));
  }

  const filteredItems = filterItemsBySearch(items, search, (item) => item.label);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
      id="add-automation-button-wrapper"
    >
      <RestrictedTooltip
        to={[permissions.create]}
        notAllowedTitle={`To create a new ${getTypeNameFromVariant(
          automationVariant,
        )}, please contact your admin.`}
      >
        <Button
          variant={variant}
          disabled={!isAllowedAddButton || disabled}
          startIconName={startIconName}
          size="small"
          onClick={(e) => {
            handleClick(e);
          }}
        >
          {buttonText}
        </Button>
      </RestrictedTooltip>

      <Popover
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...popoverProps}
      >
        <Box padding={1}>
          <SearchInput
            withFixedMagnifyingGlassIcon
            TextFieldProps={{
              value: search,
              onChange: (e) => {
                setSearch(e.target.value);
              },
            }}
            onClearButtonClick={() => setSearch('')}
          />
          <Box sx={{ maxHeight: ITEM_HEIGHT * 10, overflow: 'auto', marginTop: 1 }}>
            {filteredItems.map((item) => (
              <MenuItem
                value={item.value}
                key={item.value}
                onClick={() => {
                  onSelect(item.value);
                  handleClose();
                }}
                disableRipple
              >
                <ObjectTypeChip
                  objectType={item.value}
                  label={highlightMatch(item.label, search)}
                />
              </MenuItem>
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
