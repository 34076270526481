import { Box, styled } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { ReactNode, useEffect } from 'react';
import { MarkdownWrapper } from '../../MarkdownWrapper';

export const AiChatUserMessageBox = ({
  content,
  scrollToBottom,
}: {
  content: string | ReactNode;
  scrollToBottom?: () => void;
}) => {
  useEffect(() => {
    scrollToBottom && scrollToBottom();
  }, [content, scrollToBottom]);

  return (
    <UserMessageBox>
      <MarkdownWrapper content={content} />
    </UserMessageBox>
  );
};

const UserMessageBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '0 12px',
  borderRadius: '8px 8px 2px 8px',
  //background: '#D0F1FF', //TODO is this final?
  background: colors.storm[70],
  maxWidth: '356px',
  alignSelf: 'flex-end',
});
