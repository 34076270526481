import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCampaignsApiFacade } from '../../../../apis/facades/useCampaignsApiFacade';
import { useErrorHandling } from '../../../../hooks/useErrorHandling';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { CampaignMemberStatusContext } from './CampaignMemberStatusContext';

export const useEffectFetchCampaignMemberStatuses = (campaignId: string) => {
  const { get_campaign_members_status } = useCampaignsApiFacade();
  const ctx = useContext(CampaignMemberStatusContext);
  const { loadStatus, setCampaignMemberStatuses, setLoadStatus, initialized } = ctx;
  const defaultConfigurationId = useSelector(selectDefaultCreationCrmOrgId);
  const { errorHandlingBuilder } = useErrorHandling();

  if (!initialized) {
    throw new Error('Please wrap your component with CampaignMemberStatusProvider');
  }

  useEffect(() => {
    (async function () {
      if (loadStatus === 'idle' && defaultConfigurationId) {
        errorHandlingBuilder()
          .withErrorNotification('Error retrieving status field from salesforce')
          .execute(async () => {
            setLoadStatus('loading');
            const statuses = await get_campaign_members_status(campaignId);
            setCampaignMemberStatuses(statuses);
            setLoadStatus('success');
          })
          .catch(() => {
            setLoadStatus('error');
          });
      }
    })();
  }, [
    campaignId,
    defaultConfigurationId,
    errorHandlingBuilder,
    get_campaign_members_status,
    loadStatus,
    setCampaignMemberStatuses,
    setLoadStatus,
  ]);

  return ctx;
};
