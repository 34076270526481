import { HubspotField } from '../../components/pages/canvas-pages/canvas-hubspot-page/hubspot.types';
import {
  HubspotEventComponentType,
  HubspotEventComponentForm,
  HubspotEventComponentPage,
  HubspotEventComponentEmail,
  HubspotMembershipList,
} from '@server/hubspot';
import { useSweepApi } from '../sweep';
import { useCallback } from 'react';

const API_BASE = '/hubspot';

type HubspotEventComponentReturnTypeMap = {
  [HubspotEventComponentType.PAGE]: HubspotEventComponentPage;
  [HubspotEventComponentType.FORM]: HubspotEventComponentForm;
  [HubspotEventComponentType.EMAIL]: HubspotEventComponentEmail;
};

type HubspotEventComponentReturnType<T extends HubspotEventComponentType> =
  HubspotEventComponentReturnTypeMap[T];

export const useHubspotApiFacade = () => {
  const sweepApi = useSweepApi();

  async function getEventComponents<T extends HubspotEventComponentType>(hsOrgId: string, type: T) {
    const response = await sweepApi.get(
      `${API_BASE}/event-components?orgId=${hsOrgId}&type=${type}`,
    );

    if (type === HubspotEventComponentType.PAGE) {
      return response.data as HubspotEventComponentReturnType<T>;
    } else {
      return response.data as HubspotEventComponentReturnType<T>;
    }
  }

  return {
    create_hubspot_org: useCallback(async () => {
      const response = await sweepApi.post(`${API_BASE}/new`);
      return response.data as HubspotOrg;
    }, [sweepApi]),
    get_hubspot_org: useCallback(
      async (hsOrgId: string) => {
        const response = await sweepApi.get(`${API_BASE}/org/${hsOrgId}`);
        return response.data as HubspotOrg;
      },
      [sweepApi],
    ),
    get_hubspot_orgs: useCallback(async () => {
      const response = await sweepApi.get(API_BASE);
      return response.data as HubspotOrg[];
    }, [sweepApi]),
    delete_hubspot_org: useCallback(async () => {
      await sweepApi.delete(`/hubspot`);
    }, [sweepApi]),
    get_hubspot_fields: useCallback(
      async (hsOrgId: string, objectName: string) => {
        const response = await sweepApi.get(`${API_BASE}/fields/${hsOrgId}/${objectName}`);
        return response.data as HubspotField[];
      },
      [sweepApi],
    ),
    get_event_components: useCallback(getEventComponents, [sweepApi]),

    get_membershipLists: useCallback(async () => {
      const response = await sweepApi.get<HubspotMembershipList[]>(`${API_BASE}/membership-lists`);
      return response.data;
    }, [sweepApi]),
  };
};
