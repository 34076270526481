import { AiChatInput } from '../common/ai-chat/AiChatInput';
import { useEffect, useState } from 'react';
import useAiAgentChats from './useAiAgentChats';

const AiAgentInput = ({
  onConfirm,
  isLoading,
  disabled,
}: {
  onConfirm: (message: string) => Promise<void>;
  isLoading: boolean;
  disabled: boolean;
}) => {
  const [value, setValue] = useState('');
  const { activeChatId, inputPlaceholder } = useAiAgentChats();

  //reset the input when switching chats
  useEffect(() => {
    setValue('');
  }, [activeChatId]);

  return (
    <AiChatInput
      value={value}
      setValue={setValue}
      textFieldVariant="secondary"
      onConfirm={onConfirm}
      isLoading={isLoading}
      isCollapsiblePrompts={false}
      disabled={disabled}
      inputPlaceholder={inputPlaceholder}
    />
  );
};

export default AiAgentInput;
