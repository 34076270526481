import { useCallback, useEffect, useRef, useState } from 'react';
import useEventListener from '../common/useEventListener';
import useAiAgentCenter from './useAiAgentCenter';
import AiAgentNameDialog from './AiAgentNameDialog';
import useAiAgents from './useAiAgents';
import { SelectedStagesInFunnel } from './aiAgentsConsts';
import { AgentType } from '@server/ai';
import { usePanels } from '../panels/PanelsContext';
import { aiAgentCenterPanelType } from '../dashboard/aiAgents/aiAgentCenterPanelType';
import { Box, Tooltip } from '@mui/material';

const AiAgentCreation = () => {
  const [selectedStages, setSelectedStages] = useState<SelectedStagesInFunnel[]>([]);
  const [isNameDialogOpened, setIsNameDialogOpened] = useState(false);
  const { isCreateAgentActive, setIsAgentCreateActive, toggleIsOpened, isOpened } =
    useAiAgentCenter();
  const { createAgentAndChat } = useAiAgents();
  const { maybeOpenPanel } = usePanels();
  const layerRef = useRef<HTMLDivElement>(null);

  const catcherLayerClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    //TODO make selection dynamic
    const selectedStages = [
      {
        funnelId: 'e81a686d-e7ea-4792-9d14-9eff26bd21f2',
        stagesId: ['48c6ec43-1a16-4a97-8382-6b81d7767f2e', '20f43ca9-e42d-4368-81b6-1189e4b93ea0'],
      },
    ];
    setIsNameDialogOpened(true);
    setSelectedStages(selectedStages);
  };

  const finish = useCallback(() => {
    setIsAgentCreateActive(false);
    setSelectedStages([]);
  }, [setIsAgentCreateActive]);

  const onConfirmCreate = async (name: string) => {
    if (selectedStages) {
      await createAgentAndChat({
        name,
        selectedStages,
        type: AgentType.ProcessOptimization,
      });
      maybeOpenPanel({
        panelType: aiAgentCenterPanelType,
        onOpen: () => {
          if (!isOpened) {
            toggleIsOpened();
          }
        },
      });
      finish();
    }
  };

  const onEscapeHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        finish();
      }
    },
    [finish],
  );

  useEffect(() => {
    //upon "create agent" activation, close agents panel
    if (isCreateAgentActive) {
      if (isOpened) {
        toggleIsOpened();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateAgentActive]);

  useEventListener('keydown', onEscapeHandler);

  const showCursorTooltip = !isNameDialogOpened && selectedStages.length === 0;

  return (
    <>
      {isCreateAgentActive && (
        <Tooltip
          title={showCursorTooltip ? 'Click and drag to select stages to optimize' : undefined}
          followCursor={true}
        >
          <Box
            onClick={catcherLayerClickHandler}
            ref={layerRef}
            sx={{
              cursor: 'crosshair',
              width: '100vw',
              height: '100vh',
              top: 0,
              left: 0,
              position: 'absolute',
              zIndex: 1,
            }}
          />
        </Tooltip>
      )}
      {isNameDialogOpened && (
        <AiAgentNameDialog
          onConfirm={onConfirmCreate}
          closeDialog={() => {
            setIsNameDialogOpened(false);
            setSelectedStages([]);
          }}
        />
      )}
    </>
  );
};

export default AiAgentCreation;
