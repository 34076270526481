import { Box } from '@mui/material';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../common/fieldsSelectors/NestedFieldsSelector';
import {
  Plus as DynamicIcon,
  ChevronDown as DynamicDropdownIcon,
} from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';
import { EmailInputWithValidation } from '../common/EmailInputWithValidation';
import { TagItem } from '../common/TextFieldWithTags';
import { parseSweepFieldPolymorphicID } from '../common/fieldsSelectors/nestedFieldsSelectorHelper';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { useCallback, useMemo } from 'react';

interface DynamicRecipientSelectorProps {
  crmOrgId: string;
  onChange: (emails: EmailAutomationAction) => any;
  readonly?: boolean;
  action: EmailAutomationAction;
  objectName: string;
}

export const DynamicRecipientSelector = ({
  crmOrgId,
  onChange,
  action,
  objectName,
  readonly,
}: DynamicRecipientSelectorProps) => {
  const handleAddDynamicRecipient = useCallback(
    (sweepField: NestedFieldsField) => {
      const newItemType = sweepField.fieldType === 'Email' ? 'emailField' : 'userField';
      const newItem = {
        type: newItemType,
        value: sweepField.fieldIds,
        _label: sweepField.fieldLabels.join('.'),
      };
      const newRecipients = [
        ...((action.actionParams?.toEmailRecipients as DynamicEmailRecipient[]) || []),
        newItem,
      ];
      onChange({
        ...(action as EmailAutomationAction),
        actionParams: {
          ...(action as EmailAutomationAction).actionParams,
          toEmailRecipients: newRecipients as DynamicEmailRecipient[],
        },
      });
    },
    [action, onChange],
  );

  const onAddValue = (_value: string) => {
    const newItem = {
      type: 'address',
      value: _value,
      _label: _value,
    };
    const newRecipients = [
      ...((action.actionParams?.toEmailRecipients as DynamicEmailRecipient[]) || []),
      newItem,
    ];
    onChange({
      ...(action as EmailAutomationAction),
      actionParams: {
        ...(action as EmailAutomationAction).actionParams,
        toEmailRecipients: newRecipients as DynamicEmailRecipient[],
      },
    });
  };

  const handleDeleteChip = (chipToRemove: any) => {
    const newRecipients = action.actionParams.toEmailRecipients?.filter(
      (email) => email !== chipToRemove,
    );
    onChange({
      ...(action as EmailAutomationAction),
      actionParams: {
        ...(action as EmailAutomationAction).actionParams,
        toEmailRecipients: newRecipients as DynamicEmailRecipient[],
      },
    });
  };

  const labels: TagItem[] = action.actionParams?.toEmailRecipients
    ? action.actionParams.toEmailRecipients?.map((chip, index) => ({
        value: `chip-${index}`,
        label: chip._label,
        startIcon: chip.type === 'emailField' || chip.type === 'userField' ? 'Atom' : 'undefined',
        onDelete: () => handleDeleteChip(chip),
      }))
    : [];

  const filterByEndSelectionEmailOrUser = useCallback((field: SweepField) => {
    const { isResolvable } = parseSweepFieldPolymorphicID(field.id || '');
    if (isResolvable) {
      return true;
    }
    if (field.fieldType === 'Email') {
      return true;
    }
    if (field.fieldType === 'Lookup') {
      return Boolean(field.objectNames?.includes('User'));
    }
    return false;
  }, []);

  const _customButtonEndIcon = useMemo(() => {
    return <DynamicDropdownIcon color={colors.blue[500]} />;
  }, []);

  const _customButtonStartIcon = useMemo(() => {
    return <DynamicIcon color={colors.blue[500]} />;
  }, []);

  const _customButtonSX = useMemo(() => {
    return {
      marginTop: '12px',
      height: '28px',
      color: colors.blue[500],
      border: 'none',
      textTransform: 'unset',
      p: '4px 8px',
      '& span.MuiTypography-root': {
        fontSize: '14px',
        fontWeight: 500,
      },
      '&:hover': {
        background: 'transparent',
        color: colors.blue[600],
      },
    };
  }, []);

  return (
    <>
      <EmailInputWithValidation
        onAddValue={onAddValue}
        tags={labels}
        readonly={readonly}
        sx={{ width: '70%', minWidth: '650px' }}
      />

      <Box>
        <NestedFieldsSelector
          filterBy={filterByEndSelectionEmailOrUser}
          readonly={readonly}
          isReferencedValue
          exclusiveParentFieldType={SweepFieldTypes.Email}
          crmOrgId={crmOrgId}
          objectType={objectName}
          nestedPath={[]}
          customButtonStartIcon={_customButtonStartIcon}
          customButtonEndIcon={_customButtonEndIcon}
          useCustomButton
          customButtonText="Add Dynamic Recipient"
          customButtonSx={_customButtonSX}
          onChange={(_sweepField) => {
            handleAddDynamicRecipient(_sweepField);
          }}
        />
      </Box>
    </>
  );
};
