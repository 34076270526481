import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AiChat, AiChatMessage, ChatShort } from '../common/ai-chat/aiChatTypes';
import { RootState } from '../../reducers';
import { AgentType } from '@server/ai';

interface AiChatsOrg {
  chatsList: {
    isLoading: boolean; //used to show loader of the list
    data?: ChatShort[];
  };
  chatsDetails: {
    [chatId: string]: {
      isLoading: boolean; //used to show loader of the chat area
      data?: AiChat;
    };
  };
  activeChatId?: string;
  isLoadingGhostChatItem?: boolean;
  search?: {
    searchText?: string;
    results?: AiChat[];
    isLoading?: boolean;
  };
}

const TEMP_CHAT_ID = 'TEMP_CHAT_ID';
const TEMP_ORG_ID = 'TEMP_ORG_ID';

const getOrgInitialState: () => AiChatsOrg = () => ({
  chatsList: {
    isLoading: false,
  },
  chatsDetails: {},
  activeChatId: TEMP_CHAT_ID,
  isLoadingGhostChatItem: false,
});

const getChatInitialState: (agentId: string, agentType: AgentType) => AiChat = (
  agentId,
  agentType,
) => ({
  id: TEMP_CHAT_ID,
  name: 'New chat',
  aiChatDetails: { messages: [] },
  isFavorite: false,
  updatedAt: new Date().toISOString(),
  isLoading: false,
  agentId,
  agentType,
});

export interface AiChatsState {
  environments: {
    [crmOrgId: string]: AiChatsOrg;
  };
}

const initialState: AiChatsState = {
  environments: {},
};

export const aiChatsSlice = createSlice({
  name: 'aiChats',
  initialState,
  reducers: {
    createNewChat: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agentType: AgentType;
        agentId: string;
      }>,
    ) => {
      const { crmOrgId, agentType, agentId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      state.environments[crmOrgId].activeChatId = TEMP_CHAT_ID;
      state.environments[crmOrgId].chatsDetails[TEMP_CHAT_ID] = {
        isLoading: false,
        data: getChatInitialState(agentId, agentType),
      };
    },
    setActiveChat: (state, action: PayloadAction<{ crmOrgId: string; chatId?: string }>) => {
      const { chatId, crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      state.environments[crmOrgId].activeChatId = chatId;
    },
    setLoadingChatDetails: (state, action: PayloadAction<{ crmOrgId: string; chatId: string }>) => {
      const { chatId, crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      if (!state.environments[crmOrgId].chatsDetails[chatId]) {
        state.environments[crmOrgId].chatsDetails[chatId] = { isLoading: true };
      }
      state.environments[crmOrgId].chatsDetails[chatId].isLoading = true;
    },
    setChat: (state, action: PayloadAction<{ crmOrgId: string; chat: AiChat }>) => {
      const { chat, crmOrgId } = action.payload;
      const { id } = chat;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      state.environments[crmOrgId].chatsDetails[id] = { isLoading: false, data: chat };
    },
    replaceTempWithChatIdAndName: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        chatId: string;
        name: string;
        agentId: string;
        agentType: AgentType;
      }>,
    ) => {
      const { chatId, crmOrgId, name, agentId, agentType } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      const relevantChat = state.environments[crmOrgId].chatsDetails[TEMP_CHAT_ID]?.data;
      if (!relevantChat) {
        return;
      }
      if (!state.environments[crmOrgId].chatsList) {
        state.environments[crmOrgId].chatsList = { isLoading: false, data: [] };
      }
      if (!state.environments[crmOrgId].chatsList.data) {
        state.environments[crmOrgId].chatsList.data = [];
      }
      state.environments[crmOrgId].chatsList.data.push({
        id: chatId,
        name,
        updatedAt: new Date().toISOString(),
        isFavorite: false,
        isLoading: false,
        isAnimateName: true,
        agentId,
        agentType,
      });
      state.environments[crmOrgId].chatsDetails[chatId] = {
        isLoading: false,
        data: {
          ...relevantChat,
          id: chatId,
        },
      };
      delete state.environments[crmOrgId].chatsDetails[TEMP_CHAT_ID];
      state.environments[crmOrgId].activeChatId = chatId;
      state.environments[crmOrgId].isLoadingGhostChatItem = false;
    },
    resetAnimateName: (state, action: PayloadAction<{ crmOrgId: string; chatId: string }>) => {
      const { chatId, crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]?.chatsList?.data) {
        return;
      }
      const relevantChatItem = state.environments[crmOrgId].chatsList.data.find(
        (chat) => chat.id === chatId,
      );
      if (!relevantChatItem) {
        return;
      }
      relevantChatItem.isAnimateName = false;
    },
    addSyncMessage: (
      state,
      action: PayloadAction<{
        crmOrgId?: string | null;
        message: AiChatMessage;
        agentId?: string;
        agentType?: AgentType;
      }>,
    ) => {
      const { message, crmOrgId, agentId, agentType } = action.payload;
      //TODO check if can handle the unhappy states same way as in "copilot"
      if (!crmOrgId || !state.environments[crmOrgId]) {
        return;
      }

      const chatId = state.environments[crmOrgId].activeChatId;
      if (!chatId) {
        return;
      }
      if (!state.environments[crmOrgId].chatsDetails[chatId]) {
        if (!agentId || !agentType) {
          return;
        }
        state.environments[crmOrgId].chatsDetails[chatId] = {
          isLoading: false,
          data: getChatInitialState(agentId, agentType),
        };
      }
      if (!state.environments[crmOrgId].chatsDetails[chatId].data) {
        if (!agentId || !agentType) {
          return;
        }
        state.environments[crmOrgId].chatsDetails[chatId].data = getChatInitialState(
          agentId,
          agentType,
        );
      }
      state.environments[crmOrgId].chatsDetails[chatId].data.aiChatDetails.messages.push(message);
      state.environments[crmOrgId].chatsDetails[chatId].data.isLoading = true;
    },
    addAsyncResponse: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        chatId?: string;
        message: AiChatMessage;
      }>,
    ) => {
      const { message, crmOrgId, chatId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      const _chatId = chatId ?? state.environments[crmOrgId].activeChatId;
      if (!_chatId) {
        return;
        //this shouldn't happen
      }
      if (!state.environments[crmOrgId].chatsDetails[_chatId]?.data?.aiChatDetails.messages) {
        return;
        //this shouldn't happen
      }
      state.environments[crmOrgId].chatsDetails[_chatId].data.isLoading = false;
      state.environments[crmOrgId].chatsDetails[_chatId].data.aiChatDetails.messages.push(message);
    },
    setLoadingChatsList: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
      }>,
    ) => {
      const { crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      state.environments[crmOrgId].chatsList.isLoading = true;
      state.environments[crmOrgId].chatsList.data = undefined;
    },
    setChatsList: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        chats: ChatShort[];
      }>,
    ) => {
      const { crmOrgId, chats } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      state.environments[crmOrgId].chatsList.isLoading = false;
      state.environments[crmOrgId].chatsList.data = chats;
    },
    setChatName: (
      state,
      action: PayloadAction<{ crmOrgId: string; chatId: string; name: string }>,
    ) => {
      const { crmOrgId, chatId, name } = action.payload;
      if (!state.environments[crmOrgId] || !state.environments[crmOrgId]?.chatsList.data) {
        return;
      }
      const relevantChat = state.environments[crmOrgId].chatsList.data.find(
        (chat) => chat.id === chatId,
      );
      if (!relevantChat) {
        return;
      }
      relevantChat.name = name;
      relevantChat.isLoading = false;
      relevantChat.isAnimateName = false; //animation is only for the auto-rename
    },
    setLoadingChatListItem: (
      state,
      action: PayloadAction<{ crmOrgId: string; chatId: string }>,
    ) => {
      const { crmOrgId, chatId } = action.payload;
      if (!state.environments[crmOrgId] || !state.environments[crmOrgId]?.chatsList?.data) {
        return;
      }
      const relevantChat = state.environments[crmOrgId].chatsList.data.find(
        (chat) => chat.id === chatId,
      );
      if (!relevantChat) {
        return;
      }
      relevantChat.isLoading = true;
    },
    deleteChat: (state, action: PayloadAction<{ crmOrgId: string; chatId: string }>) => {
      const { crmOrgId, chatId } = action.payload;
      if (!state.environments[crmOrgId] || !state.environments[crmOrgId]?.chatsList?.data) {
        return;
      }
      if (state.environments[crmOrgId].activeChatId === chatId) {
        state.environments[crmOrgId].activeChatId = TEMP_CHAT_ID;
      }
      state.environments[crmOrgId].chatsList.data = state.environments[
        crmOrgId
      ].chatsList.data.filter((chat) => chat.id !== chatId);
    },
    deleteChatsForAgent: (state, action: PayloadAction<{ crmOrgId: string; agentId: string }>) => {
      const { crmOrgId, agentId } = action.payload;
      if (!state.environments[crmOrgId] || !state.environments[crmOrgId]?.chatsList?.data) {
        return;
      }
      const activeChatId = state.environments[crmOrgId].activeChatId;
      if (activeChatId) {
        const activeChatAgentId = state.environments[crmOrgId].chatsList.data.find(
          (chat) => chat.id === activeChatId,
        )?.agentId;
        if (activeChatAgentId === agentId) {
          state.environments[crmOrgId].activeChatId = TEMP_CHAT_ID;
        }
      }
      state.environments[crmOrgId].chatsList.data = state.environments[
        crmOrgId
      ].chatsList.data.filter((chat) => chat.agentId !== agentId);
    },
    setChatIsFavorite: (
      state,
      action: PayloadAction<{ crmOrgId: string; chatId: string; isFavorite: boolean }>,
    ) => {
      const { crmOrgId, chatId, isFavorite } = action.payload;
      if (!state.environments[crmOrgId] || !state.environments[crmOrgId]?.chatsList?.data) {
        return;
      }
      const relevantChat = state.environments[crmOrgId].chatsList.data.find(
        (chat) => chat.id === chatId,
      );
      if (relevantChat) {
        relevantChat.isFavorite = isFavorite;
      }
    },
    setLoadingSearch: (state, action: PayloadAction<{ crmOrgId: string; searchText: string }>) => {
      const { crmOrgId, searchText } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      state.environments[crmOrgId].search = {
        isLoading: true,
        searchText,
      };
    },
    setSearchResult: (state, action: PayloadAction<{ crmOrgId: string; chats: AiChat[] }>) => {
      const { crmOrgId, chats } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = getOrgInitialState();
      }
      if (!state.environments[crmOrgId].search) {
        state.environments[crmOrgId].search = {};
      }
      state.environments[crmOrgId].search.isLoading = false;
      state.environments[crmOrgId].search.results = chats;
    },
    clearSearch: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      const { crmOrgId } = action.payload;
      if (state.environments[crmOrgId]?.search) {
        state.environments[crmOrgId].search = {
          isLoading: false,
        };
      }
    },
    setLoadingGhostChatItem: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      const { crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        return;
      }
      state.environments[crmOrgId].isLoadingGhostChatItem = true;
    },
  },
});

export const {
  createNewChat,
  setActiveChat,
  replaceTempWithChatIdAndName,
  addAsyncResponse,
  addSyncMessage,
  setLoadingChatsList,
  setChatsList,
  setLoadingChatDetails,
  setChat,
  setLoadingChatListItem,
  setChatName,
  deleteChat,
  deleteChatsForAgent,
  setChatIsFavorite,
  setLoadingSearch,
  setSearchResult,
  clearSearch,
  setLoadingGhostChatItem,
  resetAnimateName,
} = aiChatsSlice.actions;

export const selectActiveChatId = (crmOrgId?: string) => (state: RootState) => {
  return state.aiChats.environments[crmOrgId ?? TEMP_ORG_ID]?.activeChatId;
};

export const selectChatsListData = (crmOrgId?: string) => (state: RootState) => {
  return state.aiChats.environments[crmOrgId ?? TEMP_ORG_ID]?.chatsList.data;
};

export const selectActiveIsEmptyChat = (crmOrgId?: string) => (state: RootState) => {
  return state.aiChats.environments[crmOrgId ?? TEMP_ORG_ID]?.activeChatId === TEMP_CHAT_ID;
};

export const selectChatsListIsLoading = (crmOrgId?: string) => (state: RootState) => {
  return state.aiChats.environments[crmOrgId ?? TEMP_ORG_ID]?.chatsList.isLoading;
};

export const selectChatsDetails = (crmOrgId?: string) => (state: RootState) => {
  return state.aiChats.environments[crmOrgId ?? TEMP_ORG_ID]?.chatsDetails;
};

export const selectActiveChatDetails = (crmOrgId?: string) => (state: RootState) => {
  const activeChatId = state.aiChats.environments[crmOrgId ?? TEMP_ORG_ID]?.activeChatId;
  if (activeChatId) {
    return state.aiChats.environments[crmOrgId ?? TEMP_ORG_ID].chatsDetails[activeChatId];
  }
};

export const selectSearchIsLoading = (crmOrgId?: string) => (state: RootState) => {
  if (crmOrgId) {
    return state.aiChats.environments[crmOrgId]?.search?.isLoading;
  }
};

export const selectGhostChatItemIsLoading = (crmOrgId?: string) => (state: RootState) => {
  if (crmOrgId) {
    return state.aiChats.environments[crmOrgId]?.isLoadingGhostChatItem;
  }
};

export const selectSearchText = (crmOrgId?: string) => (state: RootState) => {
  if (crmOrgId) {
    return state.aiChats.environments[crmOrgId]?.search?.searchText;
  }
};

export const selectSearchResults = (crmOrgId?: string) => (state: RootState) => {
  if (crmOrgId) {
    return state.aiChats.environments[crmOrgId]?.search?.results;
  }
};

export default aiChatsSlice.reducer;
