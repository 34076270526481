import { useFeatureToggle } from '../common/useFeatureToggle';
import CopilotChats from '../copilotChats/CopilotChats';
import AiAgentCenter from './AiAgentCenter';

const AiChatsEntryPoint = ({
  closePanel,
  isOpened,
}: {
  closePanel: () => void;
  isOpened: boolean;
}) => {
  const { aiAgentCenter } = useFeatureToggle();

  return aiAgentCenter ? (
    <AiAgentCenter closePanel={closePanel} isOpened={isOpened} />
  ) : (
    <CopilotChats closeCopilot={closePanel} isOpened={isOpened} />
  );
};

export default AiChatsEntryPoint;
