import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Box from '@mui/material/Box';
import { StyledListItem } from '../StyledListItem';
import { Check } from '@sweep-io/sweep-design/dist/icons';
import { Divider } from '@mui/material';
import { OPTION_ALL } from './utils';

export interface SingleSelectFilterPopoverOption {
  value: string;
  label: string;
  addon?: React.ReactNode;
  labelStartAdornment?: React.ReactNode;
  isDivider?: boolean;
  hideDot?: boolean;
}

interface SingleSelectFilterPopoverProps {
  options: SingleSelectFilterPopoverOption[];
  selectedItem: string;
  onSelectItem: (selectedValue: string, isAllSelected: boolean) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  menuOptions?: SingleSelectFilterPopoverOption[];
  selectedMenuOption?: string;
  onSelectMenuOption?: (selectedValue: string) => void;
}

export const SingleSelectFilterPopover = ({
  options,
  selectedItem,
  onSelectItem,
  anchorEl,
  onClose,
  anchorOrigin,
  transformOrigin,
  menuOptions,
  selectedMenuOption,
  onSelectMenuOption,
}: SingleSelectFilterPopoverProps) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={
        anchorOrigin ?? {
          vertical: 'bottom',
          horizontal: 'left',
        }
      }
      transformOrigin={
        transformOrigin ?? {
          vertical: 'top',
          horizontal: 'left',
        }
      }
      sx={{
        maxHeight: '578px',
        height: '100%',
        zIndex: 1500,
      }}
    >
      <Box>
        <Box
          sx={{
            width: '260px',
            p: 1,
            pb: 0,
            maxHeight: !!menuOptions?.length ? '440px' : '100%',
            overflow: 'auto',
          }}
        >
          {options.map((option, idx) =>
            option.isDivider ? (
              <Divider sx={{ mb: 1 }} key={'divider_' + idx} />
            ) : (
              <StyledListItem
                key={option.value}
                isActive={selectedItem === option.value}
                title={option.label}
                onClick={() => onSelectItem(option.value, option.value === OPTION_ALL.value)}
                EndAdornment={
                  selectedItem === option.value ? (
                    <Box>
                      <Check />
                    </Box>
                  ) : option.addon ? (
                    <>{option.addon}</>
                  ) : undefined
                }
                StartIcon={option.labelStartAdornment}
              />
            ),
          )}
        </Box>
        {!!menuOptions?.length && (
          <>
            <Divider />
            <Box sx={{ p: 1, pb: 0 }}>
              {menuOptions.map((menuOption, idx) =>
                menuOption.isDivider ? (
                  <Divider sx={{ mb: 1 }} key={'divider_' + idx} />
                ) : (
                  <StyledListItem
                    key={menuOption.value}
                    isActive={selectedMenuOption === menuOption.value}
                    title={menuOption.label}
                    onClick={() => onSelectMenuOption && onSelectMenuOption(menuOption.value)}
                    EndAdornment={
                      selectedMenuOption === menuOption.value ? (
                        <Box>
                          <Check />
                        </Box>
                      ) : menuOption.addon ? (
                        <>{menuOption.addon}</>
                      ) : undefined
                    }
                    StartIcon={menuOption.labelStartAdornment}
                  />
                ),
              )}
            </Box>
          </>
        )}
      </Box>
    </Popover>
  );
};
