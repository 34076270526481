import { Stack, Box } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

const AgentInfoBig = ({
  icon,
  name,
  persona,
  description,
}: {
  icon: ReactNode;
  name: string;
  persona: string;
  description: string;
}) => {
  return (
    <Stack alignItems="center" pb={1.5} pt={5}>
      <Stack maxWidth="524px" alignItems="center" gap={3}>
        <Box width="64px">{icon}</Box>
        <Stack gap={1} alignItems="center" textAlign="center">
          <Typography variant="h1" color={colors.grey[800]}>
            {name}
          </Typography>
          <Typography variant="caption" color={colors.grey[700]}>
            {persona}
          </Typography>
          <Typography variant="body" color={colors.grey[900]} textAlign="center">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AgentInfoBig;
