import { TextField } from '@mui/material';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___ } from '@sweep-io/sweep-design';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';

export const DialogHeaderTextField = ({
  value,
  onChange,
  placeholder,
  maxLength,
  editDisabled,
  errorHelperText,
  typographyVariant = 'h1',
}: {
  value: string;
  onChange: (value: string) => any;
  placeholder: string;
  maxLength?: number;
  editDisabled?: boolean;
  errorHelperText?: string;
  typographyVariant?: SweepTypographyVariants;
}) => {
  return (
    <TextField
      error={!!errorHelperText}
      disabled={editDisabled}
      fullWidth
      variant="standard"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      sx={{
        input: {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.lineHeight,
          pt: typographyVariant === 'h3' ? 1 : undefined,
        },

        '& .MuiInput-input::placeholder': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.lineHeight,
        },
      }}
      inputProps={{
        maxLength,
      }}
      helperText={errorHelperText}
    />
  );
};
