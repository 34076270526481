import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCrmOrgObjectTypes,
  selectIsLoadingObjectTypes,
  setIsLoadingObjectTypes,
  setAndSortObjectTypes,
} from '../components/pages/environments/environmentsReducer';
import { useCrmOrgsApiFacade } from '../apis/facades/useCrmOrgsApiFacade';
import { telemetry } from '../telemetry';

const useObjectTypes = ({ crmOrgId }: { crmOrgId: string }) => {
  const dispatch = useDispatch();
  const objectTypes = useSelector(selectCrmOrgObjectTypes(crmOrgId));

  const isLoadingCrmOrgObjectTypes = useSelector(selectIsLoadingObjectTypes(crmOrgId));
  const { get_crmOrgsObjectTypes } = useCrmOrgsApiFacade();

  const handleIsLoadingObjects = useCallback(
    (crmOrgId: string) => {
      dispatch(setIsLoadingObjectTypes({ crmOrgId }));
    },
    [dispatch],
  );

  const fetchCrmOrgObjectTypes = useCallback(
    async ({ crmOrgId: _crmOrgId }: { crmOrgId?: string }): Promise<{ error: boolean }> => {
      //isLoadingCrmOrgObjectTypes is relevant only in case crmOrgId exist
      //in case of using "_crmOrgId", we check is being done on a parent function
      if (isLoadingCrmOrgObjectTypes && crmOrgId) {
        return {
          error: false,
        };
      }
      const usedCrmOrgId = _crmOrgId ?? crmOrgId;

      handleIsLoadingObjects(usedCrmOrgId);

      try {
        const { objectTypes } = await get_crmOrgsObjectTypes({ orgId: usedCrmOrgId });

        dispatch(
          setAndSortObjectTypes({
            objectTypes,
            crmOrgId: usedCrmOrgId,
          }),
        );

        return {
          error: false,
        };
      } catch (e) {
        telemetry.captureError(e);
        dispatch(setAndSortObjectTypes({ crmOrgId: usedCrmOrgId, objectTypes: [] })); //so it will not cause infinite loop
        return {
          error: true,
        };
      }
    },
    [
      get_crmOrgsObjectTypes,
      crmOrgId,
      handleIsLoadingObjects,
      dispatch,
      isLoadingCrmOrgObjectTypes,
    ],
  );

  return {
    objectTypes,
    fetchCrmOrgObjectTypes,
    isLoadingCrmOrgObjectTypes,
  };
};

export default useObjectTypes;
