import uniq from 'lodash/uniq';
import { sortLexicographically } from '../../lib/sortLexicographically';
import { RecordTypesLabelsByNameAndObjectName, SelectConfigurationItemProps } from './types';
import {
  LayoutData,
  ParsedRecordType,
  ParsedRule,
  RelatedObjectData,
  RuleType,
} from '../parser/ParserTypes';
import {
  USED_UNIQUE_BY_RT,
  getDataFromRecordTypeIdWithLeadingFieldName,
} from '../parser/parserUtils';
import flatten from 'lodash/flatten';
import { ConfigurationToName, ConfigurationType, UnclassifiedTypes } from './dependencies/types';
import {
  getDisplayLineTexts,
  getRecordTypeLabelsFromAutomation,
  getStepsFromAutomation,
} from '../Automations/helper';
import { getDataFromSFFieldName } from '../pages/rollups/rollupHelpers';
import { createStepId } from './selected-object/filters/utils';
import { colors } from '@sweep-io/sweep-design';
import { Tag } from '../../apis/facades/useUserInputsApiFacade';
import { telemetry } from '../../telemetry';
import { AutomationType } from '../../types/enums/AutomationType';
import { RollupForTable } from '../pages/rollups/rollupTypes';
import { displayCorrectFormat } from '../common/rule-builder/selectors/DateSelector';
import { ConfigurationItem, FieldMetadataRecordProperties } from './dependencies/DependenciesTypes';
import { UserInfo } from '../../types/UserInfoTypes';
import pluralize from 'pluralize';
import { ReactNode } from 'react';
import { getObjectTypeColor } from '../multi-canvas/helpers/getObjectTypeColor';
import groupBy from 'lodash/groupBy';
import { FunnelFieldLabels } from '../../constants/fieldsManagementConsts';
import { formatCamelCase } from './universal-search/utils';

export const getObjectApiNamesFromFunnelMap = (funnelsData?: FunnelsData) => {
  const funnels = Object.values(funnelsData ?? {});

  return uniq(
    sortLexicographically({
      items: funnels?.map((funnel) => funnel.recordType?.objectName) ?? [],
    }),
  );
};

export const getObjectApiNamesFromRecordTypes = (recordTypesData?: RecordTypesData) => {
  return flatten(Object.keys(recordTypesData ?? {}).map((key) => key.split('.')[0]));
};

export const OCCUPIED_SPACE_SINGLE_OBJECT_VIEW = '230px';
export const OCCUPIED_SPACE_MAIN_VIEW = '200px';
export const OCCUPIED_SPACE_SINGLE_OBJECT_VIEW_CANVAS = '340px';

export const isItemActive = (item: any) =>
  item?.hasOwnProperty('isActive') ? item.isActive : true;

export const checkIfActive = (onlyActive: boolean, item: any) =>
  !onlyActive || (onlyActive && isItemActive(item));

export const translateTypeToHumanName = (type: string) => {
  if (!type) {
    return type;
  }
  return (
    ConfigurationToName[type as ConfigurationType] ??
    FunnelFieldLabels[type as SweepFieldTypes] ??
    formatCamelCase(type)
  );
};

export const ruleTypeToConfigurationType = (type: RuleType | 'apexClasses') => {
  switch (type) {
    case RuleType.apexTriggers:
      return ConfigurationType.apexTriggers;
    case RuleType.approvalProcesses:
      return ConfigurationType.approvalProcesses;
    case RuleType.workflowRules:
      return ConfigurationType.workflowRules;
    case RuleType.flows:
      return ConfigurationType.flows;
    case RuleType.processBuilderFlows:
      return ConfigurationType.processBuilderFlows;
    case RuleType.cpqData:
      return ConfigurationType.cpqData;
    case RuleType.layouts:
      return ConfigurationType.layouts;
    case RuleType.validationRules:
      return ConfigurationType.validationRules;
    case 'apexClasses':
      return ConfigurationType.apexClasses;
    default:
      telemetry.captureError('unknown type in ruleTypeToConfigurationType', { type });
      return type as unknown as ConfigurationType;
  }
};

export const automationTypeToConfigurationType = (type: AutomationType) => {
  switch (type) {
    case AutomationType.Alert:
      return ConfigurationType.sweepAlert;
    case AutomationType.Assignment:
      return ConfigurationType.sweepAssignment;
    case AutomationType.Dedupe:
      return ConfigurationType.sweepDedupe;
    case AutomationType.Matching:
      return ConfigurationType.sweepMatching;
    case AutomationType.ScheduledAssignment:
      return ConfigurationType.sweepScheduledAssignment;
    case AutomationType.Default:
      return ConfigurationType.sweepAutomation;

    case AutomationType.ScheduledReport:
      //no documentation support yet
      break;

    default:
      telemetry.captureError(new Error('unknown type in automationTypeToConfigurationType'), {
        type,
      });
      break;
  }
};

export const createConfigurationItemFromTag = (tag: Tag): ConfigurationItem => ({
  id: tag.id,
  name: tag.name,
  label: tag.name,
  objectName: '',
  type: UnclassifiedTypes.tag,
  filename: '',
  isActive: true,
  color: (colors[tag.color as keyof typeof colors] as any)?.[100] ?? tag.color ?? colors.blue[100],
  parentType: UnclassifiedTypes.tag,
});

export const createConfigurationItemFromSweepField = (
  sweepField: SweepFieldSummary,
): ConfigurationItem => ({
  id: sweepField.id ?? '',
  name: getDataFromSFFieldName(sweepField?.sfFieldName)?.[1] ?? '',
  label: sweepField.sweepFieldName ?? '',
  objectName: sweepField.objectName,
  type: sweepField.fieldType,
  filename: '',
  isActive: true,
  color: getObjectNameColor(sweepField.objectName),
  parentType: ConfigurationType.fields,
});

export const createConfigurationItemFromField = (
  field: FieldMetadataRecordProperties,
  rollups?: RollupForTable[],
): ConfigurationItem => {
  const rollup = rollups?.find(
    (rollup) => rollup.sfFieldName === field.name && field.objectName === rollup.objectName,
  );

  if (rollup) {
    return createConfigurationItemFromRollupField({
      id: rollup.rollupId,
      sweepField: rollup.rollupField,
      usage: field.usage,
      link: field.link,
    });
  }

  return {
    ...field,
    createdAt: displayCorrectFormat(field.annotations?.createdAt),
    createdBy: field.annotations?.createdBy,
    updatedAt: displayCorrectFormat(field.annotations?.changedAt),
    updatedBy: field.annotations?.changedBy,
    filename: field.filename,
    isActive: true,
    isRollup: !!rollups?.find(
      (rollup) => rollup.sfFieldName === field.name && field.objectName === rollup.objectName,
    ),
    color: getObjectNameColor(field.objectName),
    parentType: ConfigurationType.fields,
    objectApiNames: [field.objectName],
  };
};

export const createConfigurationItemFromRollupField = ({
  id,
  sweepField,
  usage,
  link,
}: {
  id: string;
  sweepField: SweepField;
  usage?: number;
  link?: string;
}): ConfigurationItem => ({
  id,
  name: getDataFromSFFieldName(sweepField?.sfFieldName)?.[1] ?? '',
  label: sweepField.sweepFieldName ?? '',
  objectName: sweepField.objectName,
  type: sweepField.fieldType,
  description: sweepField.description,
  createdAt: displayCorrectFormat((sweepField as any)._created_at),
  createdBy: (sweepField as any)._created_by,
  updatedAt: displayCorrectFormat((sweepField as any)._changed_at),
  updatedBy: (sweepField as any)._changed_by,
  helpText: sweepField.helpText,
  isMandatory: sweepField.isRequired,
  formula: sweepField.properties.formula,
  usage,
  link,
  filename: '',
  isActive: true,
  isRollup: true,
  color: getObjectNameColor(sweepField.objectName),
  parentType: ConfigurationType.rollups,
});

export const createConfigurationItemFromParsedRule = (rule: ParsedRule): ConfigurationItem => ({
  ...rule,
  createdAt: displayCorrectFormat(rule.annotations?.createdAt),
  createdBy: rule.annotations?.createdBy,
  updatedAt: displayCorrectFormat(rule.annotations?.changedAt),
  updatedBy: rule.annotations?.changedBy,
  isActive: isItemActive(rule),
  color: getObjectNameColor(rule.objectApiNames[0]),
  parentType: ruleTypeToConfigurationType(rule.type),
});

export const createConfigurationItemFromAutomation = (
  automation?: AutomationStructureNew,
  sweepUsers?: UserInfo[],
): ConfigurationItem | undefined => {
  const stagesNames = automation
    ? getStepsFromAutomation(automation.automationDetails, 'stageName')
    : [];
  const recordTypeNames = getRecordTypeLabelsFromAutomation(automation);

  const updatedBy = sweepUsers?.find((user) => user.id === automation?.updatedById);
  const createdBy = sweepUsers?.find((user) => user.id === automation?.createdById);

  const conditionsCounter = automation?.automationDetails.when?.criteria?.criteria.length;
  const conditionsStr = conditionsCounter
    ? `\n(${conditionsCounter} ${pluralize('Condition')})`
    : '';

  const { actionText, triggerText } = automation ? getDisplayLineTexts(automation) : {};
  let _actionText = '';

  const components: ReactNode[] = [];

  actionText?.forEach((action) => {
    if (typeof action === 'string') {
      _actionText = _actionText === '' ? action : _actionText + ',\n' + action;
    } else {
      components.push(action);
    }
  });

  components.push(_actionText);

  return automation
    ? {
        id: automation.automationId,
        name: automation.name,
        label: automation.name,
        createdAt: displayCorrectFormat(automation.createdAt),
        createdBy: createdBy?.name ?? 'No data',
        updatedAt: displayCorrectFormat(automation.updatedAt),
        updatedBy: updatedBy?.name ?? 'No data',
        objectName: automation.objectName,
        type: automation.type,
        stagesNames,
        usedOnlyByRecordType: recordTypeNames,
        filename: '',
        isActive: automation.isActive,
        trigger: triggerText + conditionsStr,
        actions: components,
        color: getObjectNameColor(automation.objectName),
        parentType:
          automationTypeToConfigurationType(automation.type) ?? ConfigurationType.sweepAutomation,
      }
    : undefined;
};

export const createConfigurationItemFromRecordType = (
  recordType?: ParsedRecordType,
): ConfigurationItem | undefined => {
  return recordType
    ? {
        id: recordType.objectApiName + '.' + recordType.name,
        name: recordType.name,
        label: recordType.label,
        createdAt: displayCorrectFormat(recordType.annotations?.createdAt),
        createdBy: recordType.annotations?.createdBy,
        updatedAt: displayCorrectFormat(recordType.annotations?.changedAt),
        updatedBy: recordType.annotations?.changedBy,
        objectName: recordType.objectApiName,
        type: ConfigurationType.recordTypes,
        link: recordType.link,
        filename: recordType.filename,
        isActive: isItemActive(recordType),
        color: getObjectNameColor(recordType.objectApiName),
        parentType: ConfigurationType.recordTypes,
      }
    : undefined;
};

export const createConfigurationItemFromLayout = (
  layout?: LayoutData,
  objectName?: string,
): ConfigurationItem | undefined => {
  return layout
    ? {
        ...layout,
        createdAt: displayCorrectFormat(layout.annotations?.createdAt),
        createdBy: layout.annotations?.createdBy,
        updatedAt: displayCorrectFormat(layout.annotations?.changedAt),
        updatedBy: layout.annotations?.changedBy,
        type: ConfigurationType.layouts,
        filename: layout.filename,
        objectName,
        color: getObjectNameColor(objectName ?? ''),
        parentType: ConfigurationType.layouts,
        isActive: true,
      }
    : undefined;
};

export const createConfigurationItemsFromRelatedObjectsData = (
  relatedObjects: RelatedObjectData,
  rollups?: RollupForTable[],
) => {
  const { parsedRules, parsedRecordTypes, parsedFields } = relatedObjects;

  const newParsedRules =
    parsedRules
      ?.map((rule) => ({
        ...rule,
        parentType: rule.type,
      }))
      .map(createConfigurationItemFromParsedRule) ?? [];

  const newParsedRecordTypes =
    parsedRecordTypes?.map(createConfigurationItemFromRecordType).filter((item) => !!item) ?? [];

  const parsedFieldsWithReferenceObjects = Object.entries(parsedFields ?? {})
    .map(([key, values]) => {
      return values.map((value) => ({ ...value, referencedObjects: [key] }));
    })
    .flat();

  const groupSameFieldIds = groupBy(parsedFieldsWithReferenceObjects, 'id');
  const groupedFields: ConfigurationItem[] = [];

  Object.keys(groupSameFieldIds).forEach((key) => {
    const firstField = groupSameFieldIds[key][0];
    let newItem = createConfigurationItemFromField(firstField, rollups);

    if (groupSameFieldIds[key].length > 1) {
      groupSameFieldIds[key].forEach((item) => {
        newItem = {
          ...newItem,
          referencedObjects: item.referencedObjects
            ? uniq([...(newItem.referencedObjects ?? []), ...(item.referencedObjects ?? [])])
            : newItem.referencedObjects,
        };
      });
    }

    groupedFields.push(newItem);
  });

  //If ids are the same it means that configuration is used in more than one object
  const groupSameIds = groupBy([...newParsedRules, ...newParsedRecordTypes], 'id');
  const result: ConfigurationItem[] = groupedFields;

  Object.keys(groupSameIds).forEach((key) => {
    let newItem = groupSameIds[key][0];

    if (groupSameIds[key].length > 1) {
      groupSameIds[key].forEach((item) => {
        newItem = {
          ...newItem,
          objectApiNames: uniq([...(newItem.objectApiNames ?? []), ...(item.objectApiNames ?? [])]),
          referencedObjects: uniq([
            ...(newItem.referencedObjects ?? []),
            ...(item.referencedObjects ?? []),
          ]),
        };
      });
    }

    result.push(newItem);
  });

  return result;
};

export const findRuleIdx = ({
  rules,
  searchByName,
  name,
  objectName,
  id,
}: { rules: ParsedRule[]; searchByName: boolean } & Omit<
  SelectConfigurationItemProps,
  'crmOrgId'
>) =>
  rules?.findIndex((rule) =>
    searchByName
      ? rule.name === name && (objectName ? rule.objectApiNames.includes(objectName) : true)
      : rule.id === id &&
        (name ? rule.name === name : true) &&
        (objectName ? rule.objectApiNames.includes(objectName) : true),
  ) ?? -1;

export const findLayoutIdx = ({
  layouts,
  searchByName,
  name,
  id,
}: { layouts: LayoutData[]; searchByName: boolean } & Omit<
  SelectConfigurationItemProps,
  'crmOrgId' | 'objectName'
>) =>
  layouts?.findIndex((layout) => (searchByName ? layout.name === name : layout.id === id)) ?? -1;

export const findConfigurationItemIdx = ({
  parsedConfigurationItems,
  searchByName,
  name,
  objectName,
  id,
}: { parsedConfigurationItems: ConfigurationItem[]; searchByName: boolean } & Omit<
  SelectConfigurationItemProps,
  'crmOrgId'
>) =>
  parsedConfigurationItems?.findIndex((configItem) => {
    let isFound: boolean;

    const isNameEqual = name ? configItem.name === name : true;
    const isIdEqual = configItem.id === id;
    const isObjectNameEqual = objectName
      ? configItem.objectName === objectName || !!configItem.objectApiNames?.includes(objectName)
      : true;

    if (searchByName) {
      isFound = isNameEqual && isObjectNameEqual;
    } else {
      isFound = isIdEqual && isNameEqual && isObjectNameEqual; //BE returns 'MissingInternalId' as id when its missing from Salto so we need to be more specific
    }

    return isFound;
  }) ?? -1;

export const addLayoutProperties = (
  item: ConfigurationItem,
  layout?: LayoutData,
): ConfigurationItem => ({
  ...item,
  buttons: layout?.buttons ?? item.buttons,
  createdAt: displayCorrectFormat(layout?.annotations?.createdAt ?? item.annotations?.createdAt),
  createdBy: layout?.annotations?.createdBy ?? item.annotations?.createdBy,
  updatedAt: displayCorrectFormat(layout?.annotations?.changedAt ?? item.annotations?.changedAt),
  updatedBy: layout?.annotations?.changedBy ?? item.annotations?.changedBy,
  relatedLists: layout?.relatedLists ?? item.relatedLists,
  description: layout?.description ?? item.description,
  link: layout?.link ?? item.link,
  filename: item.filename,
  isActive: isItemActive(item),
  parentType: item.parentType ?? item.type,
});

export const getConfigurationItemWithRTAttribution = (
  item: ParsedRule,
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName,
): ConfigurationItem => ({
  ...item,
  usedOnlyByRecordType: item.usedByRecordTypes
    ?.filter((name) => name.match(USED_UNIQUE_BY_RT))
    .map((name) => getDataFromRecordTypeIdWithLeadingFieldName(name)?.recordTypeName)
    .filter((n) => n !== ''),

  usedOnlyByRecordTypeLabels: item.usedByRecordTypes
    ?.filter((name) => name.match(USED_UNIQUE_BY_RT))
    .map((name) => {
      const { recordTypeName, objectApiName } =
        getDataFromRecordTypeIdWithLeadingFieldName(name) ?? {};
      const label = recordTypeName
        ? recordTypeNamesUsedInCanvas?.[objectApiName]?.[recordTypeName]
        : '';
      return label;
    })
    .filter((n) => n !== ''),
  color: getObjectNameColor(item.objectApiNames[0]),
  parentType: item.type,
  isActive: isItemActive(item),
});

export const getFunnelsStepNames = ({
  funnelsData = {},
  recordTypesData = {},
}: {
  funnelsData: FunnelsData;
  recordTypesData: RecordTypesData;
}) => {
  const stepNamesPerObject: { [objectApiName: string]: string[] } = {};

  Object.values(funnelsData).forEach((funnel) => {
    const objectApiName = funnel.funnelDetails.leadingObject.objectName;
    const recordTypeName = funnel.recordType?.name;
    const stepNames = funnel.funnelDetails.stages.map((stage) =>
      createStepId(recordTypeName, objectApiName, stage.stageName),
    );
    const oldSteps = stepNamesPerObject[objectApiName] ?? [];

    stepNamesPerObject[objectApiName] = uniq([...stepNames, ...oldSteps]);
  });

  Object.values(recordTypesData).forEach((rt) => {
    const objectApiName = rt.objectName;
    const rtStepNames =
      rt.leadingField?.values.map((step) => createStepId(rt?.name, objectApiName, step.fullName)) ??
      [];
    const oldSteps = stepNamesPerObject[objectApiName] ?? [];

    stepNamesPerObject[objectApiName] = uniq([...rtStepNames, ...oldSteps]);
  });

  return stepNamesPerObject;
};

export const getObjectNameColor = (objectName: string) => {
  const leadingColor = getObjectTypeColor(objectName)?.leadingColorName as keyof typeof colors;
  return (colors[leadingColor] as any)?.[100] ?? colors['blue'][100];
};
