import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../reducers';
import { FetchStatus } from '@server/fetch_status.type';

type CrmOrgObjectTypesInfo = {
  loadingObjectTypes: boolean;
  objectTypes?: ObjectTypeName[];
};

interface CrmOrgsObjectTypesMap {
  [crmOrgId: string]: CrmOrgObjectTypesInfo;
}

export interface EnvironmentsState {
  crmOrgsWasLoaded: boolean;
  crmOrgsAreReady: boolean; // This is set to true once the updateDefaultCreationEnv Heuristic is done
  crmOrgs: CrmOrg[];
  crmOrgsMap: CrmOrgsObjectTypesMap;
}

const initialState: EnvironmentsState = {
  crmOrgsWasLoaded: false,
  crmOrgsAreReady: false,
  crmOrgs: [], //TODO can move this inside crmOrgsMap too
  crmOrgsMap: {},
};

const getLabel = (obj?: ObjectTypeName) => obj?.label || obj?.objectType || '';
const sortByLabel = (objA: ObjectTypeName, objB: ObjectTypeName) =>
  getLabel(objA).localeCompare(getLabel(objB));

export const environmentsSlice = createSlice({
  name: 'environments',
  initialState,
  reducers: {
    addCrmOrg: (state, action: PayloadAction<{ crmOrg: CrmOrg }>) => {
      state.crmOrgs.push(action.payload.crmOrg);
    },
    setCrmOrgs: (state, action: PayloadAction<{ crmOrgs: CrmOrg[] }>) => {
      state.crmOrgs = action.payload.crmOrgs;
      state.crmOrgsWasLoaded = true;
    },
    setOrgsAreReady: (state) => {
      state.crmOrgsAreReady = true;
    },
    setCrmOrg: (state, action: PayloadAction<{ crmOrg: CrmOrg }>) => {
      const idx = state.crmOrgs.findIndex((crmOrg) => crmOrg.id === action.payload.crmOrg.id);
      idx >= 0 && (state.crmOrgs[idx] = { ...state.crmOrgs[idx], ...action.payload.crmOrg });
    },
    deleteCrmOrg: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      const idx = state.crmOrgs.findIndex((crmOrg) => crmOrg.id === action.payload.crmOrgId);

      if (idx >= 0) {
        state.crmOrgs.splice(idx, 1);
      }
    },
    addFetchingToOrgId: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      const crmOrg = state.crmOrgs.find((crmOrg) => crmOrg.id === action.payload.crmOrgId);
      if (crmOrg) {
        crmOrg.fetchStatus = FetchStatus.Fetching;
      }
    },
    setIsLoadingObjectTypes: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
      }>,
    ) => {
      const { crmOrgId } = action.payload;
      const _map = state.crmOrgsMap[crmOrgId];
      state.crmOrgsMap[crmOrgId] = {
        ..._map,
        loadingObjectTypes: true,
      };
    },
    setAndSortObjectTypes: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        objectTypes: ObjectTypeName[];
      }>,
    ) => {
      const { crmOrgId, objectTypes } = action.payload;
      if (!crmOrgId) {
        return;
      }
      const objectTypesSorted = objectTypes
        ?.map((obj) => ({ ...obj, label: obj.label.toString() }))
        .sort(sortByLabel);

      state.crmOrgsMap[crmOrgId] = {
        loadingObjectTypes: false,
        objectTypes: objectTypesSorted,
      };
    },
    updatePermissionSetGroupAssigned: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      const { crmOrgId } = action.payload;
      const idx = state.crmOrgs.findIndex((crmOrg) => crmOrg.id === crmOrgId);
      if (idx >= 0) {
        state.crmOrgs[idx].isPermissionSetGroupAssigned = true;
      }
    },
  },
});

export const {
  addCrmOrg,
  setCrmOrgs,
  setCrmOrg,
  addFetchingToOrgId,
  deleteCrmOrg,
  setAndSortObjectTypes,
  setIsLoadingObjectTypes,
  updatePermissionSetGroupAssigned,
  setOrgsAreReady,
} = environmentsSlice.actions;

export const getCrmOrgById = (crmOrgId?: string) => (state: RootState) =>
  state.environments.crmOrgs.find((crmOrg) => crmOrg.id === crmOrgId);

export const selectCrmOrgs = (state: RootState) => state.environments.crmOrgs;

export const selectConnectedCrmOrgs = createSelector([selectCrmOrgs], (crmOrgs) =>
  crmOrgs.filter(({ isConnected }) => isConnected),
);

export const selectFirstConnectedCrmOrg = (state: RootState) =>
  state.environments.crmOrgs?.filter(({ isConnected }) => isConnected)[0];

export const selectCrmOrg = (id: string | undefined) => (state: RootState) => {
  const idx = state.environments.crmOrgs.findIndex((crmOrg) => crmOrg.id === id);
  if (idx >= 0) {
    return state.environments.crmOrgs[idx];
  }
};

export const selectCrmOrgHasManagePackage = (crmOrgId: string) => (state: RootState) =>
  state.environments.crmOrgs.find((crmOrg) => crmOrg.id === crmOrgId)?.isManagedPackageInstalled;

export const selectCrmOrgObjectTypes = (crmOrgId: string) => (state: RootState) =>
  state.environments.crmOrgsMap[crmOrgId]?.objectTypes;

export const selectIsLoadingObjectTypes = (crmOrgId: string) => (state: RootState) =>
  state.environments.crmOrgsMap[crmOrgId]?.loadingObjectTypes;

export const selectCrmOrgsMap = (state: RootState) => state.environments.crmOrgsMap;

export const selectProductionCrmOrg = (state: RootState) =>
  state.environments.crmOrgs.find((crmOrg) => crmOrg.isMain && crmOrg.isConnected);

export const selectDefaultCreationEnvironment = (state: RootState) => {
  const idx = state.environments.crmOrgs.findIndex(
    (crmOrg) => crmOrg.id === state.userInfo.data?.preferences?.defaultCreationCrmOrgId,
  );
  if (idx >= 0) {
    return state.environments.crmOrgs[idx];
  }
};

export const selectCrmOrgsWasLoaded = (state: RootState) => state.environments.crmOrgsWasLoaded;

export const selectCrmOrgsAreReady = (state: RootState) => state.environments.crmOrgsAreReady;

export const selectHasConnectedCrmOrgs = (state: RootState) =>
  state.environments.crmOrgs.some((crmOrg) => crmOrg.isConnected);

export default environmentsSlice.reducer;
