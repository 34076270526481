import CloudDisconnectedIllustration from '../../../../icons/svg/CloudDisconnectedIllustration.svg?react';
import CloudLockedLightIllustration from '../../../../icons/svg/cloud-locked-light.svg?react';
import CloudLockedUserLightIllustration from '../../../../icons/svg/cloud-locked-user-light.svg?react';
import CloudLockedDarkIllustration from '../../../../icons/svg/cloud-locked-dark.svg?react';
import CloudLockedUserDarkIllustration from '../../../../icons/svg/cloud-locked-user-dark.svg?react';
import SalesforceCloudStroke from './svgs/salesforceCloudStroke.svg?react';

import { CrmOrgConnectingError } from '../../environments/connectCrmSessionHelper';

export const getCrmOrgConnectionErrorData = ({
  error,
  theme = 'light',
}: {
  theme?: 'light' | 'dark';
  error: CrmOrgConnectingError;
}): {
  illustration: React.ReactNode;
  title: string;
  description: string[];
} => {
  const isLightTheme = theme === 'light';

  switch (error) {
    case CrmOrgConnectingError.NOT_ENOUGH_PERMISSIONS:
      return {
        illustration: isLightTheme ? (
          <CloudLockedUserLightIllustration />
        ) : (
          <CloudLockedUserDarkIllustration />
        ),
        title: 'Unable to connect to Salesforce',
        description: [
          `The Salesforce user you are trying to connect with doesn't have the necessary permissions to connect.`,
          'Please try a different user or contact your admin for assistance.',
        ],
      };
    case CrmOrgConnectingError.USER_DENIED_AUTHORIZATION:
      return {
        illustration: isLightTheme ? (
          <CloudLockedLightIllustration />
        ) : (
          <CloudLockedDarkIllustration />
        ),
        title: 'You denied giving Sweep access to Salesforce',
        description: [
          'Allow Sweep to access to connect to Salesforce.',
          'Try again and allow access.',
        ],
      };
    case CrmOrgConnectingError.USER_CLOSED_TAB:
      return {
        illustration: isLightTheme ? <CloudDisconnectedIllustration /> : <SalesforceCloudStroke />,
        title: `"Connect to Salesforce" tab was closed unexpectedly`,
        description: [`The browser's tab was closed before the connection was completed.`,
          'Please try again and keep the tab open until it is closed on its own.',
        ],
      };
    case CrmOrgConnectingError.ERROR_CONNECTING:
    case CrmOrgConnectingError.UNKNOWN:
      return {
        illustration: isLightTheme ? <CloudDisconnectedIllustration /> : <SalesforceCloudStroke />,
        title: 'Unable to connect to Salesforce',
        description: [
          'There seems to be an issue connecting to Salesforce.',
          'Please try again or talk to us if the issue persists.',
        ],
      };
  }
};
