import { Stack, Box } from '@mui/material';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { Typography, Tag, colors } from '@sweep-io/sweep-design';
import CopilotScrollArea from './CopilotScrollArea';
import CopilotInput from './CopilotInput';
import { COPILOT_PROMPTS } from './copilotConsts';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { useEffect } from 'react';
import useCopilotChats from '../copilotChats/useCopilotChats';

//TODO delete when removing FT aiAgentDemo
const TEMP_PROMPT_FOR_DEMO = `Using the context of the Salesforce-based business process provided, including metadata, automations, and configurations for Pipeline Opportunity funnel, analyze and identify 3 concise opportunities for strategic optimization. Use the provided metadata as the primary source of insights and frame each recommendation in one clear, actionable sentence. Start with a concise introduction, list the recommendations, and conclude with a question inviting the user to dive deeper or suggest additional priorities.
Example: Here are a few initial optimization opportunities I have identified: Improve Data Integrity Practices: Establish clear standards for opportunity data to ensure accurate reporting and better decision-making. Enhance Opportunity Engagement: Identify and address patterns of stalled opportunities to keep deals moving forward efficiently.
Align Stage Definitions with Business Goals: Refine stage criteria to better reflect your sales strategy and provide clearer insights into pipeline health.
Would you like to dive deeper into one of these areas, or are there other opportunities or priorities you’d like to explore?`;

const GRADIENT_HEIGHT = '42px';
type NoteAlignment = 'left' | 'center';

const CopilotChat = ({
  messages,
  onConfirm,
  isLoading,
  errorMsg,
  disableInput,
  noteAlignment,
  maxWidth,
  searchTermToHighlight,
}: {
  messages: AiChatMessage[];
  onConfirm: (message: string) => Promise<void>;
  isLoading: boolean;
  disableInput: boolean;
  errorMsg?: string; //this property should be deprecated
  noteAlignment: NoteAlignment;
  maxWidth?: string;
  searchTermToHighlight?: string;
}) => {
  const { aiAgentDemo } = useFeatureToggle();
  const { isOpened } = useCopilotChats();
  const messagesToRender = aiAgentDemo ? messages.slice(1) : messages;
  const shouldAutoSendFirstUserMessage = aiAgentDemo && isOpened && messages.length === 0;

  useEffect(() => {
    if (shouldAutoSendFirstUserMessage) {
      onConfirm(TEMP_PROMPT_FOR_DEMO);
    }
  }, [shouldAutoSendFirstUserMessage, onConfirm]);

  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
        overflow: 'auto',
        flex: 1,
      }}
    >
      <CopilotScrollArea
        messages={messagesToRender}
        errorMsg={errorMsg}
        isLoading={isLoading}
        maxWidth={maxWidth}
        searchTermToHighlight={searchTermToHighlight}
      />
      <Stack
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: GRADIENT_HEIGHT,
            top: `-${GRADIENT_HEIGHT}`,
            background: `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);`,
          }}
        />
        <CopilotInput
          onConfirm={onConfirm}
          isLoading={isLoading}
          predefinedPrompts={messages.length === 0 && !aiAgentDemo ? COPILOT_PROMPTS : undefined}
          disabled={disableInput}
        />
        <Stack p={1.5} pt={0} mt={-0.5}>
          <CopilotNote alignment={noteAlignment} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const CopilotNote = ({ alignment }: { alignment: NoteAlignment }) => (
  <Box
    display="flex"
    gap={1}
    pl={alignment === 'left' ? 1.5 : 0}
    alignItems="center"
    justifyContent={alignment === 'left' ? 'start' : 'center'}
  >
    <Tag label="Beta" color={colors.storm[70]} />
    <Typography variant="caption" color={colors.grey[700]}>
      AI bots can make mistakes. Check important info.
    </Typography>
  </Box>
);

export default CopilotChat;
