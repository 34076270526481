import { Box, Collapse, Stack } from '@mui/material';
import { IconButton, Typography } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import { ReactNode, useState } from 'react';

const CollapsibleSectionLayout = ({ title, children }: { title: string; children: ReactNode }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggle = () => setIsCollapsed(!isCollapsed);
  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 16px',
          height: '32px',
        }}
      >
        <Typography variant="body-bold">{title}</Typography>
        <IconButton onClick={toggle} size="tiny" variant="flat">
          {isCollapsed ? <ChevronUp /> : <ChevronDown />}
        </IconButton>
      </Box>
      <Collapse in={!isCollapsed}>{children}</Collapse>
    </Stack>
  );
};

export default CollapsibleSectionLayout;
