import SweepAvatar from './sweepAvatar.svg?react';
import styled from '@mui/material/styles/styled';
import { colors } from '@sweep-io/sweep-design';
import { ReactNode, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import ChatLoader from './ChatLoader';
import { MarkdownWrapper } from '../../MarkdownWrapper';

export const AiChatAssistantMessageBox = ({
  content,
  scrollToBottom,
  errorState = false,
  isLoading = false,
  avatar = <SweepAvatar />,
}: {
  content: string | ReactNode;
  scrollToBottom?: () => void;
  isLoading?: boolean;
  errorState?: boolean;
  avatar?: ReactNode;
}) => {
  useEffect(() => {
    scrollToBottom && scrollToBottom();
  }, [content, scrollToBottom]);

  return (
    <Stack gap={1}>
      <Box display="flex" gap={0.5} alignItems="flex-end">
        <Box display="flex" alignItems="flex-end" flex="0 0 24px">
          {avatar}
        </Box>
        {isLoading && (
          <Box alignSelf="center" pl={1.5}>
            <ChatLoader />
          </Box>
        )}
        {!isLoading && (
          <AssistantMessageBox
            sx={{
              pr: 1.5,
              pl: 1.5,
            }}
          >
            <MarkdownWrapper content={content} color={errorState ? colors.blush[600] : undefined} />
          </AssistantMessageBox>
        )}
      </Box>
    </Stack>
  );
};

const AssistantMessageBox = styled(Box)({
  borderRadius: '8px 8px 8px 2px',
  background: '#F0F4F8',
  // background: '#ECF8FF', //TODO is this final?
  maxWidth: '100%',
});
