import { Box, Divider, Popover } from '@mui/material';
import { colors, IconButton } from '@sweep-io/sweep-design';
import {
  Apex,
  Delete,
  MoreActionsHorizontal,
  NotePencil,
  PaintBucket,
  ViewInSalesforce,
} from '@sweep-io/sweep-design/dist/icons';
import { Fragment, ReactNode } from 'react';
import { StyledListItem } from '../../../../common/StyledListItem';
import { useDependencies } from '../../hooks/useDependencies';
import { DependenciesContentViewType } from '../../types';
import { ColorPicker } from '../../annotations/tags/ColorPicker';
import usePopover from '../../../../../hooks/usePopover';
import { ConfigurationItem } from '../../DependenciesTypes';

const changeColorTitle = 'Change color';

interface ButtonWithActionMenuProps {
  configurationItem?: ConfigurationItem;
  crmOrgId: string;
  selectedColor?: string;
  onChangeName?: () => void;
  onChangeColor?: (newColor: string) => void;
  onDelete?: () => void;
  options?: ActionOption[];
  deleteTitle?: string;
  showLoader?: boolean;
}

export enum ActionOption {
  viewSource = 'viewSource',
  openInSalesforce = 'openInSalesforce',
  changeName = 'changeName',
  changeColor = 'changeColor',
  delete = 'delete',
}

export const ButtonWithActionMenu = ({
  configurationItem,
  crmOrgId,
  selectedColor,
  onChangeName,
  onChangeColor,
  onDelete,
  deleteTitle = 'Delete',
  options = [ActionOption.openInSalesforce, ActionOption.viewSource],
  showLoader,
}: ButtonWithActionMenuProps) => {
  const {
    closePopover: closeMenuPopover,
    openPopover: openMenuPopover,
    anchorElement: menuAnchorEl,
    isPopoverOpen: isMenuPopoverOpen,
    anchorRef: menuRef,
  } = usePopover();

  const {
    closePopover: closeColorPopover,
    openPopover: openColorPopover,
    anchorElement: colorAnchorEl,
    anchorRef: colorRef,
    isPopoverOpen: isColorPopoverOpen,
  } = usePopover();

  const { setContentType } = useDependencies(crmOrgId);

  const { link, filename } = configurationItem ?? {};

  const _options: {
    [key in ActionOption]: {
      icon: ReactNode;
      action: (props: any) => void;
      title: string;
      isDisplayed: boolean;
      withDivider?: boolean;
      color?: string;
      ref?: any;
    };
  } = {
    [ActionOption.openInSalesforce]: {
      icon: <ViewInSalesforce />,
      action: () => window.open(link, '_blank'),
      title: 'Open in Salesforce',
      isDisplayed: !!link,
    },
    [ActionOption.viewSource]: {
      icon: <Apex />,
      action: () => setContentType(DependenciesContentViewType.sourceCode),
      title: 'View Source',
      isDisplayed: !!filename,
    },
    [ActionOption.changeName]: {
      icon: <NotePencil />,
      action: () => onChangeName && onChangeName(),
      title: 'Rename',
      isDisplayed: true,
    },
    [ActionOption.delete]: {
      icon: <Delete />,
      action: () => onDelete && onDelete(),
      title: deleteTitle,
      withDivider: true,
      isDisplayed: true,
      color: colors.blush[600],
    },
    [ActionOption.changeColor]: {
      icon: <PaintBucket />,
      action: (event) => openColorPopover(event),
      title: changeColorTitle,
      isDisplayed: !!onChangeColor,
      ref: colorRef,
    },
  };

  const displayOptions = options.map((option) => _options[option]);

  if (displayOptions.filter((option) => option.isDisplayed).length === 0) {
    return <></>;
  }

  return (
    <>
      <IconButton
        variant="outlined"
        size={'tiny'}
        onClick={(event) => openMenuPopover(event)}
        ref={menuRef}
        loading={showLoader}
      >
        <MoreActionsHorizontal />
      </IconButton>

      <Popover
        open={isMenuPopoverOpen}
        anchorEl={menuAnchorEl}
        onClose={() => closeMenuPopover()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={1} pb={0} minWidth="206px">
          {displayOptions.map((option) => {
            if (!option.isDisplayed) {
              return <Fragment key={option.title} />;
            }

            return (
              <Box key={option.title} ref={option.ref}>
                {option.withDivider && displayOptions.length > 1 && <Divider sx={{ mb: 1 }} />}
                <StyledListItem
                  sx={{
                    color: option.color ?? 'inherit',
                    path: {
                      stroke: option.color ? `${option.color} !important` : colors.black,
                    },
                  }}
                  isActive={option.title === changeColorTitle && isColorPopoverOpen}
                  StartIcon={option.icon}
                  onClick={(event) => {
                    option.action(event);

                    if (option.title !== changeColorTitle) {
                      closeMenuPopover();
                    }
                  }}
                  title={option.title}
                />
              </Box>
            );
          })}
        </Box>
      </Popover>

      {onChangeColor && (
        <Popover
          open={isColorPopoverOpen}
          anchorEl={colorAnchorEl}
          onClose={() => closeColorPopover()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            marginTop: '10px',
            marginLeft: '15px',
            borderRadius: 1,
          }}
        >
          <ColorPicker
            onSelectColor={(color) => {
              onChangeColor(color);
              closeColorPopover();
              closeMenuPopover();
            }}
            selectedColor={selectedColor}
          />
        </Popover>
      )}
    </>
  );
};
