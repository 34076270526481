import { Box, DialogContent, DialogTitle } from '@mui/material';
import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FieldsDialogContentOptions,
  SWEEP_FIELD_NAME,
} from '../../../../../constants/fieldsManagementConsts';
import {
  FieldUsageWithFieldMetadata,
  selectFieldsSearch,
  selectSelectedFunnels,
  selectFunnelsSelectData,
  setSelectedFunnels,
  setFieldsSearch,
  closeFieldsDialog,
  setDialogContent,
} from '../../../../../reducers/funnel-flow-page/fieldsReducer';
import { filterItemsBySearch } from '../../../../../lib/filterItemsBySearch';
import { ObjectTypeChip } from '../../../../common/ObjectTypeChip';
import { DeprecatedAdvancedFilter } from '../../../../common/deprecated-advanced-filter/DeprecatedAdvancedFilter';
import { Button, IconButton, Typography } from '@sweep-io/sweep-design';
import { SearchInput } from '../../../../common/SearchInput';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';
import usePermission from '../../../../common/permissions/usePermission';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';
import FieldsTableSingleObject from './FieldsTableSingleObject';
import { FilterBySnippet } from '../../../../common/deprecated-advanced-filter/FilterBySnippet';
import useObjectTypesWithFetch from '../../../../../hooks/useObjectTypesWithFetch';
import { useFeatureToggle } from '../../../../common/useFeatureToggle';

const FieldsTables = ({
  fields,
  crmOrgId,
}: {
  fields: FieldUsageWithFieldMetadata[];
  crmOrgId: string;
}) => {
  const dispatch = useDispatch();
  const closeDialog = () => dispatch(closeFieldsDialog());
  const searchValue = useSelector(selectFieldsSearch);
  const selectedFunnels = useSelector(selectSelectedFunnels);
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });

  // Show in the table only the rollups that are used in this funnel map - if the field definition is not available ignore it
  const relevantFields = fields.filter(
    (field) => field.field && (field.field.rollup ? field.usedInFunnels.length > 0 : true),
  );

  const fieldsByObjectType = groupBy(relevantFields, (field) => field.field.objectName);

  const availableObjectTypes = Object.keys(fieldsByObjectType);

  const filteredFields = useMemo(() => {
    const filteredBySearch = filterItemsBySearch<FieldUsageWithFieldMetadata>(
      relevantFields,
      searchValue,
      (field) => [field.field[SWEEP_FIELD_NAME], field.field.objectName].join(' '),
    );

    const filteredByFunnelFilter = filteredBySearch.filter((fieldUsage) => {
      return (
        fieldUsage.isPinned ||
        Boolean(fieldUsage.usedInFunnels.find((funnelId) => selectedFunnels.includes(funnelId)))
      );
    });

    return filteredByFunnelFilter;
  }, [relevantFields, searchValue, selectedFunnels]);

  return (
    <>
      <DialogTitle>
        <TitleComponent onClose={closeDialog} crmOrgId={crmOrgId} />
      </DialogTitle>

      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '35px' }}>
          {availableObjectTypes.map((objectName) => {
            return (
              <Box key={objectName} sx={{ display: 'flex', flexDirection: 'column', gap: '19px' }}>
                <Box width="fit-content">
                  <ObjectTypeChip
                    label={objectTypesByName[objectName]?.label ?? objectName}
                    objectType={objectName}
                    key={objectName}
                  />
                </Box>
                <FieldsTableSingleObject
                  fields={filteredFields.filter((field) => field.field.objectName === objectName)}
                  crmOrgId={crmOrgId}
                />
              </Box>
            );
          })}
        </Box>
      </DialogContent>
    </>
  );
};

const FieldsAdvancedFilter = ({ crmOrgId }: { crmOrgId: string }) => {
  const dispatch = useDispatch();

  const funnelSelectData = useSelector(selectFunnelsSelectData);
  const selectedFunnels = useSelector(selectSelectedFunnels);
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });

  const filterItems = funnelSelectData.map(({ id, name, objectName }) => ({
    label: name,
    value: id,
    labelDecoration: (
      <ObjectTypeChip
        objectType={objectName}
        label={objectTypesByName[objectName]?.label ?? objectName}
      />
    ),
  }));

  return (
    <DeprecatedAdvancedFilter
      items={filterItems}
      selectedItems={selectedFunnels}
      onSelectedItemsChange={(items) => dispatch(setSelectedFunnels({ selectedFunnels: items }))}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      texts={{
        allSelected: 'All funnels',
        title: 'Funnels',
        selectAll: 'Select all funnels',
      }}
    />
  );
};

const TitleComponent = ({ onClose, crmOrgId }: { onClose: () => any; crmOrgId: string }) => {
  const dispatch = useDispatch();
  const fieldsSearch = useSelector(selectFieldsSearch);
  const { newDocumentationUiFilters } = useFeatureToggle();

  //TODO check why the search svh has height of 32 and not 24
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h1-bold">Fields</Typography>
      <Box display="flex" gap="12px" alignItems="center" position="relative">
        <Box sx={{ display: 'flex', gap: '4px' }}>
          {!newDocumentationUiFilters && <FilterBySnippet />}
          <FieldsAdvancedFilter crmOrgId={crmOrgId} />
        </Box>
        <SearchInput
          withFixedMagnifyingGlassIcon
          TextFieldProps={{
            sx: { width: '255px' },
            value: fieldsSearch,
            placeholder: 'Search fields',
            onChange: (e) => {
              dispatch(setFieldsSearch({ search: e.target.value }));
            },
          }}
          onClearButtonClick={() => dispatch(setFieldsSearch({ search: '' }))}
        />

        <CreateFieldButton />

        <IconButton variant="flat" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const CreateFieldButton = () => {
  const dispatch = useDispatch();
  const permissionString = 'create:sweep-fields';
  const [isAllowedBtn] = usePermission([permissionString]);

  const handleClick = () => {
    dispatch(setDialogContent({ dialogContent: FieldsDialogContentOptions.CreateField }));
  };

  return (
    <Box
      sx={{
        button: {
          width: 'max-content',
        },
      }}
    >
      <RestrictedTooltip
        to={[permissionString]}
        notAllowedTitle={'To add additional fields, please contact your admin.'}
      >
        <>
          <Button onClick={handleClick} disabled={!isAllowedBtn}>
            New field
          </Button>
        </>
      </RestrictedTooltip>
    </Box>
  );
};

export default FieldsTables;
