import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';
import { useAutomationsContext } from '../Automations/AutomationsContext';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { AutomationsEmptyState } from '../Automations/AutomationsEmptyState';

const ScheduledAssignmentsEmptyStateDialog = ({
  closeDialog,
  transitionDuration,
  onCreate,
  crmOrgId,
}: {
  closeDialog: () => void;
  transitionDuration?: number;
  onCreate: (object: string) => void;
  crmOrgId: string;
}) => {
  const { emptyStateImage, emptyStateTitle, emptyStateSubTitle } = useAutomationsContext();
  const { isExpandedMode } = useExpandedMode();

  return (
    <DialogOnCanvas
      onClose={closeDialog}
      open={true}
      transitionDuration={transitionDuration}
      blockFully={isExpandedMode}
      forceWideScreen={isExpandedMode}
      sx={{
        '.MuiPaper-root': {
          padding: '0 72px',
        },
      }}
    >
      <AutomationsEmptyState
        disabled={false}
        onSelect={onCreate}
        title={emptyStateTitle}
        subtitle={emptyStateSubTitle}
        crmOrgId={crmOrgId}
        image={emptyStateImage}
      />
    </DialogOnCanvas>
  );
};

export default ScheduledAssignmentsEmptyStateDialog;
