import { Box, Stack } from '@mui/material';
import AgentInfoSmall from './AgentInfoSmall';
import AiAgentChat from './AiAgentChat';
import AgentInfoBig from './AgentInfoBig';
import { AI_AGENTS_INFO, CHAT_AREA_MAX_WIDTH } from './aiAgentsConsts';
import { ReactNode, useCallback } from 'react';
import useAiAgentChats from './useAiAgentChats';
import AiAgentMenu from './AiAgentMenu';
import useAiAgents from './useAiAgents';

const ChatWithAgentExpanded = ({
  instructionsButton,
  toggleExpandButton,
  closeButton,
}: {
  instructionsButton: ReactNode;
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
}) => {
  const { activeChat, onConfirmMessage, searchText, activeChatId, disableInput } =
    useAiAgentChats();
  const { renameAgent, activeAgent } = useAiAgents();

  const _onConfirm = useCallback(
    async (message: string) => {
      await onConfirmMessage(message, activeChatId);
    },
    [activeChatId, onConfirmMessage],
  );

  if (!activeAgent) {
    return null;
  }

  const { icon, persona, description } = AI_AGENTS_INFO[activeAgent.type];

  return (
    <Stack
      flex={1}
      width="100%"
      sx={{
        overflow: 'hidden',
      }}
    >
      <Stack direction="row" justifyContent="space-between" width="100%" p={2}>
        <AgentInfoSmall
          icon={icon}
          persona={persona}
          name={activeAgent.name}
          onEditName={(name) => renameAgent(activeAgent.id, name)}
        />
        <Box display="flex" gap={1} alignItems="center">
          {instructionsButton}
          <AiAgentMenu />
          {toggleExpandButton}
          {closeButton}
        </Box>
      </Stack>

      <AiAgentChat
        messages={activeChat?.data?.aiChatDetails?.messages ?? []}
        onConfirm={_onConfirm}
        isLoadingResponse={!!activeChat?.data?.isLoading}
        disableInput={disableInput}
        noteAlignment="center"
        maxWidth={CHAT_AREA_MAX_WIDTH}
        searchTermToHighlight={searchText}
        addToChatBeginning={
          <AgentInfoBig
            icon={icon}
            persona={persona}
            name={activeAgent.name}
            description={description}
          />
        }
      />
    </Stack>
  );
};

export default ChatWithAgentExpanded;
