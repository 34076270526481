import { AutomationsEmptyState } from './AutomationsEmptyState';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SingleAutomationDialog, SingleAutomationDialogRef } from './SingleAutomationDialog';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import {
  AutomationSortOption,
  getAddAutomationButtonByType,
  getDedupMatchingDefaultAutomation,
} from './helper';
import { Button } from '@sweep-io/sweep-design';
import { SingleDedupMatchingDialog } from '../routing/SingleDedupMatchingDialog';
import { AutomationType } from '../../types/enums/AutomationType';
import { AutomationsTemplatesWrapper, WrapperType } from './AutomationsTemplatesWrapper';
import { AutoTemplatesStruct } from '../../constants/automationTemplates';
import { useSweepFields } from '../../sweep-fields/useCachedSweepFields';
import { PageTemplateCanvasDialog, PageTemplates } from '../pages/pages-templates';
import { DedupTemplateContent } from '../pages/canvas-pages/dedup-and-matching/DedupTemplateContent';
import { useAutomationsContext } from './AutomationsContext';
import useQueryParams from '../../hooks/useQueryParams';
import useSweepNavigate from '../common/useSweepNavigate';
import useCompileTemplate from '../../hooks/useCompileTemplate';
import { DedupMatchingType } from '../../types/enums/DedupMatchingType';
import AutomationsListWithSort from './AutomationsListWithSort';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { SweepCanvasFiltersMap } from '../pages/configuration-canvas-filters/filterTypes';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { Box } from '@mui/material';

interface AutomationDialogContentProps {
  onSaveOrCreate: (
    automationJson: AutomationStructureNew,
    crmOrgIds: string[],
    isEdit: boolean,
  ) => any;
  onToggleActivation: (
    automationJson: AutomationStructureNew,
    deployToOrgIds: string[],
    isActive: boolean,
  ) => any;
  onDeleteAutomation: (automationId: string, automationType: AutomationType) => any;
  automations: AutomationStructureNew[];
  crmOrgId: string;
  recordTypesData?: RecordTypesData;
  funnelsData?: FunnelsData;
  sortOptions?: AutomationSortOption[];
  setHoveredItem?: (automationId?: string) => void;
  disableCanvasTemplates?: boolean;
  onCreateData?: { newElement: Partial<AutomationStructureNew> };
  filterFunnelIds?: string[];
  excludeAutomationsNotInFunnelMapObjects?: boolean;
  objectType?: string;
  hideSort?: boolean;
  onDeployAutomation: (
    deployAutomationDto: AutomationStructureNew,
    deployToOrgIds: string[],
  ) => any;
  isShowWideScreenPopup?: boolean;
  filtersForExpandedMode: SweepCanvasFiltersMap;
  disableExpandedMode?: boolean; //used for right panel (tab in the stage dialog)
}

export const AutomationDialogContent = ({
  crmOrgId,
  automations,
  onSaveOrCreate,
  recordTypesData,
  funnelsData,
  onToggleActivation,
  onDeleteAutomation,
  sortOptions,
  setHoveredItem,
  disableCanvasTemplates,
  onCreateData,
  filterFunnelIds,
  excludeAutomationsNotInFunnelMapObjects,
  objectType,
  hideSort,
  onDeployAutomation,
  isShowWideScreenPopup,
  filtersForExpandedMode,
  disableExpandedMode,
}: AutomationDialogContentProps) => {
  const { newDocumentationUiFilters } = useFeatureToggle();
  const singleAutomationDialogRef = useRef<SingleAutomationDialogRef>(null);
  const [dedupMatchingTypeInNew, setDedupMatchingTypeInNew] = useState<DedupMatchingType>();
  const [isForceWideScreenPopup, setIsForceWideScreenPopup] = useState(false);
  const hasNoAutomations = automations.length === 0;
  const dataByVariant = useAutomationsContext();
  const { automationVariant, automationJson, setAutomationJson } = dataByVariant;
  const [isTemplateScreenOpen, setIsTemplateScreenOpen] = useState(false);
  const { getSweepFieldsById } = useSweepFields();

  const query = useQueryParams();
  const forceAutomationId = query.get('forceAutomationId');
  const forceVersionId = query.get('forceVersionId');
  const { removeQueryParams } = useSweepNavigate();
  const { openConfirm } = useConfirm();

  const isAutomationInEdit = Object.keys(automationJson)?.length > 0;

  useEffect(() => {
    if (forceAutomationId) {
      const automation = automations.find(
        (automation) => automation.automationId === forceAutomationId,
      );
      if (automation) {
        setAutomationJson(automation);
      }
      removeQueryParams(['forceAutomationId', 'forceVersionId']);
    }
  }, [automations, forceAutomationId, removeQueryParams, forceVersionId, setAutomationJson]);

  const closeAutomationDialog = useCallback(() => {
    setAutomationJson({});
    setDedupMatchingTypeInNew(undefined);
    setIsForceWideScreenPopup(false);
  }, [setAutomationJson]);

  const _onSaveOrCreate = useCallback(
    async (automationJson: AutomationStructureNew, crmOrgIds: string[]) => {
      const savedAutomation = await onSaveOrCreate(
        automationJson,
        crmOrgIds,
        !!automationJson?.automationId,
      );
      if (savedAutomation?.isError) {
        return {};
      }
      closeAutomationDialog();
      return savedAutomation;
    },
    [closeAutomationDialog, onSaveOrCreate],
  );

  const openNewAutomationWithObject = useCallback(
    (objectName: string, type?: DedupMatchingType) => {
      if (type) {
        const newAutomation = getDedupMatchingDefaultAutomation(
          type ?? DedupMatchingType.LEAD_TO_LEAD_DEDUP,
        );
        setAutomationJson({
          ...newAutomation,
          ...automationJson,
          objectName,
        });
        setDedupMatchingTypeInNew(type);
      } else {
        setAutomationJson({ ...onCreateData?.newElement, objectName });
      }
    },
    [automationJson, onCreateData?.newElement, setAutomationJson],
  );

  const compileTemplate = useCompileTemplate();

  const showRegularForm =
    automationVariant !== AutomationFormVariant.DEDUP_MATCHING &&
    automationJson?.type !== AutomationType.Dedupe &&
    automationJson?.type !== AutomationType.Matching;

  const isShowTemplatesForVariant = !disableCanvasTemplates && dataByVariant.templateSupport;
  const renderDedupTemplates = AutomationFormVariant.DEDUP_MATCHING === automationVariant;

  const getFieldsList = useCallback(
    async (fieldsList: string[]) => {
      await getSweepFieldsById({
        fieldIds: fieldsList,
      });
    },
    [getSweepFieldsById],
  );

  const changeAutomationModal = useCallback(
    (automationItem: AutomationStructureNew) => {
      setAutomationJson(automationItem);
    },
    [setAutomationJson],
  );

  const onBeforeCloseModal = useCallback(
    async (automationItem: AutomationStructureNew) => {
      const isPristine = singleAutomationDialogRef.current?.isPristine();
      if (isPristine) {
        changeAutomationModal(automationItem);
      } else {
        const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
        if (isConfirmed) {
          changeAutomationModal(automationItem);
        }
      }
    },
    [changeAutomationModal, openConfirm, singleAutomationDialogRef],
  );

  const onDuplicateAutomation = useCallback(
    (automationItem: AutomationStructureNew) => {
      if (singleAutomationDialogRef.current) {
        onBeforeCloseModal(automationItem);
      } else {
        setAutomationJson(automationItem);
      }
    },
    [onBeforeCloseModal, setAutomationJson],
  );

  const onEditAutomation = useCallback(
    (automationItem: AutomationStructureNew) => {
      if (singleAutomationDialogRef.current) {
        onBeforeCloseModal(automationItem);
      } else {
        setAutomationJson(automationItem);
      }
    },
    [onBeforeCloseModal, setAutomationJson],
  );

  const getNewButtonWithTemplates = useCallback(() => {
    const getNewButton = () => {
      return (
        <Button
          disabled={!!isTemplateScreenOpen || isAutomationInEdit}
          startIconName="Plus"
          size="small"
          onClick={() => {
            setIsTemplateScreenOpen(true);
          }}
        >
          New
        </Button>
      );
    };

    if (isShowTemplatesForVariant) {
      return (
        <>
          {getNewButton()}
          {isTemplateScreenOpen && (
            <AutomationsTemplatesWrapper
              title={`Create a new ${dataByVariant.nameInMessageSingular}`}
              onCancel={() => setIsTemplateScreenOpen(false)}
              wrapperType={WrapperType.dialog}
              automationVariant={automationVariant}
              crmOrgId={crmOrgId}
              onSelectTemplate={(item: AutoTemplatesStruct) => {
                const automationToEdit = compileTemplate(item.automation);
                setAutomationJson(automationToEdit);
                getFieldsList(item.fieldsList);
                setIsTemplateScreenOpen(false);
              }}
              createEmptyButton={getAddAutomationButtonByType({
                variant: automationVariant,
                props: {
                  disabled: false,
                  onSelect: (object: string) => {
                    openNewAutomationWithObject(object);
                    setIsTemplateScreenOpen(false);
                  },
                  crmOrgId,
                  buttonText: 'Get started',
                  excludeAutomationsNotInFunnelMapObjects,
                  funnelsData,
                  recordTypesData,
                  automationVariant,
                  isEmptyState: false,
                  variant: 'outlined',
                },
              })}
            />
          )}
        </>
      );
    }
    if (!disableCanvasTemplates && renderDedupTemplates) {
      return (
        <>
          {getNewButton()}
          <PageTemplateCanvasDialog
            isOpen={isTemplateScreenOpen}
            onCancel={() => setIsTemplateScreenOpen(false)}
            title={`Create your next ${dataByVariant.nameInMessageSingular}`}
          >
            <DedupTemplateContent
              onSelect={(objectType, type) => {
                openNewAutomationWithObject(objectType, type);
                setIsTemplateScreenOpen(false);
              }}
            />
          </PageTemplateCanvasDialog>
        </>
      );
    }

    return getAddAutomationButtonByType({
      variant: automationVariant,
      props: {
        disabled: isAutomationInEdit,
        onSelect: openNewAutomationWithObject,
        crmOrgId,
        buttonText: 'New',
        excludeAutomationsNotInFunnelMapObjects,
        funnelsData,
        recordTypesData,
        automationVariant,
        isEmptyState: false,
      },
    });
  }, [
    automationVariant,
    compileTemplate,
    crmOrgId,
    dataByVariant.nameInMessageSingular,
    disableCanvasTemplates,
    excludeAutomationsNotInFunnelMapObjects,
    funnelsData,
    getFieldsList,
    isAutomationInEdit,
    isShowTemplatesForVariant,
    isTemplateScreenOpen,
    openNewAutomationWithObject,
    recordTypesData,
    renderDedupTemplates,
    setAutomationJson,
  ]);

  const renderEmptyAutomationsState = () => {
    if (isShowTemplatesForVariant) {
      return (
        <AutomationsTemplatesWrapper
          wrapperType={_isExpandedMode ? WrapperType.box : WrapperType.fullPage}
          automationVariant={automationVariant}
          crmOrgId={crmOrgId}
          title={`Get started with your first ${dataByVariant.nameInMessageSingular}`}
          onSelectTemplate={(item: AutoTemplatesStruct) => {
            setAutomationJson(item.automation);
            getFieldsList(item.fieldsList);
            setIsForceWideScreenPopup(true);
          }}
          createEmptyButton={getAddAutomationButtonByType({
            variant: automationVariant,
            props: {
              disabled: isAutomationInEdit,
              onSelect: (object: string) => {
                openNewAutomationWithObject(object);
                setIsForceWideScreenPopup(true);
              },
              crmOrgId,
              buttonText: 'Get started',
              excludeAutomationsNotInFunnelMapObjects,
              funnelsData,
              recordTypesData,
              automationVariant,
              isEmptyState: false,
              variant: 'outlined',
            },
          })}
        />
      );
    } else if (renderDedupTemplates) {
      return (
        <PageTemplates
          isFullPage={true}
          title={`Create your first ${dataByVariant.nameInMessageSingular}`}
        >
          <DedupTemplateContent onSelect={openNewAutomationWithObject} />
        </PageTemplates>
      );
    } else {
      return (
        <AutomationsEmptyState
          disabled={isAutomationInEdit}
          onSelect={openNewAutomationWithObject}
          crmOrgId={crmOrgId}
          title={dataByVariant.emptyStateTitle}
          subtitle={dataByVariant.emptyStateSubTitle}
          image={dataByVariant.emptyStateImage}
          excludeAutomationsNotInFunnelMapObjects={excludeAutomationsNotInFunnelMapObjects}
          defaultObjectType={objectType}
          recordTypesData={recordTypesData}
          funnelsData={funnelsData}
        />
      );
    }
  };

  const { isExpandedMode } = useExpandedMode();
  const _isExpandedMode = isExpandedMode && !disableExpandedMode;

  return (
    <>
      <Box
        className="automations-dialog-content"
        sx={{
          overflow: 'hidden',
          paddingBottom: 2,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: newDocumentationUiFilters ? 1 : 2,
          height: '100%',
        }}
      >
        {hasNoAutomations && renderEmptyAutomationsState()}

        {!hasNoAutomations && (
          <AutomationsListWithSort
            automations={automations}
            crmOrgId={crmOrgId}
            closeAutomationDialog={closeAutomationDialog}
            onDeployAutomation={onDeployAutomation}
            onToggleActivation={onToggleActivation}
            onDeleteAutomation={onDeleteAutomation}
            onDuplicateAutomation={onDuplicateAutomation}
            onEditAutomation={onEditAutomation}
            hideSort={hideSort}
            getNewButtonWithTemplates={getNewButtonWithTemplates}
            onCreateData={onCreateData}
            sortOptions={sortOptions}
            setHoveredItem={setHoveredItem}
            filtersForExpandedMode={filtersForExpandedMode}
            isExpandedMode={!!isExpandedMode}
          />
        )}
      </Box>

      {isAutomationInEdit && showRegularForm && (
        <SingleAutomationDialog
          ref={singleAutomationDialogRef}
          isOpen={isAutomationInEdit}
          onSaveOrCreate={_onSaveOrCreate}
          onCancel={closeAutomationDialog}
          crmOrgId={crmOrgId}
          objectName={automationJson?.objectName ?? ''}
          filterFunnelIds={filterFunnelIds}
          isShowWideScreenPopup={isShowWideScreenPopup || isForceWideScreenPopup || _isExpandedMode}
        />
      )}
      {isAutomationInEdit && !showRegularForm && (
        <SingleDedupMatchingDialog
          ref={singleAutomationDialogRef}
          isOpen={isAutomationInEdit}
          newDedupMatchingType={
            dedupMatchingTypeInNew ??
            (
              automationJson?.automationDetails?.actions?.[0] as
                | DeduplicationAutomationAction
                | MatchingAutomationAction
            )?.actionParams?.dmType
          }
          onSaveOrCreate={_onSaveOrCreate}
          onCancel={closeAutomationDialog}
          crmOrgId={crmOrgId}
          objectName={automationJson.objectName ?? ''}
          forceWideScreen={hasNoAutomations || _isExpandedMode}
        />
      )}
    </>
  );
};
