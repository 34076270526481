import { Box } from '@mui/material';
import { RecordTypesFilter } from './RecordTypesFilter';
import { Fragment, useEffect, useMemo } from 'react';
import { StepsFilter } from './StepsFilter';
import { CanvasFilterTypes, SweepCanvasFiltersMap } from './filterTypes';
import { ObjectsFilter } from './ObjectsFilter';
import { MultiGenericFilter } from './MultiGenericFilter';
import {
  selectCanvasFilterSelectedValues,
  selectCanvasFiltersAutomations,
  selectCanvasFiltersCrmOrgId,
  selectCanvasFiltersFilters,
  selectCanvasFiltersFunnelMap,
  setCanvasFiltersAutomations,
  setCanvasFiltersCrmOrgId,
  setCanvasFiltersFilters,
  setCanvasFiltersFunnelMap,
} from './canvasFiltersReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ClearButton } from './ClearButton';
import { colors } from '@sweep-io/sweep-design';
import { ToggleFilter } from './ToggleFilter';
import { SingleGenericFilter } from './SingleGenericFilter';
import { FiltersMap } from '../../documentation/selected-object/filters/filtersOptions';
import classNames from 'classnames';
import { useCanvasFilters } from './useCanvasFilters';
import { useFeatureToggle } from '../../common/useFeatureToggle';

export interface CanvasFiltersProps {
  funnelMap?: FunnelMap;
  crmOrgId?: string;
  filters: SweepCanvasFiltersMap;
  automations?: AutomationStructureNew[];
  hideClearAllButton?: boolean;
  renderClearButtonForEveryFilter?: boolean;
  disableFitOnCanvasElements?: boolean;
}

export const CanvasFilters = ({
  funnelMap: _funnelMap,
  crmOrgId: _crmOrgId,
  filters: _filters,
  automations: _automations,
  hideClearAllButton,
  renderClearButtonForEveryFilter,
  disableFitOnCanvasElements,
}: CanvasFiltersProps) => {
  const { newDocumentationUiFilters } = useFeatureToggle();
  const dispatch = useDispatch();
  const filters = useSelector(selectCanvasFiltersFilters);
  const canvasFiltersValues = useSelector(selectCanvasFilterSelectedValues);
  const funnelMap = useSelector(selectCanvasFiltersFunnelMap);
  const crmOrgId = useSelector(selectCanvasFiltersCrmOrgId);
  const automations = useSelector(selectCanvasFiltersAutomations);

  const { clearAllValues } = useCanvasFilters();

  useEffect(() => {
    if (_funnelMap) {
      dispatch(setCanvasFiltersFunnelMap({ funnelMap: _funnelMap }));
    }
  }, [_funnelMap, dispatch]);

  useEffect(() => {
    if (_crmOrgId) {
      dispatch(setCanvasFiltersCrmOrgId({ crmOrgId: _crmOrgId }));
    }
  }, [_crmOrgId, dispatch]);

  useEffect(() => {
    if (_filters) {
      dispatch(setCanvasFiltersFilters({ filters: _filters }));
    }
  }, [_filters, dispatch]);

  useEffect(() => {
    if (_automations) {
      dispatch(setCanvasFiltersAutomations({ automations: _automations }));
    }
  }, [_automations, dispatch]);

  const funnelsData = useMemo(() => _funnelMap?.funnelsData || {}, [_funnelMap?.funnelsData]);

  const recordTypesData = useMemo(
    () => funnelMap?.recordTypesData || {},
    [funnelMap?.recordTypesData],
  );

  const showClear = hideClearAllButton
    ? !hideClearAllButton
    : Object.entries(filters).length > 1 &&
      Object.values(
        Object.entries(canvasFiltersValues).filter(([key]) => key !== FiltersMap.activeState),
      ).some(([, values]) => values.length > 0);

  const renderFilters = useMemo(() => {
    if (!filters || !crmOrgId || !funnelsData) {
      return null;
    }
    return Object.entries(filters)
      .sort(([, filterA], [, filterB]) => (filterA.position || 0) - (filterB.position || 0))
      .map(([filterKey, filter]) => {
        switch (filter.type) {
          case CanvasFilterTypes.RECORD_TYPE: {
            let filteredObjects = undefined;
            if (filter.objectsFilter?.type === 'reference') {
              filteredObjects = canvasFiltersValues[filter.objectsFilter.filterRef];
            }
            if (filter.objectsFilter?.type === 'values') {
              filteredObjects = filter.objectsFilter.values;
            }

            return (
              <RecordTypesFilter
                filterKey={filterKey}
                funnelsData={funnelsData}
                recordTypesData={recordTypesData}
                crmOrgId={crmOrgId}
                automations={automations}
                filteredObjects={filteredObjects}
                key={filterKey}
                disableFitOnCanvasElements={disableFitOnCanvasElements}
                renderSeparateClearButton={renderClearButtonForEveryFilter}
              />
            );
          }
          case CanvasFilterTypes.STEP: {
            const filteredRecordTypes = filter.recordTypeFilterRef
              ? canvasFiltersValues[filter.recordTypeFilterRef]
              : undefined;

            // const objectsFilter =  filter.recordTypeFilterRef ? (filters[filter.recordTypeFilterRef] as RecordTypesCanvasFilter)
            let filteredObjects: string[] | undefined = undefined;

            if (filter.recordTypeFilterRef) {
              const _filter = filters[filter.recordTypeFilterRef];
              if (_filter.type === CanvasFilterTypes.RECORD_TYPE) {
                filteredObjects =
                  _filter.objectsFilter?.type === 'reference'
                    ? canvasFiltersValues[_filter.objectsFilter.filterRef]
                    : _filter.objectsFilter?.values;
              }
            }

            return (
              <StepsFilter
                filterKey={filterKey}
                funnelsData={funnelsData}
                recordTypesData={recordTypesData}
                filteredRecordTypes={filteredRecordTypes}
                automations={automations}
                filteredObjects={filteredObjects}
                key={filterKey}
                filterButtonDataTestId="steps-filter-button"
                disableFitOnCanvasElements={disableFitOnCanvasElements}
                renderSeparateClearButton={renderClearButtonForEveryFilter}
              />
            );
          }
          case CanvasFilterTypes.OBJECT: {
            return (
              <ObjectsFilter
                filterKey={filterKey}
                funnelsData={funnelsData}
                recordTypesData={recordTypesData}
                crmOrgId={crmOrgId}
                key={filterKey}
                automations={automations}
                disableFitOnCanvasElements={disableFitOnCanvasElements}
                renderSeparateClearButton={renderClearButtonForEveryFilter}
              />
            );
          }

          case CanvasFilterTypes.MULTI_GENERIC:
            if (!filter.items?.length) {
              return <Fragment key={filterKey} />; //to prevent component from rendering for dynamically created items lists
            }
            return (
              <MultiGenericFilter
                filterKey={filterKey}
                items={filter.items}
                key={filterKey}
                label={filter.label}
                renderSeparateClearButton={renderClearButtonForEveryFilter}
              />
            );

          case CanvasFilterTypes.SINGLE_GENERIC:
            if (!filter.items?.length) {
              return <Fragment key={filterKey} />; //to prevent component from rendering for dynamically created items lists
            }
            return (
              <SingleGenericFilter
                filterKey={filterKey}
                items={filter.items}
                key={filterKey}
                label={filter.label}
                renderSeparateClearButton={renderClearButtonForEveryFilter}
              />
            );

          case CanvasFilterTypes.TOGGLE_FILTER:
            return (
              <ToggleFilter
                filterKey={filterKey}
                checked={filter.checked}
                label={filter.label}
                key={filterKey}
              />
            );
          default:
            return null;
        }
      });
  }, [
    filters,
    automations,
    canvasFiltersValues,
    crmOrgId,
    disableFitOnCanvasElements,
    funnelsData,
    recordTypesData,
    renderClearButtonForEveryFilter,
  ]);

  if (!filters || !crmOrgId || !funnelsData) {
    return null;
  }

  return (
    <Box
      className={classNames({ 'canvas-filters-wrapper': true })}
      sx={{
        display: 'flex',
        gap: newDocumentationUiFilters ? 2 : 1,
        'div > button.MuiButtonBase-root:not(:hover), > button.MuiButtonBase-root:not(:hover)': {
          backgroundColor: colors.white,
        },
      }}
    >
      {renderFilters}
      {showClear && !newDocumentationUiFilters && (
        <ClearButton onClick={clearAllValues}>Clear</ClearButton>
      )}
    </Box>
  );
};
