import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  selectIsExpandedAiChats,
  selectIsOpenedAiChats,
  selectIsCreateActive,
  setIsCreateActive,
  toggleExpandMode,
  toggleOpened,
} from './aiAgentCenterReducer';

const useAiAgentCenter = () => {
  const isExpanded = useSelector(selectIsExpandedAiChats);
  const isOpened = useSelector(selectIsOpenedAiChats);
  const isCreateAgentActive = useSelector(selectIsCreateActive);

  const dispatch = useDispatch();

  const toggleIsExpanded = useCallback(() => {
    dispatch(toggleExpandMode());
  }, [dispatch]);

  const toggleIsOpened = useCallback(() => {
    dispatch(toggleOpened());
  }, [dispatch]);

  const setIsAgentCreateActive = useCallback(
    (isActive: boolean) => {
      dispatch(setIsCreateActive({ isActive }));
    },
    [dispatch],
  );

  return {
    isExpanded,
    toggleIsExpanded,
    toggleIsOpened,
    isOpened,
    setIsAgentCreateActive,
    isCreateAgentActive,
  };
};

export default useAiAgentCenter;
