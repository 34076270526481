import { useDispatch } from 'react-redux';
import {
  CloudingoConnectionStatus,
  setCloudingoIntegrationStatus,
  setCustomIntegrations,
  setIncludeSandboxes,
  setSlackIntegrationFullStatus,
  SlackConnectionStatus,
} from '../reducers/integrationsReducer';
import { useDataIntegrationApiFacade } from '../apis/facades/useDataIntegrationApiFacade';
import { useCallback } from 'react';
import { setIsSlackRecipientsCached } from '../reducers/slackReducer';
import { telemetry } from '../telemetry';
import { useSweepNotifications } from '../components/notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../reducers/notificationsReducer';
import { CustomWebhookInterface } from '../components/dashboard/webhooks/WebhooksContext';

const useIntegrations = () => {
  const dispatch = useDispatch();
  const {
    get_slack_status,
    get_cloudingo_status,
    get_slack_recipients,
    patch_slackIncludeSandboxes,
    get_custom_integrations,
    post_custom_integration,
    put_custom_integration,
    delete_custom_integration,
    get_custom_integration_password,
  } = useDataIntegrationApiFacade();
  const { addNotification } = useSweepNotifications();

  const notifyUser = useCallback(
    ({
      variant,
      message,
      keepOpen = false,
    }: {
      variant: SweepNotificationVariant;
      message: string;
      keepOpen?: boolean;
    }) => {
      addNotification({
        message: message,
        keepOpen,
        variant,
      });
    },
    [addNotification],
  );

  const getSlackStatus = async () => {
    const response = await get_slack_status();
    const status = response.status as SlackConnectionStatus;
    const upgradeRequired = !!response.upgradeRequired;
    const includeSandboxes = response.includeSandboxes;
    dispatch(
      setSlackIntegrationFullStatus({
        slackIntegrationStatus: status,
        upgradeRequired,
        includeSandboxes,
      }),
    );
    return status;
  };

  const getCloudingoStatus = async () => {
    const response = await get_cloudingo_status();
    const status = response.status as CloudingoConnectionStatus;
    dispatch(setCloudingoIntegrationStatus(status));
    return status;
  };

  const getSlackRecipients = useCallback(
    async (idsArray?: string[], invalidateCache?: boolean) => {
      const response = await get_slack_recipients(idsArray, invalidateCache);
      dispatch(setIsSlackRecipientsCached({ isCached: response.isCached }));
      return response.data;
    },
    [dispatch, get_slack_recipients],
  );

  const updateIncludeSandboxes = useCallback(
    async (includeSandboxes: boolean) => {
      //supporting happy state
      dispatch(setIncludeSandboxes({ includeSandboxes }));
      const connectedMsg = 'Slack alerts can now be triggered from sandbox environments';
      const disconnectedMsg = 'Sandbox environments will no longer trigger Slack alerts';

      try {
        await patch_slackIncludeSandboxes(includeSandboxes);
        notifyUser({
          variant: includeSandboxes
            ? SweepNotificationVariant.Success
            : SweepNotificationVariant.Warning,
          message: includeSandboxes ? connectedMsg : disconnectedMsg,
        });
      } catch (error) {
        telemetry.captureError(error);
        dispatch(setIncludeSandboxes({ includeSandboxes: !includeSandboxes }));
        notifyUser({
          variant: SweepNotificationVariant.Error,
          message: 'Connection failed, please try again later',
          keepOpen: true,
        });
      }
    },
    [patch_slackIncludeSandboxes, dispatch, notifyUser],
  );

  const getCustomIntegrations = useCallback(async (): Promise<CustomWebhookInterface[]> => {
    const integrations = await get_custom_integrations();
    dispatch(setCustomIntegrations(integrations));
    return integrations;
  }, [dispatch, get_custom_integrations]);

  const createCustomIntegration = useCallback(
    async (newWebhook: CustomWebhookInterface): Promise<CustomWebhookInterface> => {
      const integration = await post_custom_integration(newWebhook);
      await getCustomIntegrations();
      return integration;
    },
    [getCustomIntegrations, post_custom_integration],
  );

  const updateCustomIntegration = useCallback(
    async (webhook: CustomWebhookInterface): Promise<CustomWebhookInterface> => {
      const integration = await put_custom_integration(webhook.id, webhook);
      await getCustomIntegrations();
      return integration;
    },
    [getCustomIntegrations, put_custom_integration],
  );

  const deleteCustomIntegration = async (webhookId: string, dryRun?: boolean): Promise<void> => {
    await delete_custom_integration(webhookId, dryRun);
    await getCustomIntegrations();
  };

  const getCustomIntegrationPassword = useCallback(
    async (webhookId: string): Promise<string> => {
      const password = await get_custom_integration_password(webhookId);
      return password;
    },
    [get_custom_integration_password],
  );

  return {
    getSlackStatus,
    getCloudingoStatus,
    getSlackRecipients,
    updateIncludeSandboxes,
    getCustomIntegrations,
    createCustomIntegration,
    updateCustomIntegration,
    deleteCustomIntegration,
    getCustomIntegrationPassword,
  };
};

export { useIntegrations };
