import { AiChat } from '../common/ai-chat/aiChatTypes';
import { highlightMatch } from '../../lib/highlightMatch';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import AiAgentChatListItem from './AiAgentChatListItem';
import useAiAgentChats from './useAiAgentChats';

const getSubtitleFromFirstMatch = (chatBody: string, searchText?: string) => {
  const firstMatchStartIndex = searchText ? chatBody.indexOf(searchText) : undefined;
  const matchInBeginning = !isNil(firstMatchStartIndex) && firstMatchStartIndex - 10 < 0;
  const subtitleStartIndex =
    matchInBeginning || !firstMatchStartIndex ? 0 : firstMatchStartIndex - 10;
  const prefix = matchInBeginning ? '' : '...';
  const subtitle = highlightMatch(`${prefix}${chatBody.slice(subtitleStartIndex)}`, searchText);
  return subtitle;
};

const AiAgentChatListItemSearch = ({
  chat,
  isActive,
  onClick,
}: {
  chat: AiChat;
  isActive: boolean;
  onClick: () => void;
}) => {
  const { searchText } = useAiAgentChats();
  const title = highlightMatch(chat.name, searchText);
  const chatBody = chat.aiChatDetails.messages.map((msg) => msg.content).join(' ');
  const subtitle = useMemo(() => {
    return getSubtitleFromFirstMatch(chatBody, searchText);
  }, [chatBody, searchText]);

  return (
    <AiAgentChatListItem
      title={title}
      subtitle={subtitle}
      isActive={isActive}
      chat={{ ...chat, isAnimateName: false }}
      onClick={onClick}
      disableActions={false}
    />
  );
};

export default AiAgentChatListItemSearch;
