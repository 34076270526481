import DialogOnCanvas from '../../DialogOnCanvas';
import useConfirm from '../../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { useCallback, useContext } from 'react';
import { HubspotCampaignCtx } from './HubspotCampaignCtx';
import { DialogAbsoluteCloseButton } from './DialogAbsoluteCloseButton';

export const HubspotCampaignDialog = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}) => {
  const { isPristine } = useContext(HubspotCampaignCtx);
  const { openConfirm } = useConfirm();

  const _onClose = useCallback(async () => {
    if (isPristine) {
      onClose?.();
    } else {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (isConfirmed) {
        onClose?.();
      }
    }
  }, [isPristine, onClose, openConfirm]);

  return (
    <DialogOnCanvas open={isOpen} onClose={_onClose} blockFully={!isPristine}>
      <DialogAbsoluteCloseButton onClose={_onClose} />
      {children}
    </DialogOnCanvas>
  );
};
