// Do not put any dispatcher here.
// Only pure API calls
// The Facades should mimic exactly the corresponding API.

import { useCallback } from 'react';
import { useSweepApi } from '../sweep';
import {
  LayoutData,
  ObjectType,
  OpenAiResponse,
  ParserResponse,
  RecordTypeData,
  SFDCLeadingField,
} from '../../components/parser/ParserTypes';
import buildURLQuery from '../../lib/buildURLQuery';
import {
  ContentSearchResponse,
  SearchResponse,
} from '../../components/documentation/universal-search/types';
import { AiChatDto } from '../../components/common/ai-chat/aiChatTypes';
import groupBy from 'lodash/groupBy';
import {
  ConfigurationDependencies,
  ConfigurationConsumers,
  FieldMetadataRecordProperties,
  NameProperties,
} from '../../components/documentation/dependencies/DependenciesTypes';
import { AiChatConfigurationContext, AiChatFieldContext } from '@server/ai';

type SFDCObjectType = 'Lead' | 'Account' | 'Opportunity' | 'Contact' | 'Case';

export enum CrmOrgPlatforms {
  Salesforce = 'salesforce',
}

export interface CrmOrgCreateDto {
  name: string;
  platform: CrmOrgPlatforms;
  isMain?: boolean;
  isSandbox: boolean;
}

export interface CrmOrgPatchDto {
  name: string;
}

export interface CrmOrgGetUsersResponse {
  users: CrmOrgUser[];
}

export interface CrmOrgUsersBaseResponse {
  includeInactive: boolean;
  users: CrmOrgUserBase[];
}

export interface CrmOrgOAuthDto {
  crmOrgId: string;
  authCode: string;
  isSandbox: boolean;
  isOnboardingFastFetch?: boolean;
}

export interface ChildRelationshipObjectResponse {
  childRelationships: ChildRelationshipObject[];
}

export interface CrmOrgEmailTemplatesResponse {
  emailTemplates: CrmOrgEmailTemplate[];
}

export interface CrmOrgQueueResponse {
  queues: CrmOrgQueue[];
}

export interface CrmOrgQueue {
  id: string;
  name: string;
}

export interface CrmOrgObjectTypesResponse {
  objectTypes: ObjectTypeName[];
}

export type AiChatWithOrgPayload = {
  sessionId: string;
  message: string;
  context: string;
  isFirstMessage: boolean;
  crmOrgId: string;
};

interface AiChatBasePayload {
  sessionId: string;
  message: string;
  isFirstMessage: boolean;
  crmOrgId: string;
}

export interface AiChatFieldPayload extends AiChatBasePayload {
  context: AiChatFieldContext;
}
export interface AiChatConfigurationPayload extends AiChatBasePayload {
  context: AiChatConfigurationContext;
}

export interface ObjectTypesArguments {
  orgId: string;
  createableObjectsOnly?: boolean;
  triggerableObjectsOnly?: boolean;
  updateableObjectsOnly?: boolean;
}

export const useCrmOrgsApiFacade = () => {
  const sweepApi = useSweepApi();

  return {
    get_crmOrgs: useCallback(
      async ({ connectedOnly }: { connectedOnly?: boolean } = {}) => {
        const response = await sweepApi.get(
          `/crm-orgs${connectedOnly ? '?connectedOnly=true' : ''}`,
        );

        return response.data as CrmOrg[];
      },
      [sweepApi],
    ),
    get_crmOrg: useCallback(
      async ({ orgId }: { orgId: string }) => {
        const response = await sweepApi.get(`/crm-orgs/org/${orgId}`);

        return response.data as CrmOrg;
      },
      [sweepApi],
    ),
    delete_crmOrg: useCallback(
      async ({ orgId }: { orgId: string }) => {
        const response = await sweepApi.delete(`/crm-orgs/org/${orgId}`);

        return response.data;
      },
      [sweepApi],
    ),
    post_crmOrg: useCallback(
      async ({ payload: { name, platform, isMain, isSandbox } }: { payload: CrmOrgCreateDto }) => {
        const response = await sweepApi.post(`/crm-orgs/new`, {
          name,
          platform,
          isMain,
          isSandbox,
        });

        return response.data as CrmOrg;
      },
      [sweepApi],
    ),
    patch_crmOrg: useCallback(
      async ({ orgId, payload: { name } }: { orgId: string; payload: CrmOrgPatchDto }) => {
        const response = await sweepApi.patch(`/crm-orgs/org/${orgId}`, { name });

        return response.data as CrmOrg;
      },
      [sweepApi],
    ),
    get_crmOrgUsers: useCallback(
      async ({ orgId, includeInactive }: { orgId: string; includeInactive?: boolean }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${orgId}/users/member-properties` +
            (includeInactive ? '?includeInactive=true' : ''),
        );

        return response.data as CrmOrgGetUsersResponse;
      },
      [sweepApi],
    ),
    get_crmOrgUsersBase: useCallback(
      async ({ orgId, includeInactive }: { orgId: string; includeInactive?: boolean }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${orgId}/users` + (includeInactive ? '?includeInactive=true' : ''),
        );

        return response.data as CrmOrgUsersBaseResponse;
      },
      [sweepApi],
    ),

    post_crmOrgSalesforceOauthCode: useCallback(
      async ({
        payload: { crmOrgId, authCode, isSandbox, isOnboardingFastFetch },
      }: {
        payload: CrmOrgOAuthDto;
      }) => {
        const response = await sweepApi.post(`/crm-orgs/salesforce-oauth2-code`, {
          crmOrgId,
          authCode,
          isSandbox,
          isOnboardingFastFetch,
        });

        return response.data;
      },
      [sweepApi],
    ),

    post_crmOrgFetch: useCallback(
      async ({ orgId, withReset }: { orgId: string; withReset: boolean }) => {
        const addReset = withReset === true ? '?factory-reset=true' : '';
        const response = await sweepApi.post(`/crm-orgs/org/${orgId}/fetch${addReset}`);

        return response.data;
      },
      [sweepApi],
    ),

    get_crmOrgEmailTemplates: useCallback(
      async ({ orgId }: { orgId: string }) => {
        const response = await sweepApi.get(`/crm-orgs/org/${orgId}/emailTemplates`);

        return response.data as CrmOrgEmailTemplatesResponse;
      },
      [sweepApi],
    ),

    get_crmOrgsQueues: useCallback(
      async ({ orgId, objectType }: { orgId: string; objectType: string }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${orgId}/queues?objectType=${objectType}`,
        );

        return response.data as CrmOrgQueueResponse;
      },
      [sweepApi],
    ),

    get_crmOrgsObjectTypes: useCallback(
      async ({
        orgId,
        createableObjectsOnly,
        triggerableObjectsOnly,
        updateableObjectsOnly,
      }: ObjectTypesArguments) => {
        const query = buildURLQuery({
          createableObjectsOnly,
          triggerableObjectsOnly,
          updateableObjectsOnly,
        });
        const response = await sweepApi.get<CrmOrgObjectTypesResponse>(
          `/crm-orgs/org/${orgId}/object-types-2` + (query ? `?${query}` : ''),
        );

        return response.data;
      },
      [sweepApi],
    ),

    get_crmOrgsObjectTypesChildRelationshipNames: useCallback(
      async ({ orgId, objectType }: { orgId: string; objectType: SFDCObjectType }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${orgId}/object-types/${objectType}/child-relationship-names`,
        );

        return response.data as ChildRelationshipObjectResponse;
      },
      [sweepApi],
    ),
    post_crmOrgParse: useCallback(
      async ({
        crmOrgId,
        payload,
      }: {
        crmOrgId: string;
        payload: { objectTypes: ObjectType[] };
      }) => {
        const response = await sweepApi.post(`/crm-orgs/org/${crmOrgId}/parse`, payload);
        return response.data as ParserResponse;
      },
      [sweepApi],
    ),
    patch_crmOrgParse: useCallback(
      async ({
        crmOrgId,
        payload,
        objectName,
      }: {
        crmOrgId: string;
        objectName: string;
        payload: { visible: boolean };
      }) => {
        const response = await sweepApi.patch(
          `/crm-orgs/org/${crmOrgId}/parse/${objectName}`,
          payload,
        );
        return response.data as ParserResponse;
      },
      [sweepApi],
    ),
    post_crmOrgGenerateConfigurationGpt: useCallback(
      async ({
        crmOrgId,
        payload,
      }: {
        crmOrgId: string;
        payload: {
          objectName: string;
          configurationKey: string;
          configurationName: string;
        };
      }) => {
        const response = await sweepApi.post(
          `/crm-orgs/org/${crmOrgId}/generate-configuration-gpt`,
          payload,
        );
        return response.data as OpenAiResponse;
      },
      [sweepApi],
    ),
    get_crmOrgConfigurationDependenciesFromSalto: useCallback(
      async ({
        crmOrgId,
        configurationName,
        configurationKey,
        objectName,
      }: {
        crmOrgId: string;
        configurationName: string;
        configurationKey: string;
        objectName: string;
      }) => {
        const searchParams = buildURLQuery({ configurationKey, objectName, activeOnly: false });
        const queryString = searchParams ? '?' + searchParams : '';
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/configuration/${encodeURIComponent(configurationName)}/dependencies${queryString}`,
        );
        return response.data as ConfigurationDependencies;
      },
      [sweepApi],
    ),
    get_crmOrgConfigurationConsumersFromSalto: useCallback(
      async ({
        crmOrgId,
        configurationName,
        configurationKey,
        objectName,
      }: {
        crmOrgId: string;
        configurationName: string;
        configurationKey: string;
        objectName: string;
      }) => {
        const searchParams = buildURLQuery({ configurationKey, objectName, activeOnly: false });
        const queryString = searchParams ? '?' + searchParams : '';
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/configuration/${encodeURIComponent(configurationName)}/consumers${queryString}`,
        );
        return response.data as ConfigurationConsumers;
      },
      [sweepApi],
    ),
    get_crmOrgFieldConsumerFromSalto: useCallback(
      async ({
        crmOrgId,
        objectName,
        fieldName,
      }: {
        crmOrgId: string;
        objectName: string;
        fieldName: string;
      }) => {
        const searchParams = buildURLQuery({ objectName, fieldName, activeOnly: false });

        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/field-consumers?${searchParams}`,
        );
        return response.data as ConfigurationConsumers;
      },
      [sweepApi],
    ),
    get_crmOrgObjectParsedLayouts: useCallback(
      async ({ crmOrgId, objectName }: { crmOrgId: string; objectName: string }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/parse/${objectName}/layouts`,
        );
        return response.data as LayoutData[];
      },
      [sweepApi],
    ),
    post_aiChatWithOrg: useCallback(
      async (payload: AiChatWithOrgPayload) => {
        const { crmOrgId, ..._payload } = payload;
        const response = await sweepApi.post(`/crm-orgs/org/${crmOrgId}/chat-with-org`, _payload);
        return response.data as AiChatDto;
      },
      [sweepApi],
    ),
    post_aiConfigurationChat: useCallback(
      async (payload: AiChatConfigurationPayload) => {
        const { crmOrgId, ..._payload } = payload;
        const response = await sweepApi.post(`/crm-orgs/org/${crmOrgId}/ai-chat`, _payload);
        return response.data as AiChatDto;
      },
      [sweepApi],
    ),
    post_aiFieldChat: useCallback(
      async (payload: AiChatFieldPayload) => {
        const { crmOrgId, ..._payload } = payload;
        const response = await sweepApi.post(`/crm-orgs/org/${crmOrgId}/ai-chat`, _payload);
        return response.data as AiChatDto;
      },
      [sweepApi],
    ),
    get_crmOrgField: useCallback(
      async ({
        crmOrgId,
        objectApiName,
        fieldName,
      }: {
        crmOrgId: string;
        objectApiName: string;
        fieldName: string;
      }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/field-metadata-with-usage?objectName=${objectApiName}&fieldName=${fieldName}`,
        );
        return response.data as FieldMetadataRecordProperties;
      },
      [sweepApi],
    ),
    get_searchNameAndLabel: useCallback(
      async ({ crmOrgId, searchTxt }: { crmOrgId: string; searchTxt: string }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/search?searchText=${encodeURIComponent(searchTxt)}`,
        );
        return response.data as SearchResponse;
      },
      [sweepApi],
    ),
    get_searchContent: useCallback(
      async ({ crmOrgId, searchTxt }: { crmOrgId: string; searchTxt: string }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/search-content?searchText=${encodeURIComponent(searchTxt)}`,
        );

        const contentSearchResponse = response.data as ContentSearchResponse;
        delete contentSearchResponse?.files; //by product decision files are not supported atm

        const { unclassifiedConfigurations, ...rest } = contentSearchResponse;
        const unclassifiedConfigurationsByType = groupBy(unclassifiedConfigurations, 'type');

        return { ...rest, ...unclassifiedConfigurationsByType } as SearchResponse;
      },
      [sweepApi],
    ),
    get_NaclFile: useCallback(
      async ({ crmOrgId, filename }: { crmOrgId: string; filename: string }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/nacl-file?filename=${filename}`,
        );
        return response.data as string;
      },
      [sweepApi],
    ),
    get_recordTypesAndFunnels: useCallback(
      async ({ crmOrgId }: { crmOrgId: string }) => {
        const response = await sweepApi.get(`/crm-orgs/org/${crmOrgId}/funnels-and-record-types-2`);
        return response.data as ParsedRecordTypesAndFunnels;
      },
      [sweepApi],
    ),
    getObjectFunnelsAndRecordTypes: useCallback(
      async ({ crmOrgId, objectName }: { crmOrgId: string; objectName: string }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/objects/${objectName}/funnels-and-record-types`,
        );
        return response.data as ParsedRecordTypesAndFunnelsObjectData;
      },
      [sweepApi],
    ),
    // This call uses Salto
    get_objectTypeRecordType: useCallback(
      async ({
        crmOrgId,
        recordTypeName,
        objectApiName,
      }: {
        crmOrgId: string;
        recordTypeName: string;
        objectApiName: string;
      }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/object-types/${objectApiName}/record-types/${recordTypeName}`,
        );
        return response.data as RecordTypeData;
      },
      [sweepApi],
    ),
    // This call uses Salesforce
    get_objectRecordType: useCallback(
      async ({
        crmOrgId,
        recordTypeName,
        objectApiName,
      }: {
        crmOrgId: string;
        recordTypeName: string;
        objectApiName: string;
      }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/objects/${objectApiName}/record-types/${recordTypeName}`,
        );
        return response.data as SFDCLeadingField[];
      },
      [sweepApi],
    ),

    get_objectRecordTypes: useCallback(
      async ({ crmOrgId, objectApiName }: { crmOrgId: string; objectApiName: string }) => {
        const response = await sweepApi.get(
          `/crm-orgs/org/${crmOrgId}/objects/${objectApiName}/record-types`,
        );
        return response.data as NameProperties[];
      },
      [sweepApi],
    ),

    post_userTimeOff: useCallback(
      async ({
        crmOrgId,
        timeOff,
        userId,
      }: {
        crmOrgId: string;
        userId: string;
        timeOff: CrmOrgUserTimeOff;
      }) => {
        await sweepApi.post(`/crm-orgs/org/${crmOrgId}/users/${userId}/timeOff`, timeOff);
      },
      [sweepApi],
    ),
    put_userTimeOff: useCallback(
      async ({
        crmOrgId,
        timeOff,
        userId,
      }: {
        crmOrgId: string;
        userId: string;
        timeOff: CrmOrgUserTimeOff;
      }) => {
        await sweepApi.put(
          `/crm-orgs/org/${crmOrgId}/users/${userId}/timeOff/${timeOff.id}`,
          timeOff,
        );
      },
      [sweepApi],
    ),
    delete_userTimeOff: useCallback(
      async ({
        crmOrgId,
        timeOffId,
        userId,
      }: {
        crmOrgId: string;
        userId: string;
        timeOffId: string;
      }) => {
        await sweepApi.delete(`/crm-orgs/org/${crmOrgId}/users/${userId}/timeOff/${timeOffId}`);
      },
      [sweepApi],
    ),
    get_crmOrgSalesforceOAuthUrl: useCallback(
      async ({ crmOrgId }: { crmOrgId: string }) => {
        const response = await sweepApi.get(`/crm-orgs/org/${crmOrgId}/salesforce-oauth`);
        return response.data as { url: string };
      },
      [sweepApi],
    ),
    post_assignPermissionSetGroup: useCallback(
      async ({ crmOrgId, assignToAll }: { crmOrgId: string; assignToAll: boolean }) => {
        await sweepApi.post(
          `/crm-orgs/org/${crmOrgId}/sweep-permission-set-group-assignment?all=${assignToAll}`,
        );
      },
      [sweepApi],
    ),
  };
};
