import { colors } from '@sweep-io/sweep-design';
import { TPF_OBJECT_TYPE } from '../../funnel-map-canvas/calculate-nodes/useCalculateThirdPartyNodes';

const objectTypeToColors: {
  [key: string]: {
    step: string;
    connection: string;
    light: string;
    leadingColorName: string;
  };
} = {
  Lead: {
    step: colors.Lead,
    connection: colors.sun[500],
    light: colors.sun[100],
    leadingColorName: 'sun',
  },
  Opportunity: {
    step: colors.lilac[300],
    connection: colors.lilac[500],
    light: colors.lilac[100],
    leadingColorName: 'lilac',
  },
  Contact: {
    step: colors.sky[300],
    connection: colors.sky[500],
    light: colors.sky[100],
    leadingColorName: 'sky',
  },
  Account: {
    step: colors.seafoam[300],
    connection: colors.seafoam[500],
    light: colors.seafoam[100],
    leadingColorName: 'seafoam',
  },
  Case: {
    step: colors.gum[300],
    connection: colors.gum[500],
    light: colors.gum[100],
    leadingColorName: 'gum',
  },
  __hubspot__: {
    step: '#FDD8D2',
    connection: '#FF7A59',
    light: '#FDD8D2',
    leadingColorName: 'gum',
  },
  [TPF_OBJECT_TYPE]: {
    step: colors.storm[200],
    connection: colors.storm[300],
    light: colors.storm[70],
    leadingColorName: 'storm',
  },
  __default: {
    step: colors.blue[100],
    connection: colors.blue[500],
    light: colors.blue[100],
    leadingColorName: 'blue',
  },
};

export const objectTypeColorNames = Object.keys(objectTypeToColors);

export const getObjectTypeColor = (objectType?: string) =>
  (objectType && objectTypeToColors[objectType]) || objectTypeToColors.__default;
