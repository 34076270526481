import { Box, Collapse, Divider } from '@mui/material';
import { SFDCObjectType } from '../../../types/enums/SFDCObjectType';
import { settingsPanelLayout } from './helpers';
import { useState } from 'react';
import { Integrations } from '@sweep-io/sweep-design/dist/icons/Integrations';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import { WebhookActionSelector } from '../../Automations/WebhookActionSelector';
import { WebhookActionWrapper } from '../../Automations/WebhookActionWrapper';
import { uniqueId } from '../../../lib/uniqueId';
import { AutomationActionType } from '../../../types/enums/AutomationActionType';
import { labelBoxDesign } from '../../Automations/AutomationsForm';
import { ActionsMenu } from '../../common/actions-menu/ActionsMenu';
import { CustomHeader } from '../../common/rule-builder/CustomHeader';
import { SweepFieldsRuleBuilder } from '../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { useRefForMultipleRuleBuilders } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';

interface ExternalIntegrationWrapperProps {
  crmOrgId?: string;
  readonly?: boolean;
  conditionalActions: AutomationConditionalActions[] | undefined;
  onChange: (automationAction: AutomationConditionalActions[]) => any;
  objectName: SFDCObjectType;
  referenceObjectType: string;
}

const options = [
  {
    label: 'Clear',
    value: 'clear',
  },
];

export const ExternalIntegrationWrapper = ({
  crmOrgId,
  readonly,
  conditionalActions,
  onChange,
  objectName,
  referenceObjectType,
}: ExternalIntegrationWrapperProps) => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(!!conditionalActions?.length);
  const ruleBuilderRef = useRefForMultipleRuleBuilders();

  const getNewConditionalAction = () => {
    return {
      _id: uniqueId(),
      _name: 'Webhook',
      actions: [getNewAction()],
    };
  };

  const getNewAction = () => {
    return {
      _id: uniqueId(),
      actionType: AutomationActionType.Webhook,
      actionParams: {} as any,
    } as WebhookAutomationAction;
  };

  const onClearAction = (idx: number) => {
    const allActions = [...(conditionalActions || [])];
    allActions?.splice(idx, 1);
    onChange(allActions);
  };

  const addConditions = (index: number) => {
    const newActions = [...(conditionalActions || [])];
    newActions[index] = { ...newActions[index], when: { isCriteria: true } };
    onChange(newActions);
  };
  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Integrations variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">Execute external integrations</Typography>
              <Typography variant="body">
                Launch follow up actions in your 3rd party applications when the deduplication has
                ended
              </Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          {conditionalActions?.length === 0 && (
            <>
              <Box sx={{ mb: 2 }}>
                <Button
                  onClick={() => {
                    const newAction = getNewConditionalAction();
                    onChange([{ ...newAction, when: { isCriteria: true } }]);
                  }}
                  size="large"
                  startIconName="Plus"
                  variant="flat"
                >
                  Add conditions
                </Button>
              </Box>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography variant="body">Choose integration:</Typography>
                <WebhookActionSelector
                  readonly={readonly}
                  action={getNewAction()}
                  onChange={(action: WebhookAutomationAction) => {
                    const newAction = getNewConditionalAction();
                    onChange([{ ...newAction, actions: [action] }]);
                  }}
                />
              </Box>
              <Divider sx={{ pt: 3 }} />
            </>
          )}
          {conditionalActions?.map((_conditionalAction, idx) => (
            <Box key={_conditionalAction?._id + idx}>
              {idx !== 0 && (
                <Box
                  sx={{
                    ...labelBoxDesign,
                    background: colors.gum[500],
                    mt: 2,
                  }}
                >
                  <Typography variant="caption-bold" textAlign="center" color={colors.white}>
                    AND THEN
                  </Typography>
                </Box>
              )}
              {!_conditionalAction?.when?.isCriteria && (
                <Box sx={{ mb: 2, mt: 2 }}>
                  <Button
                    onClick={() => addConditions(idx)}
                    size="large"
                    startIconName="Plus"
                    variant="flat"
                  >
                    Add conditions
                  </Button>
                </Box>
              )}

              {_conditionalAction?.when?.isCriteria && (
                <Box
                  sx={{
                    backgroundColor: colors.grey[100],
                    borderRadius: '6px',
                    marginBottom: 2,
                    pt: '12px',
                  }}
                >
                  <>
                    <CustomHeader
                      customHeader={'Execute integration if the following conditions are met :'}
                    />
                    <SweepFieldsRuleBuilder
                      ref={ruleBuilderRef}
                      readOnly={readonly}
                      crmOrgId={crmOrgId ?? ''}
                      objectType={objectName}
                      referenceObjectType={referenceObjectType}
                      sweepCondition={_conditionalAction?.when?.criteria}
                      onChange={(newSweepCondition) => {
                        const newActions = [...(conditionalActions || [])];
                        newActions[idx] = {
                          ...newActions[idx],
                          when: { isCriteria: true, criteria: newSweepCondition },
                        };
                        onChange(newActions);
                      }}
                    />
                  </>
                </Box>
              )}
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography variant="body">Choose integration:</Typography>

                <WebhookActionSelector
                  readonly={readonly}
                  action={_conditionalAction?.actions?.[0] as WebhookAutomationAction}
                  onChange={(action: WebhookAutomationAction) => {
                    const newActions = [...(conditionalActions || [])];
                    if (action) {
                      newActions[idx] = { ...conditionalActions[idx], actions: [action] };
                      onChange(newActions);
                    }
                  }}
                />

                <Box>
                  <ActionsMenu
                    iconSize={'tiny'}
                    actions={options}
                    onClick={(action) => {
                      switch (action.value) {
                        case 'clear':
                          onClearAction(idx);
                          break;
                      }
                    }}
                  />
                </Box>
              </Box>
              {(_conditionalAction?.actions?.[0] as WebhookAutomationAction)?.actionParams
                ?.integrationId && (
                <WebhookActionWrapper
                  readonly={readonly}
                  action={_conditionalAction?.actions[0] as WebhookAutomationAction}
                  crmOrgId={crmOrgId ?? ''}
                  onChange={(action: WebhookAutomationAction) => {
                    const newActions = [...(conditionalActions || [])];
                    if (action) {
                      newActions[idx] = { ...conditionalActions[idx], actions: [action] };
                      onChange(newActions);
                    }
                  }}
                  objectName={objectName}
                />
              )}
              <Divider sx={{ pt: 3 }} />
            </Box>
          ))}
        </Box>
        <Box sx={{ padding: '18px 0 2px 52px' }}>
          <Button
            onClick={() => {
              const allActions = [...(conditionalActions || [])];
              const newAction = getNewConditionalAction();
              onChange([...allActions, newAction]);
            }}
            startIconName="Integrations"
            endIconName="Plus"
            variant="flat"
          >
            Add integration
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};
