import { Box, DialogContent } from '@mui/material';
import { useContext, useState } from 'react';
import { DataTable } from '../../../../common/table/DataTable';
import { Button, Checkbox, colors, Typography } from '@sweep-io/sweep-design';
import { DataTableDraggableColumn } from '../../../../common/table/TableTypes';
import { NewMemberStatusDialog } from './NewMemberStatusDialog';
import { Loader } from '../../../../common/Loader';
import { CampaignMemberStatusContext } from '../../campaign-member-status/CampaignMemberStatusContext';
import { useEffectFetchCampaignMemberStatuses } from '../../campaign-member-status/useEffectFetchCampaignMemberStatuses';
import { useCampaignMemberStatuses } from '../../campaign-member-status/useCampaignMemberStatuses';
import usePermission from '../../../../common/permissions/usePermission';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';

const CREATE_MEMBER_STATUS_PERMISSION = 'create:campaigns';

export const LoadedStatusesTab = ({ campaignId }: { campaignId: string }) => {
  const { campaignMemberStatuses } = useContext(CampaignMemberStatusContext);
  const [isCreateMemberStatusAllowed] = usePermission([CREATE_MEMBER_STATUS_PERMISSION]); // TODO: Fix with the correct permission

  const [newMemberDialogOpen, setNewMemberDialogOpen] = useState(false);
  const { updateCampaignMemberStatus } = useCampaignMemberStatuses();

  const checkboxRenderer = (value: boolean, id: string, fieldName: string) => (
    <Checkbox
      checked={value}
      disabled={!isCreateMemberStatusAllowed}
      onChange={async (e) => {
        const exitingStatus = campaignMemberStatuses.find((status) => status.Id === id);
        if (!exitingStatus) {
          return;
        }
        if (fieldName === 'IsDefault' && exitingStatus.IsDefault) {
          return; // Can't uncheck default
        }
        const newStatus = { ...exitingStatus, [fieldName]: e.target.checked };
        await updateCampaignMemberStatus(newStatus);
      }}
    />
  );

  const columns: DataTableDraggableColumn[] = [
    { headerName: 'Value', field: 'Label' },
    {
      headerName: 'Default',
      field: 'IsDefault',
      renderer: checkboxRenderer,
    },
    {
      headerName: 'Responded',
      field: 'HasResponded',
      renderer: checkboxRenderer,
    },
  ];

  const rows = campaignMemberStatuses.map((status) => ({
    id: status.Id,
    ...status,
  }));

  const maybeRenderNewMemberStatusDialog = () => {
    if (!newMemberDialogOpen) {
      return null;
    }
    return (
      <NewMemberStatusDialog
        onClose={() => setNewMemberDialogOpen(false)}
        campaignId={campaignId}
      />
    );
  };

  return (
    <>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="body" color={colors.grey[700]}>
            {campaignMemberStatuses.length} member statuses
          </Typography>
          <RestrictedTooltip
            to={[CREATE_MEMBER_STATUS_PERMISSION]}
            notAllowedTitle={'To create campaign member status please contact your admin'}
          >
            <Button
              size="small"
              onClick={() => setNewMemberDialogOpen(true)}
              disabled={!isCreateMemberStatusAllowed}
            >
              New Status
            </Button>
          </RestrictedTooltip>
        </Box>
        <DataTable columns={columns} rows={rows} stickyHeader />
      </DialogContent>
      {maybeRenderNewMemberStatusDialog()}
    </>
  );
};

export const StatusesTab = ({ campaignId }: { campaignId: string }) => {
  const { loadStatus } = useEffectFetchCampaignMemberStatuses(campaignId);

  if (loadStatus !== 'success') {
    return (
      <DialogContent>
        <Loader />
      </DialogContent>
    );
  }

  return <LoadedStatusesTab campaignId={campaignId} />;
};
