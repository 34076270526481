import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { AiChatMessages } from '../common/ai-chat/AiChatMessages';
import { Stack, Box } from '@mui/material';
import { useCallback, useRef, ReactNode } from 'react';

const AiAgentScrollArea = ({
  messages,
  errorMsg,
  isLoadingResponse,
  maxWidth,
  searchTermToHighlight,
  addToChatBeginning,
  assistantAvatar,
  introMessage,
}: {
  messages: AiChatMessage[];
  errorMsg?: string; //this property should be deprecated
  isLoadingResponse: boolean;
  maxWidth?: string;
  searchTermToHighlight?: string;
  addToChatBeginning?: ReactNode;
  assistantAvatar: ReactNode;
  introMessage?: AiChatMessage;
}) => {
  const ref = useRef<HTMLDivElement>();
  const scrollToBottom = useCallback(() => {
    ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        flex: 1,
        width: '100%',
        alignItems: 'center',
      }}
    >
      {addToChatBeginning}
      <Stack padding={1.5} gap={2} paddingBottom={4} width="100%" maxWidth={maxWidth}>
        <Stack gap={2}>
          <AiChatMessages
            messages={messages}
            errorMsg={errorMsg}
            isLoading={isLoadingResponse}
            scrollToBottom={scrollToBottom}
            introMessage={introMessage}
            searchTermToHighlight={searchTermToHighlight}
            assistantAvatar={assistantAvatar}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default AiAgentScrollArea;
