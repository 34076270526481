import { Box, SxProps } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { useCallback, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import {
  NestedFieldsField,
  NestedFieldsSelector,
} from '../common/fieldsSelectors/NestedFieldsSelector';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import { useSweepFieldsLabels } from '../../sweep-fields/useSweepFieldsLabels';
import React from 'react';

interface InputWithVariablesProps {
  readonly?: boolean;
  data: SlackMessageStruct;
  placeholder?: string;
  onChange: (messageStruct: SlackMessageStruct) => any;
  withBorder?: boolean;
  crmOrgId: string;
  objectName: string;
  customOnKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  isBigInput?: boolean;
  isShowFieldSelectorAlways?: boolean;
  variableButtonSX?: SxProps;
}

const convertStringInputToValidHtml = (outerContent: string) => {
  if (!outerContent) return '';

  const getResults = (content: string) => {
    let tempString = '<p>';

    const splitArray = content.split(/({{{\d}}})/g).filter((x) => x.trim().length !== 0);

    splitArray.forEach((innerNode: string) => {
      if (innerNode.startsWith('{{{')) {
        const tagNum = innerNode.match(/\d+/)?.[0];
        const elem = `<span class="sfField" chipId=${tagNum}></span>`;
        tempString += elem;
      } else {
        tempString += innerNode;
      }
    });
    return (tempString += ' </p>');
  };

  let multilineStr = '';
  const firstSplit = outerContent.split(/\n/g);
  firstSplit.forEach((el) => {
    const splitResults = getResults(el);
    return (multilineStr += splitResults);
  });

  return multilineStr;
};

const convertHtmlToValidString = (content: string) => {
  const parser = new DOMParser();
  const document = parser.parseFromString(content, 'text/html');

  let constructedString = '';

  const loopThroughHtmlBuildString = (node: ChildNode, index: number) => {
    if (node.nodeName === '#text') {
      const data = node.nodeValue ?? '';
      return data;
    }

    if (node.nodeName === 'SPAN' && (node as Element).className === 'sfField') {
      return `{{{${(node as Element).getAttribute('chipId')}}}}`;
    }
    if (node.nodeName === 'P' && index !== 0) {
      return '\n';
    }
    return '';
  };

  const getData = (el: ChildNode, index: number) => {
    constructedString += loopThroughHtmlBuildString(el, index);
    if (el.hasChildNodes() && (el as Element).className !== 'sfField') {
      el.childNodes.forEach((innerEl) => {
        getData(innerEl, index);
      });
    }
  };
  document.body.childNodes.forEach((el, index) => {
    getData(el, index);
  });
  return constructedString;
};

const Embed = ReactQuill.Quill.import('blots/embed');
const Delta = ReactQuill.Quill.import('delta');

const modules = {
  toolbar: false,
  clipboard: {
    matchers: [
      [
        Node.ELEMENT_NODE,
        function (node: any, delta: { compose: (arg0: any) => any; length: () => any }) {
          return delta.compose(
            new Delta().retain(delta.length(), {
              color: false,
              background: false,
              bold: false,
              strike: false,
              underline: false,
            }),
          );
        },
      ],
    ],
  },
};

export const InputWithVariables = React.memo(
  ({
    readonly,
    data,
    placeholder,
    onChange,
    withBorder,
    crmOrgId,
    objectName,
    customOnKeyDown,
    isBigInput,
    isShowFieldSelectorAlways,
    variableButtonSX,
  }: InputWithVariablesProps) => {
    const [actionMessage, setActionMessage] = useState<SlackMessageStruct>(data);
    const [value, setValue] = useState(convertStringInputToValidHtml(data?.template));
    const [blockApiChangeEvents, setBlockApiChangeEvents] = useState(true);
    const quillRef = useRef() as React.MutableRefObject<ReactQuill>;
    const { getLabelFromIdsWithObjectType } = useSweepFieldsLabels();

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        customOnKeyDown && customOnKeyDown(event);
      },
      [customOnKeyDown],
    );

    const handleChange = useCallback(
      (content: string, delta: any, source: string) => {
        // block first api change when value is initialized
        if (source === 'api' && blockApiChangeEvents) {
          setBlockApiChangeEvents(false);
        } else {
          if (quillRef.current) {
            setValue(content);
          }
        }
      },
      [blockApiChangeEvents],
    );
    class FieldTagBlot extends Embed {
      static create(createData: any) {
        const node = super.create();
        node.setAttribute('chipId', createData.fieldNum);
        const innerDiv = document.createElement('span');
        innerDiv.setAttribute('contenteditable', 'false');
        innerDiv.classList.add('disablePointerEvents');
        const taglineCss = `
        text-transform: capitalize;
        border-radius: 4px;
        background: ${colors.blue[100]};
        padding: 0 4px;
        font-size: 12px;
        color: ${colors.night[900]};
        display: inline-block;
      `;
        innerDiv.setAttribute('style', taglineCss);

        let textLabel: string = createData.fieldTitle;
        if (!textLabel) {
          const fieldStruct = actionMessage?.variables[createData.fieldNum];
          if (fieldStruct) {
            textLabel = getLabelFromIdsWithObjectType(fieldStruct.fieldIds).join('.');
          } else {
            textLabel = '';
          }
        }

        const exitNode = document.createElement('span');
        exitNode.setAttribute('contenteditable', 'false');
        exitNode.classList.add('tagDeleteClass');
        const deleteTagCss = `
      font-size: 10px;
      display: inline-block;
      padding: 0 6px;
      cursor: pointer;
      position: relative;
      `;
        exitNode.setAttribute('style', deleteTagCss);
        exitNode.innerText = 'X';

        exitNode.addEventListener('click', function () {
          const quillEditor = quillRef.current.getEditor();
          const range = quillEditor.getSelection(true);
          quillEditor.removeFormat(range.index, 1, 'user');
        });

        innerDiv.innerText = textLabel;
        innerDiv.append(exitNode);
        node.appendChild(innerDiv);
        return node;
      }

      static formats(node: any) {
        const _num = node.getAttribute('chipId');
        return {
          fieldNum: _num,
        };
      }

      static value(node: any) {
        const _num = node.getAttribute('chipId');
        return {
          fieldNum: _num,
        };
      }
    }

    FieldTagBlot.blotName = 'sfField';
    FieldTagBlot.tagName = 'span';
    FieldTagBlot.className = 'sfField';
    ReactQuill.Quill.register(FieldTagBlot, true);

    const handleBlur = useCallback(
      (content: string) => {
        if (quillRef.current) {
          // Extract the fields used and update the indexes:
          const currentFields = [...(actionMessage?.variables || [])];
          const newFields: SlackFieldsStruct[] = [];
          const validContent = convertHtmlToValidString(content);
          const usedIndexFields = validContent.split(/({{{\d}}})/g);
          const alignIndexesString = usedIndexFields.map((str) => {
            if (str.startsWith('{{{')) {
              const stringNum = str.match(/\d+/)?.[0];
              if (stringNum) {
                const newIndex = newFields.length;
                newFields[newIndex] = currentFields[parseInt(stringNum)];
                return `{{{${newIndex}}}}`;
              }
            } else {
              return str;
            }
          });
          const joinedContent = alignIndexesString.join('');

          const newMessage = {
            ...actionMessage,
            template: joinedContent || '',
            variables: newFields,
          };
          onChange(newMessage);
        }
      },
      [actionMessage, onChange],
    );

    const onOuterBlur = useCallback(() => {
      const content = quillRef.current?.unprivilegedEditor?.getHTML();
      handleBlur(content ?? '');
    }, [handleBlur]);

    return (
      <Box
        sx={{
          background: '#FFFFFF',
          fontWeight: '400',
          flexGrow: 1,
          minWidth: 0,
          position: 'relative',
          '&:hover .variableButton': {
            visibility: 'visible',
          },
        }}
      >
        <Box
          sx={{
            background: '#FFFFFF',
            fontWeight: '400',
            '& .ql-container': {
              fontFamily: 'Inter',
              fontSize: '14px',
              '& .ql-editor': {
                padding: '9px 15px',
                borderRadius: 0,
                pr: isShowFieldSelectorAlways ? 0 : '170px',
                pb: isShowFieldSelectorAlways ? '34px' : 0,
                border: withBorder ? `1px solid ${colors.grey[300]}` : undefined,
                whiteSpace: 'nowrap',
                height: isBigInput ? '160px' : '40px',
              },
              '& .ql-editor:focus': {
                border: withBorder ? `1px solid ${colors.blue[500]}` : undefined,
              },
              '& .ql-editor ul>li:before': {
                content: '"\\2022"',
              },
            },
            '& .ql-editor.ql-blank::before': {
              fontStyle: 'normal',
              fontSize: '14px',
            },
          }}
          onBlur={onOuterBlur}
        >
          <ReactQuill
            readOnly={readonly}
            placeholder={placeholder ?? 'Value'}
            theme="bubble"
            value={value || ''}
            onChange={handleChange}
            modules={modules}
            ref={quillRef}
            onKeyDown={onKeyDown}
          />
        </Box>
        <Box
          className={'variableButton'}
          sx={{
            position: 'absolute',
            right: '1px',
            lineHeight: '34px',
            borderRadius: '4px',
            top: '1px',
            visibility: isShowFieldSelectorAlways ? 'visible' : 'hidden',
            height: '36px',
            background: colors.white,
            ...variableButtonSX,
          }}
        >
          <NestedFieldsSelector
            readonly={readonly}
            nestedPath={[]}
            customButtonStartIcon={<Plus color={colors.blue[500]} />}
            crmOrgId={crmOrgId}
            objectType={objectName}
            useCustomButton
            customButtonText="Add dynamic variable"
            customButtonSx={{
              height: '16px',
              fontSize: '12px',
              background: 'transparent',
              color: colors.blue[500],
              border: 'none',
              textTransform: 'unset',
              pr: '12px',
              '& span': {
                fontWeight: 500,
                fontSize: '12px',
              },
              '&:hover': {
                background: colors.white,
                color: colors.blue[600],
              },
            }}
            onChange={({ fieldIds, fieldContext, fieldLabels }: NestedFieldsField) => {
              const newVar = [...(actionMessage?.variables || [])];
              const _fieldStruct: SlackFieldsStruct = {
                fieldIds: fieldIds,
              };
              if (fieldContext) {
                _fieldStruct.fieldContext = fieldContext;
              }
              newVar.push(_fieldStruct);

              const newMessage = {
                ...(actionMessage || {}),
                variables: newVar,
              };
              setActionMessage(newMessage);
              const fieldText = fieldLabels.join('.');

              setTimeout(() => {
                const quillEditor = quillRef.current.getEditor();
                const range = quillEditor.getSelection(true);
                quillEditor.insertEmbed(
                  range.index,
                  'sfField',
                  {
                    fieldNum: newVar.length - 1,
                    fieldTitle: fieldText,
                  },
                  'silent',
                );
                quillEditor.setSelection(range.index + 1, 0, 'user');
              }, 200);
            }}
          />
        </Box>
      </Box>
    );
  },
);
