import { Box, Stack } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

const ChatsSectionLayout = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 16px',
          height: '32px',
        }}
      >
        <Typography variant="body-bold">{title}</Typography>
      </Box>
      {children}
    </Stack>
  );
};

export default ChatsSectionLayout;
