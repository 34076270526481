import { ReactNode } from 'react';
import ChatWithAgentCollapsed from './ChatWithAgentCollapsed';
import AgentsCardsCollapsed from './AgentsCardsCollapsed';
import useAiAgentChats from './useAiAgentChats';
import useAiAgents from './useAiAgents';

const AiAgentCenterCollapsed = ({
  instructionsButton,
  toggleExpandButton,
  closeButton,
  onNewAgentClick,
}: {
  instructionsButton: ReactNode;
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
  onNewAgentClick: () => void;
}) => {
  const { setActiveChatId, createChat } = useAiAgentChats();
  const { activeAgent } = useAiAgents();

  return (
    <>
      {activeAgent && (
        <ChatWithAgentCollapsed
          toggleExpandButton={toggleExpandButton}
          closeButton={closeButton}
          instructionsButton={instructionsButton}
          returnToAgentsList={() => setActiveChatId(undefined)}
        />
      )}
      {!activeAgent && (
        <AgentsCardsCollapsed
          toggleExpandButton={toggleExpandButton}
          closeButton={closeButton}
          onSelect={(agentId) => createChat(agentId)}
          onNewAgentClick={onNewAgentClick}
        />
      )}
    </>
  );
};

export default AiAgentCenterCollapsed;
