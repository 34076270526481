import { Stack } from '@mui/material';
import AiAgentChatsHistory from './AiAgentChatsHistory';
import AiAgentSearch from './AiAgentSearch';
import AiAgentsList from './AiAgentsList';
import { colors } from '@sweep-io/sweep-design';

const AiAgentCenterSideMenu = () => {
  return (
    <Stack
      height="100%"
      sx={{
        backgroundColor: 'rgba(248, 249, 250, 0.40)',
        padding: '24px 0 16px',
        borderRight: `1px solid ${colors.grey[200]}`,
        gap: '24px',
        overflow: 'hidden',
      }}
    >
      <AiAgentSearch />
      <Stack
        sx={{
          overflow: 'auto',
        }}
        gap={3}
      >
        <AiAgentsList />
        <AiAgentChatsHistory />
      </Stack>
    </Stack>
  );
};

export default AiAgentCenterSideMenu;
