import { IconButton } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons';

import { CreateEditDialogDeployButtons } from './CreateEditDialogDeployButtons';
import {
  CreateEditDialogHeaderBase,
  CreateEditDialogHeaderBaseProps,
} from './CreateEditDialogHeaderBase';
import { Box } from '@mui/material';

export interface AutomationsCreateEditDialogHeaderProps extends CreateEditDialogHeaderBaseProps {
  onCancelClick?: () => any;
  onDeployOrSave: (
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  buttonDisabled?: boolean;
  showCancelButton?: boolean;
  rightSidePermissionContent?: React.ReactNode;
}

export const AutomationsCreateEditDialogHeader = ({
  onDeployOrSave,
  buttonDisabled,
  showCancelButton,
  onCancelClick,
  rightSideContent,
  rightSidePermissionContent,
  ...restProps
}: AutomationsCreateEditDialogHeaderProps) => {
  return (
    <CreateEditDialogHeaderBase
      {...restProps}
      rightSideContent={
        <>
          {rightSideContent}
          <CreateEditDialogDeployButtons
            onDeployOrSave={onDeployOrSave}
            confirmButtonDisabled={buttonDisabled}
          />
          {rightSidePermissionContent}
          {showCancelButton && (
            <Box sx={{ ml: -1 }}>
              <IconButton variant="flat" onClick={onCancelClick} data-dd-action-name="close">
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </>
      }
    />
  );
};
