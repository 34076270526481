import { Edit, Delete, Pin, Unpin } from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import ConfirmDestructiveDialog from '../common/dialogs/ConfirmDestructiveDialog';
import { ActionsMenuItem } from '../common/actions-menu/ActionsMenuItem';

const ChatMenuActions = ({
  closeMenu,
  onRename,
  onDelete,
  togglePin,
  isPinned,
}: {
  onRename: () => void;
  onDelete: () => void;
  closeMenu: () => void;
  togglePin: () => void;
  isPinned: boolean;
}) => {
  const { openConfirm, onCancel, onConfirm } = useConfirm();

  const onDeleteClick = async () => {
    const isConfirmed = await openConfirm(
      <ConfirmDestructiveDialog
        open
        onConfirm={onConfirm}
        onClose={onCancel}
        title="Delete chat?"
        confirmText="Delete"
        cancelText="Cancel"
      >
        You'll no longer see this chat here
      </ConfirmDestructiveDialog>,
    );
    if (isConfirmed) {
      onDelete();
    }
  };
  return (
    <>
      <ActionsMenuItem
        action={{
          label: isPinned ? 'Unpin' : 'Pin',
          value: isPinned ? 'Unpin' : 'Pin',
          actionIcon: isPinned ? <Unpin /> : <Pin />,
        }}
        handleClose={closeMenu}
        onClick={togglePin}
        key="Pin"
      />
      <ActionsMenuItem
        action={{
          label: 'Rename',
          value: 'Rename',
          actionIcon: <Edit />,
        }}
        handleClose={closeMenu}
        onClick={onRename}
        key="Rename"
      />
      <ActionsMenuItem
        action={{
          label: 'Delete',
          value: 'Delete',
          actionIcon: <Delete color={colors.blush[600]} />,
          actionSx: { color: colors.blush[600] },
        }}
        handleClose={closeMenu}
        onClick={onDeleteClick}
        key="Delete"
      />
    </>
  );
};
export default ChatMenuActions;
