import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaigns } from '../../../../reducers/hubspotReducer';
import {
  SweepCanvasFiltersMap,
  CanvasFilterTypes,
} from '../../configuration-canvas-filters/filterTypes';
import { ConfigurationCanvasFilters } from '../../configuration-canvas/ConfigurationCanvasFilters';
import { HubspotCampaignCtx } from './campaign-dialog/HubspotCampaignCtx';
import { HubspotFilterTypes } from './HubspotCanvasPanel';

export const HubspotFilters = ({ withPanelWrapper }: { withPanelWrapper?: boolean }) => {
  const campaigns = useSelector(selectCampaigns);

  const { campaignStatusOptions, campaignTypeOptions } = useContext(HubspotCampaignCtx);

  const filters: SweepCanvasFiltersMap = useMemo(() => {
    const allStatuses = (campaigns || []).map((campaign) => campaign.Status).sort();

    const usedStatuses = Array.from(new Set(allStatuses));
    const statusItems = usedStatuses.map((status) => ({
      label:
        campaignStatusOptions.find((option) => option.name === status)?.label ||
        status ||
        '(no status)',
      value: status || '',
    }));

    const usedTypes = Array.from(
      new Set((campaigns || []).map((campaign) => campaign.Type)),
    ).sort();
    const typeItems = usedTypes.map((type) => ({
      label:
        campaignTypeOptions.find((option) => option.name === type)?.label || type || '(no type)',
      value: type || '',
    }));

    return {
      [HubspotFilterTypes.ACTIVITY]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 0,
        items: [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
        label: 'Campaign Activity',
      },
      // [HubspotFilterTypes.WORKFLOWS]: {
      //   type: CanvasFilterTypes.MULTI_GENERIC,
      //   position: 1,
      //   items: (workflows || []).map((workflow) => ({
      //     label: workflow.name,
      //     value: workflow.id,
      //   })),
      //   label: 'Enrollment Rules',
      // },
      [HubspotFilterTypes.CAMPAIGN_STATUS]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 2,
        items: statusItems,
        label: 'Campaign Status',
      },
      [HubspotFilterTypes.CAMPAIGN_TYPE]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 2,
        items: typeItems,
        label: 'Campaign Type',
      },
    };
  }, [campaignStatusOptions, campaignTypeOptions, campaigns]);

  return <ConfigurationCanvasFilters withPanelWrapper={withPanelWrapper} filters={filters} />;
};
