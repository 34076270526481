import * as React from "react";
const SvgAgentDocumentationAvatar = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 12, fill: "#FFF0D9", style: {
  fill: "#FFF0D9",
  fill: "color(display-p3 1.0000 0.9412 0.8510)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_1394_13196)" }, /* @__PURE__ */ React.createElement("path", { d: "M7.10396 9.63135V11.8108H19.4543V9.63135H7.10396Z", fill: "#FF9900", style: {
  fill: "#FF9900",
  fill: "color(display-p3 1.0000 0.6000 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.3689 4.5459H12.1894V16.8963H14.3689V4.5459Z", fill: "#FF9900", style: {
  fill: "#FF9900",
  fill: "color(display-p3 1.0000 0.6000 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.2793 12.2626L11.7382 10.7214L4.54688 17.9127L6.08799 19.4539L13.2793 12.2626Z", fill: "#2C1A4F", style: {
  fill: "#2C1A4F",
  fill: "color(display-p3 0.1730 0.1028 0.3083)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.68372 5.58405L8.14261 7.12517L16.8756 15.8582L18.4168 14.3171L9.68372 5.58405Z", fill: "#FF9900", style: {
  fill: "#FF9900",
  fill: "color(display-p3 1.0000 0.6000 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.8203 10.7212L13.2791 9.18013L11.7382 10.7214L13.2793 12.2626L14.8203 10.7212Z", fill: "#F8F9FA", style: {
  fill: "#F8F9FA",
  fill: "color(display-p3 0.9725 0.9765 0.9804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M18.4162 7.12497L16.8751 5.58385L13.2791 9.18013L14.8203 10.7212L18.4162 7.12497Z", fill: "#FF9900", style: {
  fill: "#FF9900",
  fill: "color(display-p3 1.0000 0.6000 0.0000)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_1394_13196" }, /* @__PURE__ */ React.createElement("rect", { width: 16, height: 16, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
}, transform: "translate(4 4)" }))));
export default SvgAgentDocumentationAvatar;
