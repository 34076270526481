import { Button } from '@sweep-io/sweep-design';
import { KeyValueTableRow } from './KeyValueTableRow';
import { Box } from '@mui/material';

interface KeyValueTableProps {
  readonly?: boolean;
  params: KeyValueStringTemplate[];
  onChange: (params: KeyValueStringTemplate[]) => any;
  crmOrgId: string;
  objectName: string;
  keyString: string;
}
export const KeyValueTable = ({
  readonly,
  params,
  onChange,
  crmOrgId,
  objectName,
  keyString,
}: KeyValueTableProps) => {
  const isHideAddNew =
    params?.length === 0 ||
    (params?.length === 1 && params?.[0]?.key === '' && params?.[0]?.value?.template === '');
  const emptyObject = {
    key: '',
    value: { template: '', variables: [] },
  };
  const tempParams = params?.length === 0 ? [emptyObject] : params;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          width: '100%',
          '& .key-value-row:first-of-type fieldset': {
            borderTopLeftRadius: '4px',
          },
          '& .key-value-row:last-of-type fieldset': {
            borderBottomWidth: '1px',
            borderBottomLeftRadius: '4px',
          },
          '& .key-value-row:first-of-type .quill .ql-editor': {
            borderTopRightRadius: '4px',
          },
          '& .key-value-row:last-of-type .quill .ql-editor': {
            borderBottomWidth: '1px',
            borderBottomRightRadius: '4px',
          },
        }}
      >
        {tempParams?.map((set, idx) => (
          <KeyValueTableRow
            readonly={readonly}
            objectName={objectName}
            crmOrgId={crmOrgId}
            key={keyString + idx}
            keyValueSet={set}
            onChange={(field) => {
              const newFields = [...(params || [])];
              if (newFields) {
                newFields[idx] = field;
                onChange(newFields);
              }
            }}
            onDelete={() => {
              const tempFields = [...(params || [])];
              tempFields.splice(idx, 1);
              onChange(tempFields);
            }}
          />
        ))}
      </Box>
      <Box>
        <Button
          disabled={isHideAddNew}
          onClick={() => {
            let tempFields = params || [];
            tempFields = [...tempFields, emptyObject];
            onChange(tempFields);
          }}
          startIconName="Plus"
          variant="flat"
        >
          Add row
        </Button>
      </Box>
    </>
  );
};
