export enum AutomationTypes {
  StageEnter = 'STAGE_ENTER',
  StageExit = 'STAGE_EXIT',
  FieldUpdate = 'FIELD_UPDATE',
  DateArrive = 'DATE_ARRIVE',
  TimeInStep = 'TIME_IN_STEP',
  OnCreate = 'ON_CREATE',
  OnUpdate = 'ON_UPDATE',
  OnUpsert = 'ON_UPSERT',
  Scheduled = 'SCHEDULED',
}

export enum ScheduledParametersOffsetType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum AutomationDataActionType {
  REPORT_RESULTS = 'REPORT_RESULTS',
  QUERY_RESULTS = 'QUERY_RESULTS',
}

export enum AutomationPermissionMode {
  UserMode = 'USER_MODE',
  SystemMode = 'SYSTEM_MODE',
}

export enum HttpVerb {
  Post = 'POST',
  Get = 'GET',
  Patch = 'PATCH',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum WebhookBodyType {
  JSON = 'JSON',
  URL_ENCODED = 'URL_ENCODED',
}
