import { MenuItem, Select } from '@mui/material';
import { useEffectFetchCampaignMemberStatuses } from '../../campaign-member-status/useEffectFetchCampaignMemberStatuses';

export const WorkflowStatusField = ({
  value,
  onChange,
  isDisabled,
  campaignId,
}: {
  value?: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  campaignId: string;
}) => {
  const { campaignMemberStatuses } = useEffectFetchCampaignMemberStatuses(campaignId);

  const renderMenuItems = () => {
    if (value && !campaignMemberStatuses) {
      return <MenuItem value={value}>{value}</MenuItem>;
    }
    if (campaignMemberStatuses) {
      return campaignMemberStatuses.map((status) => (
        <MenuItem key={status.CampaignId} value={status.Label}>
          {status.Label}
        </MenuItem>
      ));
    }
  };
  return (
    <Select
      value={value}
      placeholder="Select status"
      disabled={isDisabled}
      onChange={(e) => onChange(e.target.value)}
    >
      {renderMenuItems()}
    </Select>
  );
};
