import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import {
  WorkflowActionType,
  WorkflowDto,
} from '../components/pages/canvas-pages/canvas-hubspot-page/types';
import { HubspotField } from '../components/pages/canvas-pages/canvas-hubspot-page/hubspot.types';
import { Campaign } from '../components/pages/canvas-pages/canvas-hubspot-page/campaign-dialog/campaignTypes';
import { HubspotEmail, HubspotForm, HubspotMembershipList, HubspotPage } from '@server/hubspot';

export interface HubspotState {
  hubspotOrgs: {
    data?: HubspotOrg[];
    isLoading: boolean;
    error?: unknown;
  };
  campaigns: {
    data?: Campaign[];
    isLoading: boolean;
    error?: unknown;
  };
  workflows: {
    data?: WorkflowDto[];
    isLoading: boolean;
    error?: unknown;
  };
  hubspotFields: {
    data: HubspotField[];
    isLoading: boolean;
    error?: unknown;
  };
  hubspotForms: {
    data: HubspotForm[];
    isLoading: boolean;
    error?: unknown;
  };
  hubspotPages: {
    data: HubspotPage[];
    isLoading: boolean;
    error?: unknown;
  };
  hubspotEmails: {
    data: HubspotEmail[];
    isLoading: boolean;
    error?: unknown;
  };
  hubspotMembershipLists: {
    data: HubspotMembershipList[];
    isLoading: boolean;
    error?: unknown;
  };
}

const initialState: HubspotState = {
  hubspotOrgs: { isLoading: false },
  campaigns: { isLoading: false },
  workflows: { isLoading: false },
  hubspotFields: { data: [], isLoading: false },
  hubspotForms: { data: [], isLoading: false },
  hubspotPages: { data: [], isLoading: false },
  hubspotEmails: { data: [], isLoading: false },
  hubspotMembershipLists: { data: [], isLoading: false },
};

export const hubspotSlice = createSlice({
  name: 'hubspot',
  initialState,
  reducers: {
    setHubspotOrgs: (state, action: PayloadAction<HubspotOrg[]>) => {
      state.hubspotOrgs.data = action.payload;
      state.hubspotOrgs.isLoading = false;
    },
    addOrUpdateHubspotOrg: (state, action: PayloadAction<HubspotOrg>) => {
      const hsOrgToAdd = action.payload;
      if (state.hubspotOrgs.data) {
        const existingOrg = state.hubspotOrgs.data.find((hsOrg) => hsOrg.id === hsOrgToAdd.id);
        if (existingOrg) {
          state.hubspotOrgs.data = state.hubspotOrgs.data.map((hsOrg) => {
            if (hsOrg.id === existingOrg.id) {
              return hsOrgToAdd;
            } else {
              return hsOrg;
            }
          });
          return;
        }
        state.hubspotOrgs.data = [...state.hubspotOrgs.data, action.payload];
      } else {
        state.hubspotOrgs.data = [action.payload];
      }
    },
    deleteHubspotOrg: (state, action: PayloadAction<{ hsOrgId: string }>) => {
      state.hubspotOrgs.data = state.hubspotOrgs.data?.filter(
        (hsOrg) => hsOrg.id !== action.payload.hsOrgId,
      );
    },
    startLoadingCampaigns: (state) => {
      state.campaigns.isLoading = true;
    },
    loadCampaigns: (state, action: PayloadAction<Campaign[]>) => {
      state.campaigns.data = action.payload;
      state.campaigns.isLoading = false;
    },
    addCampaign: (state, action: PayloadAction<Campaign>) => {
      if (state.campaigns.data) {
        state.campaigns.data = [...state.campaigns.data, action.payload];
      } else {
        state.campaigns.data = [action.payload];
      }
    },
    editCampaign: (state, action: PayloadAction<Campaign>) => {
      if (state.campaigns.data) {
        state.campaigns.data = state.campaigns.data.map((campaign) => {
          if (campaign.Id === action.payload.Id) {
            return action.payload;
          }
          return campaign;
        });
      }
    },
    removeCampaign: (state, action: PayloadAction<{ campaignId: string }>) => {
      if (state.campaigns.data) {
        state.campaigns.data = state.campaigns.data.filter(
          (campaign) => campaign.Id !== action.payload.campaignId,
        );
      }
    },
    startLoadingWorkflows: (state) => {
      state.workflows.isLoading = true;
    },
    loadWorkflows: (state, action: PayloadAction<WorkflowDto[]>) => {
      state.workflows.data = action.payload;
      state.workflows.isLoading = false;
    },
    removeWorkflow: (state, action: PayloadAction<{ workflowId: string }>) => {
      state.workflows.data = state.workflows.data?.filter(
        (workflow) => workflow.id !== action.payload.workflowId,
      );
    },
    addWorkflow: (state, action: PayloadAction<WorkflowDto>) => {
      if (state.workflows.data) {
        state.workflows.data = [...state.workflows.data, action.payload];
      } else {
        state.workflows.data = [action.payload];
      }
    },
    updateWorkflow: (state, action: PayloadAction<WorkflowDto>) => {
      if (!state.workflows.data) {
        state.workflows.data = [];
      }

      state.workflows.data = (state.workflows.data || []).map((workflow) => {
        if (workflow.id === action.payload.id) {
          return action.payload;
        }
        return workflow;
      });
    },
    startLoadingHubspotFields: (state) => {
      state.hubspotFields.isLoading = true;
    },
    setLoadingHubspotFieldsError: (state, action: PayloadAction<unknown>) => {
      state.hubspotFields.error = action.payload;
      state.hubspotFields.isLoading = false;
    },
    loadHubspotFields: (state, action: PayloadAction<HubspotField[]>) => {
      state.hubspotFields.data = action.payload;
      state.hubspotFields.isLoading = false;
    },
    startLoadingHubspotPages: (state) => {
      state.hubspotPages.isLoading = true;
    },
    setLoadingHubspotPagesError: (state, action: PayloadAction<unknown>) => {
      state.hubspotPages.error = action.payload;
      state.hubspotPages.isLoading = false;
    },
    loadHubspotPages: (state, action: PayloadAction<HubspotPage[]>) => {
      state.hubspotPages.data = action.payload;
      state.hubspotPages.isLoading = false;
    },
    startLoadingHubspotForms: (state) => {
      state.hubspotForms.isLoading = true;
    },
    loadHubspotForms: (state, action: PayloadAction<HubspotForm[]>) => {
      state.hubspotForms.data = action.payload;
      state.hubspotForms.isLoading = false;
    },
    setLoadingHubspotFormsError: (state, action: PayloadAction<unknown>) => {
      state.hubspotForms.error = action.payload;
      state.hubspotForms.isLoading = false;
    },
    startLoadingHubspotEmails: (state) => {
      state.hubspotEmails.isLoading = true;
    },
    loadHubspotEmails: (state, action: PayloadAction<HubspotEmail[]>) => {
      state.hubspotEmails.data = action.payload.map((email) => ({
        emailId: email.name,
        name: email.name,
      }));
      state.hubspotEmails.isLoading = false;
    },
    setLoadingHubspotEmailsError: (state, action: PayloadAction<unknown>) => {
      state.hubspotEmails.error = action.payload;
      state.hubspotForms.isLoading = false;
    },
    startLoadingHubspotMembershipLists: (state) => {
      state.hubspotMembershipLists.isLoading = true;
    },
    loadHubspotMembershipLists: (state, action: PayloadAction<HubspotMembershipList[]>) => {
      state.hubspotMembershipLists.data = action.payload;
      state.hubspotMembershipLists.isLoading = false;
    },
    setLoadingHubspotMembershipListsError: (state, action: PayloadAction<unknown>) => {
      state.hubspotMembershipLists.error = action.payload;
      state.hubspotMembershipLists.isLoading = false;
    },
  },
});

export const {
  setHubspotOrgs,
  addOrUpdateHubspotOrg,
  addCampaign,
  editCampaign,
  removeCampaign,
  loadCampaigns,
  startLoadingCampaigns,
  deleteHubspotOrg,
  startLoadingWorkflows,
  loadWorkflows,
  addWorkflow,
  updateWorkflow,
  removeWorkflow,
  startLoadingHubspotFields,
  loadHubspotFields,
  startLoadingHubspotPages,
  loadHubspotPages,
  startLoadingHubspotForms,
  loadHubspotForms,
  setLoadingHubspotFieldsError,
  setLoadingHubspotPagesError,
  setLoadingHubspotFormsError,
  startLoadingHubspotEmails,
  loadHubspotEmails,
  setLoadingHubspotEmailsError,
  loadHubspotMembershipLists,
  startLoadingHubspotMembershipLists,
  setLoadingHubspotMembershipListsError,
} = hubspotSlice.actions;

export const selectHubspotOrgs = (state: RootState) => state.hubspot.hubspotOrgs.data;
export const selectConnectedHubspotOrg = (state: RootState) =>
  state.hubspot.hubspotOrgs.data?.find((hsOrg) => hsOrg.isConnected);
export const selectHubspotOrgsHasLoaded = (state: RootState) =>
  Boolean(state.hubspot.hubspotOrgs.data);
export const selectIsLoadingHsOrgs = (state: RootState) => state.hubspot.hubspotOrgs.isLoading;

export const selectCampaigns = (state: RootState) => state.hubspot.campaigns.data;
export const selectCampaignsIsLoading = (state: RootState) => state.hubspot.campaigns.isLoading;
export const selectCampaignById = (campaignId: string) => (state: RootState) =>
  state.hubspot.campaigns.data?.find((campaign) => campaign.Id === campaignId);

export const selectWorkflows = (state: RootState) => state.hubspot.workflows.data;
export const selectWorkflowsIsLoading = (state: RootState) => state.hubspot.workflows.isLoading;
export const selectWorkflowById = (workflowId?: string) => (state: RootState) =>
  state.hubspot.workflows.data?.find((workflow) => workflow.hubspotWorkflowId === workflowId);

export const selectCampaignWorkflows = (campaignId: string) => (state: RootState) =>
  state.hubspot.workflows.data?.filter((workflow) =>
    workflow.workflowDetails.actions.some(
      (action) =>
        action.actionType === WorkflowActionType.ENROLL_CAMPAIGN &&
        action.campaignId === campaignId,
    ),
  ) || [];

export const selectHubspotFields = (state: RootState) => state.hubspot.hubspotFields.data;
export const selectHubspotFieldsIsLoading = (state: RootState) =>
  state.hubspot.hubspotFields.isLoading;

export const selectHubspotFieldByName = (name: string) => (state: RootState) =>
  state.hubspot.hubspotFields.data.find((field) => field.name === name);

export const selectHubspotPagesData = (state: RootState) => state.hubspot.hubspotPages.data;
export const selectHubspotFormsData = (state: RootState) => state.hubspot.hubspotForms.data;
export const selectHubspotEmailsData = (state: RootState) => state.hubspot.hubspotEmails.data;

export const selectHubspotMembershipListsData = (state: RootState) =>
  state.hubspot.hubspotMembershipLists.data;
export const selectHubspotMembershipListsIsLoading = (state: RootState) =>
  state.hubspot.hubspotMembershipLists.isLoading;

export default hubspotSlice.reducer;
