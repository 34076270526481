import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';

//This reducer holds only UI related state

export interface AiAgentCenterState {
  isExpanded: boolean;
  isOpened: boolean;
  isCreateAgentActive: boolean;
}

const initialState: AiAgentCenterState = {
  isExpanded: false,
  isOpened: false,
  isCreateAgentActive: false,
};

export const aiAgentCenterSlice = createSlice({
  name: 'aiAgentCenter',
  initialState,
  reducers: {
    toggleExpandMode: (state) => {
      state.isExpanded = !state.isExpanded;
    },
    toggleOpened: (state) => {
      state.isOpened = !state.isOpened;
    },
    setIsCreateActive: (state, action: PayloadAction<{ isActive: boolean }>) => {
      const { isActive } = action.payload;
      state.isCreateAgentActive = isActive;
      if (isActive) {
        state.isOpened = false;
      }
    },
  },
});

export const {
  toggleExpandMode,
  toggleOpened,
  setIsCreateActive,
} = aiAgentCenterSlice.actions;

export const selectIsExpandedAiChats = (state: RootState) => state.aiAgentCenter.isExpanded;

export const selectIsOpenedAiChats = (state: RootState) => state.aiAgentCenter.isOpened;
export const selectIsCreateActive = (state: RootState) => state.aiAgentCenter.isCreateAgentActive;

export default aiAgentCenterSlice.reducer;
