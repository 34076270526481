import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import { Typography, colors, Button } from '@sweep-io/sweep-design';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import { useFunnelMapForm } from './useFunnelMapForm';

const RenameFunnelMapDialog = ({
  isOpen,
  closeDialog,
  confirmText,
  onConfirm,
  dialogTitle,
  description,
  initialName,
  readOnly,
}: {
  isOpen: boolean;
  closeDialog: () => void;
  confirmText: string;
  onConfirm: (arg: any) => any;
  dialogTitle: string;
  description?: string;
  initialName?: string;
  readOnly?: boolean;
}) => {
  const {
    handleChange,
    values,
    errors,
    isSubmitted,
    isValid,
    handleSubmit,
    resetForm,
    isSubmitting,
  } = useFunnelMapForm({
    initialFunnelName: initialName ?? '',
    onSubmit: onConfirm,
  });

  const { name } = values;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  // reset/initialization:
  useEffect(() => {
    resetForm();
  }, [isOpen, resetForm]);

  return (
    <Dialog open={isOpen} onClose={closeDialog} data-testid="name-funnel-dialog">
      <DialogTitle>
        <Typography variant="h2">{dialogTitle}</Typography>
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            width: '375px',
            paddingTop: 0,
          }}
        >
          {description && <Typography variant="body">{description}</Typography>}
          <FormControl fullWidth>
            <RestrictedTooltip
              to={['edit:funnels']}
              notAllowedTitle={'To rename this funnel view, please contact your admin.'}
              component={(isAllowed) => (
                <TextField
                  autoFocus
                  id="funnel_name"
                  placeholder="Enter Funnel name"
                  fullWidth
                  value={name || ''}
                  onChange={handleChange('name')}
                  helperText={isSubmitted ? errors.name : ''}
                  error={isSubmitted && Boolean(errors.name)}
                  disabled={readOnly || !isAllowed}
                  sx={{
                    input: {
                      padding: '15px 14px',
                      color: colors.black,
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: '10px',
                      marginLeft: '2px',
                    },
                  }}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ gap: '8px' }}>
          <Button variant="outlined" size="small" onClick={closeDialog}>
            Cancel
          </Button>
          <RestrictedTooltip
            to={['edit:funnels']}
            notAllowedTitle={'To rename this funnel view, please contact your admin.'}
            component={(isAllowed) => (
              <Button
                size="small"
                loading={isSubmitting}
                disabled={!isAllowed || (isSubmitted && !isValid)}
                htmlType="submit"
              >
                {confirmText}
              </Button>
            )}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RenameFunnelMapDialog;
