import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { ConfigurationItemMap } from '../configuration-item-tables/shared/helpers';
import { ConfigurationType } from './types';
import { getStepFullNameFromId } from '../selected-object/filters/utils';
import uniq from 'lodash/uniq';
import { Dictionary } from 'lodash';
import { isItemActive } from '../helpers';
import { ConfigurationItem, OpenAiDescription } from './DependenciesTypes';
import { OpenAiResponse } from '../../parser/ParserTypes';

export const SectionBox = styled(Box)(() => ({
  padding: '0 0 16px 0',
  borderBottom: `1px solid ${colors.grey[200]}`,
}));

export const HEADER_TOP_ENDORNMENT = '28px';

export const prepareConfigItemMapStructure = (items?: ConfigurationItemMap) => {
  if (!items) {
    return;
  }

  return Object.keys(items).reduce((acc: any, key) => {
    const itemsOfKey = items[key as ConfigurationType];

    acc[key] = itemsOfKey.map((item) => ({
      id: item.id,
      lastModified: item.updatedAt ?? item?.annotations?.changedAt,
      label: item.label,
      type: item.type,
      link: item.link,
      flowType: item.flowType,
      objectName: item.objectName ?? item.objectApiNames?.[0] ?? '',
      name: item.name,
      isRollup: item.isRollup,
      //using isItemActive to assign isActive property as not all of the configuration types have it
      isActive: isItemActive(item),
    }));
    return acc as ConfigurationItemMap;
  }, {});
};

export const isSupported = (supportedTypes: string[], type?: string) =>
  type ? supportedTypes.includes(type) : false;

export const SWEEP_ELEMENTS = [
  ConfigurationType.sweepAlert,
  ConfigurationType.sweepDedupe,
  ConfigurationType.sweepAutomation,
  ConfigurationType.sweepMatching,
  ConfigurationType.sweepAssignment,
  ConfigurationType.sweepScheduledAssignment,
  ConfigurationType.rollups,
];

export const excludedFromDetails = [
  ConfigurationType.apexClasses,
  ConfigurationType.apexTriggers,
  ConfigurationType.cpqData,
  ...SWEEP_ELEMENTS.filter((item) => item !== ConfigurationType.rollups),
];

export const isFieldType = (itemType: string) =>
  itemType === ConfigurationType.fields || itemType === ConfigurationType.rollups;

export const formatOpenAiResponse = (res: OpenAiResponse): OpenAiDescription => {
  const isError = Boolean(res.error);
  return {
    type: isError ? 'error' : 'data',
    text: (res.data as string) || 'Could not load content at this time',
  };
};

export const getChips = (
  configurationItem: ConfigurationItem,
  stepNamesUsedInCanvas: string[],
  objectTypesByName: Dictionary<ObjectTypeName>,
  type?: ConfigurationType,
) => {
  const isSweepElement = type && SWEEP_ELEMENTS.includes(type);
  const { objectApiNames, stagesNames, usedOnlyByRecordTypeLabels } = configurationItem ?? {};

  const objectLabels = objectApiNames?.map((name) => objectTypesByName[name]?.label ?? name);

  const stageNamesFromId = uniq(
    stagesNames
      ?.filter((stageName) => stepNamesUsedInCanvas?.includes(stageName))
      ?.map((name) => getStepFullNameFromId(name)),
  ).filter((name) => !!name);

  const _stagesNames = isSweepElement ? stagesNames : stageNamesFromId;

  const stageNameChips = _stagesNames?.length ? _stagesNames : [];
  const objectChips = objectLabels && objectLabels?.length > 1 ? objectLabels : [];
  const RTchips = usedOnlyByRecordTypeLabels?.length ? uniq(usedOnlyByRecordTypeLabels) : [];
  const chips =
    stageNameChips.length || RTchips.length ? [...stageNameChips, ...RTchips] : [...objectChips];

  return { chips, RTchips };
};
