import { useDispatch, useSelector } from 'react-redux';
import { AdvancedFilterItem } from '../../common/advanced-filter/AdvancedFilter';
import {
  selectCanvasFilterSelectedValuesFor,
  setCanvasFilterItemsDataFromFilterItems,
  setCanvasFilterSelectedValuesFor,
} from './canvasFiltersReducer';
import { useCallback, useEffect } from 'react';
import { SingleSelectFilter } from '../../common/single-select-filter/SingleSelectFilter';

interface SingleGenericFilterProps<DataT = any> {
  label: string;
  items: AdvancedFilterItem<DataT>[];
  filterKey: string;
  renderSeparateClearButton?: boolean;
}

export function SingleGenericFilter<DataT = any>({
  filterKey,
  items,
  label,
  renderSeparateClearButton,
}: SingleGenericFilterProps<DataT>) {
  const dispatch = useDispatch();
  const selectedValues = useSelector(selectCanvasFilterSelectedValuesFor(filterKey));

  const onSelectedItemsChange = useCallback(
    (item: string) => {
      dispatch(setCanvasFilterSelectedValuesFor({ filterKey, selectedValues: [item] }));
    },
    [dispatch, filterKey],
  );

  useEffect(() => {
    if (!selectedValues.length) {
      onSelectedItemsChange(items[0].value);
    }
  }, [onSelectedItemsChange, selectedValues, items]);

  useEffect(() => {
    dispatch(
      setCanvasFilterItemsDataFromFilterItems({
        filterKey,
        items,
      }),
    );
  }, [dispatch, filterKey, items]);

  return (
    <SingleSelectFilter
      options={items}
      selectedItemValue={selectedValues[0]}
      onSelectItem={onSelectedItemsChange}
      filterTitleText={label}
      renderSeparateClearButton={renderSeparateClearButton}
    />
  );
}
