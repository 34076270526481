import { useContext } from 'react';

import { FunnelMapCanvasInternalCtx } from '../FunnelMapCanvasInternalCtx';
import { UseEventHandlerReturn } from './types';

const OBJ = {};

export const useHubspotEventHandler = (): UseEventHandlerReturn => {
  const {} = useContext(FunnelMapCanvasInternalCtx);

  return OBJ;
};
