import { Box, TextField } from '@mui/material';

export type TextSelectorProps = {
  readonly: boolean;
  keyValue: string;
  onChange: any;
};

export const TextSelector = ({ readonly, onChange, keyValue }: TextSelectorProps) => {
  return (
    <Box>
      <TextField
        disabled={readonly}
        sx={{
          width: '100%',
        }}
        value={keyValue ?? ''}
        onChange={onChange}
        InputProps={{
          sx: {
            '& fieldset': {
              border: 'none',
            },
          },
        }}
        placeholder={'Type response field'}
      />
    </Box>
  );
};
