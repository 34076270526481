import { FunnelFieldLabels } from '../../../../constants/fieldsManagementConsts';
import { checkValueInRange } from '../../../../lib/checkValuesInRange';
import {
  CanvasFilterTypes,
  CanvasValuedFilters,
} from '../../../pages/configuration-canvas-filters/filterTypes';
import { isConfigurationItemUsedByRecordType } from '../../../parser/parserUtils';
import { AutomationCanvasFilter } from '../../../pages/canvas-pages/canvasAutomationsTypes';
import { ConfigurationType } from '../../dependencies/types';
import { escapeRegexp } from '../../../../lib/escapeRegex';
import isString from 'lodash/isString';
import { activeFilterOptions, ActiveState } from '../../activity-state/helpers';
import {
  ConfigurationItem,
  FieldMetadataRecordProperties,
} from '../../dependencies/DependenciesTypes';
import uniq from 'lodash/uniq';
import { isItemActive } from '../../helpers';

const USAGE_SEPARATOR = '-';

const fieldUsageOptions = [
  {
    label: '0% - 25%',
    value: `0${USAGE_SEPARATOR}25`,
  },
  {
    label: '26% - 50%',
    value: `26${USAGE_SEPARATOR}50`,
  },
  {
    label: '51% - 75%',
    value: `51${USAGE_SEPARATOR}75`,
  },
  {
    label: '76% - 100%',
    value: `76${USAGE_SEPARATOR}100`,
  },
];

const automationsTypeOptions = [
  {
    label: 'Flow',
    value: ConfigurationType.flows,
  },
  {
    label: 'Approval Process',
    value: ConfigurationType.approvalProcesses,
  },
  {
    label: 'Process Builder',
    value: ConfigurationType.processBuilderFlows,
  },
  {
    label: 'Workflow Rule',
    value: ConfigurationType.workflowRules,
  },
];

export enum FiltersMap {
  recordTypes = 'recordTypes',
  steps = 'steps',
  fieldTypes = 'fieldTypes',
  automationTypes = 'automationTypes',
  utilization = 'utilization',
  activeState = 'activeState',
  configurationType = 'configurationType',
  relatedObject = 'relatedObject',
}

export const getFieldsTabFilters = (fields: FieldMetadataRecordProperties[]) => {
  const types = uniq(
    fields?.map((field) => ({
      label: FunnelFieldLabels[field.type as SweepFieldTypes] ?? field.type,
      value: field.type,
    })),
  );

  const fieldTypes = types ? [...new Map(types.map((field) => [field.value, field])).values()] : [];

  return {
    [FiltersMap.fieldTypes]: {
      type: CanvasFilterTypes.MULTI_GENERIC,
      position: 0,
      items: fieldTypes,
      label: 'Type',
    },
    [FiltersMap.utilization]: {
      type: CanvasFilterTypes.MULTI_GENERIC,
      position: 1,
      items: fieldUsageOptions,
      label: 'Utilization',
    },
  };
};

export const getApexTabFilters = (objectFilter = '') => {
  return {
    [FiltersMap.recordTypes]: {
      type: CanvasFilterTypes.RECORD_TYPE,
      objectsFilter: {
        type: 'values',
        values: [objectFilter],
      },
      position: 0,
    },
    [FiltersMap.steps]: {
      type: CanvasFilterTypes.STEP,
      recordTypeFilterRef: FiltersMap.recordTypes,
      position: 1,
    },

    [FiltersMap.activeState]: {
      type: CanvasFilterTypes.SINGLE_GENERIC,
      recordTypeFilterRef: FiltersMap.activeState,
      position: 2,
      items: activeFilterOptions,
      label: 'Show',
    },
  };
};

export const getRelatedObjectsFilters = (
  relatedObjectsOptions: { label: string; value: string }[],
  configurationTypeOptions: { label: string; value: string }[],
) => {
  return {
    [FiltersMap.relatedObject]: {
      type: CanvasFilterTypes.MULTI_GENERIC,
      position: 0,
      items: relatedObjectsOptions,
      label: 'Related object',
    },
    [FiltersMap.configurationType]: {
      type: CanvasFilterTypes.MULTI_GENERIC,
      position: 1,
      items: configurationTypeOptions,
      label: 'Type',
    },
    [FiltersMap.activeState]: {
      type: CanvasFilterTypes.SINGLE_GENERIC,
      recordTypeFilterRef: FiltersMap.activeState,
      position: 2,
      items: activeFilterOptions,
      label: 'Show',
    },
  };
};

export const getLayoutsTabFilters = (objectFilter = '') => {
  return {
    [FiltersMap.recordTypes]: {
      type: CanvasFilterTypes.RECORD_TYPE,
      objectsFilter: {
        type: 'values',
        values: [objectFilter],
      },
      position: 0,
    },
  };
};

export const getRecordTypesTabFilters = () => {
  return {
    [FiltersMap.activeState]: {
      type: CanvasFilterTypes.SINGLE_GENERIC,
      recordTypeFilterRef: FiltersMap.activeState,
      position: 0,
      items: activeFilterOptions,
      label: 'Show',
    },
  };
};

export const getSalesforceAutomationsTabFilters = (objectFilter = '') => {
  return {
    [FiltersMap.automationTypes]: {
      type: CanvasFilterTypes.MULTI_GENERIC,
      position: 0,
      items: automationsTypeOptions,
      label: 'Type',
    },
    [FiltersMap.recordTypes]: {
      type: CanvasFilterTypes.RECORD_TYPE,
      objectsFilter: {
        type: 'values',
        values: [objectFilter],
      },
      position: 1,
    },
    [FiltersMap.steps]: {
      type: CanvasFilterTypes.STEP,
      recordTypeFilterRef: FiltersMap.recordTypes,
      position: 2,
    },

    [FiltersMap.activeState]: {
      type: CanvasFilterTypes.SINGLE_GENERIC,
      recordTypeFilterRef: FiltersMap.activeState,
      position: 3,
      items: activeFilterOptions,
      label: 'Show',
    },
  };
};

export const getValidationRulesTabFilters = (objectFilter = '') => {
  return {
    [FiltersMap.recordTypes]: {
      type: CanvasFilterTypes.RECORD_TYPE,
      objectsFilter: {
        type: 'values',
        values: [objectFilter],
      },
      position: 0,
    },
    [FiltersMap.steps]: {
      type: CanvasFilterTypes.STEP,
      recordTypeFilterRef: FiltersMap.recordTypes,
      position: 1,
    },

    [FiltersMap.activeState]: {
      type: CanvasFilterTypes.SINGLE_GENERIC,
      recordTypeFilterRef: FiltersMap.activeState,
      position: 2,
      items: activeFilterOptions,
      label: 'Show',
    },
  };
};

export const getAssignmentRulesFilter = (objectFilter = '') => ({
  [AutomationCanvasFilter.RECORD_TYPES]: {
    type: CanvasFilterTypes.RECORD_TYPE,
    position: 1,
    objectsFilter: {
      type: 'values',
      values: [objectFilter],
    },
  },
  [AutomationCanvasFilter.STEPS]: {
    type: CanvasFilterTypes.STEP,
    recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
    position: 2,
  },
});

const getRecordTypeNameAndObjectNameFromRecordTypeId = (id: string) => ({
  objectName: id.split('.')?.[0],
  recordTypeName: id.split('.')?.[1],
});

export const filterRules = ({
  rules,
  selectedStepValuesData,
  selectedTypeValuesData,
  selectedRecordTypeValuesData,
  translateStepId,
}: {
  rules: ConfigurationItem[];
  selectedStepValuesData?: CanvasValuedFilters;
  selectedTypeValuesData?: CanvasValuedFilters;
  selectedRecordTypeValuesData?: CanvasValuedFilters;
  translateStepId: (stepId: string) => string;
}) => {
  const selectedStepValues = selectedStepValuesData?.selectedValues;
  const selectedRecordTypeValues = selectedRecordTypeValuesData?.selectedValues;
  const selectedTypeValues = selectedTypeValuesData?.selectedValues;

  if (
    !selectedStepValuesData?.selectedValues.length &&
    !selectedRecordTypeValuesData?.selectedValues.length &&
    !selectedTypeValuesData?.selectedValues.length
  ) {
    return rules;
  }

  return rules.filter((rule) => {
    const isSelectedStage = selectedStepValues?.length
      ? rule.stagesNames?.some((name) =>
          selectedStepValues?.map((stage) => translateStepId(stage)).includes(name),
        )
      : true;

    const isSelectedRecordType = selectedRecordTypeValues?.length
      ? selectedRecordTypeValues?.some((recordType) => {
          const recordTypeValues = getRecordTypeNameAndObjectNameFromRecordTypeId(recordType);
          return isConfigurationItemUsedByRecordType(
            recordTypeValues.recordTypeName,
            recordTypeValues.objectName,
          )(rule);
        })
      : true;

    const isSelectedType = selectedTypeValues?.length
      ? selectedTypeValues?.includes(rule.type)
      : true;

    return isSelectedStage && isSelectedType && isSelectedRecordType;
  });
};

export const filterFields = ({
  fields,
  selectedUsageValues,
  selectedTypeValues,
}: {
  fields: ConfigurationItem[];
  selectedUsageValues?: string[];
  selectedTypeValues?: string[];
}) => {
  const selectedTypeValuesHasNoFilter = !selectedTypeValues?.length;
  const selectedUsageValuesHasNoFilter = !selectedUsageValues?.length;

  if (selectedTypeValuesHasNoFilter && selectedUsageValuesHasNoFilter) {
    return fields;
  }

  return fields.filter((field) => {
    const isSelectedFieldType =
      selectedTypeValuesHasNoFilter || selectedTypeValues?.includes(field.type);

    const isInRange =
      selectedUsageValuesHasNoFilter ||
      selectedUsageValues?.some((value) => {
        const usageRangeValues = value.split(USAGE_SEPARATOR);

        return checkValueInRange({
          valueToCheck: field.usage ?? 0,
          bottomValue: Number(usageRangeValues[0]),
          topValue: Number(usageRangeValues[1]),
        });
      });

    return isSelectedFieldType && isInRange;
  });
};

export const filterRelatedObjects = ({
  items,
  selectedActiveState,
  selectedRelatedObjects,
  selectedConfigurationTypes,
  excludedFromReferenceObjectName,
}: {
  items: ConfigurationItem[];
  selectedActiveState?: string;
  selectedRelatedObjects?: CanvasValuedFilters;
  selectedConfigurationTypes?: CanvasValuedFilters;
  excludedFromReferenceObjectName: string;
}) => {
  const selectedRelatedObjectsValues = selectedRelatedObjects?.selectedValues;
  const selectedConfigurationTypesValues = selectedConfigurationTypes?.selectedValues;
  const selectedActiveOnly = selectedActiveState
    ? selectedActiveState === ActiveState.activeOnly
    : true;

  if (
    !selectedRelatedObjectsValues?.length &&
    !selectedConfigurationTypesValues?.length &&
    !selectedActiveOnly
  ) {
    return items;
  }

  return items.filter((item) => {
    const isSelectedRelatedObject = selectedRelatedObjectsValues?.length
      ? item.objectApiNames?.some((name) => selectedRelatedObjectsValues?.includes(name)) ||
        item.referencedObjects
          ?.filter((obj) => obj !== excludedFromReferenceObjectName)
          .some((name) => selectedRelatedObjectsValues?.includes(name))
      : true;

    const isSelectedConfigurationType = selectedConfigurationTypesValues?.length
      ? selectedConfigurationTypesValues?.includes(item.parentType)
      : true;

    const isActive = selectedActiveOnly ? isItemActive(item) : true;

    return isSelectedRelatedObject && isActive && isSelectedConfigurationType;
  });
};

export const filterItemsByQuerySearch = ({
  itemProps,
  searchTxt,
  items,
}: {
  itemProps: string[];
  searchTxt: string;
  items?: any[];
}) => {
  if (!searchTxt) return items;
  const regexp = new RegExp(escapeRegexp(searchTxt), 'gi');
  return (
    items?.filter((item) =>
      itemProps.some((itemProp) => isString(item[itemProp]) && item[itemProp]?.match(regexp)),
    ) ?? []
  );
};

export const rulePropsToFilter = ['label', 'name'];
export const fieldPropsToFilter = ['label', 'name'];
