import {
  NestedFieldsSelector,
  NestedFieldsSelectorProps,
} from '../common/fieldsSelectors/NestedFieldsSelector';
import { SfChangeFeedSelectorProps } from './selectors/SfChangeFeedSelector';
import { SfChangeFeedSelector } from './selectors/SfChangeFeedSelector';
import { TerritorySelector, TerritorySelectorProps } from './selectors/TerritorySelector';
import { TextSelector, TextSelectorProps } from './selectors/TextSelector';

export enum MainSelectors {
  Territories = 'territories',
  SfChangeFeedNotifications = 'SfChangeFeedNotifications',
  TextField = 'textField',
}

export const SweepFieldsMainSelector = ({
  mainSelectorKey,
  territorySelectorProps,
  nestedSelectorProps,
  sfChangeFeedSelectorProps,
  textSelectorProps,
}: {
  mainSelectorKey?: MainSelectors;
  territorySelectorProps?: TerritorySelectorProps;
  nestedSelectorProps?: NestedFieldsSelectorProps;
  sfChangeFeedSelectorProps?: SfChangeFeedSelectorProps;
  textSelectorProps?: TextSelectorProps;
}) => {
  switch (mainSelectorKey) {
    case MainSelectors.Territories:
      return territorySelectorProps ? <TerritorySelector {...territorySelectorProps} /> : <></>;

    case MainSelectors.SfChangeFeedNotifications:
      return sfChangeFeedSelectorProps ? (
        <SfChangeFeedSelector {...sfChangeFeedSelectorProps} />
      ) : (
        <></>
      );
    case MainSelectors.TextField:
      return textSelectorProps ? <TextSelector {...textSelectorProps} /> : <></>;
  }

  if (!nestedSelectorProps) {
    return <></>;
  }

  return <NestedFieldsSelector {...nestedSelectorProps} />;
};
