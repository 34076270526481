import { Box, MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import SweepSelect from '../common/SweepSelect';
import { HttpVerb, WebhookBodyType } from '../../types/enums/AutomationTypes';
import { MandatoryCircle } from '@sweep-io/sweep-design/dist/icons';
import { InputWithVariables } from './InputWithVariables';
import { useSelector } from 'react-redux';
import { selectCustomIntegrations } from '../../reducers/integrationsReducer';
import { KeyValueTable } from './KeyValueTable';
import { useCallback, useState } from 'react';
import { useRunOnce } from '../common/useRunOnce';
import { uniqueId } from '../../lib/uniqueId';
import { labelBoxDesign } from './AutomationsForm';
import { ActionsMenu } from '../common/actions-menu/ActionsMenu';
import ConditionalActionsEditor from './ConditionalActionsEditor';

interface WebhookActionWrapperProps {
  readonly?: boolean;
  action: WebhookAutomationAction;
  crmOrgId: string;
  onChange: (action: WebhookAutomationAction) => any;
  objectName: string;
  objectTypes?: ObjectTypeName[];
}

const WebhookBodyTypeList = [
  {
    value: WebhookBodyType.JSON,
    label: 'JSON',
  },
  {
    value: WebhookBodyType.URL_ENCODED,
    label: 'X-www-form-ulencodded',
  },
  {
    value: 'NONE',
    label: 'None',
  },
];

export const onKeyDownDisableSpaces = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === ' ' || event.key === 'Tab' || event.key === 'Enter') {
    event.preventDefault();
  }
};

export const WebhookActionWrapper = ({
  action,
  crmOrgId,
  objectName,
  onChange,
  readonly,
  objectTypes,
}: WebhookActionWrapperProps) => {
  const [isFollowUpActionOpen, setIsFollowUpActionOpen] = useState(
    (action?.actionParams?.followUpActions?.[0]?.actions?.[0] as ConditionalActionsAutomationAction)
      ?.actionParams?.prioritizedActions?.length > 0,
  );
  const customIntegrations = useSelector(selectCustomIntegrations);
  const integrationSelected = customIntegrations?.find(
    (el) => el.id === action?.actionParams?.integrationId,
  );

  useRunOnce(async () => {
    onChange({
      ...action,
      actionParams: {
        ...(action as WebhookAutomationAction).actionParams,
        queryParams: action?.actionParams?.queryParams ?? [],
        headers: action?.actionParams?.headers ?? [],
        followUpActions: action?.actionParams?.followUpActions ?? [],
      },
    });
  });

  const handleChangeAction = useCallback(
    (prop: string, value: any) => {
      onChange({
        ...action,
        actionParams: {
          ...(action as WebhookAutomationAction).actionParams,
          [prop]: value,
        },
      });
    },
    [action, onChange],
  );

  const bodyJsonObject = {
    type: WebhookBodyType.JSON,
    value: { template: '', variables: [] },
  };
  const bodyUrlObject = {
    type: WebhookBodyType.URL_ENCODED,
    value: [
      {
        key: '',
        value: { template: '', variables: [] },
      },
    ],
  };

  return (
    <Stack spacing={3} mt={3}>
      <Stack direction="row" spacing={'8px'} alignItems={'center'}>
        <Typography variant="body-medium" textAlign="center">
          Method
        </Typography>
        <MandatoryCircle />
        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '120px' },
          }}
          menuMaxHeight={'360px'}
          SelectProps={{
            placeholder: 'Choose',
            disabled: readonly,
            value: action?.actionParams?.verb ?? '',
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value as HttpVerb;
              handleChangeAction('verb', val);
            },
          }}
        >
          {Object.keys(HttpVerb).map((Item) => {
            return (
              <MenuItem key={Item} value={Item.toUpperCase()}>
                {Item}
              </MenuItem>
            );
          })}
        </SweepSelect>
      </Stack>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography variant="body-medium" textAlign="center">
          Path
        </Typography>
        <MandatoryCircle />
        <Typography variant="body" textAlign="center">
          {integrationSelected?.baseUrl ?? 'https.www.snowflake.com/'}
        </Typography>
        <Box
          sx={{
            width: '100%',
            '.ql-container .ql-editor': {
              borderRadius: '4px',
            },
          }}
        >
          <InputWithVariables
            customOnKeyDown={onKeyDownDisableSpaces}
            crmOrgId={crmOrgId}
            objectName={objectName}
            withBorder={true}
            data={action?.actionParams?.path}
            placeholder={'examples: verify-url, enrichment/phone'}
            onChange={(newMsg) => {
              handleChangeAction('path', newMsg);
            }}
          />
        </Box>
      </Box>
      <Stack spacing={'12px'}>
        <Typography variant="body-medium">Query params</Typography>
        <KeyValueTable
          keyString="queryParams"
          crmOrgId={crmOrgId}
          objectName={objectName}
          params={action?.actionParams?.queryParams ?? []}
          onChange={(params) => {
            handleChangeAction('queryParams', params);
          }}
        />
      </Stack>

      <Stack spacing={'12px'}>
        <Typography variant="body-medium">Headers</Typography>
        <KeyValueTable
          keyString="headers"
          crmOrgId={crmOrgId}
          objectName={objectName}
          params={action?.actionParams?.headers ?? []}
          onChange={(params) => {
            handleChangeAction('headers', params);
          }}
        />
      </Stack>

      <Stack direction="row" spacing={'8px'} alignItems={'center'}>
        <Typography variant="body-medium" textAlign="center">
          Body
        </Typography>
        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '120px' },
          }}
          menuMaxHeight={'360px'}
          SelectProps={{
            placeholder: 'Choose method',
            disabled: readonly,
            value: action?.actionParams?.body?.type ?? '',
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value as string;
              if (val === 'NONE') {
                handleChangeAction('body', undefined);
              } else {
                const elem = val === WebhookBodyType.JSON ? bodyJsonObject : bodyUrlObject;
                handleChangeAction('body', elem);
              }
            },
          }}
        >
          {WebhookBodyTypeList.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </SweepSelect>
      </Stack>
      {action?.actionParams?.body?.type === WebhookBodyType.JSON && (
        <Box>
          <Box
            sx={{
              width: '100%',
              '.ql-container .ql-editor': {
                borderRadius: '4px',
              },
            }}
          >
            <InputWithVariables
              variableButtonSX={{ bottom: '1px', left: '4px', top: 'auto' }}
              isShowFieldSelectorAlways={true}
              isBigInput={true}
              crmOrgId={crmOrgId}
              objectName={objectName}
              withBorder={true}
              data={(action?.actionParams?.body as WebhookJsonBody)?.value}
              placeholder={'Insert JSON file here'}
              onChange={(newMsg) => {
                const _obj = {
                  type: WebhookBodyType.JSON,
                  value: newMsg,
                };
                handleChangeAction('body', _obj);
              }}
            />
          </Box>
        </Box>
      )}
      {action?.actionParams?.body?.type === WebhookBodyType.URL_ENCODED && (
        <Stack spacing={'12px'}>
          <KeyValueTable
            keyString="body"
            crmOrgId={crmOrgId}
            objectName={objectName}
            params={(action?.actionParams?.body as WebhookUrlencodedBody)?.value ?? []}
            onChange={(params) => {
              const _obj = {
                type: WebhookBodyType.URL_ENCODED,
                value: params,
              };
              handleChangeAction('body', _obj);
            }}
          />
        </Stack>
      )}
      {!isFollowUpActionOpen && (
        <Box>
          <Button
            onClick={() => {
              setIsFollowUpActionOpen(true);
            }}
            startIconName="Plus"
            variant="flat"
          >
            Use response in follow up action
          </Button>
        </Box>
      )}
      {isFollowUpActionOpen && (
        <Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              sx={{
                ...labelBoxDesign,
                background: colors.storm[400],
              }}
            >
              <Typography variant="caption-bold" textAlign="center" color={colors.white}>
                And use response in follow up actions
              </Typography>
            </Box>
            <ActionsMenu
              iconSize={'tiny'}
              actions={[
                {
                  label: 'Remove',
                  value: 'clear',
                },
              ]}
              onClick={(action) => {
                switch (action.value) {
                  case 'clear':
                    handleChangeAction('followUpActions', []);
                    setIsFollowUpActionOpen(false);
                    break;
                }
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            <Typography variant="body">
              Set up branches using the response, and execute the first action that meets the
              criteria:
            </Typography>
            <ConditionalActionsEditor
              isShowWebhookOption={true}
              objectTypes={objectTypes}
              conditionalActions={
                (
                  action?.actionParams?.followUpActions?.[0]
                    ?.actions?.[0] as ConditionalActionsAutomationAction
                )?.actionParams?.prioritizedActions ?? []
              }
              onChange={(prioritizedActions) => {
                const _inner: ConditionalActionsAutomationAction = {
                  _id:
                    (
                      action?.actionParams?.followUpActions?.[0]
                        ?.actions?.[0] as ConditionalActionsAutomationAction
                    )?._id ?? uniqueId(),
                  actionType: 'PRIORITIZED_ACTIONS',
                  actionParams: {
                    prioritizedActions: prioritizedActions,
                  },
                };

                const _tmpAction: AutomationConditionalAction = {
                  _id: action?.actionParams?.followUpActions?.[0]?._id ?? uniqueId(),
                  _name: 'AutomationConditionalAction',
                  actions: [_inner],
                };

                handleChangeAction('followUpActions', [_tmpAction]);
              }}
              crmOrgId={crmOrgId}
              objectName={objectName}
              readonly={readonly}
            />
          </Box>
        </Box>
      )}
    </Stack>
  );
};
