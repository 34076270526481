import { Box, Stack } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import { colors, IconButton } from '@sweep-io/sweep-design';
import { Search as MagnifyingGlassIcon } from '@sweep-io/sweep-design/dist/icons';
import { ChatsSections } from './ChatsSections';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import SearchInputWithTooltip from '../common/SearchInputWithTooltip';
import useCopilotChats from './useCopilotChats';
import ChatSearchResults from './ChatSearchResults';
import usePaywall from '../dashboard/paywall/usePaywall';
import { useUnhappyStates } from '../pages/environments/useUnhappyStates';

const ChatsHistory = ({ newButton }: { newButton: ReactNode }) => {
  const {
    chatsListIsLoading,
    activeChat,
    setActiveChatId,
    chats,
    getSearchResults,
    isLoadingSearch,
    clearSearchResults,
    searchResults,
    searchText,
    ghostChatItemIsLoading,
  } = useCopilotChats();

  const { isPaywallActive } = usePaywall();
  const { noConnectedOrg, isInitialFetch } = useUnhappyStates();
  const isSearchDisabled = isPaywallActive || noConnectedOrg || isInitialFetch;

  const [localSearchText, setLocalSearchText] = useState(searchText);
  const [isSearchOpen, setIsSearchOpen] = useState(!!searchText);

  const clearSearch = useCallback(() => {
    clearSearchResults();
    setIsSearchOpen(false);
    setLocalSearchText('');
  }, [clearSearchResults]);

  const onSearchKeydown = useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        clearSearch();
      }
      if (event.key === 'Enter' && !!localSearchText) {
        getSearchResults(localSearchText);
      } else if (event.key === 'Enter' && localSearchText === '') {
        clearSearch();
      }
    },
    [clearSearch, getSearchResults, localSearchText],
  );

  const showSearchResults = isLoadingSearch || searchResults;

  return (
    <Stack
      sx={{
        height: '100%',
        padding: '24px 0',
        borderRight: `1px solid ${colors.grey[200]}`,
        backgroundColor: colors.grey[100],
      }}
    >
      {chatsListIsLoading && <CenteredCircularProgress />}
      {!chatsListIsLoading && (
        <Stack
          sx={{
            height: '100%',
            gap: '24px',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="40px"
            padding="0 16px"
          >
            {!isSearchOpen && newButton}
            {!isSearchOpen && (
              <IconButton
                onClick={() => {
                  setIsSearchOpen(true);
                }}
                disabled={isSearchDisabled}
                variant="flat"
                size="small"
              >
                <MagnifyingGlassIcon />
              </IconButton>
            )}
            {isSearchOpen && (
              <SearchInputWithTooltip
                displayTooltip={localSearchText !== '' && localSearchText !== searchText}
                TextFieldProps={{
                  autoFocus: true,
                  value: localSearchText,
                  onChange: (e) => {
                    setLocalSearchText(e.target.value);
                  },
                  onKeyDown: onSearchKeydown,
                }}
                onClearButtonClick={clearSearch}
                withFixedMagnifyingGlassIcon
                forceCloseButton
              />
            )}
          </Box>
          {showSearchResults && (
            <ChatSearchResults
              isLoadingSearch={!!isLoadingSearch}
              searchResults={searchResults}
              searchText={searchText}
              activeChatId={activeChat?.data?.id}
              setActiveChatId={setActiveChatId}
            />
          )}
          {!showSearchResults && (
            <ChatsSections
              isLoadingChats={!!chatsListIsLoading}
              activeChatId={activeChat?.data?.id}
              setActiveChatId={setActiveChatId}
              chats={chats}
              ghostChatItemIsLoading={ghostChatItemIsLoading}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default ChatsHistory;
