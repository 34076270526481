import { AppBar, Box, Toolbar } from '@mui/material';
import { IconButton, Tooltip, colors } from '@sweep-io/sweep-design';
import { MenuCollapse, MenuExpand } from '@sweep-io/sweep-design/dist/icons';
import { DASHBOARD_HEADER_HEIGHT } from '../../../constants';
import { SweepLogoWhiteWandIcon } from '../../../icons/generalIcons';
import UserMenu from './UserMenu';
import { DashboardHeaderEnvSelector } from '../../pages/environments/connect-env/DashboardHeaderEnvSelector';
import DashboardHeaderFetchProgressBar from '../fetch-progress-bar/DashboardHeaderFetchProgressBar';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import AiChatDashboardButton from '../aiAgents/AiChatDashboardButton';
import PaywallDashboardHeader from './PaywallDashboardHeader';
import useSweepNavigate from '../../common/useSweepNavigate';
import { DEFAULT_ROUTE } from '../../../constants/appRoutes';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';

const DashboardHeader = ({
  sidebarMenuOpen,
  toggle,
}: {
  sidebarMenuOpen: boolean;
  toggle: () => void;
}) => {
  const { copilot } = useFeatureToggle();
  const user = useSelector(selectUserInfoData);
  const disableAi = !!user?.account?.disableAi;
  const { navigate } = useSweepNavigate();

  return (
    <AppBar sx={{ backgroundColor: colors.night[900], zIndex: 1300 }} position="sticky">
      <Toolbar
        variant="dense"
        sx={{
          justifyContent: 'space-between',
          minHeight: DASHBOARD_HEADER_HEIGHT,
          '&': {
            paddingLeft: '10px',
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Tooltip title={sidebarMenuOpen ? 'Collapse menu' : 'Expand menu'}>
            <IconButton variant="dark" size="small" onClick={toggle}>
              {sidebarMenuOpen ? <MenuCollapse /> : <MenuExpand />}
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
              height: '100%',
            }}
          >
            <Box onClick={() => navigate(DEFAULT_ROUTE)} sx={{ cursor: 'pointer' }}>
              <SweepLogoWhiteWandIcon />
            </Box>
            <DashboardHeaderEnvSelector />
            <DashboardHeaderFetchProgressBar />
          </Box>
        </Box>
        <Box display="flex" gap={2} alignItems="center">
          {copilot && !disableAi && <AiChatDashboardButton />}
          <PaywallDashboardHeader />
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
