import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  createNewChat,
  deleteChatsForAgent as deleteChatsForAgentAction,
  selectActiveChatId,
  selectActiveIsEmptyChat,
  selectChatsDetails,
  setActiveChat,
} from './aiChatsReducer';
import { useAiAgentApiFacade } from '../../apis/facades/useAiAgentApiFacade';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import { telemetry } from '../../telemetry';
import {
  addAgent,
  deleteAgent as deleteAgentAction,
  renameAgent as renameAgentAction,
  selectAgents,
  selectAgentsIsLoading,
  setAgents,
  setAgentsLoading,
} from './aiAgentsReducer';
import { AgentType } from '@server/ai';
import { SelectedStagesInFunnel } from './aiAgentsConsts';

const useAiAgents = () => {
  const { get_agents, rename_agent, delete_agent, create_agent } = useAiAgentApiFacade();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const _crmOrgId = crmOrgId ?? undefined;
  const chatsDetails = useSelector(selectChatsDetails(_crmOrgId));
  const activeChatId = useSelector(selectActiveChatId(_crmOrgId));
  const agents = useSelector(selectAgents(_crmOrgId));
  const agentsIsLoading = useSelector(selectAgentsIsLoading(_crmOrgId));
  const activeIsEmptyChat = useSelector(selectActiveIsEmptyChat(_crmOrgId));
  const activeChat = activeChatId ? chatsDetails?.[activeChatId] : undefined;

  const activeAgent = useMemo(
    () => agents?.find((agent) => agent.id === activeChat?.data?.agentId),
    [agents, activeChat?.data?.agentId],
  );

  const dispatch = useDispatch();

  const fetchAiAgents = useCallback(async () => {
    if (!crmOrgId) return;
    try {
      dispatch(setAgentsLoading({ crmOrgId }));
      const agents = await get_agents();
      const orderedAgents = agents.toSorted((a) => (a.type === AgentType.Documentation ? -1 : 1));
      dispatch(
        setAgents({
          crmOrgId,
          agents: orderedAgents,
        }),
      );
    } catch (e) {
      telemetry.captureError(e);
    }
  }, [crmOrgId, dispatch, get_agents]);

  const renameAgent = useCallback(
    async (agentId: string, name: string) => {
      if (!crmOrgId) return;
      try {
        dispatch(renameAgentAction({ crmOrgId, agentId, name }));
        await rename_agent(agentId, { name });
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [crmOrgId, dispatch, rename_agent],
  );
  const deleteAgent = useCallback(
    async (agentId: string) => {
      if (!crmOrgId) return;
      try {
        dispatch(deleteAgentAction({ crmOrgId, agentId }));
        dispatch(deleteChatsForAgentAction({ crmOrgId, agentId }));
        dispatch(setActiveChat({ crmOrgId, chatId: undefined }));
        await delete_agent(agentId);
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [crmOrgId, dispatch, delete_agent],
  );

  const createAgent = useCallback(
    async ({
      name,
      instructions,
      type,
      funnelSteps,
    }: {
      name: string;
      instructions?: string;
      type: AgentType;
      funnelSteps: SelectedStagesInFunnel[];
    }) => {
      if (!crmOrgId) return;
      try {
        //TODO add loading state?
        const agent = await create_agent({ name, instructions, type }); //TODO add funnelSteps when ready in BE
        dispatch(
          addAgent({
            crmOrgId,
            agent: { ...agent, funnelSteps },
          }),
        );
        return agent;
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [crmOrgId, create_agent, dispatch],
  );

  const createAgentAndChat = useCallback(
    async ({
      name,
      selectedStages,
      type,
    }: {
      name: string;
      selectedStages: SelectedStagesInFunnel[];
      type: AgentType;
    }) => {
      if (!crmOrgId) return;
      const newAgent = await createAgent({
        name,
        type,
        funnelSteps: selectedStages,
      });
      if (newAgent) {
        dispatch(createNewChat({ crmOrgId, agentId: newAgent.id, agentType: type }));
      }
      return newAgent;
    },
    [createAgent, crmOrgId, dispatch],
  );

  return {
    agents,
    activeAgent,
    fetchAiAgents,
    activeIsEmptyChat,
    agentsIsLoading,
    renameAgent,
    deleteAgent,
    createAgentAndChat,
  };
};

export default useAiAgents;
