import { ConfigurationType } from '@server/parser';
import { useCallback } from 'react';
import SweepStagesModel from '../../models/stagesModel';
import {
  setSingleObjectName,
  setObjectName,
  setDependenciesConfigurationItem,
} from '../../reducers/documentationReducer';
import {
  DocumentationTabTypes,
  SWEEP_ELEMENTS_TAB_TYPES,
} from '../../types/enums/DocumentationTabTypes';
import {
  StepEntityType,
  StepPillEntityType,
  GroupPillEntityType,
  GroupLabelEntityType,
  NodeEntityTypes,
} from '../multi-canvas/canvasTypes';
import { getDocumentationTabFromCanvasPill } from '../pages/canvas-pages/getDocumentationTabFromCanvasPill';
import { MASTER_KEY } from '../pages/funnel-map-flow/dialogs/import-funnel/utils';
import { DependenciesContentViewType } from './dependencies/types';
import {
  createRecordTypeId,
  createStepId,
  findRTStepFromFunnelStep,
} from './selected-object/filters/utils';
import { useTabFiltersFunctions } from './selected-object/filters/useTabFilterFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCrmOrgObjectTypes } from '../pages/environments/environmentsReducer';
import { selectParsedRecordTypes } from '../../reducers/global/globalReducer';
import { DispatchSingleObjectNameAndFocusOnElementProps } from './types';

interface UseDocumentationCanvasInteractionsProps {
  crmOrgId: string;
  setFilterValues: (
    _filterValues: {
      [filterKey: string]: string[];
    },
    focusOnFilter?: string,
  ) => void;
  focusOnCanvasElements: (
    elementIds: string[],
    options?: {},
    fitAroundFirstStepOnly?: boolean,
  ) => void;
  onSweepElementClick: (props: {
    stepId?: string;
    funnelId?: string;
    recordTypeApiName?: string;
    entity: StepEntityType | StepPillEntityType | GroupPillEntityType | GroupLabelEntityType;
    event: React.MouseEvent;
  }) => void;
}

export const useDocumentationCanvasInteractions = ({
  focusOnCanvasElements,
  setFilterValues,
  onSweepElementClick,
  crmOrgId,
}: UseDocumentationCanvasInteractionsProps) => {
  const { registerPredefinedFilters: registerInternalDocumentationFilters } =
    useTabFiltersFunctions();

  const dispatch = useDispatch();
  const _crmOrgObjects = useSelector(selectCrmOrgObjectTypes(crmOrgId));
  const parsedRecordTypes = useSelector(selectParsedRecordTypes(crmOrgId));

  const dispatchSingleObjectNameAndFocusOnElement = useCallback(
    (props: DispatchSingleObjectNameAndFocusOnElementProps) => {
      const {
        singleObjectName,
        tab,
        focusOnElement = singleObjectName?.objectType,
        fitAroundFirstStepOnly = false,
      } = props;
      if (!tab) {
        dispatch(setObjectName({ objectTypeName: singleObjectName }));
      } else {
        dispatch(setSingleObjectName({ singleObjectName, tab }));
      }

      if (focusOnElement && focusOnCanvasElements) {
        focusOnCanvasElements([focusOnElement], { maxZoom: 0.97 }, fitAroundFirstStepOnly);
      }
    },
    [dispatch, focusOnCanvasElements],
  );

  const registerCanvasFilters = useCallback(
    ({
      selectedStepName,
      selectedRecordTypeValue,
    }: {
      selectedRecordTypeValue: string;
      selectedStepName?: string;
    }) => {
      if (selectedStepName) {
        setFilterValues({
          recordTypes: [selectedRecordTypeValue],
          steps: [selectedStepName],
        });
      } else {
        setFilterValues({ steps: [], recordTypes: [selectedRecordTypeValue] });
      }
    },
    [setFilterValues],
  );

  const onDocumentationEntityClick = useCallback(
    ({
      stepId,
      funnelId,
      recordTypeApiName,
      entity,
      event,
      funnelsData,
      recordTypesData,
    }: {
      stepId?: string;
      funnelId?: string;
      entity: StepEntityType | StepPillEntityType | GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
      recordTypeApiName?: string;
      funnelsData?: FunnelsData;
      recordTypesData?: RecordTypesData;
    }) => {
      const funnel = funnelId ? funnelsData?.[funnelId] : undefined;
      const recordType = recordTypeApiName ? recordTypesData?.[recordTypeApiName] : undefined;

      if (!funnel && !recordType) {
        return;
      }

      if (entity.type === NodeEntityTypes.GroupLabel) {
        const objectName = funnel
          ? funnel.funnelDetails.leadingObject.objectName
          : recordType?.objectName;
        const rtName = recordType?.name ?? funnel?.recordType.name;
        const objectTypeName = _crmOrgObjects?.find((obj) => obj.objectType === objectName);

        if (objectTypeName && rtName) {
          dispatchSingleObjectNameAndFocusOnElement({
            singleObjectName: objectTypeName,
            tab: DocumentationTabTypes.RECORD_TYPES,
            focusOnElement: funnel?.id ?? objectName + '.' + rtName,
            fitAroundFirstStepOnly: true,
          });
          dispatch(
            setDependenciesConfigurationItem({
              id: objectName + '.' + rtName,
              name: rtName,
              dependencyType: ConfigurationType.recordTypes,
              parentType: ConfigurationType.recordTypes,
              objectName,
              clearHistory: true,
              contentType: DependenciesContentViewType.default,
            }),
          );
        }
        return;
      }

      // Support only pill clicks
      if (entity.type !== NodeEntityTypes.StepPill && entity.type !== NodeEntityTypes.GroupPill) {
        return;
      }

      let selectedRecordTypeValue: string | undefined;
      let stage: SweepStage | undefined;
      let selectedStepName: string | undefined;
      let singleObjectApiName = '';

      const defaultActiveTab = getDocumentationTabFromCanvasPill(entity.canvasPillType);

      if (funnel) {
        const recordTypeName = funnel.recordType.name ?? MASTER_KEY;
        selectedRecordTypeValue = createRecordTypeId(recordTypeName, funnel.recordType.objectName);

        const stagesModel = new SweepStagesModel(funnel.funnelDetails?.stages || []);
        stage = stepId ? stagesModel.stageByIdOrUndefined(stepId)?.getStage() : undefined;

        selectedStepName = stage
          ? createStepId(
              recordTypeName,
              funnel.recordType.objectName,
              findRTStepFromFunnelStep(funnel, stage.stageName, parsedRecordTypes)?.name ??
                stage.stageName,
            )
          : undefined;
        singleObjectApiName = funnel.recordType.objectName ?? '';
      }

      if (recordType && !funnel) {
        selectedRecordTypeValue = createRecordTypeId(recordType.name, recordType.objectName);
        selectedStepName = stepId;
        singleObjectApiName = recordType.objectName ?? '';
      }

      if (defaultActiveTab) {
        if (!SWEEP_ELEMENTS_TAB_TYPES.includes(defaultActiveTab)) {
          registerInternalDocumentationFilters({
            selectedStepName,
            defaultActiveTab,
            selectedRecordTypeValue,
            objects: _crmOrgObjects ?? [],
            singleObjectApiName,
          });

          if (selectedRecordTypeValue) {
            //On object change canvas filters don't receive new objectType on time
            //and this is documentation requirement only not to show object filter (what would fix it)
            //setTimeout is a hack-fix
            setTimeout(() => {
              registerCanvasFilters({
                selectedStepName: stepId,
                selectedRecordTypeValue: selectedRecordTypeValue ?? '',
              });
            }, 0);
          }
        } else {
          registerInternalDocumentationFilters({
            defaultActiveTab,
            objects: _crmOrgObjects ?? [],
            singleObjectApiName,
          });

          //On object change canvas filters don't receive new objectType on time
          //and this is documentation requirement only not to show object filter (what would fix it)
          //setTimeout is a hack-fix
          setTimeout(() => {
            onSweepElementClick &&
              onSweepElementClick({
                stepId,
                funnelId,
                recordTypeApiName,
                entity,
                event,
              });
          }, 0);
          return;
        }
      }
    },
    [
      dispatch,
      registerInternalDocumentationFilters,
      _crmOrgObjects,
      onSweepElementClick,
      parsedRecordTypes,
      registerCanvasFilters,
      dispatchSingleObjectNameAndFocusOnElement,
    ],
  );

  return {
    onDocumentationEntityClick,
    registerCanvasFilters,
    dispatchSingleObjectNameAndFocusOnElement,
  };
};
