import { Box, Chip, Tooltip } from '@mui/material';
import { colors, Tag as TagComponent } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { changeSweepFamilyWeight } from '../../../../../lib/sweepColorUtilities';

interface ClickableTagProps {
  label: string;
  color?: string;
  startIconName?: string;
  labelTooltip?: string;
  deleteTooltip?: string;
  onDelete?: () => void;
  onClick?: () => void;
}

//will be moved to design system after talk with designers about details of re-usage
export const ClickableTag = ({
  label,
  color,
  onDelete,
  startIconName,
  onClick,
  labelTooltip,
  deleteTooltip,
}: ClickableTagProps) => {
  if (!onClick) {
    return (
      <TagComponent startIconName={startIconName} label={label} color={color} onDelete={onDelete} />
    );
  }

  const parentColor = color || colors.grey[100];

  return (
    <Box
      sx={{
        background: parentColor,
        display: 'flex',
        alignItems: 'center',
        p: 0,
      }}
    >
      <Tooltip title={labelTooltip} placement="top">
        <Chip
          onClick={onClick}
          label={
            label ? (
              <Box display="flex" alignItems="center" gap={1}>
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {label}
                </Box>
              </Box>
            ) : undefined
          }
          sx={{
            background: color,
            '&:hover': { background: changeSweepFamilyWeight(parentColor, 300) },
            '.MuiChip-label': {
              color: colors.night[900],
            },
          }}
        />
      </Tooltip>

      {onDelete && (
        <Tooltip title={deleteTooltip} placement="top">
          <Box
            className="closeButton"
            sx={{
              '&:hover': { background: changeSweepFamilyWeight(parentColor, 300) },
              padding: 0.5,
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={onDelete}
          >
            <Close variant="tiny" />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
