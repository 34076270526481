import { useMemo } from 'react';
import { ChatShort } from '../common/ai-chat/aiChatTypes';
import { DateTime } from 'luxon';

type ChatSection<T extends ChatShort> = {
  title: string;
  items: T[];
  isCollapsible?: boolean;
};

export function useChatSections<T extends ChatShort>(chats?: T[]) {
  const [pinnedChats, todayChats, lastWeekChats, lastMonthChats, earlierChats] = useMemo(() => {
    const pinnedChats: T[] = [];
    const todayChats: T[] = [];
    const lastWeekChats: T[] = [];
    const lastMonthChats: T[] = [];
    const earlierChats: T[] = [];
    chats?.forEach((chat) => {
      if (chat.isFavorite) {
        pinnedChats.push(chat);
        return;
      }
      const now = DateTime.fromISO(new Date().toISOString());
      const updatedAt = DateTime.fromISO(chat.updatedAt);
      const diff = now.diff(updatedAt).as('days');
      if (diff < 1) {
        todayChats.push(chat);
        return;
      }
      if (diff < 8) {
        lastWeekChats.push(chat);
        return;
      }
      if (diff < 31) {
        lastMonthChats.push(chat);
        return;
      }
      earlierChats.push(chat);
    });
    return [pinnedChats, todayChats, lastWeekChats, lastMonthChats, earlierChats];
  }, [chats]);

  const sections: ChatSection<T>[] = useMemo(() => {
    return [
      { title: 'Pinned', items: pinnedChats, isCollapsible: true },
      { title: 'Today', items: todayChats },
      { title: 'Previous 7 days', items: lastWeekChats },
      { title: 'Previous 30 days', items: lastMonthChats },
      { title: 'Earlier', items: earlierChats },
    ];
  }, [pinnedChats, todayChats, lastWeekChats, lastMonthChats, earlierChats]);

  return sections;
}
