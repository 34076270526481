import { escapeRegexp } from './escapeRegex';

export const highlightMatchInMarkdown = (markdownString: string, searchString?: string) => {
  if (!searchString) {
    return markdownString;
  }
  if (!markdownString) {
    return '';
  }

  const newMarkdownString = markdownString.replace(
    new RegExp(escapeRegexp(searchString), 'gi'),
    '**$&**',
  );

  return newMarkdownString;
};
