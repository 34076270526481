import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  Stack,
} from '@mui/material';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';
import { LogoDevBrand } from '../../apis/facades/useLogoDevApiFacade';
import { LogoDevIcon } from '../common/logo-dev-selector/LogoDevIcon';
import { ActionsMenu, ActionsMenuAction } from '../common/actions-menu/ActionsMenu';
import { useState } from 'react';
import { useThirdPartyFunnelsApi } from './useThirdPartyFunnelsApi';
import { setAddOrImportDialogOpen, setEditingThirdPartyFunnelId } from './thirdPartyFunnelsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DialogLoader } from './DialogLoader';
import { Check } from '@sweep-io/sweep-design/dist/icons';
import { grey } from '@mui/material/colors';
import {
  removeAllTransientFunnelData,
  setTransientFunnel,
} from '../../reducers/multiFunnelFlowNoHistoryReducer';
import {
  addThirdPartyFunnelToCanvas,
  removeThirdPartyFunnelFromCanvas,
  selectIsFunnelMapEmpty,
} from '../../reducers/united-canvas/unitedCanvasReducer';
import { FunnelType } from '../../types/enums/FunnelType';
import { ImportTransientThirdParty } from '../funnel-map-canvas/funnelMapCanvasTypes';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { appRoutes } from '../../constants/appRoutes';

const regularActions = [
  { label: 'Edit', value: 'edit' },
  { label: 'Delete', value: 'delete' },
];

const isAddedToFunnelActions = [
  { label: 'Edit', value: 'edit' },
  { label: 'Remove from canvas', value: 'remove' },
  { label: 'Delete', value: 'delete', disabled: true },
];

const SystemTag = ({ system }: { system?: LogoDevBrand }) => {
  const name = system?.name ?? 'Third party integration';
  return (
    <Stack
      direction="row"
      gap={0.5}
      alignItems="center"
      sx={{ backgroundColor: colors.grey[100], borderRadius: '2px', padding: '2px 4px' }}
    >
      {system && <LogoDevIcon brand={system} size={16} />}
      <Typography variant="caption">{name}</Typography>
    </Stack>
  );
};

const ThirdPartyFunnelItem = ({
  thirdPartyFunnel,
  isAddedToFunnel,
  onActionClick,
  closeDialog,
}: {
  thirdPartyFunnel: ThirdPartyFunnelData;
  isAddedToFunnel: boolean;
  onActionClick: (action: ActionsMenuAction<string>) => void;
  closeDialog: () => void;
}) => {
  const dispatch = useDispatch();
  const titleColor = isAddedToFunnel ? colors.grey[500] : colors.black;
  const isFunnelMapEmpty = useSelector(selectIsFunnelMapEmpty);

  const maybeIsAddedToCanvas = () => {
    if (isAddedToFunnel) {
      return (
        <>
          <Check color={grey[500]} />
          <Typography variant="caption" color={colors.grey[500]}>
            Added to canvas
          </Typography>
        </>
      );
    }
  };

  const addFunnelToCanvas = () => {
    if (isFunnelMapEmpty) {
      dispatch(removeAllTransientFunnelData());
      dispatch(
        addThirdPartyFunnelToCanvas({
          thirdPartyFunnel: thirdPartyFunnel,
          position: { row: 0, column: 0 },
        }),
      );
    } else {
      const transientFunnel: ImportTransientThirdParty = {
        type: FunnelType.THIRD_PARTY,
        importType: 'import',
        name: thirdPartyFunnel.name,
        position: { row: 0, column: 0 },
        data: {
          thirdPartyFunnel,
        },
        isPlacingFunnel: true,
      };
      dispatch(setTransientFunnel(transientFunnel));
    }
    closeDialog();
  };

  const maybeAddToCanvasButton = () => {
    if (isAddedToFunnel) {
      return null;
    }
    return (
      <Button startIconName="Plus" variant="link" size="small" onClick={addFunnelToCanvas}>
        Add to canvas
      </Button>
    );
  };

  return (
    <ListItemButton
      key={thirdPartyFunnel.id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pl: 1,
        pr: 1,
        height: '65px',
      }}
    >
      <Stack gap={1}>
        <Stack direction="row" gap={0.5}>
          <Typography variant="body-medium" color={titleColor}>
            {thirdPartyFunnel.name}
          </Typography>
          <SystemTag system={thirdPartyFunnel.system} />
        </Stack>
        <Typography variant="caption" color={colors.grey[800]}>
          {thirdPartyFunnel.description}
        </Typography>
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        {maybeAddToCanvasButton()}
        {maybeIsAddedToCanvas()}
        <ActionsMenu
          actions={isAddedToFunnel ? isAddedToFunnelActions : regularActions}
          iconSize="small"
          onClick={onActionClick}
        />
      </Stack>
    </ListItemButton>
  );
};

const DeleteBlockedDialog = ({
  onClose,
  funnelName,
  containingFunnelMaps,
}: {
  onClose: () => void;
  funnelName: string;
  containingFunnelMaps: {
    id: string;
    name: string;
  }[];
}) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <Typography variant="h2">Delete 3rd party integration</Typography>
      </DialogTitle>
      <DialogContent sx={{ maxWidth: 500 }}>
        <Typography variant="body">
          {funnelName} is currently being used in other canvases. Remove it from the canvases listed
          below before attempting to delete it again:
          <ul>
            {containingFunnelMaps.map(({ id, name }) => (
              <li key={id}>
                <Button
                  variant="link"
                  onClick={() => {
                    window.open(`${appRoutes.canvasUnited.route}/${id}`, '_blank');
                  }}
                >
                  {name}
                </Button>
              </li>
            ))}
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export const ImportThirdPartyFunnelDialog = ({
  onClose,
  thirdPartyFunnels,
  addedFunnelIds,
}: {
  onClose: () => void;
  thirdPartyFunnels: ThirdPartyFunnelData[];
  addedFunnelIds: string[];
}) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const {
    deleteThirdPartyFunnel,
    loading: tpFunnelsLoading,
    getThirdPartyFunnels,
  } = useThirdPartyFunnelsApi();
  const { openConfirm, onCancel } = useConfirm();

  const loading = tpFunnelsLoading || deleting;

  const renderLoader = () => {
    if (!loading) return null;

    return <DialogLoader />;
  };

  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h2">Add 3rd party funnels</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              size="small"
              startIconName="Plus"
              onClick={() => dispatch(setAddOrImportDialogOpen('add'))}
            >
              Create new
            </Button>
            <IconButton size="small" variant="flat" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            paddingTop: 2,
            width: '700px',
            paddingBottom: 2,
            minHeight: '408px',
            pr: 3,
            pl: 3,
          }}
        >
          <Typography variant="body" color={colors.grey[800]}>
            Add from your existing funnels
          </Typography>
          <List sx={{ ml: -2, mr: -2 }}>
            {thirdPartyFunnels.map((funnel) => (
              <ThirdPartyFunnelItem
                key={funnel.id}
                thirdPartyFunnel={funnel}
                isAddedToFunnel={addedFunnelIds.includes(funnel.id)}
                onActionClick={async (action) => {
                  if (action.value === 'delete') {
                    setDeleting(true);
                    const { success, containingFunnelMaps } = await deleteThirdPartyFunnel(
                      funnel.id,
                    );
                    if (!success && containingFunnelMaps) {
                      await openConfirm(
                        <DeleteBlockedDialog
                          onClose={onCancel}
                          containingFunnelMaps={containingFunnelMaps}
                          funnelName={funnel.name}
                        />,
                      );
                      getThirdPartyFunnels();
                    }
                    setDeleting(false);
                  }
                  if (action.value === 'remove') {
                    dispatch(removeThirdPartyFunnelFromCanvas(funnel.id));
                  }
                  if (action.value === 'edit') {
                    dispatch(setEditingThirdPartyFunnelId(funnel.id));
                  }
                }}
                closeDialog={onClose}
              />
            ))}
          </List>
        </DialogContent>
        {renderLoader()}
      </Dialog>
    </>
  );
};
