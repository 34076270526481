import { Typography } from '@sweep-io/sweep-design';
import { ConfirmDialog } from './ConfirmDialog';
import { DialogProps } from '@mui/material';
import { ReactNode } from 'react';

interface ConfirmDestructiveDialogProps extends DialogProps {
  onConfirm: (v: any) => void;
  onClose: (v: any) => void;
  open: boolean;
  confirmText: string;
  cancelText?: string;
  title: string;
  children?: ReactNode;
}

const ConfirmDestructiveDialog = ({
  open,
  onClose,
  onConfirm,
  confirmText,
  cancelText,
  title,
  children,
  ...dialogProps
}: ConfirmDestructiveDialogProps) => {
  return (
    <ConfirmDialog
      title={title}
      confirmText={confirmText}
      confirmButtonType="error"
      onConfirm={onConfirm}
      isOpen={open}
      handleClose={onClose}
      cancelText={cancelText}
      disableHandleCloseOnConfirm
      PaperProps={{
        sx: {
          width: 400,
        },
      }}
      {...dialogProps}
    >
      <Typography variant="body">{children}</Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDestructiveDialog;
