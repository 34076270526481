import { useCallback, useContext, useMemo } from 'react';
import { OnNodeClickProps, NodeEntityTypes } from '../../multi-canvas/canvasTypes';
import { FunnelMapCanvasInternalCtx } from '../FunnelMapCanvasInternalCtx';
import { UseEventHandlerReturn } from './types';

export const useThirdPartyEventHandler = ({
  setEditingThirdPartyStepId,
}: {
  setEditingThirdPartyStepId: (id: string) => void;
}): UseEventHandlerReturn => {
  const { onThirdPartyLabelClick, onThirdPartyStepClick } = useContext(FunnelMapCanvasInternalCtx);

  const onNodeClick = useCallback(
    ({ entity, parentId, event, id }: OnNodeClickProps) => {
      switch (entity.type) {
        case NodeEntityTypes.GhostStepButton:
          setEditingThirdPartyStepId(id);
          break;
        case NodeEntityTypes.Step:
        case NodeEntityTypes.StepPill: {
          if (!parentId) {
            throw new Error('parentId was not found');
          }
          onThirdPartyStepClick?.({ stepId: id, funnelId: parentId, entity, event });
          break;
        }

        default:
          break;
      }
    },
    [onThirdPartyStepClick, setEditingThirdPartyStepId],
  );

  const onNodeNameChange = useCallback((name: string) => {
    return { isValid: name.length > 0 };
  }, []);

  const onLabelClick = useCallback(
    ({ id, entity, event }: OnNodeClickProps) => {
      if (entity.type === NodeEntityTypes.GroupLabel || entity.type === NodeEntityTypes.GroupPill) {
        onThirdPartyLabelClick?.({ funnelId: id, entity, event });
      }
    },
    [onThirdPartyLabelClick],
  );

  const returnFunctions = useMemo(
    () => ({ onNodeClick, onNodeNameChange, onLabelClick }),
    [onNodeClick, onNodeNameChange, onLabelClick],
  );

  return returnFunctions;
};
