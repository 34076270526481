export type HubspotField = {
  name: string;
  label: string;
  options: { label: string; value: string; displayOrder: number; hidden: boolean }[];
  //properties we currently dont use, but exist on the response:
  type: string;
  fieldType: string;
  description: string;
  groupName: string;
};

export type HubspotForm = { formId: string; name: string };
export type HubspotPage = { pageId: string; name: string };
export type HubspotEmail = { emailId: string; name: string };
export type HubspotMembershipList = { listId: string; name: string };

export enum HubspotEventComponentType {
  FORM = 'FORM',
  PAGE = 'PAGE',
  EMAIL = 'EMAIL',
}

type AbstractHubspotEventComponent = {
  type: HubspotEventComponentType;
};

export interface HubspotEventComponentForm extends AbstractHubspotEventComponent {
  type: HubspotEventComponentType.FORM;
  components: HubspotForm[];
}
export interface HubspotEventComponentPage extends AbstractHubspotEventComponent {
  type: HubspotEventComponentType.PAGE;
  components: HubspotPage[];
}

export interface HubspotEventComponentEmail extends AbstractHubspotEventComponent {
  type: HubspotEventComponentType.EMAIL;
  components: HubspotEmail[];
}

export type HubspotEventComponent =
  | HubspotEventComponentForm
  | HubspotEventComponentPage
  | HubspotEventComponentEmail;
