import * as React from "react";
const SvgCampaignMemberStatusImg = (props) => /* @__PURE__ */ React.createElement("svg", { width: 171, height: 76, viewBox: "0 0 171 76", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { x: 0.25, y: -634766e-8, width: 170, height: 76.0128, rx: 4, fill: "#F6F6F6", style: {
  fill: "#F6F6F6",
  fill: "color(display-p3 0.9647 0.9647 0.9647)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 12.25, y: 13.9956, width: 105.991, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 126.242, y: 11.9937, width: 12.0043, height: 12.0043, rx: 1.50053, fill: "#8FCCFF", style: {
  fill: "#8FCCFF",
  fill: "color(display-p3 0.5608 0.8000 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M135.995 15.3696L130.994 20.6215L128.493 17.9956", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.1254, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("rect", { x: 146.621, y: 12.3688, width: 11.254, height: 11.254, rx: 1.1254, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 146.621, y: 12.3688, width: 11.254, height: 11.254, rx: 1.1254, stroke: "#A9A9A9", style: {
  stroke: "#A9A9A9",
  stroke: "color(display-p3 0.6625 0.6625 0.6625)",
  strokeOpacity: 1
}, strokeWidth: 0.750266 }), /* @__PURE__ */ React.createElement("rect", { x: 12.25, y: 34, width: 105.991, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 126.617, y: 32.3732, width: 11.254, height: 11.254, rx: 1.1254, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 126.617, y: 32.3732, width: 11.254, height: 11.254, rx: 1.1254, stroke: "#A9A9A9", style: {
  stroke: "#A9A9A9",
  stroke: "color(display-p3 0.6625 0.6625 0.6625)",
  strokeOpacity: 1
}, strokeWidth: 0.750266 }), /* @__PURE__ */ React.createElement("rect", { x: 146.246, y: 31.998, width: 12.0043, height: 12.0043, rx: 1.50053, fill: "#8FCCFF", style: {
  fill: "#8FCCFF",
  fill: "color(display-p3 0.5608 0.8000 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.999 35.374L150.997 40.6259L148.497 38", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.1254, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("rect", { x: 12.25, y: 54.0039, width: 105.991, height: 8, rx: 2, fill: "#DBDBDB", style: {
  fill: "#DBDBDB",
  fill: "color(display-p3 0.8588 0.8588 0.8588)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 126.617, y: 52.3771, width: 11.254, height: 11.254, rx: 1.1254, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 126.617, y: 52.3771, width: 11.254, height: 11.254, rx: 1.1254, stroke: "#A9A9A9", style: {
  stroke: "#A9A9A9",
  stroke: "color(display-p3 0.6625 0.6625 0.6625)",
  strokeOpacity: 1
}, strokeWidth: 0.750266 }), /* @__PURE__ */ React.createElement("rect", { x: 146.246, y: 52.002, width: 12.0043, height: 12.0043, rx: 1.50053, fill: "#8FCCFF", style: {
  fill: "#8FCCFF",
  fill: "color(display-p3 0.5608 0.8000 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.999 55.3779L150.997 60.6298L148.497 58.0039", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.1254, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgCampaignMemberStatusImg;
