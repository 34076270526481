import DialogOnCanvas from '../canvas-pages/DialogOnCanvas';
import { PageTemplateDialogContent } from './PageTemplateDialogContent';
import { useExpandedMode } from '../configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';

export const PageTemplateCanvasDialog = ({
  children,
  isOpen,
  onCancel,
  title,
  transitionDuration,
}: {
  isOpen: boolean;
  onCancel?: () => any;
  children: React.ReactNode;
  title: string;
  transitionDuration?: number;
}) => {
  const { isExpandedMode } = useExpandedMode();

  return (
    <DialogOnCanvas
      open={isOpen}
      onClose={onCancel}
      blockFully={isExpandedMode}
      forceWideScreen={isExpandedMode}
      transitionDuration={transitionDuration}
    >
      <PageTemplateDialogContent title={title} onCancel={onCancel}>
        {children}
      </PageTemplateDialogContent>
    </DialogOnCanvas>
  );
};
