import _ from 'lodash';
import { keyBy } from 'lodash';
import { SELECTOR_VALUE_SEPARATOR } from '../../constants';
import { SelectorValueTypes } from '../../types/enums/SelectorValueTypes';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';

export const enum SweepFieldOperator {
  ANY = 'ANY',
  EQUALS = 'EQUALS',
  NOT_EQUAL_TO = 'NOT_EQUAL_TO',
  IS_NULL_FALSE = 'IS_NULL_FALSE',
  IS_NULL_TRUE = 'IS_NULL_TRUE',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  GREATER_THAN = 'GREATER_THAN',
  LOWER_THAN = 'LOWER_THAN',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
  LOWER_OR_EQUAL = 'LOWER_OR_EQUAL',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  IS_CHANGED_TRUE = 'IS_CHANGED_TRUE',
  IS_CHANGED_FALSE = 'IS_CHANGED_FALSE',
  HAS_INCREASED = 'HAS_INCREASED',
  HAS_DECREASED = 'HAS_DECREASED',
  IN_LAST_X = 'IN_LAST_X',
  IN_NEXT_X = 'IN_NEXT_X',
  GREATER_THAN_X = 'GREATER_THAN_X',
  GREATER_THAN_X_AGO = 'GREATER_THAN_X_AGO',
  IS_CONTAINED_IN = 'IS_CONTAINED_IN',
  IS_NOT_CONTAINED_IN = 'IS_NOT_CONTAINED_IN',
  IN_LIST = 'IN_LIST',
  NOT_IN_LIST = 'NOT_IN_LIST',
  MAX = 'MAX',
  MIN = 'MIN',
  PRIORITIZE = 'PRIORITIZE',
  CONCATENATE = 'CONCATENATE',
  SAME_RECORD_AS = 'SAME_RECORD_AS',
  MASTER_RECORD_VALUE = 'MASTER_RECORD_VALUE',
  EXACT = 'EXACT',
  FUZZY = 'FUZZY',
  DATES_APART = 'DATES_APART',
  NOT_START_WITH = 'NOT_START_WITH',
  NOT_END_WITH = 'NOT_END_WITH',
  DISTANCE = 'DISTANCE',
}

export const enum ApiSweepFieldOperator {
  ANY = 'ANY',
  EQUALS = 'EQUALS',
  NOT_EQUAL_TO = 'NOT_EQUAL_TO',
  IS_NULL = 'IS_NULL',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  GREATER_THAN = 'GREATER_THAN',
  LOWER_THAN = 'LOWER_THAN',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
  LOWER_OR_EQUAL = 'LOWER_OR_EQUAL',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  IS_CHANGED = 'IS_CHANGED',
  HAS_INCREASED = 'HAS_INCREASED',
  HAS_DECREASED = 'HAS_DECREASED',
  IN_LAST_X = 'IN_LAST_X',
  IN_NEXT_X = 'IN_NEXT_X',
  GREATER_THAN_X = 'GREATER_THAN_X',
  GREATER_THAN_X_AGO = 'GREATER_THAN_X_AGO',
  IS_CONTAINED_IN = 'IS_CONTAINED_IN',
  IS_NOT_CONTAINED_IN = 'IS_NOT_CONTAINED_IN',
  IN_LIST = 'IN_LIST',
  NOT_IN_LIST = 'NOT_IN_LIST',
  MAX = 'MAX',
  MIN = 'MIN',
  PRIORITIZE = 'PRIORITIZE',
  CONCATENATE = 'CONCATENATE',
  SAME_RECORD_AS = 'SAME_RECORD_AS',
  MASTER_RECORD_VALUE = 'MASTER_RECORD_VALUE',
  EXACT = 'EXACT',
  FUZZY = 'FUZZY',
  DATES_APART = 'DATES_APART',
  NOT_START_WITH = 'NOT_START_WITH',
  NOT_END_WITH = 'NOT_END_WITH',
  DISTANCE = 'DISTANCE',
}

export type SweepFieldOperatorsValues = 'true' | 'false' | undefined;

type SweepFieldOperatorsMap = {
  [key in SweepFieldOperator]: {
    apiOperator: ApiSweepFieldOperator;
    value?: SweepFieldOperatorsValues;
  };
};

const sweepFieldOperatorToApiSweepOperatorWithValueMapping: SweepFieldOperatorsMap = {
  [SweepFieldOperator.ANY]: {
    apiOperator: ApiSweepFieldOperator.ANY,
    value: 'true',
  },
  [SweepFieldOperator.EQUALS]: {
    apiOperator: ApiSweepFieldOperator.EQUALS,
  },
  [SweepFieldOperator.NOT_EQUAL_TO]: {
    apiOperator: ApiSweepFieldOperator.NOT_EQUAL_TO,
  },
  [SweepFieldOperator.IS_NULL_TRUE]: {
    apiOperator: ApiSweepFieldOperator.IS_NULL,
    value: 'true',
  },
  [SweepFieldOperator.IS_NULL_FALSE]: {
    apiOperator: ApiSweepFieldOperator.IS_NULL,
    value: 'false',
  },
  [SweepFieldOperator.STARTS_WITH]: {
    apiOperator: ApiSweepFieldOperator.STARTS_WITH,
  },
  [SweepFieldOperator.ENDS_WITH]: {
    apiOperator: ApiSweepFieldOperator.ENDS_WITH,
  },
  [SweepFieldOperator.CONTAINS]: {
    apiOperator: ApiSweepFieldOperator.CONTAINS,
  },
  [SweepFieldOperator.DOES_NOT_CONTAIN]: {
    apiOperator: ApiSweepFieldOperator.DOES_NOT_CONTAIN,
  },
  [SweepFieldOperator.GREATER_THAN]: {
    apiOperator: ApiSweepFieldOperator.GREATER_THAN,
  },
  [SweepFieldOperator.LOWER_THAN]: {
    apiOperator: ApiSweepFieldOperator.LOWER_THAN,
  },
  [SweepFieldOperator.GREATER_OR_EQUAL]: {
    apiOperator: ApiSweepFieldOperator.GREATER_OR_EQUAL,
  },
  [SweepFieldOperator.LOWER_OR_EQUAL]: {
    apiOperator: ApiSweepFieldOperator.LOWER_OR_EQUAL,
  },
  [SweepFieldOperator.BEFORE]: {
    apiOperator: ApiSweepFieldOperator.BEFORE,
  },
  [SweepFieldOperator.AFTER]: {
    apiOperator: ApiSweepFieldOperator.AFTER,
  },
  [SweepFieldOperator.IS_CHANGED_TRUE]: {
    apiOperator: ApiSweepFieldOperator.IS_CHANGED,
    value: 'true',
  },
  [SweepFieldOperator.IS_CHANGED_FALSE]: {
    apiOperator: ApiSweepFieldOperator.IS_CHANGED,
    value: 'false',
  },
  [SweepFieldOperator.HAS_INCREASED]: {
    apiOperator: ApiSweepFieldOperator.HAS_INCREASED,
    value: 'true',
  },
  [SweepFieldOperator.HAS_DECREASED]: {
    apiOperator: ApiSweepFieldOperator.HAS_DECREASED,
    value: 'true',
  },
  [SweepFieldOperator.IN_LAST_X]: {
    apiOperator: ApiSweepFieldOperator.IN_LAST_X,
  },
  [SweepFieldOperator.IN_NEXT_X]: {
    apiOperator: ApiSweepFieldOperator.IN_NEXT_X,
  },
  [SweepFieldOperator.GREATER_THAN_X]: {
    apiOperator: ApiSweepFieldOperator.GREATER_THAN_X,
  },
  [SweepFieldOperator.GREATER_THAN_X_AGO]: {
    apiOperator: ApiSweepFieldOperator.GREATER_THAN_X_AGO,
  },
  [SweepFieldOperator.IS_CONTAINED_IN]: {
    apiOperator: ApiSweepFieldOperator.IS_CONTAINED_IN,
  },
  [SweepFieldOperator.IS_NOT_CONTAINED_IN]: {
    apiOperator: ApiSweepFieldOperator.IS_NOT_CONTAINED_IN,
  },
  [SweepFieldOperator.IN_LIST]: {
    apiOperator: ApiSweepFieldOperator.IN_LIST,
  },
  [SweepFieldOperator.NOT_IN_LIST]: {
    apiOperator: ApiSweepFieldOperator.NOT_IN_LIST,
  },
  [SweepFieldOperator.MAX]: {
    apiOperator: ApiSweepFieldOperator.MAX,
    value: 'true',
  },
  [SweepFieldOperator.MIN]: {
    apiOperator: ApiSweepFieldOperator.MIN,
    value: 'true',
  },
  [SweepFieldOperator.PRIORITIZE]: {
    apiOperator: ApiSweepFieldOperator.PRIORITIZE,
  },
  [SweepFieldOperator.CONCATENATE]: {
    apiOperator: ApiSweepFieldOperator.CONCATENATE,
  },
  [SweepFieldOperator.SAME_RECORD_AS]: {
    apiOperator: ApiSweepFieldOperator.SAME_RECORD_AS,
  },
  [SweepFieldOperator.MASTER_RECORD_VALUE]: {
    apiOperator: ApiSweepFieldOperator.MASTER_RECORD_VALUE,
  },
  [SweepFieldOperator.EXACT]: {
    apiOperator: ApiSweepFieldOperator.EXACT,
  },
  [SweepFieldOperator.FUZZY]: {
    apiOperator: ApiSweepFieldOperator.FUZZY,
  },
  [SweepFieldOperator.DATES_APART]: {
    apiOperator: ApiSweepFieldOperator.DATES_APART,
  },
  [SweepFieldOperator.NOT_START_WITH]: {
    apiOperator: ApiSweepFieldOperator.NOT_START_WITH,
  },
  [SweepFieldOperator.NOT_END_WITH]: {
    apiOperator: ApiSweepFieldOperator.NOT_END_WITH,
  },
  [SweepFieldOperator.DISTANCE]: {
    apiOperator: ApiSweepFieldOperator.DISTANCE,
  },
};

const operators: {
  [_operator: string]: OperatorWithIndex;
} = {
  any: {
    label: 'To any value',
    operator: SweepFieldOperator.ANY,
    groupIndex: 0,
  },
  is: {
    label: 'Is',
    operator: SweepFieldOperator.EQUALS,
    groupIndex: 0,
  },
  isNot: {
    label: 'Is not',
    operator: SweepFieldOperator.NOT_EQUAL_TO,
    groupIndex: 0,
  },
  isEmpty: {
    label: 'Is empty',
    operator: SweepFieldOperator.IS_NULL_TRUE,
    groupIndex: 1,
  },
  isNotEmpty: {
    label: 'Is not empty',
    operator: SweepFieldOperator.IS_NULL_FALSE,
    groupIndex: 1,
  },
  startsWith: {
    label: 'Starts with',
    operator: SweepFieldOperator.STARTS_WITH,
    groupIndex: 2,
  },
  endsWith: {
    label: 'Ends with',
    operator: SweepFieldOperator.ENDS_WITH,
    groupIndex: 2,
  },
  contains: {
    label: 'Contains',
    operator: SweepFieldOperator.CONTAINS,
    groupIndex: 2,
  },
  doesNotContain: {
    label: 'Does not contain',
    operator: SweepFieldOperator.DOES_NOT_CONTAIN,
    groupIndex: 2,
  },
  greaterThan: {
    label: 'Greater than',
    operator: SweepFieldOperator.GREATER_THAN,
    groupIndex: 3,
  },
  lowerThan: {
    label: 'Less than',
    operator: SweepFieldOperator.LOWER_THAN,
    groupIndex: 3,
  },
  greaterOrEqual: {
    label: 'Greater than or equal',
    operator: SweepFieldOperator.GREATER_OR_EQUAL,
    groupIndex: 3,
  },
  lowerOrEqual: {
    label: 'Less than or equal',
    operator: SweepFieldOperator.LOWER_OR_EQUAL,
    groupIndex: 3,
  },
  before: {
    label: 'Before',
    operator: SweepFieldOperator.BEFORE,
    groupIndex: 4,
  },
  after: {
    label: 'After',
    operator: SweepFieldOperator.AFTER,
    groupIndex: 4,
  },
  equals: {
    label: 'Equals',
    operator: SweepFieldOperator.EQUALS,
    groupIndex: 3,
  },
  doesNotEqual: {
    label: 'Does not equal',
    operator: SweepFieldOperator.NOT_EQUAL_TO,
    groupIndex: 3,
  },
  isChanged: {
    label: 'Has changed',
    operator: SweepFieldOperator.IS_CHANGED_TRUE,
    groupIndex: 5,
  },
  isNotChanged: {
    label: 'Is not changed',
    operator: SweepFieldOperator.IS_CHANGED_FALSE,
    groupIndex: 5,
  },
  hasIncreased: {
    label: 'Has increased',
    operator: SweepFieldOperator.HAS_INCREASED,
    groupIndex: 5,
  },
  hasDecreased: {
    label: 'Has decreased',
    operator: SweepFieldOperator.HAS_DECREASED,
    groupIndex: 5,
  },
  inLastX: {
    label: 'Is in the last X days',
    operator: SweepFieldOperator.IN_LAST_X,
    groupIndex: 4,
  },
  inNextX: {
    label: 'Is in the next X days',
    operator: SweepFieldOperator.IN_NEXT_X,
    groupIndex: 4,
  },
  greaterThanX: {
    label: 'Is more than X days from today',
    operator: SweepFieldOperator.GREATER_THAN_X,
    groupIndex: 4,
  },
  greaterThanXAgo: {
    label: 'Is more than X days ago',
    operator: SweepFieldOperator.GREATER_THAN_X_AGO,
    groupIndex: 4,
  },
  isContainedIn: {
    label: 'Is contained in',
    operator: SweepFieldOperator.IS_CONTAINED_IN,
    groupIndex: 6,
  },
  isNotContainedIn: {
    label: 'Is not contained in',
    operator: SweepFieldOperator.IS_NOT_CONTAINED_IN,
    groupIndex: 6,
  },
  inList: {
    label: 'In list',
    operator: SweepFieldOperator.IN_LIST,
    groupIndex: 6,
  },
  notInList: {
    label: 'Not in list',
    operator: SweepFieldOperator.NOT_IN_LIST,
    groupIndex: 6,
  },
  doesNotStartWith: {
    label: 'Does not start with',
    operator: SweepFieldOperator.NOT_START_WITH,
    groupIndex: 2,
  },
  doesNotEndWith: {
    label: 'Does not end with',
    operator: SweepFieldOperator.NOT_END_WITH,
    groupIndex: 2,
  },
};

export const allOperators = Array.from(new Set(_.flatMap(operators)));

export const sweepFieldTypesOperatorsMapping: {
  [fieldType in SweepFieldTypes]?: OperatorWithIndex[];
} = {
  [SweepFieldTypes.Picklist]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.contains,
    operators.doesNotContain,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
  ],
  [SweepFieldTypes.Combobox]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.contains,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.doesNotContain,
  ],
  [SweepFieldTypes.Text]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.FormulaText]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.Lookup]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.LongTextArea]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.TextArea]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.Number]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.greaterThan,
    operators.lowerThan,
    operators.greaterOrEqual,
    operators.lowerOrEqual,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.hasIncreased,
    operators.hasDecreased,
  ],
  [SweepFieldTypes.Summary]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.greaterThan,
    operators.lowerThan,
    operators.greaterOrEqual,
    operators.lowerOrEqual,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.hasIncreased,
    operators.hasDecreased,
  ],
  [SweepFieldTypes.FormulaNumber]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.greaterThan,
    operators.lowerThan,
    operators.greaterOrEqual,
    operators.lowerOrEqual,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.hasIncreased,
    operators.hasDecreased,
  ],
  [SweepFieldTypes.Phone]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.Date]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.before,
    operators.after,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.inLastX,
    operators.inNextX,
    operators.greaterThanX,
    operators.greaterThanXAgo,
  ],
  [SweepFieldTypes.FormulaDate]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.before,
    operators.after,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.inLastX,
    operators.inNextX,
    operators.greaterThanX,
    operators.greaterThanXAgo,
  ],
  [SweepFieldTypes.DateTime]: [
    operators.any,
    operators.before,
    operators.after,
    operators.equals,
    operators.doesNotEqual,
    operators.greaterThan,
    operators.lowerThan,
    operators.greaterOrEqual,
    operators.lowerOrEqual,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.inLastX,
    operators.inNextX,
    operators.greaterThanX,
    operators.greaterThanXAgo,
  ],
  [SweepFieldTypes.FormulaDateTime]: [
    operators.any,
    operators.before,
    operators.after,
    operators.equals,
    operators.doesNotEqual,
    operators.greaterThan,
    operators.lowerThan,
    operators.greaterOrEqual,
    operators.lowerOrEqual,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.inLastX,
    operators.inNextX,
    operators.greaterThanX,
    operators.greaterThanXAgo,
  ],
  [SweepFieldTypes.Time]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.before,
    operators.after,
    operators.isEmpty,
    operators.isNotEmpty,
  ],
  [SweepFieldTypes.FormulaTime]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.before,
    operators.after,
    operators.isEmpty,
    operators.isNotEmpty,
  ],
  [SweepFieldTypes.Checkbox]: [operators.any, operators.is, operators.isChanged],
  [SweepFieldTypes.FormulaCheckbox]: [operators.any, operators.is, operators.isChanged],
  [SweepFieldTypes.Email]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.Percent]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.greaterThan,
    operators.lowerThan,
    operators.greaterOrEqual,
    operators.lowerOrEqual,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.hasIncreased,
    operators.hasDecreased,
  ],
  [SweepFieldTypes.FormulaPercent]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.greaterThan,
    operators.lowerThan,
    operators.greaterOrEqual,
    operators.lowerOrEqual,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.hasIncreased,
    operators.hasDecreased,
  ],
  [SweepFieldTypes.Url]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.Currency]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.greaterThan,
    operators.lowerThan,
    operators.greaterOrEqual,
    operators.lowerOrEqual,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.hasIncreased,
    operators.hasDecreased,
  ],
  [SweepFieldTypes.FormulaCurrency]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.greaterThan,
    operators.lowerThan,
    operators.greaterOrEqual,
    operators.lowerOrEqual,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.hasIncreased,
    operators.hasDecreased,
  ],
  [SweepFieldTypes.MultiselectPicklist]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
    operators.contains,
    operators.startsWith,
    operators.endsWith,
    operators.doesNotContain,
  ],
  [SweepFieldTypes.EncryptedText]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.Html]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.Id]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.Hierarchy]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.MasterDetail]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.isChanged,
  ],
  [SweepFieldTypes.Address]: [operators.isContainedIn, operators.isNotContainedIn],
  [SweepFieldTypes.Territory]: [
    operators.any,
    operators.is,
    operators.isNot,
    operators.startsWith,
    operators.doesNotStartWith,
    operators.endsWith,
    operators.doesNotEndWith,
    operators.contains,
    operators.doesNotContain,
    operators.isEmpty,
    operators.isNotEmpty,
    operators.inList,
    operators.notInList,
  ],
};

export const sweepFieldOperatorToApiSweepOperatorWithValue = (
  sweepFieldOperator: SweepFieldOperator,
): {
  apiOperator: ApiSweepFieldOperator;
  value?: SweepFieldOperatorsValues;
} => sweepFieldOperatorToApiSweepOperatorWithValueMapping[sweepFieldOperator];

export const apiSweepOperatorWithValueToSweepFieldOperator = (
  apiSweepFieldOperator: ApiSweepFieldOperator,
  value?: SweepFieldOperatorsValues,
) =>
  Object.keys(sweepFieldOperatorToApiSweepOperatorWithValueMapping).find((key) => {
    const sweepApiOperatorWithValue =
      sweepFieldOperatorToApiSweepOperatorWithValueMapping[key as SweepFieldOperator];
    const matchesOperators = sweepApiOperatorWithValue.apiOperator === apiSweepFieldOperator;

    const matchesValuesIfApplicable =
      !sweepApiOperatorWithValue.value ||
      (sweepApiOperatorWithValue.value && sweepApiOperatorWithValue.value === value);

    return matchesOperators && matchesValuesIfApplicable;
  }) as SweepFieldOperator;

export const operatorHasAutomaticValue = (operator: ApiSweepFieldOperator) =>
  [
    ApiSweepFieldOperator.IS_NULL,
    ApiSweepFieldOperator.IS_CHANGED,
    ApiSweepFieldOperator.HAS_DECREASED,
    ApiSweepFieldOperator.HAS_INCREASED,
    ApiSweepFieldOperator.ANY,
    ApiSweepFieldOperator.MAX,
    ApiSweepFieldOperator.MIN,
    ApiSweepFieldOperator.MASTER_RECORD_VALUE,
  ].includes(operator);

export const sweepFieldOperatorToLabel = (sweepFieldOperator?: SweepFieldOperator) => {
  if (!sweepFieldOperator) {
    return '';
  }
  const operatorLabels = keyBy(
    Object.keys(operators).map((_operator: string) => operators[_operator]),
    'operator',
  );

  return operatorLabels[sweepFieldOperator].label;
};

export const humanReadableCriterion = (
  criterion: SweepCriterionWithLabels,
  valueMapping?: any[],
) => {
  let { value } = criterion;
  const { valueType, _valueLabels, fieldType, operator } = criterion;

  if (_valueLabels && valueType === SelectorValueTypes.REFERENCE) {
    value = _valueLabels.join(SELECTOR_VALUE_SEPARATOR);
  }
  if (fieldType === SweepFieldTypes.Address && valueMapping) {
    const addressValue = valueMapping.find((el) => el.id === value);
    value = addressValue?.name ?? '<Territory was deleted>';
  }

  const operatorsToShow: { label: string; operator: SweepFieldOperator }[] =
    (fieldType && sweepFieldTypesOperatorsMapping[fieldType as SweepFieldTypes]) || allOperators;

  const sweepFieldOperator = operator
    ? apiSweepOperatorWithValueToSweepFieldOperator(
        operator as ApiSweepFieldOperator,
        value as SweepFieldOperatorsValues,
      )
    : undefined;

  const __operator = operatorsToShow.find((_operator) => sweepFieldOperator === _operator.operator);

  const showValue = __operator?.operator
    ? sweepFieldOperatorToApiSweepOperatorWithValue(__operator?.operator as SweepFieldOperator)
        .value === undefined
    : false;

  const keyValue = criterion?.keyValue ?? criterion._fieldLabels[criterion._fieldLabels.length - 1];

  return `${keyValue} ${__operator?.label} ${showValue ? value : ''}`;
};

export const humanReadableCriteria = (
  criteria?: SweepCriterionWithLabels[],
  criteriaLogic?: string,
  valueMapping?: any[], // valueMapping is used to add descriptive data to object (like Territory where we only have the id)
) => {
  if (!criteria || !criteriaLogic) {
    return '';
  }

  return (
    criteriaLogic.match(/\d+/g)?.reduce((acc, curr) => {
      return acc.replace(curr, humanReadableCriterion(criteria[parseInt(curr) - 1], valueMapping));
    }, criteriaLogic) || ''
  );
};
