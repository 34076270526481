import { Box, Stack, TextField, Typography } from '@mui/material';
import { WorkflowActionEditRecordProperty } from '../types';
import { HubspotFieldSelector } from '../hubspot-rule-builder/HubspotFieldSelector';
import { HsFilterOperatorsOperators, HubspotField } from '../hubspot.types';
import { HubspotFieldsValueSelector } from '../hubspot-rule-builder/HubspotFieldValueSelector';
import { useSelector } from 'react-redux';
import { selectHubspotFieldByName } from '../../../../../reducers/hubspotReducer';
import { hsValueToSweepConditionValue } from '../hubspot-rule-builder/utils';

import { AddAndDeleteRowButtons } from '../../../../common/AddAndDeleteRowButtons';

const filterByDisabledAndFieldsSetByHubspot = (field: HubspotField) =>
  !field.modificationMetadata.readOnlyValue &&
  !field.name.startsWith('hs_time_to_move') &&
  !field.name.startsWith('hs_time_between');

export const UpdateContactProperty = ({
  property,
  onChange,
  readOnly,
  onAddProperty,
  onRemoveProperty,
}: {
  property: WorkflowActionEditRecordProperty;
  onChange: (property: WorkflowActionEditRecordProperty) => void;
  readOnly?: boolean;
  onAddProperty?: () => void;
  onRemoveProperty?: () => void;
}) => {
  const hsField = useSelector(selectHubspotFieldByName(property.name || ''));
  const hsPropertyType = hsField?.type;
  const hsPropertyFieldType = hsField?.fieldType;
  const options = hsField?.options || [];

  const maybeRenderValueSelector = () => {
    if (property.name && hsPropertyType) {
      return (
        <HubspotFieldsValueSelector
          hsPropertyType={hsPropertyType}
          hsPropertyFieldType={hsPropertyFieldType}
          value={property.value}
          disabled={readOnly}
          onChange={(value) => {
            onChange({ ...property, value: hsValueToSweepConditionValue(value) });
          }}
          operator={HsFilterOperatorsOperators.IS_EXACTLY}
          options={options}
        />
      );
    } else {
      return (
        <Box width="246px">
          <TextField disabled={true} fullWidth />
        </Box>
      );
    }
  };

  return (
    <Stack direction="row" spacing={1} width="800px" alignItems={'center'}>
      <Box width={'246px'}>
        <HubspotFieldSelector
          onChange={(hsField) => {
            onChange({
              _id: property._id,
              name: hsField.value,
              value: '',
            });
          }}
          value={property.name}
          disabled={readOnly}
          filterBy={filterByDisabledAndFieldsSetByHubspot}
          disabledTooltip="This field is read-only"
        />
      </Box>
      <Typography variant="body">to</Typography>
      <Box width={'246px'}>{maybeRenderValueSelector()}</Box>
      <AddAndDeleteRowButtons
        addButtonDisabled={readOnly}
        deleteButtonDisabled={readOnly}
        onAdd={onAddProperty}
        onDelete={onRemoveProperty}
      />
    </Stack>
  );
};
