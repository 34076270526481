import { Box, Card } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { FunnelMapPreview } from './FunnelMapPreview';
import { FunnelMapMetaInfo } from './FunnelMapMetaInfo';
import { UNTITLED_FUNNEL_MAP_NAME } from '../../../../constants';
import { CanvasMode } from '../../../multi-canvas/canvasTypes';
import { ReactNode, useState } from 'react';

//Keep this component without logic
export const FunnelMapCardBase = ({
  funnelMap,
  isDeployment,
  onClick,
  isActive,
  hasRequestedChanges,
  isPinned,
  onHoverToggle,
  canvasMode,
  children,
}: {
  funnelMap: FunnelMap;
  onClick: () => void;
  onHoverToggle?: (isHovered: boolean) => void;
  isDeployment?: boolean;
  isPinned: boolean;
  isActive: boolean;
  hasRequestedChanges: boolean;
  canvasMode: CanvasMode;
  children?: ReactNode;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const previewAdditionalSx = isPinned
    ? {
        borderBottom: 'none',
        height: '258px',
      }
    : {};

  const onHover = (isHovered: boolean) => {
    setIsHovered(isHovered);
    onHoverToggle && onHoverToggle(isHovered);
  };

  return (
    <Card
      variant={'outlined'}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      className={isActive ? 'active' : ''}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: `1px solid ${colors.grey[300]}`,
        border: isPinned ? `8px solid ${isHovered ? colors.storm[50] : colors.grey[100]}` : 'none',

        '& .react-flow__node': {
          cursor: 'pointer',
        },

        '&:hover, &.active': {
          outlineColor: colors.blue[500],
          borderColor: colors.storm[50], //to override Mui default
        },
      }}
      onClick={onClick}
    >
      <Box sx={{ padding: '0', position: 'relative' }}>
        <FunnelMapPreview
          funnelMap={funnelMap}
          showPreviewBackground={!isPinned && (isHovered || isActive)}
          canvasMode={canvasMode}
          sxProps={{
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            ...previewAdditionalSx,
          }}
        />
      </Box>

      <Box sx={{ zIndex: '1', width: '100%', padding: 2, height: '64px' }}>
        <FunnelMapMetaInfo
          currentVersion={
            {
              updatedAt: funnelMap.updatedAt,
              updatedById: funnelMap.updatedById,
            } as CurrentVersion
          }
          name={isPinned ? undefined : funnelMap.name || UNTITLED_FUNNEL_MAP_NAME}
          isDeployment={!!isDeployment}
          hasRequestedChanges={hasRequestedChanges}
        />
      </Box>
      {children}
    </Card>
  );
};
