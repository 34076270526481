import { Box, FormControlLabel, Checkbox, Divider } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AutomationDataActionType, AutomationTypes } from '../../types/enums/AutomationTypes';
import StyledTooltip from '../common/StyledTooltip';
import { AutomationActionType } from '../../types/enums/AutomationActionType';
import { AutomationsCreateEditDialogHeader } from '../common/create-edit-dialog/AutomationsCreateEditDialogHeader';
import { useIsPristine } from '../../hooks/useIsPristine';
import { Typography, colors, IconButton } from '@sweep-io/sweep-design';
import usePermission from '../common/permissions/usePermission';
import AlertPermission from '../common/AlertPermission';
import { AutomationActionPopupMenu } from './AutomationActionPopupMenu';
import { CustomHeader } from '../common/rule-builder/CustomHeader';
import { SweepFieldsRuleBuilder } from '../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import {
  useRefForMultipleRuleBuilders,
  useValidateAllAndReturnIsValid,
} from '../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import {
  FormulaEditorStateContextProvider,
  useFormulaEditorState,
} from './FormulaEditorStateContext';
import { SelectorValueTypes } from '../../types/enums/SelectorValueTypes';
import { clearFirstConditionIfEmpty } from '../common/rule-builder/helpers';
import { TimeSelector } from './Triggers/TimeSelector';
import FieldLabel from '../common/FieldLabel';
import { Delete as DeleteListIcon } from '@sweep-io/sweep-design/dist/icons';
import { ObjectTypeChip } from '../common/ObjectTypeChip';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { AUTOMATIONS_LAYOUT_MAX_WIDTH, getIsScheduleValid, validateAnAction } from './helper';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDefaultTriggers } from './AutomationDefaultTriggers';
import { AutomationScheduledAssignmentTriggers } from './AutomationScheduledAssignmentTriggers';
import { SalesforceReportSelector } from './SalesforceReportSelector';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { useSelector } from 'react-redux';
import { selectSlackIntegrationStatus } from '../../reducers/integrationsReducer';
import { AutomationType } from '../../types/enums/AutomationType';
import { AutomationsActionsWrapper } from './AutomationsActionsWrapper';
import { useAutomationsContext } from './AutomationsContext';
import { AutomationPermissionModeSelector } from './AutomationPermissionModeSelector';
import {
  useValueFromAnotherObjectEditorState,
  ValueFromAnotherObjectEditorStateContextProvider,
} from './ValueFromAnotherObjectEditorStateContext';

export const labelBoxDesign = {
  width: 'fit-content',
  borderRadius: '4px',
  height: '24px',
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: '0 8px',
};

interface AutomationFormProps {
  onSaveOrCreate: (
    automationJson: AutomationStructureNew,
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  onBackButtonClick: () => any;
  crmOrgId: string;
  onPristineChange: (pristine: boolean) => any;
  readonly?: boolean;
  objectName: ObjectTypeValues;
  recordTypesAndFunnelsDataForObject?: ParsedRecordTypesAndFunnelsObjectData;
  filterFunnelIds?: string[];
  isDoneLoading: boolean;
}

export type PartialAutomationStructure = DeepPartial<AutomationStructureNew>;

function helperShowFieldContextMenu(
  automationVariant: AutomationFormVariant,
  automationJson: DeepPartial<AutomationStructureNew>,
): boolean | undefined {
  return (
    automationVariant !== AutomationFormVariant.SCHEDULED_ASSIGNMENTS &&
    (automationJson.automationDetails?.triggerType === AutomationTypes.OnUpdate ||
      automationJson.automationDetails?.triggerType === AutomationTypes.FieldUpdate)
  );
}

const AutomationsForm = ({
  onBackButtonClick,
  onSaveOrCreate,
  crmOrgId,
  onPristineChange,
  readonly = false,
  objectName,
  recordTypesAndFunnelsDataForObject,
  filterFunnelIds,
  isDoneLoading,
}: AutomationFormProps) => {
  const dataByVariant = useAutomationsContext();
  const { automationJson, setAutomationJson } = dataByVariant;
  const { automationVariant, permissions } = dataByVariant;
  const isPlaybookAlerts = automationVariant === AutomationFormVariant.PLAYBOOK_ALERT;
  const isScheduledAssignment = automationVariant === AutomationFormVariant.SCHEDULED_ASSIGNMENTS;
  const isScheduledReport = automationVariant === AutomationFormVariant.SCHEDULED_REPORTS;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const slackStatus = useSelector(selectSlackIntegrationStatus);

  const isShowFollowUpAction = !(isPlaybookAlerts || isScheduledAssignment || isScheduledReport);

  const [isAllowedBtn, isAllowedDeployBtn] = usePermission([permissions.edit, permissions.deploy]);
  if (!isAllowedBtn) {
    readonly = true;
  }
  readonly = isSubmitting || readonly;

  let funnelsList = recordTypesAndFunnelsDataForObject?.funnels;
  if (funnelsList && filterFunnelIds) {
    funnelsList = funnelsList.filter((funnel) => filterFunnelIds.includes(funnel.id));
  }

  const { objectTypes } = useObjectTypesWithFetch({ crmOrgId });

  const { anyFormulaEditorOpenExists } = useFormulaEditorState();
  const { anyOtherObjectEditorOpenExists } = useValueFromAnotherObjectEditorState();

  const isPristine = useIsPristine(automationJson);

  useEffect(() => {
    onPristineChange(isPristine);
  }, [isPristine, onPristineChange]);

  const validateTrigger = useCallback(() => {
    if (!automationJson.objectName || !automationJson.automationDetails?.triggerType) {
      return false;
    }
    const { stageEnter, stageExit, fieldUpdate, dateArrive, timeInStep, schedule } =
      automationJson.automationDetails?.automationParams || {};
    switch (automationJson.automationDetails?.triggerType) {
      case AutomationTypes.StageEnter:
        return stageEnter?.toStage;

      case AutomationTypes.StageExit:
        return stageExit?.fromStage && stageExit?.toStage;
      case AutomationTypes.FieldUpdate:
        return fieldUpdate?.fieldType && fieldUpdate?.operator && fieldUpdate?.operatorValue;
      case AutomationTypes.DateArrive:
        return (
          dateArrive?.relativeTime &&
          dateArrive?.sourceField?._leadingFieldLabels &&
          dateArrive?.sourceField?._leadingFieldLabels.length > 1 &&
          (dateArrive.relativeTime !== 'exact'
            ? dateArrive.offsetValue && dateArrive.offsetType
            : true)
        );
      case AutomationTypes.TimeInStep:
        return (
          timeInStep?.offsetType &&
          timeInStep?.stage?.stageName &&
          timeInStep?.stage?._stageId &&
          timeInStep?.offsetValue
        );
      case AutomationTypes.Scheduled:
        const isScheduleValid = getIsScheduleValid(schedule);
        const isDataTypeReport =
          automationJson.automationDetails?.getDataAction?.actionType ===
          AutomationDataActionType.REPORT_RESULTS;
        let isValidData = false;
        if (isDataTypeReport) {
          isValidData =
            !!(automationJson.automationDetails?.getDataAction as AutomationDataActionReportResult)
              ?.actionParams?.reportName &&
            !!(automationJson.automationDetails?.getDataAction as AutomationDataActionReportResult)
              ?.actionParams?.columnIdFieldLabel;
        } else {
          const condition = (
            automationJson.automationDetails?.getDataAction as AutomationDataActionQueryResult
          )?.actionParams?.criteria;
          isValidData =
            condition?.criteria?.length > 0 &&
            condition?.criteria?.[0]?._fieldIds?.length > 0 &&
            !!condition?.criteriaLogic;
        }
        if (automationJson.type === AutomationType.ScheduledReport) {
          isValidData = true;
        }
        return isScheduleValid && isValidData;
    }
    return true;
  }, [automationJson]);

  const isAutomationsFormValid = useMemo(() => {
    const validateActions = (_actions?: (DeepPartial<AutomationAction> | undefined)[]) => {
      const _currActions = _actions ?? automationJson.automationDetails?.actions;
      if (_currActions?.length === 0) {
        return false;
      }

      const hasAnyInvalidAction = _currActions?.find(
        (action) => action && !validateAnAction(action, slackStatus, automationJson.objectName),
      );
      return !hasAnyInvalidAction;
    };

    const validateDelay = () => {
      if (automationJson.automationDetails?.delay) {
        if (
          automationJson.automationDetails?.delay.offsetType &&
          automationJson.automationDetails?.delay.offsetValue
        ) {
          return true;
        }
      } else {
        return true;
      }
    };

    const isTriggerValid = validateTrigger();
    const areActionsValid = validateActions();
    const isDelayValid = validateDelay();

    return (
      isTriggerValid &&
      areActionsValid &&
      !anyFormulaEditorOpenExists &&
      isDelayValid &&
      !anyOtherObjectEditorOpenExists
    );
  }, [
    validateTrigger,
    anyFormulaEditorOpenExists,
    anyOtherObjectEditorOpenExists,
    automationJson.automationDetails?.actions,
    automationJson.automationDetails?.delay,
    automationJson.objectName,
    slackStatus,
  ]);

  const _cleanConditionTriggerBeforeSaving = (_automationJson: PartialAutomationStructure) => {
    if (_automationJson.automationDetails?.when) {
      const tempEc = _automationJson?.automationDetails?.when?.criteria;
      if (tempEc) {
        const tempNewEc = clearFirstConditionIfEmpty(tempEc);
        if (tempNewEc.criteria.length === 0) {
          return {
            ..._automationJson,
            automationDetails: {
              ..._automationJson.automationDetails,
              when: undefined,
            },
          };
        } else {
          const temp: CriteriaDetails = {
            ..._automationJson.automationDetails?.when,
            criteria: tempNewEc,
          };
          return {
            ..._automationJson,
            automationDetails: {
              ..._automationJson.automationDetails,
              when: temp,
            },
          };
        }
      } else {
        return {
          ..._automationJson,
          automationDetails: {
            ..._automationJson.automationDetails,
            when: undefined,
          },
        };
      }
    }
    return _automationJson;
  };

  const validateAllRuleBuilders = useValidateAllAndReturnIsValid();
  const ruleBuilderRef = useRefForMultipleRuleBuilders();

  const _createOrSaveAutomation = useCallback(
    async (crmOrgIds: string[]) => {
      const isValid = validateAllRuleBuilders();
      if (!isValid) {
        return { isError: 'Invalid rule builder' };
      }
      setIsSubmitting(true);

      const savedAutomation = await onSaveOrCreate(
        _cleanConditionTriggerBeforeSaving(automationJson) as AutomationStructureNew,
        crmOrgIds,
      );
      setIsSubmitting(false);
      return savedAutomation;
    },
    [automationJson, onSaveOrCreate, validateAllRuleBuilders],
  );

  const _onChange = useCallback(
    async (action: DeepPartial<AutomationAction>, idx?: number) => {
      const temp = [...(automationJson?.automationDetails?.actions || [])];
      if (temp) {
        if (idx !== undefined) {
          temp[idx] = action;
        } else {
          temp.push(action);
        }

        setAutomationJson({
          ...automationJson,
          automationDetails: {
            ...automationJson.automationDetails,
            actions: temp,
          },
        });
      }
    },
    [automationJson, setAutomationJson],
  );

  const removeConditionsToTrigger = () => {
    setAutomationJson({
      ...automationJson,
      automationDetails: {
        ...automationJson.automationDetails,
        when: undefined,
      },
    });
  };
  const removeDelayAction = () => {
    setAutomationJson({
      ...automationJson,
      automationDetails: {
        ...automationJson.automationDetails,
        delay: undefined,
      },
    });
  };
  const addScheduledDataAction = (newSweepCondition: SweepCondition) => {
    setAutomationJson({
      ...automationJson,
      automationDetails: {
        ...automationJson.automationDetails,
        getDataAction: {
          ...automationJson.automationDetails?.getDataAction,
          ...{
            actionType: AutomationDataActionType.QUERY_RESULTS,
            actionParams: {
              criteria: newSweepCondition,
            },
          },
        } as AutomationDataActionQueryResult,
      },
    });
  };

  const { criteria, sweepCondition } = useMemo(() => {
    const criteria = automationJson.automationDetails?.when?.criteria;

    const sweepCondition: SweepCondition | undefined = criteria
      ? {
          criteria: criteria.criteria,
          criteriaLogic: criteria.criteriaLogic,
        }
      : undefined;

    return { criteria, sweepCondition };
  }, [automationJson.automationDetails?.when?.criteria]);

  const excludeValueType = useCallback(
    (type: SelectorValueTypes) => {
      if (type === SelectorValueTypes.PRIOR) {
        const currType = automationJson.automationDetails?.triggerType;
        if (
          currType === AutomationTypes.OnCreate ||
          currType === AutomationTypes.DateArrive ||
          currType === AutomationTypes.TimeInStep ||
          currType === AutomationTypes.Scheduled
        ) {
          return true;
        }
        // exclude Prior value if automation has Delay
        if (automationJson.automationDetails?.delay) {
          return true;
        }
      }
      return false;
    },
    [automationJson.automationDetails?.delay, automationJson.automationDetails?.triggerType],
  );

  const onClearAction = useCallback(
    (idx: number) => {
      const temp = [...(automationJson?.automationDetails?.actions || [])];
      temp?.splice(idx, 1);
      setAutomationJson({
        ...automationJson,
        automationDetails: {
          ...automationJson.automationDetails,
          actions: temp,
        },
      });
    },
    [automationJson, setAutomationJson],
  );

  const onAddAction = useCallback(
    (emptyAction: Partial<AutomationAction>, isBefore: boolean, idx: number) => {
      const temp = [...(automationJson?.automationDetails?.actions || [])];
      if (isBefore) {
        temp?.splice(idx, 0, emptyAction);
      } else {
        temp?.splice(idx + 1, 0, emptyAction);
      }

      setAutomationJson({
        ...automationJson,
        automationDetails: {
          ...automationJson.automationDetails,
          actions: temp,
        },
      });
    },
    [automationJson, setAutomationJson],
  );

  const isTriggerScheduled =
    automationJson?.automationDetails?.triggerType === AutomationTypes.Scheduled &&
    automationJson?.type !== AutomationType.ScheduledAssignment &&
    automationJson?.type !== AutomationType.ScheduledReport;

  const isHideActionSelector = automationJson?.type === AutomationType.ScheduledReport;

  const getScheduledDataCriteria = (
    automationJson?.automationDetails?.getDataAction as AutomationDataActionQueryResult
  )?.actionParams?.criteria;

  if (!isDoneLoading) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box>
      <AutomationsCreateEditDialogHeader
        showCancelButton
        onCancelClick={onBackButtonClick}
        rightSideContent={
          <>
            <ObjectTypeChip
              objectType={objectName}
              label={recordTypesAndFunnelsDataForObject?.label || objectName}
              tagVariant="medium"
            />
            <Divider orientation="vertical" variant="fullWidth" flexItem />
          </>
        }
        isStickyHeader
        sx={{
          background: colors.white,
          paddingTop: '33px',
          paddingBottom: '24px',
          gap: '16px',
        }}
        hideBackButton={true}
        onDeployOrSave={_createOrSaveAutomation}
        onChange={(value) => {
          setAutomationJson({
            ...automationJson,
            name: value,
          });
        }}
        placeholder={dataByVariant.placeholder}
        value={automationJson.name || ''}
        buttonDisabled={
          !isAutomationsFormValid || ((!isAllowedBtn || readonly) && !isAllowedDeployBtn)
        }
        editDisabled={!isAllowedBtn || readonly}
        rightSidePermissionContent={<AutomationPermissionModeSelector />}
      />
      <Box
        sx={{
          maxHeight: 'calc(100% - 95px)',
          overflowY: 'auto',
          maxWidth: AUTOMATIONS_LAYOUT_MAX_WIDTH,
        }}
      >
        {!isAllowedBtn && (
          <AlertPermission
            alertMessage={`Your account doesn't have permission to edit ${dataByVariant.nameInMessage}`}
            addedStyle={{
              width: '470px',
              position: 'absolute',
              right: '40px',
              top: '98px',
              zIndex: '1000',
            }}
          />
        )}
        {!isScheduledAssignment && (
          <AutomationDefaultTriggers
            automationJson={automationJson}
            objectName={objectName}
            callBack={(data: DeepPartial<AutomationStructureNew>) => {
              setAutomationJson(data);
            }}
            crmOrgId={crmOrgId}
            readonly={readonly}
            recordTypesAndFunnelsDataForObject={recordTypesAndFunnelsDataForObject}
            filterFunnelIds={filterFunnelIds}
          />
        )}
        {isScheduledAssignment && (
          <AutomationScheduledAssignmentTriggers
            automationJson={automationJson}
            objectName={objectName}
            callBack={(data: DeepPartial<AutomationStructureNew>) => {
              setAutomationJson(data);
            }}
          />
        )}

        {automationJson.automationDetails?.when?.isCriteria && automationJson.objectName && (
          <>
            <Box sx={{ marginBottom: '16px' }}>
              <Box
                sx={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  gap: '17px',
                  height: '24px',
                  mb: '8px',
                  '&:hover .automationTriggerCriteria': {
                    display: 'block',
                  },
                }}
              >
                <Box
                  sx={{
                    ...labelBoxDesign,
                    background: colors.lilac[500],
                  }}
                >
                  <Typography variant="caption-bold" textAlign="center" color={colors.white}>
                    AND
                  </Typography>
                </Box>
                <Box className="automationTriggerCriteria" sx={{ display: 'none' }}>
                  <StyledTooltip title="Delete conditions" placement="top">
                    <span>
                      <IconButton variant="flat" size="tiny" onClick={removeConditionsToTrigger}>
                        <DeleteListIcon />
                      </IconButton>
                    </span>
                  </StyledTooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: colors.grey[100],
                  paddingTop: '12px',
                  borderRadius: '6px',
                }}
              >
                <>
                  <CustomHeader customHeader={'When these criteria are met:'} />
                  <SweepFieldsRuleBuilder
                    ref={ruleBuilderRef}
                    readOnly={readonly}
                    crmOrgId={crmOrgId ?? ''}
                    objectType={automationJson.objectName}
                    sweepCondition={sweepCondition}
                    onChange={(newSweepCondition) => {
                      const _criteria = criteria || {
                        criteria: [],
                        criteriaLogic: '',
                      };
                      const temp: CriteriaDetails = {
                        ...automationJson.automationDetails?.when,
                        criteria: { ..._criteria, ...newSweepCondition },
                      };
                      setAutomationJson({
                        ...automationJson,
                        automationDetails: {
                          ...automationJson.automationDetails,
                          when: temp,
                        },
                      });
                    }}
                    showFieldContextMenu={helperShowFieldContextMenu(
                      automationVariant,
                      automationJson,
                    )}
                  />
                </>
              </Box>
            </Box>
          </>
        )}
        {automationJson.automationDetails?.delay && automationJson.objectName && (
          <>
            <Box sx={{ marginBottom: '16px' }}>
              <Box
                sx={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  gap: '17px',
                  height: '24px',
                  mb: '8px',
                  '&:hover .automationRemoveDelay': {
                    display: 'block',
                  },
                }}
              >
                <Box
                  sx={{
                    ...labelBoxDesign,
                    background: colors.seafoam[500],
                  }}
                >
                  <Typography variant="caption-bold" textAlign="center" color={colors.white}>
                    WAIT
                  </Typography>
                </Box>
                <Box className="automationRemoveDelay" sx={{ display: 'none' }}>
                  <StyledTooltip title="Remove delay" placement="top">
                    <span>
                      <IconButton variant="flat" size="tiny" onClick={removeDelayAction}>
                        <DeleteListIcon />
                      </IconButton>
                    </span>
                  </StyledTooltip>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <TimeSelector
                    onChangeValue={(val) => {
                      const _delay = {
                        ...automationJson.automationDetails?.delay,
                        offsetValue: val,
                      };
                      setAutomationJson({
                        ...automationJson,
                        automationDetails: {
                          ...automationJson.automationDetails,
                          delay: _delay,
                        },
                      });
                    }}
                    onChangeValueType={(val) => {
                      const _delay = {
                        ...automationJson.automationDetails?.delay,
                        offsetType: val,
                      };
                      setAutomationJson({
                        ...automationJson,
                        automationDetails: {
                          ...automationJson.automationDetails,
                          delay: _delay,
                        },
                      });
                    }}
                    object={automationJson.automationDetails?.delay || {}}
                    intervalsArray={['Minutes', 'Hours', 'Days']}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '8px',
                    '& label': { paddingBottom: 0 },
                  }}
                >
                  <FormControlLabel
                    label={
                      <FieldLabel
                        label={
                          'Continue to actions only if the trigger and conditions are still valid'
                        }
                      />
                    }
                    control={
                      <Checkbox
                        disabled={readonly}
                        checked={automationJson.automationDetails?.delay?.shouldReevaluate ?? false}
                        onChange={(value) => {
                          const isChecked = value.target.checked;
                          const _delay = {
                            ...automationJson.automationDetails?.delay,
                            shouldReevaluate: isChecked,
                          };
                          setAutomationJson({
                            ...automationJson,
                            automationDetails: {
                              ...automationJson.automationDetails,
                              delay: _delay,
                            },
                          });
                        }}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}

        {isTriggerScheduled && (
          <Box
            sx={{
              backgroundColor: colors.grey[100],
              paddingTop: '12px',
              borderRadius: '6px',
              mb: 2,
            }}
          >
            <>
              <CustomHeader
                customHeader={`For every ${recordTypesAndFunnelsDataForObject?.label || objectName} record that meet the following conditions`}
              />
              <SweepFieldsRuleBuilder
                ref={ruleBuilderRef}
                readOnly={readonly}
                crmOrgId={crmOrgId ?? ''}
                objectType={objectName}
                sweepCondition={getScheduledDataCriteria}
                onChange={addScheduledDataAction}
                hideSelectValuesFromRecord={true}
                nestedSelectorFilterBy={(field) => field.fieldType !== SweepFieldTypes.LongTextArea}
                disableResolvePolymorphic
              />
            </>
          </Box>
        )}

        <Box>
          <Box sx={{ marginBottom: '16px' }}>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Box
                sx={{
                  ...labelBoxDesign,
                  background: isPlaybookAlerts ? colors.lemon[500] : colors.gum[500],
                }}
              >
                <Typography
                  variant="caption-bold"
                  textAlign="center"
                  color={isPlaybookAlerts ? colors.black : colors.white}
                >
                  THEN
                </Typography>
              </Box>
              {isShowFollowUpAction && (
                <AutomationActionPopupMenu
                  readonly={readonly}
                  action={
                    automationJson.automationDetails?.actions &&
                    automationJson.automationDetails?.actions[0]
                  }
                  onClearAction={() => onClearAction(0)}
                  onAddAction={(emptyAction, isBefore) => onAddAction(emptyAction, isBefore, 0)}
                  isClearable={
                    automationJson.automationDetails?.actions &&
                    automationJson.automationDetails?.actions[0]?.actionType !==
                      AutomationActionType.AssignmentRules
                  }
                />
              )}
            </Box>

            {isScheduledAssignment && (
              <SalesforceReportSelector
                automationJson={automationJson}
                readonly={readonly}
                crmOrgId={crmOrgId}
                callBack={(data: DeepPartial<AutomationStructureNew>) => {
                  setAutomationJson(data);
                }}
              />
            )}

            <AutomationsActionsWrapper
              isHideActionSelector={isHideActionSelector}
              onAddAction={onAddAction}
              onClearAction={onClearAction}
              isScheduledAssignment={isScheduledAssignment}
              onChange={_onChange}
              objectName={automationJson.objectName || ''}
              readonly={readonly}
              actionList={(automationJson.automationDetails?.actions as AutomationAction[]) || []}
              excludeValueType={excludeValueType}
              isPlaybookAlerts={isPlaybookAlerts}
              objectTypes={objectTypes}
              crmOrgId={crmOrgId}
              isActionEnabled={validateTrigger()}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const AutomationFormWithProviders = (props: AutomationFormProps) => (
  <ValueFromAnotherObjectEditorStateContextProvider>
    <FormulaEditorStateContextProvider>
      <AutomationsForm {...props} />
    </FormulaEditorStateContextProvider>
  </ValueFromAnotherObjectEditorStateContextProvider>
);

export { AutomationFormWithProviders as AutomationsForm };
