import { Stack, Box } from '@mui/material';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { Typography, Tag, colors } from '@sweep-io/sweep-design';
import AiAgentScrollArea from './AiAgentScrollArea';
import AiAgentInput from './AiAgentInput';
import { ReactNode } from 'react';
import { AI_AGENTS_INFO } from './aiAgentsConsts';
import { AgentType } from '@server/ai';
import useAiAgentCenter from './useAiAgentCenter';
import useAiAgents from './useAiAgents';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';

type NoteAlignment = 'left' | 'center';

const AiAgentChat = ({
  messages,
  onConfirm,
  isLoadingResponse,
  errorMsg,
  disableInput,
  noteAlignment,
  maxWidth,
  searchTermToHighlight,
  addToChatBeginning,
  introMessage,
}: {
  messages: AiChatMessage[];
  onConfirm: (message: string) => Promise<void>;
  isLoadingResponse: boolean;
  disableInput: boolean;
  errorMsg?: string; //this property should be deprecated
  noteAlignment: NoteAlignment;
  maxWidth?: string;
  searchTermToHighlight?: string;
  addToChatBeginning?: ReactNode;
  introMessage?: AiChatMessage;
}) => {
  const { isExpanded } = useAiAgentCenter();
  const { activeAgent } = useAiAgents();
  const { assistantAvatar } = AI_AGENTS_INFO[activeAgent?.type ?? AgentType.Documentation];
  const isLoadingChat = false //TODO complete

  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
        overflow: 'auto',
        flex: 1,
      }}
    >
      {isLoadingChat && <CenteredCircularProgress/>}
      {!isLoadingChat && <AiAgentScrollArea
        messages={messages}
        errorMsg={errorMsg}
        isLoadingResponse={isLoadingResponse}
        maxWidth={maxWidth}
        searchTermToHighlight={searchTermToHighlight}
        addToChatBeginning={addToChatBeginning}
        assistantAvatar={assistantAvatar}
        introMessage={introMessage}
      />}
      <Stack
        sx={{
          width: '100%',
          maxWidth,
          backgroundColor: isExpanded ? undefined : '#F7F9FA',
          borderTop: isExpanded ? undefined : `1px solid ${colors.grey[300]}`,
        }}
      >
        <AiAgentInput onConfirm={onConfirm} isLoading={isLoadingResponse} disabled={disableInput || isLoadingChat} />
        <Stack p={1.5} pt={0}>
          <ChatNote alignment={noteAlignment} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const ChatNote = ({ alignment }: { alignment: NoteAlignment }) => (
  <Box
    display="flex"
    gap={1}
    pl={alignment === 'left' ? 1.5 : 0}
    alignItems="center"
    justifyContent={alignment === 'left' ? 'start' : 'center'}
  >
    <Tag label="Beta" color={colors.storm[70]} />
    <Typography variant="caption" color={colors.grey[700]}>
      AI bots can make mistakes. Check important info.
    </Typography>
  </Box>
);

export default AiAgentChat;
