import { FormControl, Select, Box, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { Tag } from '@sweep-io/sweep-design';
import React from 'react';

interface MultipleSelectProps {
  value: string[];
  options: { value: string; label: string }[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
  removeBorders?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MultipleSelect: React.FC<MultipleSelectProps> = ({
  value,
  options,
  onChange,
  disabled,
  removeBorders,
}) => {
  return (
    <FormControl fullWidth sx={{ fieldset: { border: removeBorders ? 'none' : undefined } }}>
      <Select
        variant="outlined"
        multiple
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value as string[]);
        }}
        renderValue={(selected: string[]) => (
          <Box display="flex" gap={1}>
            {options
              .filter((o) => selected.includes(o.value))
              .map((o) => (
                <Tag key={o.value} label={o.label} size="medium" />
              ))}
          </Box>
        )}
        MenuProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
