import { useCallback, useMemo, useState } from 'react';

export type WizardStep = {
  name: string;
  isCompleted: boolean;
};

export type WizardProps = {
  goNext: () => void;
  goBack: () => void;
  goToStep: (index: number) => void;
  activeStep: number;
  isLastStep: boolean;
  isFirstStep: boolean;
  steps: WizardStep[];
};

const useWizard = (stepsNames: string[]): WizardProps => {
  const steps = useMemo(() => {
    return stepsNames.map((name) => ({
      name,
      isCompleted: false,
    }));
  }, [stepsNames]);

  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === steps.length - 1;
  const isFirstStep = activeStep === 0;

  const updateStepCompleted = useCallback(
    (stepName: string) => {
      const stepToUpdate = steps.find((step) => step.name === stepName);
      if (stepToUpdate) {
        stepToUpdate.isCompleted = true;
      }
    },
    [steps],
  );

  const goNext = useCallback(() => {
    if (isLastStep) {
      return;
    }
    updateStepCompleted(steps[activeStep].name);
    setActiveStep((curr) => curr + 1);
  }, [isLastStep, updateStepCompleted, steps, activeStep]);

  const goBack = useCallback(() => {
    if (isFirstStep) {
      return;
    }
    setActiveStep(activeStep - 1);
  }, [isFirstStep, setActiveStep, activeStep]);

  const goToStep = useCallback(
    (index: number) => {
      //can go directly only to completed steps
      if (steps[index].isCompleted) {
        setActiveStep(index);
      }
    },
    [steps, setActiveStep],
  );

  return { goNext, goBack, goToStep, activeStep, isLastStep, isFirstStep, steps };
};

export default useWizard;
