import * as React from "react";
const SvgNewProcessAgentIcon = (props) => /* @__PURE__ */ React.createElement("svg", { width: 40, height: 40, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 20, cy: 20, r: 20, fill: "#EBDEFF", style: {
  fill: "#EBDEFF",
  fill: "color(display-p3 0.9235 0.8708 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.5 20H25.5M20 14.5V25.5", stroke: "#504F87", style: {
  stroke: "#504F87",
  stroke: "color(display-p3 0.3137 0.3098 0.5294)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgNewProcessAgentIcon;
