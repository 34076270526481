import { Box } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { useEffect, useState } from 'react';
import { useSnapshots } from '../../../../../hooks/useSnapshots';
import { CenteredCircularProgress } from '../../../../common/CenteredCircularProgress';
import FunnelVersionsTable from '../../../version-table/FunnelVersionsTable';
import { useSelector } from 'react-redux';
import { selectConnectedCrmOrgs } from '../../../environments/environmentsReducer';
import { SingleSelectFilter } from '../../../../common/single-select-filter/SingleSelectFilter';
import { OPTION_ALL } from '../../../../common/single-select-filter/utils';

interface VersionHistoryTabProps {
  funnel: Funnel;
  crmOrgId: string;
  funnelMapId: string;
  onNavigateCallback: () => void;
}

const VersionHistoryTab = ({
  funnel,
  crmOrgId,
  onNavigateCallback,
  funnelMapId,
}: VersionHistoryTabProps) => {
  const [selectedCrmOrgId, setSelectedCrmOrgId] = useState(OPTION_ALL.value);
  const [isLoading, setIsLoading] = useState(true);
  const { getSnapshots } = useSnapshots();
  const crmOrgs = useSelector(selectConnectedCrmOrgs);

  const crmOrgsOptions = [
    OPTION_ALL,
    ...crmOrgs.map((org) => ({ label: org.name, value: org.id })),
  ];

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      await getSnapshots(funnel.id).then(() => setIsLoading(false));
      setIsLoading(false);
    };
    fetch();
  }, [funnel.id, getSnapshots]);

  if (isLoading) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Version History</Typography>
        <SingleSelectFilter
          selectedItemValue={selectedCrmOrgId}
          filterTitleText="Deployed to"
          onSelectItem={setSelectedCrmOrgId}
          options={crmOrgsOptions}
        />
      </Box>
      <FunnelVersionsTable
        funnel={funnel}
        onNavigateCallback={onNavigateCallback}
        filterByCrmOrgId={selectedCrmOrgId}
        funnelMapId={funnelMapId}
        funnelMapCrmOrgId={crmOrgId}
      />
    </Box>
  );
};

export default VersionHistoryTab;
