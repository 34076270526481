const findAndCompare = (obj: ObjectLiteral, property: string, valueToCompare: string) => {
  // Base case: If the object is not an object or is null, return false.
  if (obj === null || typeof obj !== 'object') {
    return false;
  }

  if (obj.hasOwnProperty(property)) {
    return obj[property] === valueToCompare;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === valueToCompare) {
        return true;
      }
      if (findAndCompare(obj[key], property, valueToCompare)) {
        return true;
      }
    }
  }
  return false;
};

export const findSweepElementsRelatedToObject = (
  automations: AutomationStructureNew[],
  relatedTo: string,
) => {
  return automations.filter((automation) => {
    if (automation.objectName === relatedTo) {
      //object cannot be related to itself
      return false;
    }
    const withObjectType = findAndCompare(automation, 'objectType', relatedTo);
    const withObjectName = findAndCompare(automation.automationDetails, 'objectName', relatedTo);

    return withObjectType ?? withObjectName;
  });
};
