import { Box, Stack } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import CopilotChat from '../copilotChat/CopilotChat';
import useCopilotChats from './useCopilotChats';

const CopilotChatsCollapsed = ({
  newButton,
  toggleExpandButton,
  closeButton,
}: {
  newButton: ReactNode;
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
}) => {
  const { onConfirmMessage, activeChat, disableInput } = useCopilotChats();

  return (
    <Stack height="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="56px"
        sx={{
          padding: '12px',
          borderBottom: `1px solid ${colors.grey[200]}`,
        }}
        mb={0.5}
      >
        <Box display="flex" gap={1} alignItems="center">
          {newButton}
          {toggleExpandButton}
        </Box>
        {closeButton}
      </Box>
      <CopilotChat
        messages={activeChat?.data?.aiChatDetails?.messages ?? []}
        onConfirm={onConfirmMessage}
        isLoading={!!activeChat?.data?.isLoading}
        disableInput={disableInput}
        noteAlignment="left"
      />
    </Stack>
  );
};

export default CopilotChatsCollapsed;
