import { useCallback, useState } from 'react';
import SearchInputWithTooltip from '../common/SearchInputWithTooltip';
import { Box } from '@mui/material';
import useAiAgentChats from './useAiAgentChats';
import usePaywall from '../dashboard/paywall/usePaywall';
import { useUnhappyStates } from '../pages/environments/useUnhappyStates';

const AiAgentSearch = () => {
  const { getSearchResults, clearSearchResults, searchText } = useAiAgentChats();
  const [localSearchText, setLocalSearchText] = useState(searchText);

  const clearSearch = useCallback(() => {
    clearSearchResults();
    setLocalSearchText('');
  }, [clearSearchResults]);

  const onSearchKeydown = useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        clearSearch();
      }
      if (event.key === 'Enter' && !!localSearchText) {
        getSearchResults(localSearchText);
      } else if (event.key === 'Enter' && localSearchText === '') {
        clearSearch();
      }
    },
    [clearSearch, getSearchResults, localSearchText],
  );

  const { isPaywallActive } = usePaywall();
  const { noConnectedOrg, isInitialFetch } = useUnhappyStates();

  const isSearchDisabled = isPaywallActive || noConnectedOrg || isInitialFetch;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="40px"
      padding="0 16px"
    >
      <SearchInputWithTooltip
        displayTooltip={localSearchText !== '' && localSearchText !== searchText}
        TextFieldProps={{
          autoFocus: true,
          value: localSearchText,
          onChange: (e) => {
            setLocalSearchText(e.target.value);
          },
          onKeyDown: onSearchKeydown,
          disabled: isSearchDisabled,
        }}
        onClearButtonClick={clearSearch}
        withFixedMagnifyingGlassIcon
        forceCloseButton
      />
    </Box>
  );
};

export default AiAgentSearch;
