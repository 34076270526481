import { Box } from '@mui/material';
import { CanvasFilters, CanvasFiltersProps } from '../configuration-canvas-filters/CanvasFilters';
import { useConfigurationCanvas } from './public/useConfigurationCanvas';
import { useGetReservedSpaceForPanels } from './panels-reserve-space/ReserveSpaceForPanelsCtx';
import { useMemo } from 'react';
import { AdvancedFiltersPanel } from '../../common/advanced-filter/AdvancedFiltersPanel';
import { useCanvasFilters } from '../configuration-canvas-filters/useCanvasFilters';
import { FiltersMap } from '../../documentation/selected-object/filters/filtersOptions';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { useRunOnce } from '../../common/useRunOnce';

const staticSx = {
  position: 'relative',
  top: 0,
  left: 0,
};

export const ConfigurationCanvasFilters = ({
  filters,
  automations,
  staticView,
  disableFitOnCanvasElements,
  withPanelWrapper,
}: Pick<
  CanvasFiltersProps,
  'filters' | 'automations' | 'disableFitOnCanvasElements' | 'hideClearAllButton'
> & {
  staticView?: boolean;
  withPanelWrapper?: boolean;
}) => {
  const { newDocumentationUiFilters } = useFeatureToggle();
  const { canvasFunnelMap, appCrmOrgId } = useConfigurationCanvas();
  const { leftReservedSpace } = useGetReservedSpaceForPanels();
  const { clearAllValues, filterValues } = useCanvasFilters();

  useRunOnce(() => {
    clearAllValues();
  });

  const filtersJsx = useMemo(() => {
    return (
      <CanvasFilters
        filters={filters}
        automations={automations}
        funnelMap={canvasFunnelMap}
        crmOrgId={appCrmOrgId}
        disableFitOnCanvasElements={disableFitOnCanvasElements}
        hideClearAllButton={newDocumentationUiFilters ? withPanelWrapper : false}
        renderClearButtonForEveryFilter={withPanelWrapper && newDocumentationUiFilters}
      />
    );
  }, [
    filters,
    automations,
    canvasFunnelMap,
    disableFitOnCanvasElements,
    appCrmOrgId,
    withPanelWrapper,
    newDocumentationUiFilters,
  ]);

  const panelJsx = useMemo(() => {
    const showClearAll =
      Object.entries(filters).length > 0 &&
      Object.values(
        Object.entries(filterValues).filter(([key]) => key !== FiltersMap.activeState),
      ).some(([, values]) => values.selectedValues.length > 0);

    return (
      <AdvancedFiltersPanel displayClearAll={showClearAll} onClearAll={clearAllValues}>
        {filtersJsx}
      </AdvancedFiltersPanel>
    );
  }, [filtersJsx, clearAllValues, filterValues, filters]);

  return (
    <Box
      sx={{
        position: 'absolute',
        left: leftReservedSpace + 16,
        top: 16,
        zIndex: 1,
        ...(staticView || withPanelWrapper ? staticSx : {}),
      }}
      className="comments-layer"
    >
      {!newDocumentationUiFilters && filtersJsx}
      {newDocumentationUiFilters && (
        <>
          {withPanelWrapper && panelJsx}
          {!withPanelWrapper && filtersJsx}
        </>
      )}
    </Box>
  );
};
