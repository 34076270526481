import { Box, Stack } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import ChatsHistory from './ChatsHistory';
import CopilotChat from '../copilotChat/CopilotChat';
import useCopilotChats from './useCopilotChats';

const CHATS_PANEL_WIDTH = '256px';
const ACTIVE_CHAT_MAX_WIDTH = '712px';

  const CopilotChatsExpanded = ({
  newButton,
  toggleExpandButton,
  closeButton,
}: {
  newButton: ReactNode;
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
}) => {
  const { activeChat, onConfirmMessage, searchText, activeChatId, disableInput } = useCopilotChats();

  const _onConfirm = useCallback(
    async (message: string) => {
      await onConfirmMessage(message, activeChatId);
    },
    [activeChatId, onConfirmMessage],
  );

  return (
    <Stack height="100%" direction="row" position="relative">
      <Stack
        sx={{
          flex: `0 0 ${CHATS_PANEL_WIDTH}`,
        }}
      >
        <ChatsHistory newButton={newButton} />
      </Stack>
      <Stack
        sx={{
          flex: 1,
          alignItems: 'center',
          height: '100%',
          pt: 2,
        }}
      >
        <CopilotChat
          messages={activeChat?.data?.aiChatDetails?.messages ?? []}
          onConfirm={_onConfirm}
          isLoading={!!activeChat?.data?.isLoading}
          disableInput={disableInput}
          noteAlignment="center"
          maxWidth={ACTIVE_CHAT_MAX_WIDTH}
          searchTermToHighlight={searchText}
        />
      </Stack>

      <Box
        display="flex"
        gap={1}
        alignItems="center"
        sx={{
          position: 'absolute',
          right: '12px',
          top: '24px',
        }}
      >
        {toggleExpandButton}
        {closeButton}
      </Box>
    </Stack>
  );
};

export default CopilotChatsExpanded;
