import { Box, SxProps, TextField, TextFieldProps } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { Sparkle } from '@sweep-io/sweep-design/dist/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { buttons } from './Buttons';
import { getOpacityInHex } from '../../../../lib/getOpacityInHex';
import classNames from 'classnames';

export type TextFieldVariant = 'primary' | 'secondary';

type TextFieldWithButtonProps = TextFieldProps & {
  onConfirm: (message: string) => void;
  inputPlaceholder?: string;
  readonly?: boolean;
  isLoading?: boolean;
  inputVariant?: TextFieldVariant;
  value: string;
  setValue: (value: string) => void;
};

export const TextFieldWithButton = ({
  value,
  setValue,
  onConfirm,
  onFocus,
  onBlur,
  inputPlaceholder,
  readonly = false,
  isLoading = false,
  inputVariant = 'primary',
  ...rest
}: TextFieldWithButtonProps) => {
  const ButtonComponent = buttons[inputVariant];
  const inputRef = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && !isLoading) {
      event.preventDefault(); //prevent going down a row in textarea
      _onConfirm();
    }
  };

  //return the focus to the input after getting server response
  useEffect(() => {
    if (!isLoading && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isLoading])

  const _onConfirm = useCallback(() => {
    onConfirm(value);
    setValue('');
  }, [onConfirm, value, setValue]);

  return (
    <TextField
      {...rest}
      inputRef={inputRef}
      onKeyDown={handleKeyDown}
      onChange={(event) => setValue(event.target.value)}
      onFocus={(event) => {
        setFocused(true);
        onFocus && onFocus(event);
      }}
      onBlur={(event) => {
        setFocused(false);
        onBlur && onBlur(event);
      }}
      value={value}
      multiline={true}
      className={classNames({ focused: focused })}
      sx={{
        width: '100%',
        borderRadius: '12px',
        boxShadow: 'none',
        ...textFieldVariantStyles[inputVariant],
      }}
      InputProps={{
        className: classNames({ focused: focused }),
        placeholder: inputPlaceholder,
        disabled: readonly || isLoading,
        sx: {
          minHeight: '48px', //to reach input height of 48px, for "secondary" variant
          paddingTop: '6px!important', //to reach input height of 48px, for "primary" variant
          paddingBottom: '6px!important', //to reach input height of 48px, for "primary" variant
          display: 'flex',
          alignItems: 'center',
          '.MuiOutlinedInput-input': {
            minHeight: '20px', //to override default, so it will be centered
          },
          ...inputVariantStyles[inputVariant],
        },
        endAdornment: ButtonComponent({ onClick: _onConfirm, disabled: isLoading || readonly }),
        startAdornment: (
          <>
            {inputVariant === 'primary' ? (
              <Box display="flex" alignItems="center">
                <Sparkle color={colors.blue[500]} />
              </Box>
            ) : (
              <></>
            )}
          </>
        ),
      }}
    />
  );
};

const sharedStyles = {
  borderRadius: '8px',
};

const textFieldVariantStyles: { [key in TextFieldVariant]: SxProps } = {
  primary: {
    border: `4px solid ${colors.blue[500]}${getOpacityInHex(20)}`,

    '&.focused': {
      borderColor: `${colors.blue[500]}${getOpacityInHex(20)}`,
    },

    '&:hover': {
      background: `${colors.blue[500]}${getOpacityInHex(40)}`,
    },
  },
  secondary: {},
};

const inputVariantStyles: { [key in TextFieldVariant]: SxProps } = {
  primary: {
    ...sharedStyles,
    border: `2px solid ${colors.blue[500]}`,

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '&.MuiInputBase-root': {
      '& .MuiInputBase-inputAdornedStart': {
        pl: 0.5,
      },
    },

    '&.MuiInputBase-adornedEnd': {
      pr: 1,
    },
  },
  secondary: {
    ...sharedStyles,
  },
};
