import cloneDeep from 'lodash/cloneDeep';
import { SelectorValueTypes } from '../../../types/enums/SelectorValueTypes';
import {
  ApiSweepFieldOperator,
  SweepFieldOperatorsValues,
  SweepFieldOperator,
} from '../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { SweepFieldsSelectionRow } from '../../SweepFieldsSelectionRow/SweepFieldsSelectionRow';
import { RuleBuilder } from '../rule-builder/RuleBuilder';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { uniqueId } from '../../../lib/uniqueId';
import { SxProps, Theme } from '@mui/material';
import { RuleBuilderData, RuleBuilderRowComponentProps } from '../rule-builder/rule-builder-types';
import { SweepConditionRuleBuilderRef } from './sweep-condition-rule-builder-types';
import { validateSweepCriteria } from '../rule-builder/validateSweepCriteria';
import { MainSelectors } from '../../SweepFieldsMainSelector/SweepFieldsMainSelector';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';

export interface RuleBuilderSelectionRowData {
  id: string;
  criteria: SweepCriterionWithLabels;
  objectType: string;
  crmOrgId: string;
  mainSelectorKey?: MainSelectors;
  referenceObjectType?: string;
  filterOperatorBy?: (operatorList: OperatorWithIndex[]) => OperatorWithIndex[];
  nestedSelectorFilterBy?: (field: SweepField) => boolean;
  hideSelectValuesFromRecord?: boolean;
  isBinaryRuleBuilder?: boolean;
  disableLookupItemsResolve?: boolean;
  displayFieldContextMenu?: boolean;
  disableResolvePolymorphic?: boolean;
  onOperatorValueChange?: (operatorValue: OperatorOffset, lineNumber: number) => any;
  selectValuesFromRecordCustomButtonText?: string;
  showCompoundGeolocation?: boolean;
}

function RuleBuilderSweepConditionRow({
  data,
  index,
  readOnly,
  onRowChange,
  onRowDelete,
}: RuleBuilderRowComponentProps<RuleBuilderSelectionRowData>) {
  const {
    criteria,
    crmOrgId,
    objectType,
    mainSelectorKey,
    referenceObjectType,
    filterOperatorBy,
    nestedSelectorFilterBy,
    hideSelectValuesFromRecord,
    isBinaryRuleBuilder,
    disableLookupItemsResolve,
    displayFieldContextMenu,
    disableResolvePolymorphic,
    onOperatorValueChange,
    selectValuesFromRecordCustomButtonText,
    showCompoundGeolocation,
  } = data;
  return (
    <SweepFieldsSelectionRow
      nestedSelectorFilterBy={nestedSelectorFilterBy}
      filterOperatorBy={filterOperatorBy}
      referenceObjectType={referenceObjectType}
      showCompoundAddress={true}
      removeBorders={true}
      readonly={readOnly}
      crmOrgId={crmOrgId}
      lineNumber={index + 1}
      fieldIds={criteria._fieldIds}
      fieldLabels={criteria._fieldLabels}
      keyValue={criteria.keyValue}
      objectType={objectType}
      fieldType={criteria.fieldType as SweepFieldTypes}
      initialOperator={criteria.operator as ApiSweepFieldOperator}
      initialValue={criteria.value}
      initialOperatorValue={criteria.value as SweepFieldOperatorsValues}
      valueType={criteria?.valueType}
      valueReferencedFieldLabels={criteria._valueLabels}
      mainSelectorKey={mainSelectorKey}
      disableLookupItemsResolve={disableLookupItemsResolve}
      showDelete
      onChange={(event) => {
        onRowChange({
          ...data,
          id: data.criteria.criterionId,
          criteria: {
            criterionId: data.criteria.criterionId,
            fieldType: event.fieldType,
            operator: event.operator,
            value: event.value || '',
            _fieldIds: event.fieldInfo?.fieldIds,
            _fieldLabels: event.fieldInfo?.fieldLabels,
            valueType: event.valueType || SelectorValueTypes.LITERAL,
            _valueLabels: event._valueLabels || [],
            fieldContext: event.fieldContext,
            operatorValue: event?.operatorValue ?? undefined,
            keyValue: event?.keyValue ?? undefined,
          },
          crmOrgId,
          objectType,
        });
      }}
      onDelete={onRowDelete}
      hideSelectValuesFromRecord={hideSelectValuesFromRecord}
      isBinaryRuleBuilder={isBinaryRuleBuilder}
      fieldContext={criteria.fieldContext}
      displayFieldContextMenu={displayFieldContextMenu}
      disableResolvePolymorphic={disableResolvePolymorphic}
      onOperatorValueChange={onOperatorValueChange}
      operatorOffsetValue={criteria?.operatorValue}
      selectValuesFromRecordCustomButtonText={selectValuesFromRecordCustomButtonText}
      showCompoundGeolocation={showCompoundGeolocation}
    />
  );
}

interface SweepConditionRuleBuilderProps {
  sweepCondition?: SweepConditionWithLabels;
  onChange: (newSweepCondition: SweepConditionWithLabels) => any;
  crmOrgId: string;
  objectType: string;
  readOnly?: boolean;
  mainSelectorKey?: MainSelectors;
  excludeFirstEntryFromValidationIfEmpty?: boolean;
  sx?: SxProps<Theme>;
  referenceObjectType?: string;
  filterOperatorBy?: (
    operatorList: OperatorWithIndex[],
    fieldType?: SweepFieldTypes,
  ) => OperatorWithIndex[];
  nestedSelectorFilterBy?: (field: SweepField) => boolean;
  hideSelectValuesFromRecord?: boolean;
  isBinaryRuleBuilder?: boolean;
  headerRowComponent?: JSX.Element | string;
  disableLookupItemsResolve?: boolean;
  showFieldContextMenu?: boolean;
  disableResolvePolymorphic?: boolean;
  onOperatorValueChange?: (operatorValue: OperatorOffset, lineNumber: number) => any;
  selectValuesFromRecordCustomButtonText?: string;
  showCompoundGeolocation?: boolean;
}

export const SweepConditionRuleBuilder = forwardRef<
  SweepConditionRuleBuilderRef,
  SweepConditionRuleBuilderProps
>(
  (
    {
      crmOrgId,
      objectType,
      sweepCondition,
      onChange,
      readOnly,
      mainSelectorKey,
      referenceObjectType,
      sx,
      filterOperatorBy,
      nestedSelectorFilterBy,
      excludeFirstEntryFromValidationIfEmpty = true,
      hideSelectValuesFromRecord,
      isBinaryRuleBuilder,
      headerRowComponent,
      disableLookupItemsResolve,
      showFieldContextMenu,
      disableResolvePolymorphic,
      onOperatorValueChange,
      selectValuesFromRecordCustomButtonText,
      showCompoundGeolocation,
    },
    ref,
  ) => {
    const ruleBuilderData: RuleBuilderData<RuleBuilderSelectionRowData> = useMemo(() => {
      const { criteria, criteriaLogic } = cloneDeep(
        sweepCondition || {
          criteria: [],
          criteriaLogic: '',
        },
      );
      const entries: RuleBuilderSelectionRowData[] = criteria.map((c) => ({
        id: c.criterionId,
        criteria: c,
        crmOrgId,
        objectType,
        mainSelectorKey,
        referenceObjectType,
        filterOperatorBy,
        nestedSelectorFilterBy,
        hideSelectValuesFromRecord,
        isBinaryRuleBuilder,
        disableLookupItemsResolve,
        displayFieldContextMenu: showFieldContextMenu,
        disableResolvePolymorphic,
        onOperatorValueChange,
        selectValuesFromRecordCustomButtonText,
        showCompoundGeolocation,
      }));

      return {
        entries,
        logicString: criteriaLogic,
      };
    }, [
      sweepCondition,
      crmOrgId,
      objectType,
      mainSelectorKey,
      referenceObjectType,
      filterOperatorBy,
      nestedSelectorFilterBy,
      hideSelectValuesFromRecord,
      isBinaryRuleBuilder,
      disableLookupItemsResolve,
      showFieldContextMenu,
      disableResolvePolymorphic,
      onOperatorValueChange,
      selectValuesFromRecordCustomButtonText,
      showCompoundGeolocation,
    ]);

    const newRowProvider = useCallback(() => {
      const newId = uniqueId();
      return {
        id: newId,
        criteria: {
          criterionId: newId,
          fieldType: '',
          operator: '',
          value: '',
          _fieldIds: [],
          _fieldLabels: [],
          valueType: SelectorValueTypes.LITERAL,
        },
        crmOrgId,
        objectType,
        disableResolvePolymorphic,
      };
    }, [crmOrgId, disableResolvePolymorphic, objectType]);

    const [errorIds, setErrorIds] = useState<string[]>([]);
    const [displayErrors, setDisplayErrors] = useState(false);

    const triggerValidation = useCallback(() => {
      const criteria = ruleBuilderData.entries.map((c) => c.criteria);
      const _errorIds = validateSweepCriteria(criteria, excludeFirstEntryFromValidationIfEmpty);
      setErrorIds(_errorIds);
      if (_errorIds.length) {
        setDisplayErrors(true);
      }
      return _errorIds;
    }, [excludeFirstEntryFromValidationIfEmpty, ruleBuilderData.entries]);

    useImperativeHandle(
      ref,
      () => ({
        triggerValidation,
      }),
      [triggerValidation],
    );

    useEffect(() => {
      triggerValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <RuleBuilder
        sx={sx}
        readonly={readOnly}
        ruleBuilderData={ruleBuilderData}
        onChange={(ruleBuilderCondition) => {
          const { entries, logicString: criteriaLogic } = ruleBuilderCondition;
          const _criteria = entries.map((c) => c.criteria);
          onChange({ criteria: _criteria, criteriaLogic });
          const _errorIds = validateSweepCriteria(
            _criteria,
            excludeFirstEntryFromValidationIfEmpty,
          );
          setErrorIds(_errorIds);
          if (!_errorIds.length) {
            setDisplayErrors(false);
          }
        }}
        newRowProvider={newRowProvider}
        RowComponent={RuleBuilderSweepConditionRow}
        errorIds={errorIds}
        displayErrors={displayErrors}
        headerRowComponent={headerRowComponent}
      />
    );
  },
);

interface SweepFieldsRuleProps
  extends Omit<SweepConditionRuleBuilderProps, 'sweepCondition' | 'onChange'> {
  sweepCondition?: SweepCondition;
  onChange: (newSweepCondition: SweepCondition) => any;
}

export const SweepFieldsRuleBuilder = forwardRef<
  SweepConditionRuleBuilderRef,
  SweepFieldsRuleProps
>((props, ref) => {
  const { enrichCriteriaWithLabels: populateCriteriaWithLabels, removeLabelsFromCriteria } =
    useSweepFieldsLabels();
  const { sweepCondition, onChange, ...rest } = props;

  let sweepConditionWithLabels: SweepConditionWithLabels | undefined;

  if (sweepCondition) {
    sweepConditionWithLabels = {
      criteria: sweepCondition.criteria?.map(populateCriteriaWithLabels),
      criteriaLogic: sweepCondition.criteriaLogic,
    };
  }

  return (
    <SweepConditionRuleBuilder
      {...rest}
      ref={ref}
      sweepCondition={sweepConditionWithLabels}
      onChange={({ criteria, criteriaLogic }) => {
        onChange({ criteriaLogic, criteria: criteria.map(removeLabelsFromCriteria) });
      }}
    />
  );
});

interface SweepBinaryRuleProps
  extends Omit<SweepConditionRuleBuilderProps, 'sweepCondition' | 'onChange'> {
  sweepCondition?: SweepCondition;
  onChange: (newSweepCondition: SweepCondition) => any;
}

export const SweepBinaryRuleBuilder = forwardRef<
  SweepConditionRuleBuilderRef,
  SweepBinaryRuleProps
>((props, ref) => {
  const { enrichCriteriaWithLabels, removeLabelsFromCriteria } = useSweepFieldsLabels();
  const {
    sweepCondition,
    onChange,
    headerRowComponent,
    filterOperatorBy,
    disableResolvePolymorphic,
    onOperatorValueChange,
    showCompoundGeolocation,
    ...rest
  } = props;

  let sweepConditionWithLabels: SweepConditionWithLabels | undefined;

  if (sweepCondition) {
    sweepConditionWithLabels = {
      criteria: sweepCondition.criteria?.map(enrichCriteriaWithLabels),
      criteriaLogic: sweepCondition.criteriaLogic,
    };
  }

  const mainFilterOperatorBy = (operatorList: OperatorWithIndex[], fieldType?: SweepFieldTypes) => {
    const listOfOperatorsToRemove = [
      SweepFieldOperator.IS_NULL_TRUE,
      SweepFieldOperator.IS_NULL_FALSE,
      SweepFieldOperator.IS_CHANGED_TRUE,
      SweepFieldOperator.IS_CHANGED_FALSE,
      SweepFieldOperator.HAS_INCREASED,
      SweepFieldOperator.HAS_DECREASED,
      SweepFieldOperator.IN_LAST_X,
      SweepFieldOperator.IN_NEXT_X,
      SweepFieldOperator.GREATER_THAN_X,
      SweepFieldOperator.GREATER_THAN_X_AGO,
    ];
    let listToReturn = operatorList.filter(({ operator }) => {
      return !listOfOperatorsToRemove.includes(operator);
    });
    if (filterOperatorBy) {
      listToReturn = filterOperatorBy(listToReturn, fieldType);
    }

    return listToReturn;
  };

  return (
    <>
      <SweepConditionRuleBuilder
        {...rest}
        filterOperatorBy={mainFilterOperatorBy}
        hideSelectValuesFromRecord={true}
        isBinaryRuleBuilder={true}
        headerRowComponent={headerRowComponent}
        ref={ref}
        sweepCondition={sweepConditionWithLabels}
        onChange={({ criteria, criteriaLogic }) => {
          onChange({ criteriaLogic, criteria: criteria.map(removeLabelsFromCriteria) });
        }}
        disableResolvePolymorphic={disableResolvePolymorphic}
        onOperatorValueChange={onOperatorValueChange}
        showCompoundGeolocation={showCompoundGeolocation}
      />
    </>
  );
});
