import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useCallback, useState } from 'react';
import { useCrmOrgs } from '../pages/environments/useCrmOrgs';
import SweepSelect from '../common/SweepSelect';
import { telemetry } from '../../telemetry';

interface EmailTemplateSelectorProps {
  emailTemplate?: CrmOrgEmailTemplate;
  crmOrgId: string;
  onChange: (emailTemplate: CrmOrgEmailTemplate) => any;
  readonly?: boolean;
}
export const EmailTemplateSelector = ({
  emailTemplate,
  crmOrgId,
  onChange,
  readonly,
}: EmailTemplateSelectorProps) => {
  const [crmOrgEmailTemplatesItems, setCrmOrgEmailTemplatesItems] = useState<CrmOrgEmailTemplate[]>(
    emailTemplate ? [emailTemplate] : [],
  );

  const { fetchCrmOrgEmailTemplates } = useCrmOrgs();

  const [isLoading, setIsLoading] = useState(false);

  const onOpen = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const { emailTemplates } = await fetchCrmOrgEmailTemplates(crmOrgId);
        setCrmOrgEmailTemplatesItems(emailTemplates.sort());
      } catch (error) {
        telemetry.captureError(error);
      }
      setIsLoading(false);
    }
  }, [crmOrgId, fetchCrmOrgEmailTemplates, isLoading]);

  return (
    <SweepSelect
      SelectProps={{
        disabled: readonly,
        placeholder: 'Select an Email Template',
        value: emailTemplate ?? '',
        onChange: (event: SelectChangeEvent<unknown>) => {
          const val = event.target.value;
          onChange(val as string);
        },
        renderValue: (val: any) => val,
      }}
      onOpenCb={onOpen}
      isLoading={isLoading}
    >
      {crmOrgEmailTemplatesItems.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </SweepSelect>
  );
};
