import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { SlackActionButtonTextField, SlackActionRow } from './common';
import FieldLabel from '../../../common/FieldLabel';
import {
  NestedFieldsField,
  NestedFieldsSelector,
} from '../../../common/fieldsSelectors/NestedFieldsSelector';
import SweepSelect from '../../../common/SweepSelect';
import { InputWithVariables } from '../../InputWithVariables';
import { SweepFieldTypes } from '../../../../types/enums/SweepFieldTypes';
import { useSweepFieldsLabels } from '../../../../sweep-fields/useSweepFieldsLabels';
import { colors } from '@sweep-io/sweep-design';

export const SlackActionLink = ({
  action,
  crmOrgId,
  idx,
  objectName,
  onUpdateAction,
  buttonAction,
}: {
  crmOrgId: string;
  objectName: string;
  onUpdateAction: (action: LinkButtonSlackAction, index: number) => any;
  action: LinkButtonSlackAction;
  idx: number;
  buttonAction: React.ReactNode;
}) => {
  const { getEnrichedNestedPath } = useSweepFieldsLabels();
  const isDynamicButtonType = action?._valueType !== 'field';
  return (
    <>
      <SlackActionRow>
        {buttonAction}
        <Box>
          <FieldLabel label={'Type'} markLabelAsRequired={true} />
          <SweepSelect
            FormControlProps={{
              sx: { minWidth: '140px', marginRight: '8px' },
            }}
            SelectProps={{
              placeholder: 'Choose a Record',
              value: (action as LinkButtonSlackAction)?._valueType ?? 'dynamic',
              onChange: (event: SelectChangeEvent<unknown>) => {
                const val = event.target.value as SlackLinkActionTypes;
                onUpdateAction({ ...action, _valueType: val }, idx);
              },
            }}
          >
            {[
              {
                value: 'dynamic',
                label: 'URL',
              },
              {
                value: 'field',
                label: 'From a Salesforce field',
              },
            ].map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </SweepSelect>
        </Box>
        {isDynamicButtonType && (
          <Box>
            <FieldLabel label={'URL'} markLabelAsRequired={true} />
            <Box
              sx={{
                width: '100%',
                minWidth: '450px',
                maxWidth: '525px',
                '.ql-container .ql-editor': {
                  borderRadius: '4px',
                },
              }}
            >
              <InputWithVariables
                crmOrgId={crmOrgId}
                objectName={objectName}
                withBorder={true}
                data={action?.url}
                placeholder={'Type URL'}
                onChange={(newMsg) => {
                  onUpdateAction({ ...action, url: newMsg }, idx);
                }}
              />
            </Box>
          </Box>
        )}
        {!isDynamicButtonType && (
          <Box>
            <FieldLabel label={'Field'} markLabelAsRequired={true} />
            <Box
              sx={{
                borderRadius: '4px',
                border: `1px solid ${colors.grey[300]}`,
              }}
            >
              <NestedFieldsSelector
                filterBy={(field: SweepField) => field?.fieldType === SweepFieldTypes.Url}
                placeholder="Choose field"
                crmOrgId={crmOrgId}
                objectType={objectName}
                nestedPath={getEnrichedNestedPath(action?.url?.variables?.[0]?.fieldIds)}
                onChange={(sweepField: NestedFieldsField) => {
                  onUpdateAction(
                    {
                      ...action,
                      url: { template: '{{{0}}}', variables: [{ fieldIds: sweepField.fieldIds }] },
                    },
                    idx,
                  );
                }}
              />
            </Box>
          </Box>
        )}
        <SlackActionButtonTextField action={action} idx={idx} onUpdateAction={onUpdateAction} />
      </SlackActionRow>
    </>
  );
};
