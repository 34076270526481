import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  RuleBuilderData,
  RuleBuilderRowComponentProps,
} from '../../../../common/rule-builder/rule-builder-types';
import { RuleBuilder } from '../../../../common/rule-builder/RuleBuilder';
import { RuleBuilderSelectionRow } from '../../../../common/rule-builder/RuleBuilderSelectionRow';
import { uniqueId } from '../../../../../lib/uniqueId';
import { HubspotFieldsOperatorSelector } from './HubspotFieldsOperatorSelector';
import { HsFilterOperatorsOperators, HsPropertyTypes } from '../hubspot.types';
import { SelectorValueTypes } from '../../../../../types/enums/SelectorValueTypes';
import { validateSweepCriteria } from '../../../../common/rule-builder/validateSweepCriteria';
import { useSelector } from 'react-redux';
import { selectHubspotMembershipListsData } from '../../../../../reducers/hubspotReducer';
import { SearchSelect, SearchSelectItem } from '../../../../common/SearchSelect';

interface RuleBuilderListMembershipConditionRowData {
  id: string;
  criterion: DeepPartial<SweepCriterion>;
}

const operators = [HsFilterOperatorsOperators.IN_LIST, HsFilterOperatorsOperators.NOT_IN_LIST];

function RuleBuilderListMembershipConditionRow({
  data,
  index,
  onRowChange,
  onRowDelete,
  readOnly,
}: RuleBuilderRowComponentProps<RuleBuilderListMembershipConditionRowData>) {
  const membershipList = useSelector(selectHubspotMembershipListsData);
  const { criterion } = data;

  const [_hsPropertyType] = criterion.fieldType ? criterion.fieldType.split(':') : [undefined];
  const hsPropertyType = _hsPropertyType as HsPropertyTypes | undefined;

  const items: SearchSelectItem[] = useMemo(() => {
    const items = membershipList.map((option) => ({
      label: option.name,
      value: option.listId,
      data: option,
    }));
    return items;
  }, [membershipList]);

  const hubspotFieldSelector = (
    <SearchSelect
      items={items}
      FormControlProps={{ fullWidth: true }}
      onChange={(item) => {
        onRowChange({
          ...data,
          criterion: {
            criterionId: uniqueId(),
            _fieldIds: ['N/A'],
            valueType: SelectorValueTypes.LITERAL,
            operator: '',
            value: item.value,
            fieldType: 'hubspot',
          },
        });
      }}
      SelectProps={{
        value: criterion.value,
      }}
      removeBorders
      disabled={readOnly}
    />
  );

  const operatorsSelector = criterion.value ? (
    <HubspotFieldsOperatorSelector
      hsPropertyType={hsPropertyType as HsPropertyTypes}
      operator={criterion.operator as HsFilterOperatorsOperators}
      disabled={readOnly}
      onChange={(operator) => {
        onRowChange({
          ...data,
          criterion: {
            ...criterion,
            operator,
          },
        });
      }}
      operators={operators}
    />
  ) : null;

  const valueSelector = null;

  return (
    <RuleBuilderSelectionRow
      lineNumber={index + 1}
      firstComponent={hubspotFieldSelector}
      secondComponent={operatorsSelector}
      thirdComponent={valueSelector}
      showDelete
      onDelete={onRowDelete}
      readonly={readOnly}
    />
  );
}

interface HubspotListMembershipRuleBuilderProps {
  sweepCondition?: DeepPartial<SweepCondition>;
  onChange: (newSweepCondition: DeepPartial<SweepCondition>) => any;
  readOnly?: boolean;
  headerRowComponent?: JSX.Element | string;
}

export type HubspotListMembershipRuleBuilderRef = {
  triggerValidation: () => string[];
};

export const HubspotListMembershipRuleBuilder = forwardRef<
  HubspotListMembershipRuleBuilderRef,
  HubspotListMembershipRuleBuilderProps
>(({ readOnly, headerRowComponent, sweepCondition, onChange }, ref) => {
  const ruleBuilderData: RuleBuilderData<RuleBuilderListMembershipConditionRowData> =
    useMemo(() => {
      const entries: RuleBuilderListMembershipConditionRowData[] = (
        sweepCondition?.criteria || []
      ).map((criterion) => {
        const id = criterion?.criterionId || uniqueId();
        return {
          id,
          criterion: criterion || {
            criterionId: id,
          },
        };
      });
      return {
        entries,
        logicString: sweepCondition?.criteriaLogic || '',
      };
    }, [sweepCondition?.criteria, sweepCondition?.criteriaLogic]);

  const newRowProvider = useCallback(() => {
    const newId = uniqueId();
    const data: RuleBuilderListMembershipConditionRowData = {
      id: newId,
      criterion: {
        criterionId: newId,
        valueType: SelectorValueTypes.LITERAL,
        operator: '',
        value: '',
        fieldType: 'hubspot',
      },
    };
    return data;
  }, []);

  const [errorIds, setErrorIds] = useState<string[]>([]);
  const [displayErrors, setDisplayErrors] = useState(false);

  const triggerValidation = useCallback(() => {
    const criteria = ruleBuilderData.entries.map((c) => c.criterion);
    const _errorIds = validateSweepCriteria(
      criteria,
      /*excludeFirstEntryFromValidationIfEmpty*/ true,
      true,
    );
    setErrorIds(_errorIds);
    if (_errorIds.length) {
      setDisplayErrors(true);
    }
    return _errorIds;
  }, [ruleBuilderData.entries]);

  useImperativeHandle(
    ref,
    () => ({
      triggerValidation,
    }),
    [triggerValidation],
  );

  useEffect(() => {
    triggerValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RuleBuilder
      readonly={readOnly}
      onChange={(data) => {
        if (data.entries.length === 0) {
          onChange({});
          return;
        }
        const newSweepCondition: DeepPartial<SweepCondition> = {
          criteriaLogic: data.logicString,
          criteria: data.entries.length ? data.entries.map((entry) => entry.criterion) : undefined,
        };

        onChange(newSweepCondition);
      }}
      newRowProvider={newRowProvider}
      ruleBuilderData={ruleBuilderData}
      RowComponent={RuleBuilderListMembershipConditionRow}
      errorIds={errorIds}
      displayErrors={displayErrors}
      headerRowComponent={headerRowComponent}
    />
  );
});
