import * as React from "react";
const SvgThreeAvatars = (props) => /* @__PURE__ */ React.createElement("svg", { width: 22, height: 20, viewBox: "0 0 22 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 6, cy: 6, r: 6, fill: "#FFD28D", style: {
  fill: "#FFD28D",
  fill: "color(display-p3 1.0000 0.8235 0.5529)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M1.94181 3.74469C1.73346 2.77453 2.58941 1.90955 3.5617 2.10772L5.72523 2.54869C5.90655 2.58565 6.09345 2.58565 6.27477 2.54869L8.4383 2.10772C9.41059 1.90955 10.2665 2.77453 10.0582 3.74469L9.64485 5.66932C9.60395 5.85974 9.60395 6.05667 9.64485 6.24709L10.0582 8.17172C10.2665 9.14188 9.41059 10.0069 8.4383 9.80868L6.27477 9.36771C6.09345 9.33075 5.90655 9.33075 5.72523 9.36771L3.5617 9.80868C2.58941 10.0069 1.73346 9.14188 1.94181 8.17172L2.35515 6.24709C2.39605 6.05667 2.39605 5.85973 2.35515 5.66932L1.94181 3.74469Z", fill: "#FFF0D9", style: {
  fill: "#FFF0D9",
  fill: "color(display-p3 1.0000 0.9412 0.8510)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("circle", { cx: 11, cy: 14, r: 6, fill: "#ACE6F9", style: {
  fill: "#ACE6F9",
  fill: "color(display-p3 0.6745 0.9020 0.9765)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.27989 11.5032C6.99395 10.8375 7.65898 10.1599 8.32989 10.4334L8.42787 10.4734C8.63437 10.5576 8.86563 10.5576 9.07213 10.4734L10.4807 9.89921C10.8136 9.76349 11.1864 9.76349 11.5193 9.89921L12.9279 10.4734C13.1344 10.5576 13.3656 10.5576 13.5721 10.4734L13.6701 10.4334C14.341 10.1599 15.006 10.8375 14.7201 11.5032C14.6324 11.7073 14.6324 11.9384 14.7201 12.1425L15.2668 13.4152C15.4157 13.7619 15.4157 14.1545 15.2668 14.5012L14.7201 15.7739C14.6324 15.978 14.6324 16.2091 14.7201 16.4132C15.006 17.0789 14.341 17.7565 13.6701 17.483L13.5721 17.443C13.3656 17.3588 13.1344 17.3588 12.9279 17.443L11.5193 18.0172C11.1864 18.1529 10.8136 18.1529 10.4807 18.0172L9.07213 17.443C8.86563 17.3588 8.63437 17.3588 8.42787 17.443L8.32989 17.483C7.65898 17.7565 6.99395 17.0789 7.27989 16.4132C7.36756 16.2091 7.36756 15.978 7.27989 15.7739L6.73322 14.5012C6.58432 14.1545 6.58432 13.7619 6.73322 13.4152L7.27989 12.1425C7.36756 11.9384 7.36756 11.7073 7.27989 11.5032Z", fill: "#DEFBFF", style: {
  fill: "#DEFBFF",
  fill: "color(display-p3 0.8706 0.9843 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 6, r: 6, fill: "#C7ADFF", style: {
  fill: "#C7ADFF",
  fill: "color(display-p3 0.7818 0.6792 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M12.6561 3.52558C12.7978 3.22738 13.0421 2.99008 13.3442 2.85701L15.4455 1.93167C15.7988 1.7761 16.2012 1.7761 16.5545 1.93167L18.6558 2.85701C18.9579 2.99008 19.2022 3.22738 19.3439 3.52559L20.2193 5.36766C20.3969 5.74133 20.3969 6.17508 20.2193 6.54875L19.3439 8.39082C19.2022 8.68903 18.9579 8.92632 18.6558 9.05939L16.5545 9.98473C16.2012 10.1403 15.7988 10.1403 15.4455 9.98473L13.3442 9.05939C13.0421 8.92632 12.7978 8.68902 12.6561 8.39082L11.7807 6.54875C11.6031 6.17508 11.6031 5.74133 11.7807 5.36766L12.6561 3.52558Z", fill: "#EBDEFF", style: {
  fill: "#EBDEFF",
  fill: "color(display-p3 0.9235 0.8708 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M12 6C12 7.01161 11.4408 7.96475 10.6639 8.80084L9.5 6L10.5 3.5L9.99979 2L6 0C9.31371 0 12 2.68629 12 6Z", fill: "#FFD28D", style: {
  fill: "#FFD28D",
  fill: "color(display-p3 1.0000 0.8235 0.5529)",
  fillOpacity: 1
} }));
export default SvgThreeAvatars;
