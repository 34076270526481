import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { ChatCompletionRequestMessageRole } from '@server/ai';
import { AiChatMessages } from '../common/ai-chat/AiChatMessages';
import { Stack, Box } from '@mui/material';
import { useCallback, useRef } from 'react';
import { colors, Typography } from '@sweep-io/sweep-design';
import { useFeatureToggle } from '../common/useFeatureToggle';

const FIRST_MSG =
  'Ask me any question about your Salesforce and I’ll give you answers based on analyzing any part of your configuration, including relations between objects, fields, or flows. permissions, profiles, errors and more.';

const CopilotScrollArea = ({
  messages,
  errorMsg,
  isLoading,
  maxWidth,
  searchTermToHighlight,
}: {
  messages: AiChatMessage[];
  errorMsg?: string; //this property should be deprecated
  isLoading: boolean;
  maxWidth?: string;
  searchTermToHighlight?: string;
}) => {
  const { aiAgentDemo } = useFeatureToggle();

  const introMessage = {
    createdAt: new Date().toISOString(),
    role: ChatCompletionRequestMessageRole.ASSISTANT,
    content: FIRST_MSG,
  };
  const ref = useRef<HTMLDivElement>();
  const scrollToBottom = useCallback(() => {
    ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        flex: 1,
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Stack padding={1.5} gap={2} paddingBottom={4} width="100%" maxWidth={maxWidth}>
        <Stack gap={2}>
          {messages.length === 0 && !aiAgentDemo && (
            <Stack textAlign="center" mt="10%">
              <Typography variant="h2-bold" color={colors.grey[800]}>
                Hi 👋,
                <br /> I'm your AI Assistant.
              </Typography>
            </Stack>
          )}
          <AiChatMessages
            messages={messages}
            errorMsg={errorMsg}
            isLoading={isLoading}
            scrollToBottom={scrollToBottom}
            introMessage={aiAgentDemo ? undefined : introMessage}
            searchTermToHighlight={searchTermToHighlight}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default CopilotScrollArea;
