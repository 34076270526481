import { Box } from '@mui/material';
import { DependenciesSection } from './DependenciesSection';
import { DetailsSection } from './DetailsSection';
import { ConfigurationChatInputWrapper } from './configuration-chat/ConfigurationChatInputWrapper';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  SWEEP_ELEMENTS,
  excludedFromDetails,
  isFieldType,
  isSupported,
  prepareConfigItemMapStructure,
} from './helpers';
import { whatDoesItDoPossibleTypes } from './configuration-chat/configurationChatConsts';
import OpenAiSection from './OpenAiSection';
import { useDependencies } from './hooks/useDependencies';
import { selectOpenAiDescriptionForRule } from '../../../reducers/documentationReducer';
import {
  ConfigurationType,
  CONFIGURATION_ITEMS_FOR_DEPENDS_ON,
  CONFIGURATIONS_FOR_USED_BY,
  ConfigurationToName,
} from './types';
import { LatestDeployedSection } from './latest-deployed/LatestDeployedSection';
import { SweepElementTypes } from '../types';
import { useGetUsedBySweepElements } from './hooks/useGetUsedBySweepElements';
import { useGetParsedDependencies } from './hooks/useGetParsedDependencies';
import { UI_EVENTS } from '../../../services/events';
import { useGetRecordTypeDependencies } from './hooks/useGetRecordTypeDependencies';
import { useGetSweepElementsDependencies } from './hooks/useGetSweepElementsDependencies';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { AiChatMessages } from '../../common/ai-chat/AiChatMessages';
import { useConfigurationChat } from './configuration-chat/useConfigurationChat';
import { telemetry } from '../../../telemetry';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { TagsSection } from './annotations/tags/TagsSection';
import { useActiveInactive } from '../activity-state/useActiveInactive';
import { ActiveState } from '../activity-state/helpers';
import { useInitializeOpenAiDescriptionForRule } from './hooks/useInitializeOpenAiDescriptionForRule';
import { DependenciesHeader } from './header/DependenciesHeader';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { getItemType } from './header/utils';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { ConfigurationItem } from './DependenciesTypes';
import { setSfFieldName } from '../../pages/rollups/rollupHelpers';

interface DependenciesContentProps {
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
  crmOrgId: string;
}

export const DependenciesContent = ({
  configurationItem,
  type,
  crmOrgId,
}: DependenciesContentProps) => {
  const { userInputsTags } = useFeatureToggle();
  const sendBiEvent = useSendBiEvent();
  const ref = useRef<HTMLDivElement>();
  const { id, name, objectApiNames, objectName, type: dependencyType } = configurationItem ?? {};
  const _objectName = objectApiNames?.[0] ?? objectName;

  const openAiDescription = useSelector(selectOpenAiDescriptionForRule(id, crmOrgId));
  const isSweepElement = SWEEP_ELEMENTS.includes(type);

  const user = useSelector(selectUserInfoData);
  const disableAi = !!user?.account?.disableAi;

  const {
    handleSetConfigurationItem,
    getConfigurationDependencies,
    getConfigurationUsedBy,
    getFieldConsumers,
    historyLength,
  } = useDependencies(crmOrgId);

  useInitializeOpenAiDescriptionForRule({ configurationItem, crmOrgId, type });

  //filters below can coexist but they shouldn't share state so 3 separate initializations are necessary
  //dependsOnActiveState, usedByActiveState, recordTypesActiveState,
  const {
    activeState: dependsOnActiveState,
    onToggleActiveInactive: _dependsOnToggleActiveInactive,
  } = useActiveInactive();

  const { activeState: usedByActiveState, onToggleActiveInactive: _usedByOnToggleActiveInactive } =
    useActiveInactive();

  const {
    activeState: recordTypesActiveState,
    onToggleActiveInactive: _recordTypesOnToggleActiveInactive,
  } = useActiveInactive();

  const _getConfigurationUsedBy = useCallback(async () => {
    if (type && !isSweepElement) {
      switch (type) {
        case ConfigurationType.fields:
          if (name && objectName && id) {
            await getFieldConsumers({
              id,
              fieldName: name,
              objectName,
              crmOrgId,
            });
          } else {
            telemetry.captureError(new Error('missing values for getFieldConsumers'), {
              name,
              objectName,
            });
          }
          break;

        case ConfigurationType.recordTypes:
          break;

        default:
          await getConfigurationUsedBy({
            ruleType: type,
            ruleId: id,
            crmOrgId,
            objectName: objectName ?? objectApiNames?.[0] ?? '',
            configurationName: name,
          });
      }
    }
  }, [
    type,
    name,
    objectName,
    getConfigurationUsedBy,
    getFieldConsumers,
    id,
    crmOrgId,
    objectApiNames,
    isSweepElement,
  ]);

  const scrollToBottom = useCallback(() => {
    ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
  }, []);

  // custom fields ids are being provided from BE as sf field name + objectName, not as real id
  const configurationIdForSweepElements =
    type === ConfigurationType.fields
      ? setSfFieldName(configurationItem.name, configurationItem.objectName ?? '')
      : id;

  const dependsOnForSweepElement = useGetSweepElementsDependencies(
    crmOrgId,
    configurationIdForSweepElements,
    type as SweepElementTypes,
    dependsOnActiveState === ActiveState.activeOnly,
  );

  const dependsOnItems = useGetParsedDependencies({
    crmOrgId,
    configurationItemId: id,
    type: 'dependsOnIdsForRules',
    name,
    objectName: objectName ?? objectApiNames?.[0] ?? '',
    isSupportedType: isSupported(CONFIGURATION_ITEMS_FOR_DEPENDS_ON, type),
    onlyActive: dependsOnActiveState === ActiveState.activeOnly,
  });

  const usedByItems = useGetParsedDependencies({
    configurationItemId: id,
    name,
    crmOrgId,
    type: type === ConfigurationType.fields ? 'usedByIdsForFields' : 'usedByIdsForRules',
    objectName: objectName ?? objectApiNames?.[0] ?? '',
    isSupportedType: isSupported(CONFIGURATIONS_FOR_USED_BY, type),
    onlyActive: usedByActiveState === ActiveState.activeOnly,
  });

  const {
    attributions: usedByRecordTypes,
    sweepElementsAttributions: usedRecordTypeBySweepElements,
  } = useGetRecordTypeDependencies({
    recordTypeName: name,
    objectName: objectName ?? objectApiNames?.[0] ?? '',
    crmOrgId,
    isSupportedType: type === ConfigurationType.recordTypes,
    onlyActive: recordTypesActiveState === ActiveState.activeOnly,
  });

  const usedBySweepElements = useGetUsedBySweepElements({
    crmOrgId,
    configurationId: configurationIdForSweepElements,
    isSupportedType: isSupported(CONFIGURATIONS_FOR_USED_BY, type),
    onlyActive: usedByActiveState === ActiveState.activeOnly,
  });

  const configurationChatObjectName = objectName ?? objectApiNames?.[0];
  const { objectTypesByName } = useObjectTypesWithFetch({
    crmOrgId,
  });
  const isAiAllowed = !disableAi && type && whatDoesItDoPossibleTypes.includes(type);
  const isField = isFieldType(type);

  const {
    onConfirmOrgChatForConfiguration,
    onConfirmOrgChatForFields,
    isLoading,
    messages,
    error,
    disableInput,
  } = useConfigurationChat();

  return (
    <>
      <DependenciesHeader
        configurationItem={configurationItem}
        type={type}
        crmOrgId={crmOrgId}
        historyLength={historyLength}
      />

      <Box p={2} overflow="auto" ref={ref} height="100%">
        {isAiAllowed && (
          <Box pb={2}>
            <OpenAiSection openAiDescription={openAiDescription} />
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {type && !excludedFromDetails.includes(type) && (
            <DetailsSection
              key={`details-${id}`}
              configurationItem={configurationItem}
              isField={isField}
              crmOrgId={crmOrgId}
            />
          )}

          <DependenciesSection
            crmOrgId={crmOrgId}
            key={`configurations-${id}.${name}`}
            id={id}
            name={name}
            objectName={objectName ?? objectApiNames?.[0] ?? ''}
            configurationItems={prepareConfigItemMapStructure(usedByRecordTypes)}
            sweepConfigurationItems={prepareConfigItemMapStructure(usedRecordTypeBySweepElements)}
            type={type}
            title="Configurations"
            isSupportedType={isSupported([ConfigurationType.recordTypes], type)}
            onChooseNewRule={handleSetConfigurationItem}
            onToggleActiveInactive={_recordTypesOnToggleActiveInactive}
            activeState={recordTypesActiveState}
          />

          <DependenciesSection
            key={`dependsOn-${id}.${name}`}
            id={id}
            name={name}
            objectName={objectName ?? objectApiNames?.[0] ?? ''}
            type={type}
            configurationItems={prepareConfigItemMapStructure(
              isSweepElement ? dependsOnForSweepElement : dependsOnItems,
            )}
            title="What does this depend on?"
            fetchDependenciesApi={isSweepElement ? undefined : getConfigurationDependencies}
            isSupportedType={isSupported(CONFIGURATION_ITEMS_FOR_DEPENDS_ON, type)}
            onChooseNewRule={handleSetConfigurationItem}
            crmOrgId={crmOrgId}
            onToggleActiveInactive={_dependsOnToggleActiveInactive}
            activeState={dependsOnActiveState}
          />

          <DependenciesSection
            crmOrgId={crmOrgId}
            key={`usedBy-${id}.${name}`}
            id={id}
            name={name}
            objectName={objectName ?? objectApiNames?.[0] ?? ''}
            configurationItems={prepareConfigItemMapStructure(usedByItems)}
            sweepConfigurationItems={prepareConfigItemMapStructure(usedBySweepElements)}
            type={type}
            title="Where is this used?"
            fetchDependenciesApi={_getConfigurationUsedBy}
            isSupportedType={isSupported(CONFIGURATIONS_FOR_USED_BY, type)}
            onChooseNewRule={handleSetConfigurationItem}
            onToggleActiveInactive={_usedByOnToggleActiveInactive}
            activeState={usedByActiveState}
          />

          {isSweepElement && (
            <LatestDeployedSection
              sweepElementType={type as SweepElementTypes}
              parentElementId={configurationItem.id}
              crmOrgId={crmOrgId}
            />
          )}

          {userInputsTags && (
            <TagsSection
              crmOrgId={crmOrgId}
              configurationItemId={configurationItem.id}
              objectNames={objectName ? [objectName] : (objectApiNames as string[])}
              configurationItemType={
                ConfigurationToName[configurationItem.parentType as ConfigurationType] ??
                configurationItem.parentType
              }
            />
          )}

          {!disableAi && (
            <AiChatMessages
              scrollToBottom={scrollToBottom}
              messages={messages}
              isLoading={isLoading}
              errorMsg={error}
            />
          )}
        </Box>
      </Box>

      {type && name && !disableAi && (
        <ConfigurationChatInputWrapper
          onConfirm={(msg: string) => {
            sendBiEvent({
              name: UI_EVENTS.documentationAiEngage,
              props: { object: configurationChatObjectName, element: type },
            });

            if (type === ConfigurationType.fields || type === ConfigurationType.rollups) {
              onConfirmOrgChatForFields({
                message: msg,
                crmOrgId,
                objectName: configurationChatObjectName ?? '',
                fieldName: name,
              });
              return;
            }

            onConfirmOrgChatForConfiguration({
              message: msg,
              crmOrgId,
              filename: configurationItem.filename ?? '',
            });
          }}
          isLoading={isLoading}
          configurationKey={type}
          itemType={getItemType({
            objectTypesByName,
            _objectName,
            parentType: type,
            dependencyType,
          })}
          disableInput={disableInput}
          messagesCount={messages.length}
        />
      )}
    </>
  );
};
