import { Box, Card, Stack, styled } from '@mui/material';
import AgentInfoSmall from './AgentInfoSmall';
import { colors } from '@sweep-io/sweep-design';
import NewProcessAgentIcon from './svg/newProcessAgentIcon.svg?react';

const CreateNewAgentCard = ({
  onClick,
  variant,
}: {
  onClick: () => void;
  variant: 'small' | 'big';
}) => {
  return (
    <Card onClick={onClick} sx={{ borderColor: '#E4F2F8', overflow: 'visible' }}>
      <Stack p={2} gap={2}>
        <AgentInfoSmall
          icon={<NewProcessAgentIcon />}
          name="Create new visual agent"
          persona="Helps revenue teams detect strategic optimization opportunities for business processes"
        />
        {variant === 'big' && (
          <Stack gap={2}>
            {' '}
            <Stack gap={1}>
              <DescriptionSkeleton width="334px" />
              <DescriptionSkeleton width="295px" />
            </Stack>
            <Stack direction="row" gap={1}>
              <TagSkeleton />
              <TagSkeleton />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

const DescriptionSkeleton = styled(Box)({
  height: '24px',
  borderRadius: '4px',
  backgroundColor: colors.grey[100],
});

const TagSkeleton = styled(Box)({
  height: '24px',
  border: `1px solid ${colors.grey[300]}`,
  borderRadius: '2px',
  width: '71px',
});

export default CreateNewAgentCard;
