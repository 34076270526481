import { useContext, useCallback } from 'react';
import { useCampaignsApiFacade } from '../../../../apis/facades/useCampaignsApiFacade';
import { CampaignMemberStatus } from '../canvas-hubspot-page/campaign-dialog/campaignTypes';
import { CampaignMemberStatusContext } from './CampaignMemberStatusContext';

export const useCampaignMemberStatuses = () => {
  const { patch_campaign_member_status } = useCampaignsApiFacade();
  const { campaignMemberStatuses, setCampaignMemberStatuses } = useContext(
    CampaignMemberStatusContext,
  );

  // Update the status of a campaign member
  // The IsDefault is unique, so we need to update the other statuses to false
  // if the new status is default
  const updateCampaignMemberStatus = useCallback(
    async ({
      Id,
      HasResponded,
      IsDefault,
    }: Pick<CampaignMemberStatus, 'IsDefault' | 'HasResponded' | 'Id'>) => {
      const exitingStatus = campaignMemberStatuses.find((status) => status.Id === Id);
      if (!exitingStatus) {
        return;
      }
      const newStatus = { ...exitingStatus, HasResponded, IsDefault };

      const newStatuses = campaignMemberStatuses.map((status) => {
        if (status.Id === Id) {
          return newStatus;
        }
        if (IsDefault) {
          status.IsDefault = false;
        }
        return status;
      });
      setCampaignMemberStatuses(newStatuses);
      await patch_campaign_member_status(newStatus);
    },
    [campaignMemberStatuses, patch_campaign_member_status, setCampaignMemberStatuses],
  );

  return {
    campaignMemberStatuses,
    updateCampaignMemberStatus,
  };
};
