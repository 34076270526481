import './App.css';
import { Outlet } from 'react-router';
import { useAuthenticateUser } from '../hooks/useAuthenticateUser';
import LoadingSweep from './common/LoadingSweep';
import { GeneralError } from './pages/GeneralError';
import VerificationNeeded from './pages/VerificationNeeded';
import { Notifications } from './notifications/Notifications';
import ErrorBoundary from '../ErrorBoundary';

const AuthenticatedApp = () => {
  //Here goes logic that is relevant to all routes (including "*Auth2Redirect")
  return <Outlet />;
};

export const App = () => {
  const { userInfo, error, notVerifiedAuthUserDetails } = useAuthenticateUser();
  const { isVerificationNeeded, authUserId, authUserEmail } = notVerifiedAuthUserDetails;
  const renderError = !!error;

  return (
    <ErrorBoundary>
      <div data-testid="sweep-app" style={{ height: '100%' }}>
        <Notifications />
        {userInfo ? (
          <AuthenticatedApp />
        ) : isVerificationNeeded ? (
          <VerificationNeeded authUserId={authUserId} authUserEmail={authUserEmail} />
        ) : renderError ? (
          <GeneralError />
        ) : (
          <LoadingSweep />
        )}
      </div>
    </ErrorBoundary>
  );
};
