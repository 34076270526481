import { UserInputTypes } from '@server/user-inputs';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback } from 'react';
import { getRandomColorName } from '../../../../../lib/getRandomColorName';
import { InlineAutocompleteItem } from '../../../../common/InlineAutocomplete/InlineAutocomplete';
import { createUserInputData } from './utils';
import { useDependencies } from '../../hooks/useDependencies';
import { useDispatch, useSelector } from 'react-redux';
import {
  openEditTag,
  selectDependencyCustomNotesById,
} from '../../../../../reducers/documentationReducer';
import { useTags } from './useTags';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';
import { ACTIONS_EVENTS } from '../../../../../services/events';

export const useConfigurationTags = (configurationItemId: string, crmOrgId: string) => {
  const { onAddTag } = useTags(crmOrgId);
  const { updateDependencyTags } = useDependencies(crmOrgId);
  const sendBiEvent = useSendBiEvent();
  const dispatch = useDispatch();

  const dependencyTags = useSelector(selectDependencyCustomNotesById(configurationItemId));

  const onSelectItem = useCallback(
    (
      item: InlineAutocompleteItem,
      parentKey: UserInputTypes,
      notParsedObjects: string[],
      elementType: string,
    ) => {
      const newCustomNotes = dependencyTags ? cloneDeep(dependencyTags) : createUserInputData();

      if (parentKey === UserInputTypes.Owner) {
        newCustomNotes[parentKey] = item.value;
        sendBiEvent({
          name: ACTIONS_EVENTS.documentationAddOwner,
          props: { ownerName: item.label, element: elementType },
        });
      } else {
        newCustomNotes[parentKey].push(item.value);

        if (UserInputTypes.Collaborators) {
          sendBiEvent({
            name: ACTIONS_EVENTS.documentationAddCollaborator,
            props: { collaboratorName: item.label, element: elementType },
          });
        }

        if (UserInputTypes.Tags) {
          sendBiEvent({
            name: ACTIONS_EVENTS.documentationAddTag,
            props: { tagName: item.label, element: elementType },
          });
        }
      }

      updateDependencyTags({ [configurationItemId]: newCustomNotes }, notParsedObjects);
    },
    [dependencyTags, configurationItemId, updateDependencyTags, sendBiEvent],
  );

  const onCreateTag = useCallback(
    async (name: string, notParsedObjects: string[], elementType: string) => {
      const colorName = getRandomColorName();
      const tagId = await onAddTag({ name, color: colorName });

      if (tagId) {
        const newDependencyTags = cloneDeep(dependencyTags) ?? createUserInputData();
        newDependencyTags[UserInputTypes.Tags].push(tagId);

        if (UserInputTypes.Tags) {
          sendBiEvent({
            name: ACTIONS_EVENTS.documentationAddTag,
            props: { tagName: name, element: elementType },
          });
        }

        updateDependencyTags(
          {
            [configurationItemId]: newDependencyTags,
          },
          notParsedObjects,
        );
      }
    },
    [configurationItemId, dependencyTags, onAddTag, sendBiEvent, updateDependencyTags],
  );

  const onDeleteItem = useCallback(
    (item: InlineAutocompleteItem, parentKey: UserInputTypes, elementType: string) => {
      const newDependencyTags = cloneDeep(dependencyTags);

      if (newDependencyTags) {
        switch (parentKey) {
          case UserInputTypes.Owner:
            newDependencyTags[parentKey] = '';
            sendBiEvent({
              name: ACTIONS_EVENTS.documentationRemoveOwner,
              props: { ownerName: item.label, element: elementType },
            });
            break;
          default:
            newDependencyTags[parentKey] = newDependencyTags[parentKey].filter(
              (tagId) => tagId !== item.value,
            );

            if (UserInputTypes.Tags === parentKey) {
              sendBiEvent({
                name: ACTIONS_EVENTS.documentationRemoveTag,
                props: { tagName: item.label, element: elementType },
              });
            }

            if (UserInputTypes.Collaborators === parentKey) {
              sendBiEvent({
                name: ACTIONS_EVENTS.documentationRemoveCollaborator,
                props: { collaboratorName: item.label, element: elementType },
              });
            }
        }

        updateDependencyTags({ [configurationItemId]: newDependencyTags });
      }
    },
    [dependencyTags, configurationItemId, updateDependencyTags, sendBiEvent],
  );

  const onTagClick = useCallback(
    (tag: InlineAutocompleteItem) => {
      dispatch(openEditTag({ tagId: tag.value, tagName: tag.label }));
    },
    [dispatch],
  );

  return { onSelectItem, onDeleteItem, onCreateTag, onTagClick };
};
