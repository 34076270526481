import { Box, Paper, Tooltip } from '@mui/material';
import { Typography, colors, __SWEEP_DESIGN_TYPOGRAPHY_STYLES___ } from '@sweep-io/sweep-design';
import { NodeProps } from 'reactflow';
import { GroupLabelNodeData } from '../canvasTypes/nodeTypesData';
import { ObjectTypeChip } from '../../common/ObjectTypeChip';
import { PluginTypes } from '../../../types/enums/PluginTypes';
import {
  PluginDataManagementSvg,
  PluginBantSvg,
  PluginDynamicPathSvg,
  PluginMeddicSvg,
  PluginMeddpiccSvg,
} from '../../pages/funnel-map-flow/templates/plugins/icons';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import { Pills } from './components/pills/Pills';
import { AggregatedPill } from './components/pills/AggregatedPill';
import { CanvasPill, NodeEntityTypes } from '../canvasTypes';
import { CONTEXT_ZOOM_DEFAULT_CLASS_NAME } from './contextZoomConsts';
import { getObjectTypeColor, objectTypeColorNames } from '../helpers/getObjectTypeColor';
import HubspotIcon from './svg/hubspot.svg?react';
import { LogoDevIcon } from '../../common/logo-dev-selector/LogoDevIcon';
import { capitalizeString } from '../../../lib/capitalizeFirstLetter';

const MIN_ZOOM_LEVEL_LABEL_SCALE = 0.18;

type PluginsDataType = {
  [key in PluginTypes]: {
    tooltip: string;
    icon: JSX.Element;
  };
};

const pluginsData: PluginsDataType = {
  [PluginTypes.DATA_MANAGEMENT]: {
    tooltip: 'Data Management',
    icon: <PluginDataManagementSvg />,
  },
  [PluginTypes.BANT]: {
    tooltip: 'BANT',
    icon: <PluginBantSvg />,
  },
  [PluginTypes.DYNAMIC_PATH]: {
    tooltip: 'Dynamic Path',
    icon: <PluginDynamicPathSvg />,
  },
  [PluginTypes.MEDDIC]: {
    tooltip: 'MEDDIC',
    icon: <PluginMeddicSvg />,
  },
  [PluginTypes.MEDDPICC]: {
    tooltip: 'MEDDIC',
    icon: <PluginMeddpiccSvg />,
  },
};

const PluginButton = styled(Box)`
  cursor: pointer;
  display: flex;
  border: 2px solid ${colors.white};
  border-radius: 4px;
  svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
    opacity: 0.8;
    border-color: ${colors.blue[500]};
  }
`;

const getSweepLabelBackgroundColors = () =>
  objectTypeColorNames.reduce((acc, objectType) => {
    const color = getObjectTypeColor(objectType).step;
    return {
      ...acc,
      [`&.object-type-${objectType}`]: {
        backgroundColor: color,
      },
    };
  }, {});

export const groupLabelStyle = {
  '.sweep-group-label-node .group-label-bottom-row, .sweep-group-label-node .aggregated-pill': {
    display: 'none',
  },
  '.sweep-group-label-node .group-label-paper': {},

  [`&.${CONTEXT_ZOOM_DEFAULT_CLASS_NAME}`]: {
    '.sweep-group-label-node .group-label-bottom-row': {
      display: 'block',
    },
    '.sweep-group-label-node .aggregated-pill': {
      display: 'none',
    },
  },
  [`&:not(.${CONTEXT_ZOOM_DEFAULT_CLASS_NAME})`]: {
    '.sweep-group-label-node .group-label-bottom-row': {
      display: 'none',
    },
    '.sweep-group-label-node .aggregated-pill': {
      display: 'block',
    },
    '.sweep-group-label-node .group-label-paper': {
      backgroundColor: getObjectTypeColor().step,
      ...getSweepLabelBackgroundColors(),
    },
    '.sweep-group-label-node .object-type-chip': {
      '&, span': {
        backgroundColor: '#fff',
      },
    },
  },
};

export const getGroupLabelStyleTransformations = (zoomLevel: number) => {
  const _zoomLevel =
    zoomLevel < MIN_ZOOM_LEVEL_LABEL_SCALE ? MIN_ZOOM_LEVEL_LABEL_SCALE : zoomLevel;
  const transform = `scale(${1 / _zoomLevel})`;

  return {
    [`&:not(.${CONTEXT_ZOOM_DEFAULT_CLASS_NAME})`]: {
      '.sweep-group-label-node .sweep-group-label-transform': {
        transform,
      },
    },
  };
};

const GroupLabelNode = ({ data }: NodeProps<GroupLabelNodeData>) => {
  if (data.hideGroupInfo) {
    return <></>;
  }

  const showPills = Boolean(data.pills?.length);
  const showBottomRow = showPills || data.description;
  const showAggregatedPills = false;

  const showClickableIcon = !!data.onLabelClick;
  const startIcon = data.objectLabelLogoDevBrand ? (
    <LogoDevIcon brand={data.objectLabelLogoDevBrand} size={16} quality="higher" />
  ) : undefined;

  return (
    <Box
      data-testid="sweep-group-label-node"
      className="sweep-group-label-node"
      sx={{
        width: '1px',
        height: '1px',
        cursor: data.isDragging ? 'move' : showClickableIcon ? 'pointer' : 'move',
      }}
      onClick={(event) => {
        data.onLabelClick?.({
          event,
          entity: { type: NodeEntityTypes.GroupLabel },
          id: data.groupId,
        });
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '30px',
          left: '52px',
        }}
      >
        <Box
          sx={{
            transformOrigin: 'top left',
          }}
          className="sweep-group-label-transform"
        >
          <Paper
            elevation={1}
            sx={{
              borderRadius: '8px',
              border: '2px solid',
              borderColor: 'transparent',
              transition: 'width .3s ease, background-color 0.5s ease',
              display: 'flex',
              flexDirection: 'column',
              padding: `12px 16px`,
              gap: '8px',
            }}
            className={`group-label-paper object-type-${data.objectType}`}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                width: 'auto',
                '&:hover': {
                  borderColor: colors.blue[500],
                },
                '.object-type-chip div': {
                  display: 'flex',
                  gap: 0.5,
                  alignItems: 'center',
                },
              }}
            >
              {data.startingIcon === 'hubspot' && <HubspotIcon />}
              <Typography variant="h2" whiteSpace="nowrap">
                {data.label}
              </Typography>
              <ObjectTypeChip
                objectType={data.objectType}
                label={capitalizeString(data.objectLabel)}
                startIcon={startIcon}
                tagVariant="medium"
                noObjectNameTooltip={['__hubspot__', '__third_party_funnel__'].includes(
                  data.objectType,
                )}
              />
              {data.labelPlugins.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    width: 'auto',
                  }}
                >
                  {data.labelPlugins.map((plugin) => (
                    <Tooltip key={plugin.type} title={`${pluginsData[plugin.type].tooltip} plugin`}>
                      <PluginButton
                        ref={plugin.ref}
                        onClick={(e) => {
                          e.stopPropagation();
                          plugin.onClick && plugin.onClick(e);
                        }}
                      >
                        {pluginsData[plugin.type].icon}
                      </PluginButton>
                    </Tooltip>
                  ))}
                </Box>
              )}
              {showPills && showAggregatedPills && <AggregatedPill data={data} />}
            </Box>

            {showBottomRow && (
              <Box
                className="group-label-bottom-row"
                sx={{
                  borderTop: `1px solid ${colors.grey[200]}`,
                  paddingTop: '12px',
                  lineHeight: '14px',
                  ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___.caption,
                  color: colors.grey[700],
                }}
              >
                {data.description && <>{data.description}</>}
                {data.pills?.length && (
                  <Pills
                    nodeId={data.groupId}
                    nodeLabel={data.label}
                    objectType={data.objectType}
                    pills={data.pills as CanvasPill[]}
                    onPillClick={data.onPillClick}
                    entityType={NodeEntityTypes.GroupPill}
                  />
                )}
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

const _memoGroupLabelNode = memo(GroupLabelNode);

export default _memoGroupLabelNode;
