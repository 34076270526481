import { Box, Stack } from '@mui/material';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { ChevronLeft } from '@sweep-io/sweep-design/dist/icons';
import AgentInfoSmall from './AgentInfoSmall';
import AiAgentChat from './AiAgentChat';
import { ReactNode } from 'react';
import useAiAgentChats from './useAiAgentChats';
import { AI_AGENTS_INFO, TOP_BAR_COMMON_SX } from './aiAgentsConsts';
import useAiAgents from './useAiAgents';
import { AgentType, ChatCompletionRequestMessageRole } from '@server/ai';

const DOCUMENTATION_FIRST_MSG =
  'Ask me any question about your Salesforce and I’ll give you answers based on analyzing any part of your configuration, including relations between objects, fields, or flows. permissions, profiles, errors and more.';

const ChatWithAgentCollapsed = ({
  instructionsButton,
  toggleExpandButton,
  closeButton,
  returnToAgentsList,
}: {
  instructionsButton: ReactNode;
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
  returnToAgentsList: () => void;
}) => {
  const { onConfirmMessage, activeChat, disableInput, createChat } = useAiAgentChats();
  const { activeAgent, renameAgent } = useAiAgents();

  if (!activeAgent) {
    return null;
  }

  const { icon, persona } = AI_AGENTS_INFO[activeAgent.type];

  const newButton = (
    <Button
      startIconName="Plus"
      variant="outlined"
      onClick={() => createChat(activeAgent.id)}
      size="small"
    >
      New chat
    </Button>
  );

  const introMessage =
    activeAgent?.type === AgentType.Documentation
      ? {
          createdAt: new Date().toISOString(),
          role: ChatCompletionRequestMessageRole.ASSISTANT,
          content: DOCUMENTATION_FIRST_MSG,
        }
      : {
        createdAt: new Date().toISOString(),
        role: ChatCompletionRequestMessageRole.ASSISTANT,
        content: 'Remove me when BE sends the real message', //TODO
      };

  return (
    <Stack height="100%">
      <Box
        sx={{
          ...TOP_BAR_COMMON_SX,
          borderBottom: `1px solid ${colors.grey[200]}`,
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          <IconButton variant="flat" size="tiny" onClick={returnToAgentsList}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="body-bold">AI Agents</Typography>
        </Box>

        <Box display="flex" gap={1} alignItems="center">
          {newButton}
          {toggleExpandButton}
          {closeButton}
        </Box>
      </Box>

      <Stack p="24px 12px 16px">
        <Stack direction="row" justifyContent="space-between">
          <AgentInfoSmall
            icon={icon}
            persona={persona}
            name={activeAgent.name}
            onEditName={(name) => renameAgent(activeAgent.id, name)}
          />
          <Box>{instructionsButton}</Box>
        </Stack>
      </Stack>

      <AiAgentChat
        messages={activeChat?.data?.aiChatDetails?.messages ?? []}
        onConfirm={onConfirmMessage}
        isLoadingResponse={!!activeChat?.data?.isLoading}
        disableInput={disableInput}
        noteAlignment="left"
        introMessage={introMessage}
      />
    </Stack>
  );
};

export default ChatWithAgentCollapsed;
