import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { RoutingTabs, useRoutingTabs } from './useRoutingTabs';
import { RoutingElementsList } from './RoutingElementsList';
import { RoutingTabContent } from './RoutingTabContent';
import { ConfigurationCanvasLeftPanel } from '../pages/configuration-canvas-panel';
import { useCallback } from 'react';
import MembersDialog from './MembersDialog';
import GroupsDialog from './GroupsDialog';
import TerritoriesDialog from './TerritoriesDialog';
import { RoutingTabsInfo } from './routingTabsInfo';
import { useSelector } from 'react-redux';
import { useRunOnce } from '../common/useRunOnce';
import { AutomationAssignmentsContextProvider } from './AutomationAssignmentsContextProvider';
import { AutomationScheduledAssignmentsContextProvider } from './AutomationScheduledAssignmentsContextProvider';
import { RoutingAutomationsContextWrapper } from './RoutingAutomationsContextWrapper';
import usePaywall, { PaywallAction } from '../dashboard/paywall/usePaywall';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { RoutingContextProvider } from './RoutingContext';

const useIsEmptyState = (routingTab?: RoutingTabs) => {
  const routeInfo = routingTab ? RoutingTabsInfo[routingTab] : undefined;
  const { selector } = routeInfo ?? {};
  const items = useSelector(selector ?? (() => undefined));
  return items?.length === 0;
};

const RoutingPage = () => {
  const { appCrmOrgId } = useConfigurationCanvas();
  const { clearTab, changeTab, routingTab } = useRoutingTabs();
  const isNoAssignments = useIsEmptyState(RoutingTabs.assignment);
  const isMenuNavigation = !routingTab;
  const disableOpenAnimation = !!routingTab;
  const transitionDuration = disableOpenAnimation ? 0 : undefined;
  const { isExpandedMode } = useExpandedMode();
  const isEmptyState = useIsEmptyState(routingTab);
  const isDrillableRoute = routingTab ? RoutingTabsInfo[routingTab].drillable : false;

  const renderElementsList = isExpandedMode ? false : !isDrillableRoute || isEmptyState;

  const { paywallAction } = usePaywall();
  const isPaywallV2Block = paywallAction === PaywallAction.TALK_TO_SALES;
  const isExpandAllowed = !isPaywallV2Block;

  const goToTab = (tab: RoutingTabs) => {
    changeTab(tab, appCrmOrgId);
  };

  const goToRoutingHome = useCallback(() => {
    clearTab(appCrmOrgId);
  }, [appCrmOrgId, clearTab]);

  //First Time Experience product: if entering "routing" and there are no assignments, open the templates dialog
  //(should not applicable for deep links, only menu navigation)
  useRunOnce(() => {
    if (isNoAssignments && isMenuNavigation) {
      changeTab(RoutingTabs.assignment, appCrmOrgId);
    }
  });

  return (
    <RoutingContextProvider>
      <ConfigurationCanvasLeftPanel
        withExpandedModeButton={isExpandAllowed}
        sx={{
          gap: isExpandedMode && !!routingTab ? 1 : 2,
          '.configuration-canvas-panel-content': {
            overflow: 'hidden',
          },
        }}
      >
        {renderElementsList && (
          <RoutingElementsList
            goToTab={goToTab}
            withExpandedModeButton={isExpandAllowed}
            isExpanded={!!isExpandedMode}
          />
        )}
        {!renderElementsList && (
          <RoutingTabContent goToTab={goToTab} goToRoutingHome={goToRoutingHome} />
        )}
      </ConfigurationCanvasLeftPanel>

      {routingTab === RoutingTabs.assignment && (
        <AutomationAssignmentsContextProvider>
          <RoutingAutomationsContextWrapper
            goToRoutingHome={goToRoutingHome}
            isExpanded={!!isExpandedMode}
          />
        </AutomationAssignmentsContextProvider>
      )}

      {routingTab === RoutingTabs.scheduled && (
        <AutomationScheduledAssignmentsContextProvider>
          <RoutingAutomationsContextWrapper
            goToRoutingHome={goToRoutingHome}
            isExpanded={!!isExpandedMode}
          />
        </AutomationScheduledAssignmentsContextProvider>
      )}

      {routingTab === RoutingTabs.members && !isExpandedMode && (
        <MembersDialog closeDialog={goToRoutingHome} transitionDuration={transitionDuration} />
      )}
      {routingTab === RoutingTabs.groups && !isExpandedMode && (
        <GroupsDialog closeDialog={goToRoutingHome} transitionDuration={transitionDuration} />
      )}
      {routingTab === RoutingTabs.territories && !isExpandedMode && (
        <TerritoriesDialog closeDialog={goToRoutingHome} transitionDuration={transitionDuration} />
      )}
    </RoutingContextProvider>
  );
};

export default RoutingPage;
