import { useCallback } from 'react';
import { useFeatureToggle } from '../common/useFeatureToggle';
import useAiAgentChats from './useAiAgentChats';
import useCopilotChats from '../copilotChats/useCopilotChats';
import useAiAgents from './useAiAgents';
import useAiAgentCenter from './useAiAgentCenter';

const useChatsEntryPoint = () => {
  const { aiAgentCenter } = useFeatureToggle();
  const {
    fetchChats: fetchChatsCopilot,
    isOpened: isOpenedCopilot,
    isExpanded: isExpandedCopilot,
    toggleIsExpanded: toggleExpandedCopilot,
    toggleIsOpened: toggleOpenedCopilot,
  } = useCopilotChats();
  const { fetchChats: fetchChatsAiAgentCenter } = useAiAgentChats();
  const {
    isOpened: isOpenedAiAgentCenter,
    isExpanded: isExpandedAiAgentCenter,
    toggleIsOpened: toggleOpenedAiChats,
    toggleIsExpanded: toggleExpandedAiAgentCenter,
  } = useAiAgentCenter();
  const { fetchAiAgents } = useAiAgents();

  const toggleIsOpened = useCallback(() => {
    if (aiAgentCenter) {
      toggleOpenedAiChats();
    } else {
      toggleOpenedCopilot();
    }
  }, [aiAgentCenter, toggleOpenedAiChats, toggleOpenedCopilot]);

  const toggleIsExpanded = useCallback(() => {
    if (aiAgentCenter) {
      toggleExpandedAiAgentCenter();
    } else {
      toggleExpandedCopilot();
    }
  }, [aiAgentCenter, toggleExpandedAiAgentCenter, toggleExpandedCopilot]);

  const isOpened = aiAgentCenter ? isOpenedAiAgentCenter : isOpenedCopilot;
  const isExpanded = aiAgentCenter ? isExpandedAiAgentCenter : isExpandedCopilot;

  const fetchChatsAndAgents = useCallback(() => {
    if (aiAgentCenter) {
      fetchChatsAiAgentCenter();
    } else {
      fetchChatsCopilot();
    }
    fetchAiAgents();
  }, [fetchAiAgents, fetchChatsAiAgentCenter, fetchChatsCopilot, aiAgentCenter]);

  return {
    toggleIsOpened,
    toggleIsExpanded,
    isOpened,
    isExpanded,
    fetchChatsAndAgents,
  };
};

export default useChatsEntryPoint;
