import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { Box } from '@mui/material';
import { AiChat } from '../common/ai-chat/aiChatTypes';
import { NoResults } from '../documentation/shared/NoResults';
import { useChatSections } from '../aiAgentCenter/useChatSections';
import ChatsSectionLayout from '../aiAgentCenter/ChatsSectionLayout';
import ChatListItemSearch from './ChatListItemSearch';

const ChatSearchResults = ({
  isLoadingSearch,
  searchResults,
  searchText,
  activeChatId,
  setActiveChatId,
}: {
  isLoadingSearch: boolean;
  searchResults?: AiChat[];
  searchText?: string;
  activeChatId?: string;
  setActiveChatId: (id: string) => Promise<void>;
}) => {
  const noResults = !!searchText && !isLoadingSearch && searchResults && searchResults.length === 0;
  const sections = useChatSections(searchResults);

  if (noResults) {
    return (
      <Box pr={1} pl={2}>
        <NoResults />
      </Box>
    );
  }
  return (
    <>
      {isLoadingSearch && <CenteredCircularProgress />}
      {!isLoadingSearch &&
        sections.map((section) =>
          section.items.length > 0 ? (
            <ChatsSectionLayout key={section.title} title={section.title}>
              {section.items.map((chat) => (
                <ChatListItemSearch
                  key={chat.id}
                  chat={chat}
                  isActive={activeChatId === chat.id}
                  onClick={() => setActiveChatId(chat.id)}
                />
              ))}
            </ChatsSectionLayout>
          ) : null,
        )}
    </>
  );
};

export default ChatSearchResults;
