import { createContext, Dispatch, SetStateAction } from 'react';
import { CampaignMemberStatus } from '../canvas-hubspot-page/campaign-dialog/campaignTypes';

export type LoadStatus = 'idle' | 'loading' | 'success' | 'error';

interface CampaignMemberStatusContextProps {
  campaignMemberStatuses: CampaignMemberStatus[];
  setCampaignMemberStatuses: Dispatch<SetStateAction<CampaignMemberStatus[]>>;
  loadStatus: LoadStatus;
  setLoadStatus: Dispatch<SetStateAction<LoadStatus>>;
  initialized: boolean;
}

export const CampaignMemberStatusContext = createContext<CampaignMemberStatusContextProps>({
  campaignMemberStatuses: [],
  setCampaignMemberStatuses: () => {},
  loadStatus: 'idle',
  setLoadStatus: () => {},
  initialized: false,
});
