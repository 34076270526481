import { Box, MenuItem, Stack, TextField } from '@mui/material';
import { Section } from './atoms/Section';
import { colors, Typography } from '@sweep-io/sweep-design';
import {
  UserDefinedRoleGroupsInterface,
  RoleGroupType,
  RoleLevel,
  Sections,
} from '@server/role-group-interface';
import SweepSelect from '../../common/SweepSelect';
import { featureTranslations, roleTranslations } from './utils';
import { useRoleManagerContext } from './RoleManagerContext';

interface SingleRoleDetailsProps {
  role: UserDefinedRoleGroupsInterface;
}

// This will filter out the 'REQUEST_TO_DEPLOY' roleKey if the roleLevel is 'hubspot'
// until we add the request to deploy feature to HubSpot
const temporarilyFilterHubspotRequestToDeploy = (roleLevel: string) => (roleKey: string) => {
  if (roleLevel === 'hubspot' && roleKey === 'REQUEST_TO_DEPLOY') {
    return false;
  }

  return true;
};

export const SingleRoleDetails = ({ role }: SingleRoleDetailsProps) => {
  const { updateRole } = useRoleManagerContext();
  const isEditable = role.type !== RoleGroupType.SYSTEM;

  return (
    <Stack>
      <Section headerTxt="Description">
        {!isEditable && (
          <Typography variant="body" color={colors.grey[800]}>
            {role.description}
          </Typography>
        )}

        {isEditable && (
          <TextField
            multiline
            rows={2}
            value={role.description ?? ''}
            onChange={(e) => updateRole('description', e.target.value)}
            placeholder="What is this role used for?"
          />
        )}
      </Section>

      <Section headerTxt="Permissions" withBorderBottom>
        <Box>
          {Sections.map((roleLevel) => {
            const groupValue = role.roleLevels?.[roleLevel];

            return (
              <Box
                key={roleLevel}
                display="flex"
                alignItems="center"
                padding="10px"
                pl="2px"
                height="52px"
                sx={{
                  '&:hover': {
                    backgroundColor: isEditable ? colors.grey[100] : 'transparent',
                  },
                }}
              >
                <Box minWidth="166px">
                  <Typography variant="caption">
                    {featureTranslations[roleLevel as keyof typeof featureTranslations]}
                  </Typography>
                </Box>

                <Box width="100%">
                  {isEditable && (
                    <SweepSelect
                      FormControlProps={{ sx: { width: '100%' } }}
                      SelectProps={{
                        value: groupValue ?? '',
                        placeholder: 'Select',
                        onChange: (event) => {
                          updateRole('roleLevels', {
                            ...role.roleLevels,
                            [roleLevel]: event.target.value as RoleLevel,
                          });
                        },
                      }}
                    >
                      {Object.keys(RoleLevel)
                        .filter(temporarilyFilterHubspotRequestToDeploy(roleLevel))
                        .map((roleKey) => (
                          <MenuItem
                            key={roleKey}
                            value={RoleLevel[roleKey as keyof typeof RoleLevel]}
                          >
                            {roleTranslations[roleKey as keyof typeof roleTranslations]}
                          </MenuItem>
                        ))}
                    </SweepSelect>
                  )}

                  {!isEditable && (
                    <Typography variant="caption" color={colors.grey[800]}>
                      {roleTranslations[groupValue as keyof typeof roleTranslations]}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Section>
    </Stack>
  );
};
