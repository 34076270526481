import AgentDocumentationAvatar from './svg/agentDocumentationAvatar.svg?react';
import AgentDocumentationIcon from './svg/agentDocumentationIcon.svg?react';
import AgentProcessAvatar from './svg/agentProcessAvatar.svg?react';
import AgentProcessIcon from './svg/agentProcessIcon.svg?react';
import ChatDocumentationIcon from './svg/chatDocumentationIcon.svg?react';
import ChatProcessIcon from './svg/chatProcessIcon.svg?react';
import { AgentType, AiAgentProperties } from '@server/ai';

export const CHAT_AREA_MAX_WIDTH = '924px';

//TODO use type from BE?
export interface SelectedStagesInFunnel {
  funnelId: string;
  stagesId: string[];
}

export interface AiAgent extends Omit<AiAgentProperties, 'updatedAt' | 'createdAt'> {
  updatedAt: string; //in the BE it is "Date" so can't reuse the type

  funnelSteps: SelectedStagesInFunnel[]; //TODO remove when added to AiAgentProperties
}

export const AI_AGENTS_INFO = {
  [AgentType.Documentation]: {
    defaultName: 'Documentation Agent',
    persona: 'Salesforce Architect',
    description:
      'Helps Admins to uncover Salesforce configurations, troubleshoot issues, and map dependencies.',
    tags: ['Map dependencies', 'Troubleshoot errors'],
    assistantAvatar: <AgentDocumentationAvatar />,
    icon: <AgentDocumentationIcon />,
    chatIcon: <ChatDocumentationIcon />,
  },
  [AgentType.ProcessOptimization]: {
    defaultName: 'Process Optimization Agent',
    persona: 'Business Analyst',
    description:
      'Identifies optimization opportunities and provides actionable analysis to enhance funnel performance.',
    tags: ['Analyze business process', ' Optimize processes'],
    assistantAvatar: <AgentProcessAvatar />,
    icon: <AgentProcessIcon />,
    chatIcon: <ChatProcessIcon />,
  },
};

export const TOP_BAR_COMMON_SX = {
  display: 'flex',
  alignItems: 'center',
  height: '56px',
  padding: '12px',
  gap: '8px',
};
