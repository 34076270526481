export enum WorkflowActionType {
  ENROLL_CAMPAIGN = 'SET_SALESFORCE_CAMPAIGN_MEMBERSHIP',
  EDIT_RECORD = 'EDIT_RECORD',
}

export enum WorkflowWhenType {
  FILTERS = 'FILTERS',
  EVENT = 'EVENT',
}

interface WorkflowActionBase {
  _id: string;
  actionType: WorkflowActionType;
  condition?: WorkflowWhenFilters;
}

export interface WorkflowActionEnrollCampaignDto extends WorkflowActionBase {
  actionType: WorkflowActionType.ENROLL_CAMPAIGN;
  campaignId: string;
  status: string;
}

export interface WorkflowActionEditRecordProperty {
  _id: string;
  name: string;
  value: string;
}

export interface WorkflowActionEditRecordDto extends WorkflowActionBase {
  actionType: WorkflowActionType.EDIT_RECORD;
  properties: WorkflowActionEditRecordProperty[];
}
export enum WorkflowWhenEventType {
  AD_INTERACTION = 'AD_INTERACTION',
  EMAIL_REPLY = 'EMAIL_REPLY',
  FORM_SUBMITTED = 'FORM_SUBMITTED',
}

export enum WorkflowWhenFiltersType {
  PROPERTY = 'PROPERTY',
  ADS_SEARCH = 'ADS_SEARCH',
  IN_LIST = 'IN_LIST',
}

interface WorkflowWhenBase {
  _id: string;
  whenType: WorkflowWhenType;
}
interface WorkflowWhenEvent extends WorkflowWhenBase {
  _id: string;
  whenType: WorkflowWhenType.EVENT;
  eventType: WorkflowWhenEventType;
  filter?: SweepCondition;
}

export interface WorkflowWhenFilters extends WorkflowWhenBase {
  _id: string;
  whenType: WorkflowWhenType.FILTERS;
  filterType: WorkflowWhenFiltersType;
  filter: SweepCondition;
}

export type WorkflowAction = WorkflowActionEnrollCampaignDto | WorkflowActionEditRecordDto;
export type WorkflowWhen = WorkflowWhenFilters | WorkflowWhenEvent;

interface WorkflowDetailDto {
  when: WorkflowWhen;
  actions: WorkflowAction[];
  onlyEnrollsManually: boolean;
}

export interface WorkflowBaseDto {
  id?: string;
  name: string;
  workflowDetails: WorkflowDetailDto;
  objectName: string;
}

export interface WorkflowCreateDto {
  name: string;
  workflowDetails: WorkflowDetailDto;
  objectName: string;
}

export interface WorkflowUpdateDto {
  name: string;
  workflowId: string;
  workflowDetails: WorkflowDetailDto;
}

export interface WorkflowDto extends Required<WorkflowBaseDto> {
  hubspotWorkflowId: string;
  accountId: string;
  isActive: boolean;
  createdById: string;
  createdAt: Date;
  updatedById: string;
  updatedAt: Date;
}

interface AbstractHubspotRuleBuilderWhen {
  whenType: WorkflowWhenType;
}

interface HubspotRuleBuilderWhenEvent extends AbstractHubspotRuleBuilderWhen {
  whenType: WorkflowWhenType.EVENT;
  eventType: WorkflowWhenEventType;
}

interface HubspotRuleBuilderWhenFilter extends AbstractHubspotRuleBuilderWhen {
  whenType: WorkflowWhenType.FILTERS;
}

export type HubspotRuleBuilderWhen = HubspotRuleBuilderWhenEvent | HubspotRuleBuilderWhenFilter;
