import { Box, Typography } from '@mui/material';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { ArrowLeft } from '@sweep-io/sweep-design/dist/icons';
import { CustomWebhookInterface, useWebhooksContext } from './WebhooksContext';
import { useIntegrations } from '../../../hooks/useIntegrations';
import { useMemo } from 'react';
import { CustomIntegrationAuthenticationType } from './WebhookContent';
import usePermission from '../../common/permissions/usePermission';

export const WebhooksHeader = () => {
  const { setWebhooks, setEditWebhook, editWebhook } = useWebhooksContext();
  const { createCustomIntegration, updateCustomIntegration } = useIntegrations();
  const [isAllowedBtnCreate] = usePermission(['create:data-integration:custom-integrations']);

  const isIntegrationFormValid = useMemo(() => {
    if (editWebhook) {
      if (editWebhook.name && editWebhook.baseUrl && editWebhook.authentication) {
        if (!editWebhook?.id) {
          const authentication = editWebhook.authentication;
          if (
            authentication?.type === CustomIntegrationAuthenticationType.BASIC ||
            authentication?.type === CustomIntegrationAuthenticationType.API_KEY
          ) {
            if (authentication?.password && authentication?.key) {
              return true;
            }
          } else if (authentication?.password) {
            return true;
          }
        }
        return true;
      }
    }
    return false;
  }, [editWebhook]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton
            variant="flat"
            onClick={() => {
              setWebhooks(false);
              setEditWebhook(undefined);
            }}
          >
            <ArrowLeft />
          </IconButton>
          <Typography variant="h2">Add new integration</Typography>
        </Box>
        <Box>
          <Button
            size="small"
            disabled={!isIntegrationFormValid || !isAllowedBtnCreate}
            onClick={() => {
              if (editWebhook?.id) {
                updateCustomIntegration(editWebhook as CustomWebhookInterface);
              } else {
                createCustomIntegration(editWebhook as CustomWebhookInterface);
              }
              setWebhooks(false);
              setEditWebhook(undefined);
            }}
          >
            Done
          </Button>
        </Box>
      </Box>
    </>
  );
};
