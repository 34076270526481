import { ChatCompletionRequestMessageRole } from '@server/ai';
import { Fragment, ReactNode } from 'react';
import { AiChatAssistantMessageBox } from './message-atoms/AiChatAssistantMessageBox';
import { AiChatUserMessageBox } from './message-atoms/AiChatUserMessageBox';
import { AiChatMessage } from './aiChatTypes';
import usePaywall from '../../dashboard/paywall/usePaywall';
import AiChatPaywallBlockMessage from './AiChatPaywallBlockMessage';
import { Stack } from '@mui/material';
import { useUnhappyStates } from '../../pages/environments/useUnhappyStates';
import AiChatNoOrgConnectedMessage from './AiChatNoOrgConnectedMessage';
import AiChatInitialFetchMessage from './AiChatInitialFetchMessage';
import { highlightMatchInMarkdown } from '../../../lib/highlightMatchInMarkdown';

interface AiChatMessagesProps {
  scrollToBottom?: () => void;
  messages: AiChatMessage[];
  isLoading?: boolean;
  errorMsg?: string; //this property should be deprecated
  introMessage?: AiChatMessage;
  searchTermToHighlight?: string;
  assistantAvatar?: ReactNode;
}

export const AiChatMessages = ({
  scrollToBottom,
  messages,
  isLoading,
  errorMsg,
  introMessage,
  searchTermToHighlight,
  assistantAvatar,
}: AiChatMessagesProps) => {
  const { isPaywallActive } = usePaywall();
  const { noConnectedOrg, isInitialFetch } = useUnhappyStates();

  const userMessagesCount = messages.filter(
    (msg) => msg.role === ChatCompletionRequestMessageRole.USER,
  ).length;

  const shouldAddAutoResponse = isPaywallActive || noConnectedOrg || isInitialFetch;
  const showAutoResponse = shouldAddAutoResponse && userMessagesCount > 0;

  const messagesAfterPaywall = shouldAddAutoResponse
    ? messages.filter((msg) => msg.role !== ChatCompletionRequestMessageRole.ASSISTANT)
    : messages;

  const messagesToRender = introMessage
    ? [introMessage].concat(messagesAfterPaywall)
    : messagesAfterPaywall;

  return (
    <Stack gap={2}>
      {messagesToRender.map((message, idx) => {
        const content = searchTermToHighlight
          ? highlightMatchInMarkdown(message.content, searchTermToHighlight)
          : message.content;
        switch (message.role) {
          case ChatCompletionRequestMessageRole.USER:
            return (
              <AiChatUserMessageBox
                key={`user_message_${idx}`}
                content={content}
                scrollToBottom={scrollToBottom}
              />
            );

          case ChatCompletionRequestMessageRole.ASSISTANT:
            return (
              <AiChatAssistantMessageBox
                key={`assistant_message_${idx}`}
                content={content}
                scrollToBottom={scrollToBottom}
                errorState={!!message.isError}
                avatar={assistantAvatar}
              />
            );

          default:
            return <Fragment key={`no_message_${idx}`} />;
        }
      })}

      {!!errorMsg && !isPaywallActive && (
        <AiChatAssistantMessageBox
          key="error-assistant"
          content={errorMsg}
          scrollToBottom={scrollToBottom}
          errorState
          avatar={assistantAvatar}
        />
      )}

      {isLoading && !isPaywallActive && (
        <AiChatAssistantMessageBox
          isLoading
          scrollToBottom={scrollToBottom}
          content=""
          avatar={assistantAvatar}
        />
      )}

      {showAutoResponse && isPaywallActive && (
        <AiChatAssistantMessageBox
          content={<AiChatPaywallBlockMessage />}
          scrollToBottom={scrollToBottom}
          avatar={assistantAvatar}
        />
      )}
      {showAutoResponse && !isPaywallActive && noConnectedOrg && (
        <AiChatAssistantMessageBox
          content={<AiChatNoOrgConnectedMessage />}
          scrollToBottom={scrollToBottom}
          avatar={assistantAvatar}
        />
      )}
      {showAutoResponse && !isPaywallActive && isInitialFetch && (
        <AiChatAssistantMessageBox
          content={<AiChatInitialFetchMessage />}
          scrollToBottom={scrollToBottom}
          avatar={assistantAvatar}
        />
      )}
    </Stack>
  );
};
