import { useEffect } from 'react';
import { useConfigurationCanvasContext } from './ConfigurationCanvasContext';
import { useSave } from './useSave';
import { areFunnelMapsEquivalent } from './areFunnelMapsEquivalent';
import cloneDeep from 'lodash/cloneDeep';
import { useErrorHandling } from '../../../hooks/useErrorHandling';
import { updateRecordTypesFunnelsAndHubspotFunnels } from './updateRecordTypesFunnelsAndHubspotFunnels';
import { selectProductionCrmOrg } from '../environments/environmentsReducer';
import { useSelector } from 'react-redux';

export const useAutoSave = () => {
  const { lastAttemptedFunnelMapSave, setCanvasFunnelMap, canvasFunnelMap, canvasCrmOrgId } =
    useConfigurationCanvasContext();
  const isProductionOrg = useSelector(selectProductionCrmOrg)?.id === canvasCrmOrgId;

  const { errorHandlingBuilder } = useErrorHandling();
  const { saveFunnelMapIfAllowed } = useSave();

  useEffect(() => {
    if (canvasFunnelMap) {
      const { changes, funnelMap: newFunnelMap } = updateRecordTypesFunnelsAndHubspotFunnels(
        cloneDeep(canvasFunnelMap),
        isProductionOrg,
      );

      // TODO: Add notifications when they are ready in design

      // changes.forEach((change) => {
      //   addNotification({
      //     message: change,
      //     variant: SweepNotificationVariant.Default,
      //     keepOpen: false,
      //   });
      // });

      if (changes.length > 0) {
        setCanvasFunnelMap(newFunnelMap);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasFunnelMap?.recordTypesData]);

  useEffect(() => {
    if (!canvasFunnelMap) return;

    const funnelMapHasChanges = !areFunnelMapsEquivalent(
      canvasFunnelMap,
      lastAttemptedFunnelMapSave,
    );

    if (funnelMapHasChanges && canvasFunnelMap) {
      errorHandlingBuilder()
        .withErrorNotification('Error updating environment view')
        .execute(async () => await saveFunnelMapIfAllowed(canvasFunnelMap));
    }
  }, [
    errorHandlingBuilder,
    lastAttemptedFunnelMapSave,
    saveFunnelMapIfAllowed,
    canvasFunnelMap,
    canvasCrmOrgId,
  ]);
};
