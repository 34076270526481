import AiChatButtonEntryPoint from '../../aiAgentCenter/AiChatButtonEntryPoint';
import useChatsEntryPoint from '../../aiAgentCenter/useChatsEntryPoint';

const AiChatDashboardButton = () => {
  const { toggleIsOpened, isOpened } = useChatsEntryPoint(); //TODO use directly useAiAgentCenter SWEEP-5978

  return (
    <AiChatButtonEntryPoint
      onClick={() => {
        if (!isOpened) {
          toggleIsOpened();
        }
      }}
      variant="primary"
    />
  );
};
export default AiChatDashboardButton;
