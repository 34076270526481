import Box from '@mui/material/Box';
import { IconButton } from '@sweep-io/sweep-design';
import { Plus, Delete } from '@sweep-io/sweep-design/dist/icons';

interface AddAndDeleteRowButtonsProps {
  deleteButtonDisabled?: boolean;
  addButtonDisabled?: boolean;
  onDelete?: () => void;
  onAdd?: () => void;
}

export const AddAndDeleteRowButtons = ({
  onDelete,
  onAdd,
  deleteButtonDisabled,
  addButtonDisabled,
}: AddAndDeleteRowButtonsProps) => {
  return (
    <>
      <Box>
        <IconButton disabled={deleteButtonDisabled} onClick={onDelete} variant="flat" size="small">
          <Delete />
        </IconButton>
      </Box>

      <Box>
        <IconButton disabled={addButtonDisabled} onClick={onAdd} variant="flat" size="small">
          <Plus />
        </IconButton>
      </Box>
    </>
  );
};
