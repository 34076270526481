import { ChatShort } from '../common/ai-chat/aiChatTypes';
import { useChatSections } from './useChatSections';
import ChatsSectionLayout from './ChatsSectionLayout';
import AiAgentChatListItem from './AiAgentChatListItem';
import CollapsibleSectionLayout from './CollapsibleSectionLayout';

export const AiAgentChatsSections = ({
  activeChatId,
  setActiveChatId,
  chats,
}: {
  activeChatId?: string;
  setActiveChatId: (id: string) => Promise<void>;
  chats: ChatShort[];
}) => {
  const sections = useChatSections(chats);

  return (
    <>
      {sections.map((section) => {
        const empty = section.items.length === 0;
        if (empty) return null;
        const { isCollapsible } = section;
        const SectionComponent = isCollapsible ? CollapsibleSectionLayout : ChatsSectionLayout;
        return (
          <SectionComponent key={section.title} title={section.title}>
            {section.items.map((chat) => (
              <AiAgentChatListItem
                key={chat.id}
                chat={chat}
                isActive={activeChatId === chat.id}
                onClick={() => setActiveChatId(chat.id)}
                disableActions={false}
              />
            ))}
          </SectionComponent>
        );
      })}
    </>
  );
};
