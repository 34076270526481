import { useState } from 'react';
import { BaseCampaignMemberStatus } from './campaignTypes';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import { Button, Checkbox, Typography } from '@sweep-io/sweep-design';
import { TextFieldSelector } from '../../../../common/rule-builder/selectors/TextFieldSelector';
import { useCampaignsApiFacade } from '../../../../../apis/facades/useCampaignsApiFacade';
import { useEffectFetchCampaignMemberStatuses } from '../../campaign-member-status/useEffectFetchCampaignMemberStatuses';

export const NewMemberStatusDialog = ({
  onClose,
  campaignId,
}: {
  onClose: () => any;
  campaignId: string;
}) => {
  const { post_campaign_member_status } = useCampaignsApiFacade();
  const { campaignMemberStatuses, setCampaignMemberStatuses } =
    useEffectFetchCampaignMemberStatuses(campaignId);

  const getValidationError = () => {
    if (campaignMemberStatus.Label.length === 0) {
      return 'The member status name is required';
    }
    if (campaignMemberStatuses?.find((status) => status.Label === campaignMemberStatus.Label)) {
      return 'This member status name already exists';
    }
  };
  const [deploying, setDeploying] = useState(false);
  const onSave = async (campaignMemberStatus: BaseCampaignMemberStatus) => {
    setDeploying(true);
    const newCampaignMemberStatus = await post_campaign_member_status(campaignMemberStatus);
    setCampaignMemberStatuses((campaignMemberStatuses) => [
      ...campaignMemberStatuses,
      newCampaignMemberStatus,
    ]);
    setDeploying(false);
    onClose();
  };

  const [campaignMemberStatus, setCampaignMemberStatus] = useState<BaseCampaignMemberStatus>({
    CampaignId: campaignId,
    Label: '',
    IsDefault: false,
    HasResponded: false,
  });

  const [submitted, setSubmitted] = useState(false);

  const displayError = submitted ? getValidationError() : '';

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h1">New Campaign Member Status</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          width: 720,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <TextFieldSelector
          id="member-status"
          onChange={(value: string) =>
            setCampaignMemberStatus((campaignMemberStatus) => ({
              ...campaignMemberStatus,
              Label: value,
            }))
          }
          label="Member Status"
          fullWidth
          initialValue={campaignMemberStatus.Label}
          isRequired
          error={!!displayError}
          helperText={displayError}
          shouldBeDisabled={deploying}
        />
        <FormControlLabel
          disabled={deploying}
          label={<Typography variant="body">Responded</Typography>}
          control={
            <Checkbox
              checked={campaignMemberStatus.HasResponded}
              onChange={(e) =>
                setCampaignMemberStatus((campaignMemberStatus) => ({
                  ...campaignMemberStatus,
                  HasResponded: e.target.checked,
                }))
              }
              disabled={deploying}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={deploying}>
          Cancel
        </Button>
        <Button
          disabled={campaignMemberStatus.Label.length === 0 || deploying}
          onClick={() => {
            setSubmitted(true);
            if (getValidationError()) {
              return;
            }
            onSave(campaignMemberStatus);
          }}
        >
          Deploy
        </Button>
      </DialogActions>
    </Dialog>
  );
};
