import * as React from "react";
const SvgAgentDocumentationIcon = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "avatar" }, /* @__PURE__ */ React.createElement("circle", { id: "Ellipse 73", cx: 15, cy: 15, r: 15, fill: "#FFD28D", style: {
  fill: "#FFD28D",
  fill: "color(display-p3 1.0000 0.8235 0.5529)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "Rectangle 1553", d: "M6.64024 8.81396C6.99454 8.06844 7.60514 7.4752 8.36057 7.14254L13.6138 4.82918C14.497 4.44025 15.503 4.44025 16.3862 4.82918L21.6394 7.14254C22.3949 7.4752 23.0055 8.06844 23.3598 8.81396L25.5484 13.4191C25.9923 14.3533 25.9923 15.4377 25.5484 16.3719L23.3598 20.977C23.0055 21.7226 22.3949 22.3158 21.6394 22.6485L16.3862 24.9618C15.503 25.3508 14.497 25.3508 13.6138 24.9618L8.36057 22.6485C7.60514 22.3158 6.99454 21.7226 6.64024 20.977L4.45164 16.3719C4.00767 15.4377 4.00767 14.3533 4.45164 13.4191L6.64024 8.81396Z", fill: "#FFF0D9", style: {
  fill: "#FFF0D9",
  fill: "color(display-p3 1.0000 0.9412 0.8510)",
  fillOpacity: 1
} })));
export default SvgAgentDocumentationIcon;
