import useCopilotChats from './useCopilotChats';
import ChatListItem from './ChatListItem';
import { ChatShort } from '../common/ai-chat/aiChatTypes';
import { ReactNode } from 'react';

const ChatListItemConnected = ({
  chat,
  isActive,
  disableActions,
  onClick,
  title,
  subtitle,
}: {
  title?: ReactNode; //relevant for search results
  subtitle?: ReactNode; //relevant for search results
  chat: ChatShort;
  isActive: boolean;
  disableActions?: boolean;
  onClick: () => void;
}) => {
  const { renameChat, deleteChat, togglePin } = useCopilotChats();

  return (
    <ChatListItem
      key={chat.id}
      name={chat.name}
      title={title}
      subtitle={subtitle}
      isActive={isActive}
      isLoading={chat.isLoading}
      isFavorite={chat.isFavorite}
      onClick={onClick}
      disableActions={disableActions}
      onDelete={() => deleteChat(chat.id)}
      onRename={(name) => renameChat({ chatId: chat.id, name })}
      togglePin={() => togglePin(chat.id)}
      isAnimateName={!!chat.isAnimateName}
    />
  );
};

export default ChatListItemConnected;
