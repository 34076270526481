import { Autocomplete, Box, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { useRunOnce } from '../common/useRunOnce';
import { ChevronDown } from '@sweep-io/sweep-design/dist/icons';
import { InfoTooltip } from '../common/InfoTooltip';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import SweepSelect from '../common/SweepSelect';
import { AutomationDataActionType } from '../../types/enums/AutomationTypes';
import { useRefForMultipleRuleBuilders } from '../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { SweepFieldsRuleBuilder } from '../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { useReports } from '../../hooks/useReports';

interface SalesforceReportSelectorProps {
  readonly?: boolean;
  automationJson: DeepPartial<AutomationStructureNew>;
  callBack: (data: DeepPartial<AutomationStructureNew>) => void;
  crmOrgId: string;
}
export type SfdcReportAttributes = {
  id: string;
  name: string;
  apiName: string;
};

export type SfdcReportColumnsAttributes = {
  dataType: string;
  fieldApiName: string;
  label: string;
  name: string;
  isLookup: boolean;
};

export const SalesforceReportSelector = ({
  readonly,
  automationJson,
  callBack,
  crmOrgId,
}: SalesforceReportSelectorProps) => {
  const ruleBuilderRef = useRefForMultipleRuleBuilders();
  const { getSfdcTabularReports, getSfdcReportColumnsIds } = useReports();

  const [sfReports, setSfReports] = useState<SfdcReportAttributes[]>();
  const [loading, setLoading] = useState(true);
  const [sfReportColumns, setSfReportColumns] = useState<SfdcReportColumnsAttributes[]>();

  const [actionDataType, setActionDataType] = useState(
    automationJson.automationDetails?.getDataAction?.actionType,
  );

  useRunOnce(async () => {
    try {
      const types = (await getSfdcTabularReports()) ?? [];
      setSfReports(types);
      const existingReport = (
        automationJson.automationDetails?.getDataAction as AutomationDataActionReportResult
      )?.actionParams?.reportName;

      if (existingReport) {
        const currReportApi = types.find((el: SfdcReportAttributes) => el.name === existingReport);
        currReportApi && handleGetReportFields(currReportApi.id);
      }
    } catch {
      setSfReports([]);
    }
    setLoading(false);
  });

  const handleOnChange = (
    value?: AutomationDataActionParamsReportResults | SweepCondition,
    type?: AutomationDataActionType,
  ) => {
    const dataActionType = !type ? AutomationDataActionType.REPORT_RESULTS : type;

    const dataAction = {
      actionType: dataActionType,
      actionParams:
        dataActionType === AutomationDataActionType.REPORT_RESULTS
          ? value
          : {
              criteria: value as SweepCondition,
            },
    };

    callBack({
      ...automationJson,
      automationDetails: {
        ...automationJson.automationDetails,
        getDataAction: {
          ...(dataAction as AutomationDataActionReportResult | AutomationDataActionQueryResult),
        },
      },
    });
  };

  const handleGetReportFields = async (reportId: string) => {
    const idTypes = await getSfdcReportColumnsIds(reportId);
    setSfReportColumns(idTypes);
    return idTypes?.length == 1 ? idTypes[0].label : undefined;
  };

  return (
    <>
      <Box
        sx={{
          height: '40px',
          mb: 2,
          display: 'flex',
          gap: 1.5,
          alignItems: 'center',
          '& div[role="presentation"].MuiAutocomplete-popper .MuiPaper-root .MuiMenuItem-root': {
            pr: 6,
          },
        }}
      >
        <Typography variant="body" textAlign="center">
          Find records to assign
        </Typography>

        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '120px', marginRight: '8px' },
          }}
          menuMaxHeight={'360px'}
          SelectProps={{
            placeholder: 'Choose where to find records',
            disabled: readonly,
            value: automationJson.automationDetails?.getDataAction?.actionType,
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value as AutomationDataActionType;
              setActionDataType(val);
              handleOnChange(undefined, val);
            },
            renderValue: (val: any) => {
              if (val) {
                return val === AutomationDataActionType.REPORT_RESULTS
                  ? 'in Salesforce report'
                  : 'that meet the following criteria';
              }
            },
          }}
        >
          <MenuItem
            key={AutomationDataActionType.REPORT_RESULTS}
            value={AutomationDataActionType.REPORT_RESULTS}
          >
            In Salesforce report
          </MenuItem>
          <MenuItem
            key={AutomationDataActionType.QUERY_RESULTS}
            value={AutomationDataActionType.QUERY_RESULTS}
          >
            Records that meet the following criteria
          </MenuItem>
        </SweepSelect>
        {actionDataType === AutomationDataActionType.REPORT_RESULTS && (
          <>
            {loading && <CenteredCircularProgress circularProgressProps={{ size: '20px' }} />}
            {!loading && sfReports && (
              <Autocomplete
                sx={{
                  minWidth: '160px',
                  '& .MuiAutocomplete-inputRoot': {
                    rowGap: '8px',
                    paddingTop: '6px',
                    paddingBottom: '6px',

                    '& fieldset': {
                      borderWidth: `thin solid ${colors.grey[300]}`,
                    },
                    '& .MuiAutocomplete-input': {
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  },
                  '#sf-reports': {
                    width: 'fit-content',
                  },
                }}
                options={sfReports}
                disablePortal
                value={
                  sfReports.find(
                    (el) =>
                      el.name ===
                      (
                        automationJson.automationDetails
                          ?.getDataAction as AutomationDataActionReportResult
                      )?.actionParams?.reportName,
                  ) ?? undefined
                }
                onChange={async (e, val) => {
                  const reportField = await handleGetReportFields(val.id);
                  handleOnChange(
                    {
                      reportName: val.name,
                      columnIdFieldLabel: reportField,
                    },
                    actionDataType,
                  );
                }}
                renderInput={(params) => <TextField {...params} placeholder="Choose report" />}
                popupIcon={<ChevronDown />}
                getOptionLabel={(options) => options.name}
                isOptionEqualToValue={(option: any, value) => option?.name === value?.name}
                id="sf-reports"
                disabled={readonly}
                disableClearable
                renderOption={(props, option: SfdcReportAttributes) => {
                  return (
                    <MenuItem {...props} key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  );
                }}
              />
            )}
            <InfoTooltip title="Report results are limited to 2,000 records due to Salesforce limitations. Ensure that the selected report is of the 'Tabular' type and includes a column for the record ID." />
          </>
        )}
      </Box>
      {actionDataType === AutomationDataActionType.REPORT_RESULTS &&
        sfReportColumns &&
        sfReportColumns?.length > 1 && (
          <Box sx={{ height: '40px', mb: 2, display: 'flex', gap: 1.5, alignItems: 'center' }}>
            <Typography variant="body" textAlign="center">
              Identify records by field
            </Typography>
            <SweepSelect
              FormControlProps={{
                sx: { minWidth: '120px', marginRight: '8px' },
              }}
              SelectProps={{
                placeholder: 'Choose ID field',
                disabled: readonly,
                value:
                  (
                    automationJson.automationDetails
                      ?.getDataAction as AutomationDataActionReportResult
                  )?.actionParams?.columnIdFieldLabel ?? '',
                onChange: (event: SelectChangeEvent<unknown>) => {
                  const val = event.target.value as string;

                  handleOnChange(
                    {
                      reportName: (
                        automationJson.automationDetails
                          ?.getDataAction as AutomationDataActionReportResult
                      )?.actionParams?.reportName,
                      columnIdFieldLabel: val,
                    },
                    actionDataType,
                  );
                },
              }}
            >
              {sfReportColumns.map((elem) => {
                return (
                  <MenuItem key={elem.fieldApiName} value={elem.label}>
                    {elem?.label}
                  </MenuItem>
                );
              })}
            </SweepSelect>
          </Box>
        )}
      {actionDataType === AutomationDataActionType.QUERY_RESULTS && (
        <Box sx={{ mb: 2 }}>
          <SweepFieldsRuleBuilder
            hideSelectValuesFromRecord={true}
            nestedSelectorFilterBy={(field) => field.fieldType !== SweepFieldTypes.LongTextArea}
            ref={ruleBuilderRef}
            readOnly={readonly}
            crmOrgId={crmOrgId ?? ''}
            objectType={automationJson.objectName ?? ''}
            sweepCondition={
              (automationJson.automationDetails?.getDataAction as AutomationDataActionQueryResult)
                ?.actionParams?.criteria
            }
            onChange={(val) => handleOnChange(val, actionDataType)}
            disableResolvePolymorphic
          />
        </Box>
      )}
    </>
  );
};
