import RightPanel from '../common/RightPanel';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import CopilotChatsExpanded from './CopilotChatsExpanded';
import CopilotChatsCollapsed from './CopilotChatsCollapsed';
import useCopilotChats from './useCopilotChats';

const CopilotChats = ({
  closeCopilot,
  isOpened,
}: {
  closeCopilot: () => void;
  isOpened: boolean;
}) => {
  const { isExpanded, toggleIsExpanded, createChat } = useCopilotChats();

  const newButton = (
    <Button startIconName="Plus" variant="outlined" onClick={() => createChat()} size="small">
      New chat
    </Button>
  );

  const toggleExpandButton = (
    <Button
      startIconName={isExpanded ? 'Minimize' : 'Expand'}
      variant="outlined"
      onClick={toggleIsExpanded}
      size="small"
    >
      {isExpanded ? 'Minimize' : 'Expand'}
    </Button>
  );

  const closeButton = (
    <IconButton onClick={closeCopilot} variant="flat" size="small">
      <Close />
    </IconButton>
  );

  return (
    <RightPanel isOpened={isOpened} isExpanded={isExpanded}>
      {isExpanded && (
        <CopilotChatsExpanded
          newButton={newButton}
          toggleExpandButton={toggleExpandButton}
          closeButton={closeButton}
        />
      )}
      {!isExpanded && (
        <CopilotChatsCollapsed
          newButton={newButton}
          toggleExpandButton={toggleExpandButton}
          closeButton={closeButton}
        />
      )}
    </RightPanel>
  );
};

export default CopilotChats;
