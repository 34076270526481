import { Tooltip, Box } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';
import { useRef, useState, useEffect, ReactNode } from 'react';

const isTextClamped = (elm: any) => elm.scrollHeight > elm.clientHeight;

interface ClampedTextProps {
  text: string | number | ReactNode;
  tooltip?: string; //if different than text, if not passed it's the same as text
  typographyVariant?: SweepTypographyVariants;
  endAdornment?: React.ReactNode;
  textColor?: string;
  continuouslyListenToHover?: boolean;
}

export const ClampedText = ({
  text,
  tooltip,
  typographyVariant = 'body',
  endAdornment,
  textColor,
  continuouslyListenToHover,
}: ClampedTextProps) => {
  const ref = useRef();
  const [isClamped, setIsClamped] = useState(false);

  useEffect(() => {
    setIsClamped(isTextClamped(ref.current));
  }, []);

  return (
    <>
      {/* TODO SWEEP-4694 */}
      <Tooltip
        placement="bottom-start"
        title={tooltip ?? text}
        disableHoverListener={continuouslyListenToHover ? false : !isClamped}
      >
        <Box overflow="hidden">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              minWidth: 0,
              //TODO add support in design system SWEEP-4335
              '& .MuiTypography-root': {
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
                color: textColor ? textColor + ' !important' : 'inherit',
              },
            }}
            ref={ref}
          >
            <Typography
              color={textColor}
              whiteSpace="break-spaces"
              variant={typographyVariant}
              ellipsis={isClamped}
            >
              {text}
            </Typography>
            {endAdornment}
          </Box>
        </Box>
      </Tooltip>
    </>
  );
};
