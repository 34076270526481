import { useDispatch, useSelector } from 'react-redux';
import {
  setHubspotOrgs,
  selectHubspotOrgs,
  selectIsLoadingHsOrgs,
  addOrUpdateHubspotOrg,
  selectConnectedHubspotOrg,
  deleteHubspotOrg as deleteHubspotOrgAction,
  startLoadingHubspotFields,
  loadHubspotFields as loadHubspotFieldsAction,
  selectHubspotOrgsHasLoaded,
  startLoadingHubspotForms,
  loadHubspotForms as loadHubspotFormsAction,
  loadHubspotPages as loadHubspotPagesAction,
  loadHubspotEmails as loadHubspotEmailsAction,
  setLoadingHubspotFieldsError,
  setLoadingHubspotFormsError,
  setLoadingHubspotPagesError,
  startLoadingHubspotPages,
  startLoadingHubspotEmails,
  setLoadingHubspotEmailsError,
  startLoadingHubspotMembershipLists,
  loadHubspotMembershipLists,
  setLoadingHubspotMembershipListsError,
} from '../../../../reducers/hubspotReducer';
import { useHubspotApiFacade } from '../../../../apis/facades/useHubspotApiFacade';
import { useRunOnce } from '../../../common/useRunOnce';
import { useCallback, useMemo } from 'react';
import { HubspotField } from './hubspot.types';
import { telemetry } from '../../../../telemetry';
import { HubspotEventComponentType } from '@server/hubspot';

const SERVER_URL = import.meta.env.VITE_SERVER_URL;

export const useHubspot = () => {
  const dispatch = useDispatch();
  const {
    create_hubspot_org,
    delete_hubspot_org,
    get_event_components,
    get_hubspot_fields,
    get_hubspot_org,
    get_hubspot_orgs,
    get_membershipLists,
  } = useHubspotApiFacade();
  const hubspotOrgs = useSelector(selectHubspotOrgs);
  const connectedHubspotOrg = useSelector(selectConnectedHubspotOrg);
  const isLoading = useSelector(selectIsLoadingHsOrgs);
  const hasLoadedHubspotOrgs = useSelector(selectHubspotOrgsHasLoaded);

  useRunOnce(() => {
    if (!hubspotOrgs && !isLoading) {
      getHubspotOrgs();
    }
  });

  const createHubspotOrg = useCallback(async () => {
    const hsOrgs = await create_hubspot_org();
    dispatch(addOrUpdateHubspotOrg(hsOrgs));
    return hsOrgs;
  }, [create_hubspot_org, dispatch]);

  const getHubspotOrgs = useCallback(async () => {
    try {
      const hsOrgs = await get_hubspot_orgs();
      dispatch(setHubspotOrgs(hsOrgs));
      return hsOrgs;
    } catch (e) {
      telemetry.captureError(e);
    }
  }, [dispatch, get_hubspot_orgs]);

  const getHubspotOrg = useCallback(
    async (hsOrgId: string) => {
      try {
        const hsOrg = await get_hubspot_org(hsOrgId);
        dispatch(addOrUpdateHubspotOrg(hsOrg));
        return hsOrg;
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [dispatch, get_hubspot_org],
  );

  const getHubspotRedirectToOAuthUrl = useCallback(
    ({ hubspotOrgId }: { hubspotOrgId: string }) =>
      `${SERVER_URL}/hubspot-connector/redirect-to-oauth?data=${hubspotOrgId}`,
    [],
  );

  const deleteHubspotOrg = useCallback(
    async (hsOrgId: string) => {
      try {
        await delete_hubspot_org();
        if (hubspotOrgs?.length) {
          dispatch(deleteHubspotOrgAction({ hsOrgId }));
        }
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [delete_hubspot_org, dispatch, hubspotOrgs?.length],
  );

  const deleteConnectedHubspotOrg = useCallback(async () => {
    if (connectedHubspotOrg) {
      await deleteHubspotOrg(connectedHubspotOrg.id);
    }
  }, [connectedHubspotOrg, deleteHubspotOrg]);

  const loadHubspotFields = useCallback(
    async (hsOrgId: string, objectName: string) => {
      dispatch(startLoadingHubspotFields());
      try {
        let fields = await get_hubspot_fields(hsOrgId, objectName);

        fields = fields
          .filter(({ hidden }) => !Boolean(hidden))
          .map(
            ({
              description,
              fieldType,
              groupName,
              label,
              name,
              options,
              type,
              hidden,
              modificationMetadata,
            }) => ({
              description,
              fieldType,
              groupName,
              label,
              name,
              options,
              type,
              hidden,
              modificationMetadata,
            }),
          ) as HubspotField[];

        dispatch(loadHubspotFieldsAction(fields));
        return fields;
      } catch (e) {
        dispatch(setLoadingHubspotFieldsError(e));
        throw e;
      }
    },
    [dispatch, get_hubspot_fields],
  );

  const loadContactHubspotFieldsForConnectedHsOrg = useCallback(async () => {
    if (connectedHubspotOrg) {
      await loadHubspotFields(connectedHubspotOrg.id, 'contacts');
    }
  }, [connectedHubspotOrg, loadHubspotFields]);

  const loadHubspotForms = useCallback(
    async (hsOrgId: string) => {
      dispatch(startLoadingHubspotForms());
      try {
        const forms = await get_event_components(hsOrgId, HubspotEventComponentType.FORM);
        dispatch(loadHubspotFormsAction(forms.components));
      } catch (e) {
        dispatch(setLoadingHubspotFormsError(e));
        throw e;
      }
    },
    [dispatch, get_event_components],
  );

  const loadHubspotPages = useCallback(
    async (hsOrgId: string) => {
      dispatch(startLoadingHubspotPages());
      try {
        const pages = await get_event_components(hsOrgId, HubspotEventComponentType.PAGE);
        dispatch(loadHubspotPagesAction(pages.components));
      } catch (e) {
        dispatch(setLoadingHubspotPagesError(e));
        throw e;
      }
    },
    [dispatch, get_event_components],
  );

  const loadHubspotEmails = useCallback(
    async (hsOrgId: string) => {
      try {
        dispatch(startLoadingHubspotEmails());
        const emails = await get_event_components(hsOrgId, HubspotEventComponentType.EMAIL);
        dispatch(loadHubspotEmailsAction(emails.components));
      } catch (e) {
        dispatch(setLoadingHubspotEmailsError(e));
        throw e;
      }
    },
    [dispatch, get_event_components],
  );

  const loadMembershipLists = useCallback(async () => {
    dispatch(startLoadingHubspotMembershipLists());
    try {
      const membershipLists = await get_membershipLists();
      dispatch(loadHubspotMembershipLists(membershipLists));

      return membershipLists;
    } catch (e) {
      dispatch(setLoadingHubspotMembershipListsError(e));
      throw e;
    }
  }, [dispatch, get_membershipLists]);

  return useMemo(
    () => ({
      hubspotOrgs,
      connectedHubspotOrg,
      hasLoadedHubspotOrgs,
      createHubspotOrg,
      getHubspotOrgs,
      getHubspotOrg,
      getHubspotRedirectToOAuthUrl,
      deleteConnectedHubspotOrg,
      loadHubspotFields,
      loadContactHubspotFieldsForConnectedHsOrg,
      loadHubspotForms,
      loadHubspotPages,
      loadHubspotEmails,
      loadMembershipLists,
    }),
    [
      connectedHubspotOrg,
      createHubspotOrg,
      deleteConnectedHubspotOrg,
      getHubspotOrg,
      getHubspotOrgs,
      getHubspotRedirectToOAuthUrl,
      hasLoadedHubspotOrgs,
      hubspotOrgs,
      loadContactHubspotFieldsForConnectedHsOrg,
      loadHubspotEmails,
      loadHubspotFields,
      loadHubspotForms,
      loadHubspotPages,
      loadMembershipLists,
    ],
  );
};
