import { MenuItem, ListItemIcon, Stack } from '@mui/material';
import { SearchInput } from '../SearchInput';
import { useCallback, useState } from 'react';
import useLogoDevApiFacade, { LogoDevBrand } from '../../../apis/facades/useLogoDevApiFacade';
import { Typography } from '@sweep-io/sweep-design';
import SweepSelect from '../SweepSelect';
import { SearchSelectListSubheader } from '../SearchSelect';
import { LogoDevIcon } from './LogoDevIcon';

interface LogoDevSelectorProps {
  label?: string;
  onChange: (brand: LogoDevBrand | null) => void;
  brand?: LogoDevBrand | null;
  placeholder?: string;
}

const predefinedBrandList: LogoDevBrand[] = [
  { name: 'Salesloft', domain: 'salesloft.com' },
  { name: 'Outreach', domain: 'outreach.io' },
  { name: 'Apollo', domain: 'apollo.io' },
  { name: 'Snowflake', domain: 'snowflake.com' },
  { name: 'Gong', domain: 'gong.io' },
  { name: 'Zendesk', domain: 'zendesk.com' },
  { name: 'Oneflow', domain: 'oneflow.com' },
  { name: 'Docusign', domain: 'docusign.com' },
];

export const LogoDevSelector = ({ onChange, brand, label, placeholder }: LogoDevSelectorProps) => {
  const [search, setSearch] = useState<string>('');
  const [items, setItems] = useState<LogoDevBrand[]>(
    brand ? [brand, ...predefinedBrandList] : predefinedBrandList,
  );

  const { get_search } = useLogoDevApiFacade();
  const [abortController, setAbortController] = useState<AbortController>();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
  };

  const setItemsWithCurrentItem = useCallback(
    (items: LogoDevBrand[]) => {
      const currentItem = items.find((item) => item.domain === brand?.domain);
      const itemsAlreadyHasCurrentItem = items.some((item) => item.domain === brand?.domain);

      if (currentItem && !itemsAlreadyHasCurrentItem) {
        setItems([currentItem, ...items]);
      } else {
        setItems(items);
      }
    },
    [brand?.domain],
  );

  return (
    <SweepSelect
      label={label}
      MenuProps={{
        PaperProps: {
          onKeyDown: handleKeyDown,
        },
        autoFocus: false,
      }}
      SelectProps={{
        value: brand?.domain,
        autoFocus: true,
        onChange: (e) => {
          const selectedBrand = items.find((item) => item.domain === e.target.value);
          onChange(selectedBrand ?? null);
          if (selectedBrand) {
            const selectedBrandInPredefinedList = predefinedBrandList.find(
              (item) => item.domain === selectedBrand.domain,
            );
            if (selectedBrandInPredefinedList) {
              setItems(predefinedBrandList);
            } else {
              setItems([selectedBrand, ...predefinedBrandList]);
            }
          } else {
            setItems(predefinedBrandList);
          }
          setSearch('');
        },
        renderValue: (value) => {
          const selectedBrand = items.find((item) => item.domain === value);
          if (!selectedBrand) {
            return '';
          }
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <LogoDevIcon brand={selectedBrand} />
              <Typography variant="body">{selectedBrand.name}</Typography>
            </Stack>
          );
        },
        placeholder,
      }}
    >
      <SearchSelectListSubheader>
        <SearchInput
          withFixedMagnifyingGlassIcon
          placeholder="Search any system"
          value={search}
          onChange={async (e) => {
            setSearch(e.target.value);
            if (abortController) {
              abortController.abort();
            }
            const _newAbortController = new AbortController();
            setAbortController(_newAbortController);
            const _items = await get_search({
              query: { query: e.target.value },
              abortController: _newAbortController,
            });

            setItemsWithCurrentItem(_items);
          }}
          TextFieldProps={{
            onKeyDown: (e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            },
            onClick: (e) => {
              e.stopPropagation();
            },
          }}
        />
      </SearchSelectListSubheader>
      {items.map((item) => (
        <MenuItem key={item.domain} value={item.domain}>
          <ListItemIcon>
            <LogoDevIcon brand={item} />
          </ListItemIcon>
          <Typography variant="body">{item.name}</Typography>
        </MenuItem>
      ))}
    </SweepSelect>
  );
};
