import { useCallback } from 'react';
import { AutomationActionRow } from './automation-action-row/AutomationActionRow';

interface AutomationActionRowsProps {
  initialObjectName?: string;
  objectName?: string;
  onChange: (field: AutomationActionField[]) => any;
  cmOrgId: string;
  readonly?: boolean;
  fields: AutomationActionField[];
  hideRecordTypeIdField?: boolean;
  disableLookupItems: boolean;
  showCreatableFields?: boolean;
  excludeValueType?: (type: SelectorValueTypes) => boolean;
  showVerifiedUrlOption?: string;
  isShowWebhookOption?: boolean;
}

export const AutomationActionRows = ({
  initialObjectName,
  objectName,
  onChange,
  cmOrgId,
  readonly,
  fields,
  hideRecordTypeIdField,
  disableLookupItems,
  showCreatableFields,
  excludeValueType,
  showVerifiedUrlOption,
  isShowWebhookOption,
}: AutomationActionRowsProps) => {
  const _onChange = useCallback(
    (field: AutomationActionField, idx: number) => {
      const newFields = [...(fields || [])];
      if (newFields) {
        newFields[idx] = field;
        onChange(newFields);
      }
    },
    [fields, onChange],
  );
  const _onDelete = useCallback(
    (idx: number) => {
      const tempFields = [...(fields || [])];
      tempFields.splice(idx, 1);
      onChange(tempFields);
    },
    [fields, onChange],
  );
  const _onAdd = useCallback(
    (emptyField: AutomationActionField) => {
      let tempFields = fields || [];
      tempFields = [...tempFields, emptyField];
      onChange(tempFields);
    },
    [fields, onChange],
  );

  return (
    <>
      {fields.length === 0 && (
        <AutomationActionRow
          showVerifiedUrlOption={showVerifiedUrlOption}
          excludeValueType={excludeValueType}
          showCreatableFields={showCreatableFields}
          disableLookupItems={disableLookupItems}
          hideRecordTypeIdField={hideRecordTypeIdField}
          readonly={readonly}
          objectName={objectName}
          crmOrgId={cmOrgId}
          initialObjectName={initialObjectName}
          onChange={(field) => {
            onChange([field]);
          }}
          isShowWebhookOption={isShowWebhookOption}
        />
      )}
      {fields?.map((field, idx) => (
        <AutomationActionRow
          showVerifiedUrlOption={showVerifiedUrlOption}
          excludeValueType={excludeValueType}
          showCreatableFields={showCreatableFields}
          disableLookupItems={disableLookupItems}
          hideRecordTypeIdField={hideRecordTypeIdField}
          readonly={readonly}
          crmOrgId={cmOrgId}
          key={field._id}
          objectName={objectName}
          initialObjectName={initialObjectName}
          field={field}
          isShowWebhookOption={isShowWebhookOption}
          onChange={(field) => {
            _onChange(field, idx);
          }}
          onDelete={() => {
            _onDelete(idx);
          }}
          onAdd={_onAdd}
        />
      ))}
    </>
  );
};
